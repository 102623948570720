import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IForgotPasswordInitialState } from '../../interfaces/initial-state-interfaces/IForgotPasswordInitialState';
import { IForgotPasswordRequest } from '../../interfaces/services-interfaces/IForgotPasswordRequest';
import { forgotPasswordApi } from '../../services/forgot-password.api';

const initialState: IForgotPasswordInitialState = {
  forgotPasswordLoading: false,
  concluded: false,
};

export const asyncActions = {
  FORGOTPASSWORD: createAsyncThunk(
    'FORGOTPASSWORD',
    async (payload: IForgotPasswordRequest) => {
      await forgotPasswordApi.forgotPassword(payload);
    },
  ),
};

export const ForgotPasswordSlice = createSlice({
  name: 'forgotPasswordSlice',
  initialState,
  reducers: {
    FORGOTPASSWORDLOADING: (state, action) => {
      state.forgotPasswordLoading = action.payload.loadingState;
    },
    RESET_INITIAL_STATE: (state) => {
      state.concluded = initialState.concluded;
      state.forgotPasswordLoading = initialState.forgotPasswordLoading;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncActions.FORGOTPASSWORD.pending, (state) => {
      state.forgotPasswordLoading = true;
    }),
    builder.addCase(asyncActions.FORGOTPASSWORD.fulfilled, (state) => {
      state.forgotPasswordLoading = false;

      state.concluded = true;
    }),
    builder.addCase(asyncActions.FORGOTPASSWORD.rejected, (state) => {
      state.forgotPasswordLoading = false;
    }),
  ],
});
