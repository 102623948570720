import { TNewsLanguages } from '../pages/AdministrativePanel/News/News.Slice';
import { nonSecuredBaseApi, securedBaseApi } from './api.config';

export interface INewsBody {
  id?: number;
  link: string;
  image: string;
  active: boolean;
  contents: {
    id: number;
    title: string;
    subtitle: string;
    language: string;
  }[];
}

export interface INewsActivatedList {
  content: INewsBody[];
}

export interface INewsListItem {
  id: number;
}

export interface INewsList {
  content: INewsListItem[];
  totalElements: number;
  idsInDatabase: number[] | undefined;
}

export const NEWS_LANGUAGE_MAP = new Map<string, TNewsLanguages>([
  ['PORTUGUESE', 'pt'],
  ['ENGLISH', 'en'],
  ['SPANISH', 'es'],
]);

export const administrativePanelNewsApi = {
  newNews: (newNewsBody: INewsBody) => {
    return securedBaseApi.post<INewsBody>('/newsV3', newNewsBody);
  },
  getNewsList: (queryParams: string) => {
    return securedBaseApi.get<INewsList>(`/newsV3${queryParams}`);
  },
  getNewsById: (id: number) => {
    return securedBaseApi.get<INewsBody>(`/newsV3/${id}`);
  },
  getNewsActivated: () => {
    return securedBaseApi.get<INewsActivatedList>(`/news?status=ACTIVATED`);
  },
  getNewsActivatedToCarousel: () => {
    return nonSecuredBaseApi.get<INewsBody[]>('/public/newsV3');
  },
  updateNews: (id: number, updateNewsBody: INewsBody) => {
    return securedBaseApi.put<INewsBody>(`/newsV3/${id}`, updateNewsBody);
  },
  deleteOne: (id: number) => {
    return securedBaseApi.delete(`/newsV3/${id}`);
  },
  deleteMany: (ids: number | number[]) => {
    return securedBaseApi.post(`/newsV3/batch/delete`, ids);
  },
  activateNews: (id: number) => {
    return securedBaseApi.patch(`newsV3/${id}/activate`);
  },
  deactivateNews: (id: number) => {
    return securedBaseApi.patch(`/newsV3/${id}/deactivate`);
  },
};
