import { securedBaseApi } from './api.config';

export interface IRequestCompanyResponse {
  type: string;
  id: number;
  status: string;
  name: string;
  email: string;
  createdAt: string;
  company: {
    id: number;
    name: string;
    address: string;
    country: {
      id: number;
      code: string;
      name: string;
      companyIdentifierType: string;
    };
    identifier: string;
  };
}

export interface IRequestUserResponse {
  type: string;
  id: number;
  status: string;
  name: string;
  email: string;
  createdAt: string;
  user: {
    email: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    company: {
      name: string | undefined;
      address: string | undefined;
      country: {
        name: string | undefined;
      };
      identifier: string | undefined;
    };
  };
}

export const administrativePanelRequestsApi = {
  getCompanySolicitation: (queryParams: string) => {
    return securedBaseApi.get<IRequestCompanyResponse>(
      `/solicitations/company${queryParams}`,
    );
  },
};
