import { Col, Row, Table, Tag, Tooltip } from 'antd';
import { TFunction } from 'i18next';
import React from 'react';
import uniqid from 'uniqid';

import { ReactComponent as NewUser } from '../../assets/request-new-user.svg';
import { ReactComponent as NewCompany } from '../../assets/request-new-company.svg';

import { ReactComponent as ApproveRequest } from '../../assets/approve-request.svg';
import { ReactComponent as RefuseRequest } from '../../assets/refuse-request.svg';
import { ReactComponent as ViewRequest } from '../../assets/view-request.svg';
import { asyncActions } from '../../pages/AdministrativePanel/Requests/PendingRequests/PendingRequests.Slice';

export const generateRequestsTypeColumn = (
  tableColumns: Array<JSX.Element>,
  t: TFunction,
) => {
  if (window.location.href.includes('administrative-panel?tab=requests')) {
    tableColumns.push(
      <Table.Column
        title={t('administrative-panel-requests-table-col-type')}
        className="request-type-column"
        key="requestType"
        align="center"
        dataIndex="type"
        sorter
        render={(record) => {
          switch (record) {
            case 'USER':
              return (
                <Tooltip
                  placement="right"
                  title={t('administrative-panel-requests-table-type-new-user')}
                >
                  <NewUser />
                </Tooltip>
              );

            case 'COMPANY':
              return (
                <Tooltip
                  placement="right"
                  title={t(
                    'administrative-panel-requests-table-type-new-company',
                  )}
                >
                  <NewCompany />
                </Tooltip>
              );

            default:
              return <span />;
          }
        }}
      />,
    );
  }

  return tableColumns;
};

export const generateRequestsActionColumns = (
  tableColumns: Array<JSX.Element>,
  rowClickAction: Function,
  t: TFunction,
  dispatch: any,
) => {
  tableColumns.push(
    <Table.Column
      className="request-actions-column"
      key={uniqid()}
      title=""
      align="center"
      showSorterTooltip={false}
      onCell={(record: any) => {
        return {
          onClick: () => {
            dispatch(
              asyncActions.APPROVE_OR_REFUSE_PENDING_REQUEST({
                approve_or_refuse: 'approve',
                pendingRequestId: record.id,
              }),
            );
          },
        };
      }}
      render={() => {
        return (
          <Row align="middle" justify="space-around">
            <Col>
              <ApproveRequest />
            </Col>
          </Row>
        );
      }}
    />,
  );

  tableColumns.push(
    <Table.Column
      className="request-actions-column"
      key={uniqid()}
      title=""
      align="center"
      showSorterTooltip={false}
      onCell={(record: any) => {
        return {
          onClick: () => {
            dispatch(
              asyncActions.APPROVE_OR_REFUSE_PENDING_REQUEST({
                approve_or_refuse: 'refuse',
                pendingRequestId: record.id,
              }),
            );
          },
        };
      }}
      render={() => {
        return (
          <Row align="middle" justify="space-around">
            <Col>
              <RefuseRequest />
            </Col>
          </Row>
        );
      }}
    />,
  );

  tableColumns.push(
    <Table.Column
      className="request-actions-column"
      key={uniqid()}
      title=""
      align="center"
      showSorterTooltip={false}
      onCell={(record: any) => {
        return {
          onClick: () => rowClickAction(record),
        };
      }}
      render={() => {
        return (
          <Row align="middle" justify="space-around">
            <Col className="view-icon">
              <ViewRequest />
            </Col>
          </Row>
        );
      }}
    />,
  );
};

export const generatePagingTableSpecialColumns = (
  tableColumns: Array<JSX.Element>,
  t: TFunction,
) => {
  if (window.location.href.includes('administrative-panel?tab=users')) {
    tableColumns.push(
      <Table.Column
        title="Status"
        key="status"
        align="left"
        showSorterTooltip={false}
        render={(record) => {
          return (
            <Tag color={record.blocked ? 'red' : 'green'} key="statusTag">
              {record.blocked
                ? t('administrative-panel-user-status-column-blocked')
                : t('administrative-panel-user-status-column-unblocked')}
            </Tag>
          );
        }}
      />,
    );
  }

  if (window.location.href.includes('administrative-panel?tab=news')) {
    tableColumns.push(
      <Table.Column
        title="Status"
        key="status"
        align="left"
        showSorterTooltip={false}
        render={(record) => {
          return (
            <Tag color={record.status ? 'green' : 'red'} key="statusTag">
              {record.status
                ? t('news-listing-table-status-active')
                : t('news-listing-table-status-inactive')}
            </Tag>
          );
        }}
      />,
    );
  }

  if (window.location.href.includes('administrative-panel?tab=news')) {
    tableColumns.push(
      <Table.Column
        title="Status"
        key="status"
        align="left"
        showSorterTooltip={false}
        render={(record) => {
          return (
            <Tag color={record.status ? 'green' : 'red'} key="statusTag">
              {record.status
                ? t('news-listing-table-status-active')
                : t('news-listing-table-status-inactive')}
            </Tag>
          );
        }}
      />,
    );
  }

  if (window.location.href.includes('administrative-panel?tab=requests')) {
    tableColumns.push(
      <Table.Column
        title="Status"
        key="status"
        align="left"
        sorter
        showSorterTooltip={false}
        render={(record) => {
          return generateRequestTypeTag(record.status, t);
        }}
        width="12rem"
      />,
    );
  }

  return tableColumns;
};

const generateRequestTypeTag = (status: string, t: TFunction) => {
  let tagValue;
  let tagColor;

  switch (status) {
    case 'ACCEPTED':
      tagValue = t('administrative-panel-requests-accepted');
      tagColor = 'green';
      break;

    case 'AUTOMATICALLY_APPROVED':
      tagValue = t('administrative-panel-requests-accepted-automatically');
      tagColor = 'cyan';
      break;

    default:
      tagValue = t('administrative-panel-requests-refused');
      tagColor = 'red';
  }

  return (
    <Tag color={tagColor} key="statusTag">
      {tagValue}
    </Tag>
  );
};
