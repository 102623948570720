import React, { Fragment, useEffect } from 'react';
import { Button, Col, Steps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';

import { StepsRequestAccess } from './StepsRequestAccess/StepsRequestAccess';
import { Header } from '../../components/Header/Header';
import { Company } from './StepsRequestAccess/Company/Company';
import { RequestAccessSlice, asyncActions } from './RequestAccess.Slice';
import { AppDispatch, RootState } from '../../store/store';
import { PersonalData } from './StepsRequestAccess/PersonalData/PersonalData';
import { Terms } from './StepsRequestAccess/Terms/Terms';
import { IRequestAccessConcludeRequest } from '../../interfaces/services-interfaces/IRequestAccessConcludeRequest';
import { useScrollToTop } from '../../helpers/useScrollToTop/useScrollToTop';

export const RequestAccess: React.FC = () => {
  useScrollToTop('request-access-container');
  const requestAccessState = useSelector(
    (state: RootState) => state.requestAccess,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { actions } = RequestAccessSlice;

  useEffect(() => {
    if (requestAccessState.concluded) {
      navigate('/request-access/concluded');
    }
  }, [actions, dispatch, history, requestAccessState.concluded]);

  const concludeRequestAccess = async () => {
    const newRequestAccess: IRequestAccessConcludeRequest = {
      country: { id: requestAccessState.companyStep.company?.country.id || 0 },
      firstName: requestAccessState.personalDataStep.name,
      lastName: requestAccessState.personalDataStep.surname,
      email: requestAccessState.personalDataStep.email,
      confirmEmail: requestAccessState.personalDataStep.emailConfirmation,
      password: requestAccessState.personalDataStep.password,
      passwordConfirmation:
        requestAccessState.personalDataStep.passwordConfirmation,
      role: 'U',
      preferenceLanguage:
        requestAccessState.personalDataStep.preferenceLanguage,
      company: { id: requestAccessState.companyStep.company?.id || 0 },
      termAccepted: true,
    };

    const formData: FormData = new FormData();
    formData.append(
      'user',
      new Blob([JSON.stringify(newRequestAccess)], {
        type: 'application/json',
      }),
    );

    const userAvatar = requestAccessState.personalDataStep.avatar;

    if (userAvatar) {
      const fetchedAvatar = await fetch(userAvatar);
      const asBlob = await fetchedAvatar.blob();
      const file = new File([asBlob], `${uniqid()}.png`, { type: 'image/png' });
      formData.append('profilePicture', file);
    }

    if (
      requestAccessState.companyStep.company.emailDomain &&
      requestAccessState.companyStep.company.emailDomain !== ''
    ) {
      dispatch(asyncActions.CONCLUDE_REQUEST_ACCESS(formData));
    } else {
      dispatch(asyncActions.CONCLUDE_REQUEST_ACCESS_AS_SOLICITATION(formData));
    }
  };

  const steps = [
    {
      title: t('request-access-step-1'),
      content: <Company />,
    },
    {
      title: t('request-access-step-2'),
      content: <PersonalData />,
    },
    {
      title: t('request-access-step-3'),
      content: <Terms />,
    },
  ];

  const mapStep = () => {
    return steps.map((item) => (
      <Steps.Step key={item.title} title={item.title} />
    ));
  };

  const getRequestAccessStepButtons = () => {
    const actualStep = requestAccessState.currentStep;
    const nextStep0ButtonEnabled = requestAccessState.enableStep0;
    const nextStep1ButtonEnabled = requestAccessState.enableStep1;

    const buttonEnabler =
      actualStep === 0 ? nextStep0ButtonEnabled : nextStep1ButtonEnabled;

    return (
      <Fragment>
        <Button
          name="previousButton"
          shape="round"
          className="steps-previous-button"
          onClick={() =>
            actualStep === 0
              ? navigate('/login')
              : dispatch(actions.PREVIOUS_STEP())
          }
          type="link"
        >
          {t('request-access-button-back')}
        </Button>
        {actualStep !== 2 ? (
          <Button
            name="nextButton"
            shape="round"
            disabled={!buttonEnabler}
            onClick={() => dispatch(actions.NEXT_STEP())}
          >
            {t('request-access-button-next')}
          </Button>
        ) : (
          <Button
            name="acceptButton"
            shape="round"
            onClick={() => concludeRequestAccess()}
          >
            {t('request-access-button-accept')}
          </Button>
        )}
      </Fragment>
    );
  };

  return (
    <Col push={1} span={22} pull={1} id="request-access-container">
      <Header title={t('request-access-header')}>
        <StepsRequestAccess
          mapStep={mapStep}
          generateRequestAccessStepButtons={getRequestAccessStepButtons}
          currentStep={requestAccessState.currentStep}
          currentStepContent={steps[requestAccessState.currentStep].content}
        />
      </Header>
    </Col>
  );
};
