import { store } from '../../store/store';

export const checkChangesOnCreateOrEditTerms = (mode: 'create' | 'edit') => {
  const termVersion = document.getElementById('term-version-input') as any;
  const termArea = document.querySelector('iframe') as any;
  const contentInEnglish = store.getState().createEditTerms.form.enContent;
  const contentInPortuguese = store.getState().createEditTerms.form.ptContent;
  const contentInSpanish = store.getState().createEditTerms.form.esContent;

  if (!termArea) {
    return false;
  }

  const contentWithoutTags = termArea.contentWindow.document.body.innerHTML;

  const filterEditorContent = () => {
    const spanStart = /<\s*span[^>]*>/g;
    const spanEnd = /<\/\s*span[^>]*>/g;
    const linkStart = /<\s*a[^>]*>/g;
    const linkEnd = /<\/\s*a[^>]*>/g;
    return contentWithoutTags
      .replace('<h1>', '')
      .replace('</h1>', '')
      .replace('<h2>', '')
      .replace('</h2>', '')
      .replace('<p>', '')
      .replace('</p>', '')
      .replace('&amp;', '&')
      .replace('&lt;', '<')
      .replace('&gt;', '>')
      .replace('<strong>', '')
      .replace('</strong>', '')
      .replace('<em>', '')
      .replace('</em>', '')
      .replace('<U>', '')
      .replace('</U>', '')
      .replace('<span style="text-decoration: underline;">', '')
      .replace('</span>', '')
      .replace(spanStart, '')
      .replace(spanEnd, '')
      .replace(linkStart, '')
      .replace(linkEnd, '')
      .replace('&nbsp;', '')
      .replace('<br data-mce-bogus="1">', '')
      .replace('<br>', '')
      .trim();
  };

  const termLanguage =
    store.getState().createEditTerms.language.selectedLanguage;

  if (mode === 'create') {
    if (termLanguage === 'pt') {
      return (
        (termVersion && termVersion.value) ||
        filterEditorContent().length > 0 ||
        contentInEnglish ||
        contentInSpanish ||
        false
      );
    }
    if (termLanguage === 'es') {
      return (
        (termVersion && termVersion.value) ||
        filterEditorContent().length > 0 ||
        contentInEnglish ||
        contentInPortuguese ||
        false
      );
    }
    if (termLanguage === 'en') {
      return (
        (termVersion && termVersion.value) ||
        filterEditorContent().length > 0 ||
        contentInEnglish ||
        contentInPortuguese ||
        false
      );
    }
  }

  const term = store.getState().createEditTerms.form;

  if (mode === 'edit') {
    if (termLanguage === 'pt') {
      return (
        term.originalVersion !== term.version ||
        term.originalPtContent !== contentWithoutTags ||
        term.originalEnContent !== term.enContent ||
        term.originalEsContent !== term.esContent ||
        false
      );
    }
    if (termLanguage === 'en') {
      return (
        term.originalVersion !== term.version ||
        term.originalEnContent !== contentWithoutTags ||
        term.originalPtContent !== term.ptContent ||
        term.originalEsContent !== term.esContent ||
        false
      );
    }
    if (termLanguage === 'es') {
      return (
        term.originalVersion !== term.version ||
        term.originalEsContent !== contentWithoutTags ||
        term.originalEnContent !== term.enContent ||
        term.originalPtContent !== term.ptContent ||
        false
      );
    }
  }
};
