import { nonSecuredBaseApi, securedBaseApi } from './api.config';
import { ICompanyList } from '../interfaces/services-interfaces/ICompanyList';

export interface ICompanyResponse {
  id: number;
  name: string;
  address: string;
  country: {
    id: number;
    code: string;
    name: string;
    companyIdentifierType: string;
  };
  createdAt: string;
  updatedAt: string;
  responsibleEmail: string;
  emailDomain: string;
  identifier: string;
}

export interface ICountriesListInsideContent {
  content: ICountriesList[];
}

export interface ICountriesList {
  id: number;
  code: string;
  name: string;
}

export const administrativePanelCompaniesApi = {
  getCompaniesList: (queryParams: string) => {
    return securedBaseApi.get<ICompanyList>(`companies${queryParams}`);
  },
  deleteOneCompany: (id: number) => {
    return securedBaseApi.delete(`companies/${id}`);
  },
  deleteManyCompanies: (id: number | number[]) => {
    return securedBaseApi.post(`/companies/batch/delete`, id);
  },
  getCountriesList: () => {
    return nonSecuredBaseApi.get<ICountriesListInsideContent>('/countries');
  },
  newCompanyRegister: (newCompanyBody: ICompanyResponse) => {
    return securedBaseApi.post<ICompanyResponse>('/companies', newCompanyBody);
  },
  newCompanyRegisterWithAHistorySolicitation: (
    newCompany: ICompanyResponse,
  ) => {
    return securedBaseApi.post<ICompanyResponse>(
      `/companies?checkForSolicitation=false`,
      newCompany,
    );
  },
  getCompanyById: (id: number) => {
    return securedBaseApi.get<ICompanyResponse>(`/companies/${id}`);
  },
  updateCompanyById: (id: number, companyUpdate: ICompanyResponse) => {
    return securedBaseApi.put<ICompanyResponse>(
      `/companies/${id}`,
      companyUpdate,
    );
  },
};
