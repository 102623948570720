import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { messageNotification } from '../../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../../helpers/unhookedTranslation/unhookedTranslation';
import { administrativePanelPendingRequestsApi } from '../../../services/administrative-panel-pending-requests.api';
import { administrativePanelUsersApi } from '../../../services/administrative-panel-users';
// eslint-disable-next-line import/no-cycle
import { store } from '../../../store/store';
import axios from 'axios';
import { IUsersTab } from '../../../interfaces/IUsersTab';

export interface IAdministrativePanelUsersState {
  showFilter: string;
  showEditMode: boolean;
  returnToAllUsers: boolean;
  list: IUsersTab;
  newRegister: INewUsers;
  linkToResetPassoword: string | undefined;
  showResetPasswordModal: boolean;
  userExist: boolean;
  showRequestModal: boolean;
  pendingUser: boolean;
  refusedUser: boolean;
  requestData: IRequestUserExistsData;
}
interface INewUsers {
  country: {
    id: number | undefined;
    name: string;
  };
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  password: string;
  passwordConfirmation: string;
  userTypeOrFunction: string | undefined;
  currentValueTypeOrFunctionUser: string | undefined;
  company: {
    id: number | undefined;
  };
  originalFirstName: string;
  originalLastName: string;
  originalCompany: {
    id: number | undefined;
  };
  originalUserTypeOrFunction: string;
  emailIsValid: boolean;
  confirmEmailIsEqual: boolean;
  termAccepted: boolean;
}

interface IRequestUserExistsData {
  requestId: number | undefined;
  userId: number | undefined;
  firstname: string;
  lastname: string;
  companyName: string;
  companyAddress: string;
  companyIdentifier: string | undefined;
  country: number | undefined;
  email: string;
  emailDomain: string | undefined;
  dateOfOrder: string;
}

const initialState: IAdministrativePanelUsersState = {
  showFilter: '1',
  showEditMode: false,
  list: {
    data: [],
    companiesData: [],
    pageIndex: 1,
    numberOfItems: 0,
    idsInDatabase: undefined,
    pageSize: 15,
    shouldRefresh: true,
    tableLoading: false,
    sortBy: undefined,
    selectedRowToDeleteUsers: undefined,
    deleteManyUsersIds: undefined,
    deleteOneUserId: undefined,
    filter: {
      filterByCompany: undefined,
      filterByCountry: undefined,
      filterByEmail: '',
      filterByFirstName: '',
      filterByLastName: '',
      filterByLastInteraction: undefined,
      filterByStatus: undefined,
    },
    userQueryString: [],
    userFirstQueryBehaviour: true,
  },
  newRegister: {
    country: {
      id: undefined,
      name: '',
    },
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    password: '',
    passwordConfirmation: '',
    userTypeOrFunction: undefined,
    currentValueTypeOrFunctionUser: undefined,
    company: {
      id: undefined,
    },
    originalFirstName: '',
    originalLastName: '',
    originalCompany: {
      id: undefined,
    },
    originalUserTypeOrFunction: '',
    emailIsValid: false,
    confirmEmailIsEqual: false,
    termAccepted: false,
  },
  linkToResetPassoword: undefined,
  showResetPasswordModal: false,
  userExist: true,
  returnToAllUsers: false,
  showRequestModal: false,
  pendingUser: false,
  refusedUser: false,
  requestData: {
    requestId: undefined,
    userId: undefined,
    dateOfOrder: '',
    firstname: '',
    lastname: '',
    companyName: '',
    companyAddress: '',
    companyIdentifier: '',
    country: undefined,
    email: '',
    emailDomain: '',
  },
};

export const asyncActions = {
  NEW_USER: createAsyncThunk(
    'NEW_USER',
    async (payload: any, { dispatch, rejectWithValue }) => {
      try {
        const newUserResponse =
          await administrativePanelUsersApi.newUser(payload);
        dispatch(asyncActions.USERS_LIST(''));

        return newUserResponse.data;
      } catch (error) {
        // FIXME: should return something else if not Axios Error?
        if (axios.isAxiosError(error) && error.response) {
          return rejectWithValue(error.response.data);
        }
      }
    },
  ),
  APPROVE_OR_REFUSE_PENDING_USER_REQUEST: createAsyncThunk(
    'APPROVE_OR_REFUSE_PENDING_USER_REQUEST',
    async (payload: any, asyncThunkApi) => {
      const approvedOrRefusedBy = {
        analyzedBy: `${store.getState().login.user?.firstName} (${
          store.getState().login.user?.email
        })`,
      };

      switch (payload.approve_or_refuse) {
        case 'approve':
          await administrativePanelPendingRequestsApi.approveRequest(
            payload.pendingRequestId,
            approvedOrRefusedBy,
          );
          break;

        case 'refuse':
          await administrativePanelPendingRequestsApi.refuseRequest(
            payload.pendingRequestId,
            approvedOrRefusedBy,
          );
          break;

        default:
          break;
      }

      asyncThunkApi.dispatch(
        asyncActions.REGISTER_USER_WITH_A_SOLICITATION_HISTORY(
          payload.newUserBody,
        ),
      );
    },
  ),
  REGISTER_USER_WITH_A_SOLICITATION_HISTORY: createAsyncThunk(
    'REGISTER_USER_WITH_A_SOLICITATION_HISTORY',
    async (payload: any) => {
      const newUserResponse =
        await administrativePanelUsersApi.newUserRegisterWithAHistorySolicitation(
          payload,
        );
      return newUserResponse.data;
    },
  ),
  USERS_LIST: createAsyncThunk('USERS_LIST', async (queryParams: string) => {
    const result = await administrativePanelUsersApi.getUsersList(queryParams);
    const dataToList = result.data.content.map((x, i) => {
      return {
        ...x,
        indexInStore: i,
      };
    });
    return {
      dataToList,
      totalElements: result.data.totalElements,
      idsInDatabase: result.data.idsInDatabase,
    };
  }),
  GET_USER_BY_ID: createAsyncThunk('GET_USER_BY_ID', async (payload: any) => {
    const userResponse = await administrativePanelUsersApi.getUserById(payload);

    return {
      userResponse: userResponse.data,
    };
  }),
  UPDATE_USER: createAsyncThunk('UPDATE_USER', async (arg: any) => {
    await administrativePanelUsersApi.updateUser(arg.id, arg.payload);
  }),
  DELETE_ONE_USER: createAsyncThunk('DELETE_ONE_USER', async (id: number) => {
    const userDelete = await administrativePanelUsersApi.deleteOne(id);

    return {
      deleteOneUserId: userDelete.data,
    };
  }),
  DELETE_MANY_USERS: createAsyncThunk(
    'DELETE_MANY_USERS',
    async (ids: number[]) => {
      const manyUsersDelete = await administrativePanelUsersApi.deleteMany(ids);

      return {
        deleteManyUsersIds: manyUsersDelete.data,
      };
    },
  ),
  BLOCK_USER: createAsyncThunk('BLOCK_USER', async (id: number) => {
    await administrativePanelUsersApi.blockUser(id);
  }),
  UNBLOCK_USER: createAsyncThunk('UNBLOCK_USER', async (id: number) => {
    await administrativePanelUsersApi.unblockUser(id);
  }),
  GET_LINK_TO_RESET_PASSWORD: createAsyncThunk(
    'GET_LINK_TO_RESET_PASSWORD',
    async (id: number) => {
      const linkToResetPassword =
        await administrativePanelUsersApi.getLinkToResetPassword(id);

      return {
        linkToResetPassword: linkToResetPassword.data,
      };
    },
  ),
};

export const UsersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    TAB_CHANGE: (state) => {
      state.showFilter = '';
      state.list.selectedRowToDeleteUsers = undefined;
    },
    USER_BACK_TO_ORIGINAL_STATE: () => {
      return {
        ...initialState,
      };
    },
    USER_BACK_TO_ORIGINAL_STATE_PERSISTING_FILTER: (state) => {
      return {
        ...initialState,
        showFilter: state.showFilter,
        list: {
          ...state.list,
        },
      };
    },
    SET_EDIT_MODE: (state) => {
      state.showEditMode = true;
    },
    SET_USER_FIRST_NAME: (state, action) => {
      state.newRegister.firstName = action.payload;
    },
    SET_USER_LAST_NAME: (state, action) => {
      state.newRegister.lastName = action.payload;
    },
    SET_TYPE_OF_USER: (state, action) => {
      state.newRegister.userTypeOrFunction = action.payload;

      if (action.payload === 'commonUser') {
        state.newRegister.currentValueTypeOrFunctionUser = unhookedTranslation(
          'administrative-panel-users-tab-user-type-commonUser-option',
        );
      } else if (action.payload === 'administrator') {
        state.newRegister.currentValueTypeOrFunctionUser = unhookedTranslation(
          'administrative-panel-users-tab-user-type-administrator-option',
        );
      }
    },
    SET_USER_EMAIL: (state, action) => {
      state.newRegister.email = action.payload;
    },
    SET_VALIDATION_EMAIL: (state, action) => {
      state.newRegister.emailIsValid = action.payload;
    },
    SET_USER_EMAIL_CONFIRMATION: (state, action) => {
      state.newRegister.confirmEmail = action.payload;
    },
    SET_VALIDATION_CONFIRM_EMAIL: (state, action) => {
      state.newRegister.confirmEmailIsEqual = action.payload;
    },
    SET_USER_COMPANY: (state, action) => {
      state.newRegister.company.id = action.payload.companyId;
      state.newRegister.country.id = action.payload.countryId;
      state.newRegister.country.name = action.payload.countryName;
    },
    SET_USER_COUNTRY: (state, action) => {
      state.newRegister.country = action.payload;
    },
    USER_REGISTER_SAVE_VALUES_ON_CHANGING_COMPANY: (state, action) => {
      state.newRegister.firstName = action.payload.firstName;
      state.newRegister.lastName = action.payload.lastName;
      state.newRegister.company.id = action.payload.userCompany;
      state.newRegister.email = action.payload.userEmail;
      state.newRegister.confirmEmail = action.payload.confirmEmail;
      state.newRegister.userTypeOrFunction = action.payload.userType;
      state.newRegister.country.name = action.payload.userCountry;
    },
    USERS_CREATED_SET_ID_TO_DELETE_USERS: (state, action) => {
      const payloadKeyId = action.payload.map((y: any) => y);
      const usersIdDataSource = state.list.idsInDatabase?.map((x) => x);

      if (usersIdDataSource) {
        const payloadKeyExist = payloadKeyId.filter((x: any) =>
          usersIdDataSource.includes(x),
        );

        state.list.deleteManyUsersIds = payloadKeyExist;
      }
    },
    USERS_CREATED_SET_SELECTED_ROWS: (state, action) => {
      const usersIdDataSource = state.list.idsInDatabase?.map((x) => x);

      const payloadKeyId = action.payload.map((y: any) => y.id);

      if (usersIdDataSource) {
        const payloadKeyExist = usersIdDataSource.filter((x) =>
          payloadKeyId.includes(x),
        );
        state.list.selectedRowToDeleteUsers = payloadKeyExist.length;
      }
    },
    DISABLE_DEFAULT_SORT_BEHAVIOUR: (state) => {
      state.list.userFirstQueryBehaviour = false;
    },
    FILTER_STATUS_CHANGE: (state, action) => {
      state.showFilter = action.payload;
    },
    FILTER_SET_NAME: (state, action) => {
      state.list.filter.filterByFirstName = action.payload;
    },
    FILTER_SET_LASTNAME: (state, action) => {
      state.list.filter.filterByLastName = action.payload;
    },
    FILTER_SET_EMAIL: (state, action) => {
      state.list.filter.filterByEmail = action.payload;
    },
    FILTER_SET_COMPANY: (state, action) => {
      state.list.filter.filterByCompany = action.payload;
    },
    FILTER_SET_COUNTRY: (state, action) => {
      state.list.filter.filterByCountry = action.payload;
    },
    SET_FILTER_QUERY_STRING: (state, action) => {
      state.list.userQueryString = action.payload;
      state.list.shouldRefresh = true;
      state.list.pageIndex = initialState.list.pageIndex;
    },
    CLEAR_FILTER_FIELDS: (state) => {
      if (state.list.userQueryString.length) {
        state.list.pageIndex = initialState.list.pageIndex;
      }
      state.list.filter = initialState.list.filter;
      state.list.userQueryString = initialState.list.userQueryString;
      state.list.shouldRefresh = true;
    },
    USERS_CHANGE_PAGE: (state, action) => {
      state.list.pageIndex = action.payload;
    },
    USERS_SORT_BY: (state, action) => {
      state.list.shouldRefresh = true;
      state.list.sortBy = action.payload;
    },
    CLOSE_RESET_PASSWORD_MODAL: (state) => {
      state.showResetPasswordModal = false;
    },
    CLOSE_PENDING_MODAL: (state) => {
      state.showRequestModal = false;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncActions.NEW_USER.fulfilled, (state) => {
      state.returnToAllUsers = true;
      state.list.pageIndex = initialState.list.pageIndex;

      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-register-user-title'),
        unhookedTranslation('administrative-panel-register-user-content'),
      );
    }),
    builder.addCase(asyncActions.NEW_USER.rejected, (state, action) => {
      const rejectedBody = action.payload as any;
      if (
        rejectedBody.i18n === 'error-user-with-refused-solicitation' ||
        rejectedBody.i18n === 'error-user-with-pending-solicitation'
      ) {
        if (rejectedBody.i18n === 'error-user-with-refused-solicitation') {
          state.refusedUser = true;
        }
        if (rejectedBody.i18n === 'error-user-with-pending-solicitation') {
          state.pendingUser = true;
        }
        state.showRequestModal = true;
        state.requestData.userId = rejectedBody.content.user.id;
        state.requestData.requestId = rejectedBody.content.id;
        state.requestData.companyName = rejectedBody.content.user.company.name;
        state.requestData.firstname = rejectedBody.content.user.firstName;
        state.requestData.lastname = rejectedBody.content.user.lastName;
        state.requestData.email = rejectedBody.content.user.email;
        state.requestData.dateOfOrder = rejectedBody.content.createdAt;
        state.requestData.companyIdentifier =
          rejectedBody.content.user.company.identifier;
        state.requestData.companyAddress =
          rejectedBody.content.user.company.address;
        state.requestData.country =
          rejectedBody.content.user.company.country.name;
      }
    }),
    builder.addCase(asyncActions.USERS_LIST.pending, (state) => {
      state.list.tableLoading = true;
    }),
    builder.addCase(asyncActions.USERS_LIST.rejected, (state) => {
      state.list.tableLoading = false;
    }),
    builder.addCase(asyncActions.USERS_LIST.fulfilled, (state, action) => {
      state.list.tableLoading = false;
      state.list.numberOfItems = action.payload.totalElements;
      state.list.idsInDatabase = action.payload.idsInDatabase;
      state.list.data = action.payload.dataToList;
      state.list.shouldRefresh = false;

      if (
        !state.list.data.length &&
        state.list.pageIndex !== initialState.list.pageIndex
      ) {
        state.list.pageIndex = initialState.list.pageIndex;
        state.list.shouldRefresh = true;
      }

      const usersIdDataSource = state.list.idsInDatabase?.map((x) => x);
      if (usersIdDataSource) {
        const userExist = usersIdDataSource.filter((x) =>
          state.list.deleteManyUsersIds?.includes(x),
        );

        if (
          userExist &&
          state.list.selectedRowToDeleteUsers &&
          state.list.deleteManyUsersIds
        ) {
          state.list.selectedRowToDeleteUsers! = userExist!.length;
        }
      }
    }),
    builder.addCase(asyncActions.GET_USER_BY_ID.fulfilled, (state, action) => {
      state.newRegister.firstName = action.payload.userResponse.firstName;
      state.newRegister.originalFirstName =
        action.payload.userResponse.firstName;
      state.newRegister.lastName = action.payload.userResponse.lastName;
      state.newRegister.originalLastName = action.payload.userResponse.lastName;
      state.newRegister.company.id = action.payload.userResponse.company.id;
      state.newRegister.originalCompany.id =
        action.payload.userResponse.company.id;
      state.newRegister.country.id = action.payload.userResponse.country.id;
      state.newRegister.country.name = action.payload.userResponse.country.name;
      state.newRegister.termAccepted =
        action.payload.userResponse.termV3Accepted;
      if (action.payload.userResponse.role === 'U') {
        state.newRegister.userTypeOrFunction = 'commonUser';
        state.newRegister.originalUserTypeOrFunction = 'commonUser';
        state.newRegister.currentValueTypeOrFunctionUser = unhookedTranslation(
          'administrative-panel-users-tab-user-type-commonUser-option',
        );
      } else if (action.payload.userResponse.role === 'A') {
        state.newRegister.userTypeOrFunction = 'administrator';
        state.newRegister.originalUserTypeOrFunction = 'administrator';
        state.newRegister.currentValueTypeOrFunctionUser = unhookedTranslation(
          'administrative-panel-users-tab-user-type-administrator-option',
        );
      }

      state.newRegister.email = action.payload.userResponse.email;
      state.newRegister.confirmEmail = action.payload.userResponse.email;
    }),
    builder.addCase(asyncActions.GET_USER_BY_ID.rejected, (state) => {
      state.userExist = false;
    }),
    builder.addCase(asyncActions.UPDATE_USER.fulfilled, (state) => {
      state.showEditMode = false;
      state.list.shouldRefresh = true;
      state.returnToAllUsers = true;

      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-edit-user-title'),
        unhookedTranslation('administrative-panel-edit-user-content'),
      );
    }),
    builder.addCase(asyncActions.DELETE_ONE_USER.fulfilled, (state) => {
      state.list.shouldRefresh = true;
      if (state.list.selectedRowToDeleteUsers !== undefined) {
        state.list.selectedRowToDeleteUsers -= 1;
      }
      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-delete-user-title'),
        unhookedTranslation('administrative-panel-delete-user-content'),
      );
    }),
    builder.addCase(asyncActions.DELETE_MANY_USERS.fulfilled, (state) => {
      state.list.shouldRefresh = true;
      state.list.selectedRowToDeleteUsers = undefined;

      if (
        state.list.deleteManyUsersIds &&
        state.list.deleteManyUsersIds.length === 1
      ) {
        messageNotification.successMessage(
          unhookedTranslation('administrative-panel-delete-user-title'),
          unhookedTranslation('administrative-panel-delete-user-content'),
        );
      } else {
        messageNotification.successMessage(
          unhookedTranslation('administrative-panel-delete-user-title'),
          unhookedTranslation('administrative-panel-delete-many-users-content'),
        );
      }
    }),
    builder.addCase(asyncActions.BLOCK_USER.fulfilled, (state) => {
      state.list.shouldRefresh = true;
      state.list.selectedRowToDeleteUsers = undefined;

      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-user-blocked-title'),
        unhookedTranslation('administrative-panel-user-blocked-content'),
      );
    }),
    builder.addCase(asyncActions.UNBLOCK_USER.fulfilled, (state) => {
      state.list.shouldRefresh = true;
      state.list.selectedRowToDeleteUsers = undefined;

      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-user-unblocked-title'),
        unhookedTranslation('administrative-panel-user-unblocked-content'),
      );
    }),
    builder.addCase(
      asyncActions.GET_LINK_TO_RESET_PASSWORD.fulfilled,
      (state, action) => {
        state.showResetPasswordModal = true;
        state.list.tableLoading = false;
        state.linkToResetPassoword = action.payload.linkToResetPassword.url;
      },
    ),
    builder.addCase(
      asyncActions.GET_LINK_TO_RESET_PASSWORD.pending,
      (state) => {
        state.list.tableLoading = true;
      },
    ),
    builder.addCase(
      asyncActions.REGISTER_USER_WITH_A_SOLICITATION_HISTORY.fulfilled,
      (state) => {
        state.list.shouldRefresh = true;
        state.returnToAllUsers = true;

        messageNotification.successMessage(
          unhookedTranslation('administrative-panel-edit-user-title'),
          unhookedTranslation('administrative-panel-edit-user-content'),
        );
      },
    ),
  ],
});
