import { createSlice } from '@reduxjs/toolkit';

interface IPathTrackerState {
  pagesPreviousPath: string;
}

const initialState: IPathTrackerState = {
  pagesPreviousPath: '/created-pages',
};

export const PathTrackerSlice = createSlice({
  name: 'PathTrackerSlice',
  initialState,
  reducers: {
    SET_PREVIOUS_PATH_FOR_PAGES: (state, action) => {
      state.pagesPreviousPath = action.payload;
    },
  },
});
