import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'antd';
import { IngenicoWorld } from '../../components/IngenicoWorld/IngenicoWorld';
import { CreatePasswordForm } from '../../components/CreatePassword/CreatePasswordForm';
import { ICreatePasswordRequest } from '../../services/create-password.api';
import { asyncActions, CreatePasswordSlice } from './CreatePassword.Slice';
import { messageNotification } from '../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../helpers/unhookedTranslation/unhookedTranslation';
import { AppDispatch, RootState } from '../../store/store';
import { showModal } from '../../helpers/showModal/showModal';

export const CreatePassword: React.FC = () => {
  const createPasswordState = useSelector(
    (state: RootState) => state.createPassword,
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { actions } = CreatePasswordSlice;

  const showModalToGoBackToLogin = () => {
    showModal(
      'warning',
      `${t('create-password-cancel-warning-sure-title')}`,
      `${t('create-password-cancel-warning-sure-content')}`,
      () => {
        navigate(`/login`);
      },
    );
  };

  const checkIfPasswordFieldIsValid = (password: string) => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;

    if (password) {
      const lowerCaseMatch = password.match(lowerCaseLetters);
      const upperCaseMatch = password.match(upperCaseLetters);
      const numberMatch = password.match(numbers);
      const lenMatch = password.length > 7;

      if (lowerCaseMatch) {
        dispatch(actions.SET_LOWER_CASE_VALIDATION('valid'));
      } else {
        dispatch(actions.SET_LOWER_CASE_VALIDATION('invalid'));
      }

      if (upperCaseMatch) {
        dispatch(actions.SET_UPPER_CASE_VALIDATION('valid'));
      } else {
        dispatch(actions.SET_UPPER_CASE_VALIDATION('invalid'));
      }

      if (numberMatch) {
        dispatch(actions.SET_NUMBER_VALIDATION('valid'));
      } else {
        dispatch(actions.SET_NUMBER_VALIDATION('invalid'));
      }

      if (lenMatch) {
        dispatch(actions.SET_LENGTH_VALIDATION('valid'));
      } else {
        dispatch(actions.SET_LENGTH_VALIDATION('invalid'));
      }

      if (lowerCaseMatch && upperCaseMatch && numberMatch && lenMatch) {
        return dispatch(actions.SET_PASSWORD_VALIDATION(true));
      }
    } else {
      dispatch(actions.SET_LOWER_CASE_VALIDATION('invalid'));
      dispatch(actions.SET_UPPER_CASE_VALIDATION('invalid'));
      dispatch(actions.SET_NUMBER_VALIDATION('invalid'));
      dispatch(actions.SET_LENGTH_VALIDATION('invalid'));
    }

    return false;
  };

  useEffect(() => {
    if (createPasswordState.concludedPage === true) {
      return navigate('/create-password/concluded');
    }
  }, [history, createPasswordState.concludedPage]);

  useEffect(() => {
    const tokenFromURL = window.location.href.match(/.{36}$/);

    if (tokenFromURL) {
      dispatch(asyncActions.VALIDATE_TOKEN(tokenFromURL[0]));
    }
  }, [dispatch]);

  useEffect(() => {
    if (createPasswordState.invalidToken) {
      return navigate('/home');
    }
  }, [createPasswordState.invalidToken, history]);

  const submitNewPassword = (createNewPassword: ICreatePasswordRequest) => {
    const tokenFromURL = window.location.href.match(/.{36}$/);
    if (!tokenFromURL) {
      messageNotification.errorMessage(
        unhookedTranslation('create-password-error-token-notification-title'),
        unhookedTranslation('create-password-error-token-notification-content'),
      );
      return;
    }
    const token = tokenFromURL[0].toString();
    if (createPasswordState.passwordValidation === true) {
      dispatch(asyncActions.CREATEPASSWORD({ token, body: createNewPassword }));
    } else {
      messageNotification.warningMessage(
        unhookedTranslation(
          'create-password-error-weak-password-notification-title',
        ),
        unhookedTranslation(
          'create-password-error-weak-password-notification-content',
        ),
      );
    }
  };

  const onChangePassword = (data: string) => {
    dispatch(actions.SET_NEW_PASSWORD(data));
  };

  const onChangePasswordConfirmation = (data: string) => {
    dispatch(actions.SET_PASSWORD_CONFIRMATION(data));
  };

  return (
    <Col push={1} span={22} pull={1}>
      <IngenicoWorld title={t('create-password-header')}>
        <CreatePasswordForm
          submitNewPassword={submitNewPassword}
          showModalToGoBackToLogin={showModalToGoBackToLogin}
          onChangePassword={onChangePassword}
          onChangePasswordConfirmation={onChangePasswordConfirmation}
          passwordVerificationFunction={checkIfPasswordFieldIsValid}
          cardValidationStyleLowerCase={
            createPasswordState.cardValidationStyleLowerCase
          }
          cardValidationStyleUpperCase={
            createPasswordState.cardValidationStyleUpperCase
          }
          cardValidationStyleNumber={
            createPasswordState.cardValidationStyleNumber
          }
          cardValidationStyleLength={
            createPasswordState.cardValidationStyleLength
          }
        />
      </IngenicoWorld>
    </Col>
  );
};
