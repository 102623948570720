import React, { useEffect } from 'react';

import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';
import { RedocAPI } from './components/RedocAPI/RedocAPI';
import { messageNotification } from './helpers/messageNotification/messageNotification';
import { onStartMessage } from './helpers/onStartMessage/onStartMessage';
import { Container } from './pages/.Container/Container';
import { IE11FallbackPage } from './pages/IE11FallbackPage/IE11FallbackPage';
// Global Interface of WebApp - Just to Declare DocumentMode Property
declare global {
  interface Document {
    documentMode?: any;
  }
}

export const App: React.FC = () => {
  const isIE = false || !!document.documentMode;
  const { t } = useTranslation();

  // In this case, it's important this array to remain with empty dependencies!
  // If the second param, the array of dependencies, even empty, is removed, this message
  // will be displayed in any render of this components.
  // If you pass the param t, which is missing in this case, the message will still be displayed two times

  useEffect(() => {
    const storedOnStartMessage = localStorage.getItem('on-start-message');

    onStartMessage(storedOnStartMessage);
  });

  useEffect(() => {
    if (window.location.href.includes('mustAcceptTerms')) {
      messageNotification.warningMessage(
        t('must-accept-terms-title'),
        t('must-accept-terms-content'),
      );
    }
    // eslint-disable-next-line
  }, [])

  if (
    window.location.href.includes('logoutFromRepository') ||
    window.location.href.includes('fromRepositoryRedirect')
  ) {
    localStorage.removeItem('token');
    localStorage.removeItem('authorization');
  }

  if (process.env.REACT_APP_ENABLE_API_KEYS_AND_TELEMETRY) {
    const trackingId = 'UA-180235593-1';
    ReactGA.initialize(trackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
    hotjar.initialize({
      id: 2033850,
      sv: 6,
    });
  }

  const getContainerOrIEFallbackPage = () => {
    if (isIE) {
      return <IE11FallbackPage />;
    }

    if (window.location.href.includes('apidoc')) {
      return <RedocAPI />;
    }

    return <Container />;
  };

  return getContainerOrIEFallbackPage();
};
