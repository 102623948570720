import React, { useRef } from 'react';
import { Carousel, Button } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import './CarouselHeader.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import Background01 from '../../../assets/news-background-01.svg';
import Background02 from '../../../assets/news-background-02.svg';
import Background03 from '../../../assets/news-background-03.svg';
import Background04 from '../../../assets/news-background-04.svg';
import { RootState } from '../../../store/store';
import { POP_UP_LANGUAGE_MAP_INVERTED } from '../../../services/administrative-panel-pop-up';

const banner01Key = 'bannerBackground01';
const banner02Key = 'bannerBackground02';
const banner03Key = 'bannerBackground03';
const banner04Key = 'bannerBackground04';

const whichBackgroundSelected = (key: string) => {
  switch (key) {
    case banner01Key:
      return Background01;
    case banner02Key:
      return Background02;
    case banner03Key:
      return Background03;
    case banner04Key:
      return Background04;
    default:
      return Background01;
  }
};

export const CarouselHeader: React.FC = () => {
  const carouselState = useSelector((state: RootState) => state.carousel);
  const caroselRef = useRef(null);

  const applicationLanguage = useSelector(
    (state: RootState) => state.navBar.defaultLanguage,
  );

  const languageValue = Array.from(POP_UP_LANGUAGE_MAP_INVERTED).find(
    ([value]) => applicationLanguage === value,
  );

  const { t } = useTranslation();

  const moveToRight = () => {
    if (!caroselRef.current) {
      return;
    }
    const carousel = caroselRef.current as unknown as CarouselRef;
    carousel.next();
  };

  const moveToLeft = () => {
    if (!caroselRef.current) {
      return;
    }
    const carousel = caroselRef.current as unknown as CarouselRef;
    carousel.prev();
  };

  const goToCarouselInformationLink = (link: string) => {
    window.open(link, '_blank');
  };

  const generateCarouselItems = () => {
    if (!window.location.href.includes('/home?networkRedirector=true')) {
      return carouselState.data.map((x, index) => {
        return (
          <div
            id="carousel-container-news"
            className="carousel-header-items"
            onClick={() => goToCarouselInformationLink(x.link)}
            key={index.toString()}
          >
            <div className="carousel-header-image">
              <img
                className="image"
                src={whichBackgroundSelected(x.image)}
                alt={
                  x.contents.find(
                    (e: any) =>
                      e.language ===
                      (languageValue ? languageValue[1] : 'PORTUGUESE'),
                  ).title
                }
              />
            </div>
            <div className="carousel-information-labels">
              <div className="carousel-information-labels-header-red-horizontal-line" />
              <span className="carousel-information-label-text-title">
                {
                  x.contents.find(
                    (e: any) =>
                      e.language ===
                      (languageValue ? languageValue[1] : 'PORTUGUESE'),
                  ).title
                }
              </span>
              <br />
              <span className="carousel-information-label-text-subtitle">
                {
                  x.contents.find(
                    (e: any) =>
                      e.language ===
                      (languageValue ? languageValue[1] : 'PORTUGUESE'),
                  ).subtitle
                }
              </span>
            </div>
          </div>
        );
      });
    }
    return (
      <div id="carousel-container-news" className="carousel-header-items">
        <div className="carousel-information-labels">
          <div className="carousel-information-labels-header-red-horizontal-line" />
          <span className="carousel-information-label-text-title">
            {t('return-message-network-error-carousel-title')}
          </span>
          <br />
          <span className="carousel-information-label-text-subtitle">
            {t('return-message-network-error-carousel-description')}
          </span>
        </div>
      </div>
    );
  };

  const returnButtonsInCarousel = () => {
    if (
      !window.location.href.includes('/home?networkRedirector=true') &&
      carouselState.data.length > 1
    ) {
      return (
        <div className="carousel-buttons">
          <Button
            id="carousel-button-prev-news"
            type="link"
            className="carousel-button-prev"
            onClick={moveToLeft}
          >
            <LeftOutlined className="carousel-button-icon" />
          </Button>
          <Button
            id="carousel-button-next-news"
            type="link"
            className="carousel-button-next"
            onClick={moveToRight}
          >
            <RightOutlined className="carousel-button-icon" />
          </Button>
        </div>
      );
    }
  };

  const getCarouselClass = () => {
    return carouselState.data.length === 0 ? 'EmptyCarousel' : 'Carousel';
  };

  return (
    <div className={getCarouselClass()}>
      <Carousel
        dots
        dotPosition="bottom"
        infinite
        speed={500}
        autoplay
        autoplaySpeed={3000}
        effect="fade"
        accessibility
        ref={caroselRef}
        className="CarouselHeader"
      >
        {generateCarouselItems()}
      </Carousel>
      {returnButtonsInCarousel()}
    </div>
  );
};
