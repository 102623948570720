import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { requestAccessApi } from '../../services/request-access.api';

import { unhookedTranslation } from '../../helpers/unhookedTranslation/unhookedTranslation';
import { ICountriesList } from '../../services/administrative-panel-companies';

import { IErrorResponseData } from '../../interfaces/IErrorResponseData';

import axios from 'axios';

interface IRequestAccess {
  concluded: boolean;
  currentStep: number;

  personalDataStep: IPersonalDataStep;
  companyStep: ICompanyStep;

  enableStep0: boolean;
  enableStep1: boolean;
}

interface ICompany {
  id: number;
  cnpj: string;
  name: string;
  address: string;
  country: ICountry;
  emailDomain: string;
}

interface ICountry {
  id: number;
  code: string;
  name: string;
}

interface ICompanyStep {
  company: ICompany;
  idNumber: string;
  searchButtonDisabled: boolean;
  companyCnpjOrTaxIdDirtyInput: boolean;
  inputCnpjOrTaxIdDisabled: boolean;
  companyCnpjOrTaxIdInputMask: string;
  searchButtonLoading: boolean;
  placeholder: string;
  foundCompany: boolean | undefined;
  countryList: ICountriesList[];
  suggestCompanyModalVisibility: boolean;
  requestCompany: boolean;
  hideSuggestCompanyButtonForPendingCompanies: boolean;
  showSuggestCompanyScreen: boolean;
}

interface IPersonalDataStep {
  loadingAvatar: boolean;
  avatar: any;
  validate: boolean;
  name: string;
  surname: string;
  email: string;
  emailConfirmation: string;
  emailComparisonMessage: string;
  isEmailValid: boolean;
  password: string;
  passwordConfirmation: string;
  passwordComparisonMessage: string;
  isPasswordValid: boolean;
  showPasswordValidationCard: boolean;
  cardValidationStyleLet: string;
  cardValidationStyleCap: string;
  cardValidationStyleNum: string;
  cardValidationStyleLen: string;
  preferenceLanguage: string;
}

const initialState: IRequestAccess = {
  concluded: false,
  currentStep: 0,
  enableStep0: false,
  enableStep1: false,
  companyStep: {
    idNumber: '',
    company: {
      id: 0,
      address: '',
      cnpj: '',
      name: '',
      emailDomain: '',
      country: {
        id: 0,
        code: '',
        name: '',
      },
    },
    companyCnpjOrTaxIdInputMask: '*',
    companyCnpjOrTaxIdDirtyInput: false,
    placeholder: '',
    inputCnpjOrTaxIdDisabled: true,
    searchButtonDisabled: true,
    searchButtonLoading: false,
    foundCompany: undefined,
    countryList: [],
    suggestCompanyModalVisibility: false,
    requestCompany: false,
    showSuggestCompanyScreen: false,
    hideSuggestCompanyButtonForPendingCompanies: false,
  },
  personalDataStep: {
    loadingAvatar: false,
    avatar: undefined,
    validate: false,
    name: '',
    surname: '',
    email: '',
    emailConfirmation: '',
    emailComparisonMessage: '',
    isEmailValid: false,
    password: '',
    passwordConfirmation: '',
    showPasswordValidationCard: false,
    passwordComparisonMessage: '',
    isPasswordValid: false,
    cardValidationStyleLet: 'invalid',
    cardValidationStyleCap: 'invalid',
    cardValidationStyleNum: 'invalid',
    cardValidationStyleLen: 'invalid',
    preferenceLanguage: '',
  },
};

export const asyncActions = {
  COMPANY_GET_COMPANY: createAsyncThunk(
    'COMPANY_GET_COMPANY',
    async (companySearchQueryParam: string, { rejectWithValue }) => {
      try {
        const companyResult = await requestAccessApi.getCompany(
          companySearchQueryParam,
        );
        return {
          company: companyResult.data,
        };
      } catch (err) {
        // FIXME: should return something else if not Axios Error?
        if (axios.isAxiosError(err) && err.response) {
          return rejectWithValue(err.response.data);
        }
      }
    },
  ),
  REQUEST_NEW_COMPANY: createAsyncThunk(
    'REQUEST_NEW_COMPANY',
    async (payload: any) => {
      await requestAccessApi.requestCompany(payload);
    },
  ),
  CONCLUDE_REQUEST_ACCESS: createAsyncThunk(
    'CONCLUDE_REQUEST_ACCESS',
    async (payload: FormData) => {
      await requestAccessApi.finishRequestAccess(payload);
    },
  ),
  CONCLUDE_REQUEST_ACCESS_AS_SOLICITATION: createAsyncThunk(
    'CONCLUDE_REQUEST_ACCESS_AS_SOLICITATION',
    async (payload: FormData) => {
      try {
        await requestAccessApi.finishRequestAccessAsSolicitation(payload);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data) {
          const data = error.response.data as IErrorResponseData;
          if (data.i18n) {
            if (
              error.response.data.i18n !==
              'error-user-with-refused-solicitation'
            ) {
              throw error;
            }
          }
        }
      }
    },
  ),
  GET_REQUEST_ACCESS_COUNTRY_LIST: createAsyncThunk(
    'GET_REQUEST_ACCESS_COUNTRY_LIST',
    async () => {
      const countriesListResult = await requestAccessApi.getCountriesList();
      return {
        countriesList: countriesListResult.data.content,
      };
    },
  ),
};

export const RequestAccessSlice = createSlice({
  name: 'requestAccessSlice',
  initialState,
  reducers: {
    BACK_TO_ORIGINAL_STATE: () => {
      return {
        ...initialState,
      };
    },
    NEXT_STEP: (state) => {
      if (state.currentStep !== 2) {
        state.currentStep += 1;
      }
    },
    PREVIOUS_STEP: (state) => {
      if (state.currentStep !== 0) {
        state.currentStep -= 1;
      }
    },
    ENABLE_STEP_0: (state) => {
      state.enableStep0 = true;
    },
    DISABLE_STEP_0: (state) => {
      state.enableStep0 = false;
    },
    ENABLE_STEP_1: (state) => {
      state.enableStep1 = true;
    },
    DISABLE_STEP_1: (state) => {
      state.enableStep1 = false;
    },
    SET_CONCLUDED_REQUEST_ACCESS: (state) => {
      state.concluded = true;
    },
    CLEAN_CONCLUDED_REQUEST_ACCESS: (state) => {
      state.concluded = false;
    },
    COMPANY_ENABLE_SEARCH_BUTTON: (state) => {
      state.companyStep.searchButtonDisabled = false;
    },
    COMPANY_DISABLE_SEARCH_BUTTON: (state) => {
      state.companyStep.searchButtonDisabled = true;
    },
    COMPANY_SET_DIRTY_CNPJ_OR_TAX_ID_INPUT: (state) => {
      state.companyStep.companyCnpjOrTaxIdDirtyInput = true;
    },
    COMPANY_CHANGE_ID_NUMBER: (state, action) => {
      let idNumber = action.payload;

      if (typeof idNumber === 'string' || idNumber instanceof String) {
        // Remove any whitespaces from the string
        idNumber = idNumber.replace(/\s/g, '');
      }

      state.companyStep.idNumber = idNumber;

      if (state.companyStep.company.country.id === 30) {
        // Check if the input is properly filled before enabling the search button
        if (
          state.companyStep.idNumber.includes('_') ||
          state.companyStep.idNumber.length != 14
        ) {
          state.companyStep.searchButtonDisabled = true;
          state.companyStep.foundCompany =
            initialState.companyStep.foundCompany;
        } else {
          state.companyStep.searchButtonDisabled = false;
        }
      } else if (state.companyStep.idNumber) {
        state.companyStep.searchButtonDisabled = false;
      } else {
        state.companyStep.searchButtonDisabled = true;
        state.companyStep.foundCompany = initialState.companyStep.foundCompany;
      }
    },
    COMPANY_CHANGE_COUNTRY: (state, action) => {
      const selectedCountry = state.companyStep.countryList.find(
        (x) => x.id === action.payload,
      );

      if (selectedCountry) {
        state.companyStep.company.country = selectedCountry;
        state.companyStep.inputCnpjOrTaxIdDisabled = false;
        state.companyStep.foundCompany = initialState.companyStep.foundCompany;
        state.companyStep.searchButtonDisabled =
          initialState.companyStep.searchButtonDisabled;

        state.companyStep.idNumber = initialState.companyStep.idNumber;
      }
    },
    COMPANY_TOGGLE_SUGGEST_COMPANY_MODAL: (state) => {
      state.companyStep.suggestCompanyModalVisibility =
        !state.companyStep.suggestCompanyModalVisibility;
      state.companyStep.requestCompany = !state.companyStep.requestCompany;
    },
    PERSONAL_DATA_LOADING_AVATAR: (state, action) => {
      state.personalDataStep.loadingAvatar = action.payload.loadingAvatar;
    },
    PERSONAL_DATA_CHANGE_AVATAR: (state, action) => {
      state.personalDataStep.loadingAvatar = false;
      state.personalDataStep.avatar = action.payload.avatar;
    },
    PERSONAL_DATA_DELETE_AVATAR: (state) => {
      state.personalDataStep.avatar = undefined;
    },
    PERSONAL_DATA_SET_NAME: (state, action) => {
      state.personalDataStep.name = action.payload;
    },
    PERSONAL_DATA_SET_SURNAME: (state, action) => {
      state.personalDataStep.surname = action.payload;
    },
    PERSONAL_DATA_SET_EMAIL: (state, action) => {
      state.personalDataStep.email = action.payload;

      if (
        state.personalDataStep.email.length > 0 &&
        state.personalDataStep.emailConfirmation.length > 0
      ) {
        if (
          state.personalDataStep.email !==
          state.personalDataStep.emailConfirmation
        ) {
          state.personalDataStep.emailComparisonMessage = unhookedTranslation(
            'personal-data-email-comparison',
          );
        } else {
          state.personalDataStep.emailComparisonMessage = '';
        }
      } else {
        state.personalDataStep.emailComparisonMessage = '';
      }
    },
    PERSONAL_DATA_SET_EMAIL_CONFIRMATION: (state, action) => {
      state.personalDataStep.emailConfirmation = action.payload;

      if (
        state.personalDataStep.email.length > 0 &&
        state.personalDataStep.emailConfirmation.length > 0
      ) {
        if (
          state.personalDataStep.email !==
          state.personalDataStep.emailConfirmation
        ) {
          state.personalDataStep.emailComparisonMessage = unhookedTranslation(
            'personal-data-email-comparison',
          );
        } else {
          state.personalDataStep.emailComparisonMessage = '';
        }
      } else {
        state.personalDataStep.emailComparisonMessage = '';
      }
    },
    PERSONAL_DATA_SET_EMAIL_AS_VALID_OR_INVALID: (state, action) => {
      state.personalDataStep.isEmailValid = action.payload;
    },
    PERSONAL_DATA_SET_PASSWORD: (state, action) => {
      state.personalDataStep.password = action.payload;

      if (
        state.personalDataStep.password.length > 0 &&
        state.personalDataStep.passwordConfirmation.length > 0
      ) {
        if (
          state.personalDataStep.password !==
          state.personalDataStep.passwordConfirmation
        ) {
          state.personalDataStep.passwordComparisonMessage =
            unhookedTranslation('personal-data-password-comparison');
        } else {
          state.personalDataStep.passwordComparisonMessage = '';
        }
      } else {
        state.personalDataStep.passwordComparisonMessage = '';
      }
    },
    PERSONAL_DATA_SET_PASSWORD_CONFIRMATION: (state, action) => {
      state.personalDataStep.passwordConfirmation = action.payload;

      if (
        state.personalDataStep.password.length > 0 &&
        state.personalDataStep.passwordConfirmation.length > 0
      ) {
        if (
          state.personalDataStep.password !==
          state.personalDataStep.passwordConfirmation
        ) {
          state.personalDataStep.passwordComparisonMessage =
            unhookedTranslation('personal-data-password-comparison');
        } else {
          state.personalDataStep.passwordComparisonMessage = '';
        }
      } else {
        state.personalDataStep.passwordComparisonMessage = '';
      }
    },
    PERSONAL_DATA_SET_PASSWORD_AS_VALID_OR_INVALID: (state, action) => {
      state.personalDataStep.isPasswordValid = action.payload;
    },
    PERSONAL_DATA_SET_PASSWORD_VALIDATION_CARD: (state, action) => {
      state.personalDataStep.showPasswordValidationCard = action.payload;
    },
    PERSONAL_DATA_SET_LOWER_CASE_VALIDATION: (state, action) => {
      state.personalDataStep.cardValidationStyleLet = action.payload;
    },
    PERSONAL_DATA_SET_UPPER_CASE_VALIDATION: (state, action) => {
      state.personalDataStep.cardValidationStyleCap = action.payload;
    },
    PERSONAL_DATA_SET_LENGTH_VALIDATION: (state, action) => {
      state.personalDataStep.cardValidationStyleLen = action.payload;
    },
    PERSONAL_DATA_SET_NUMBER_VALIDATION: (state, action) => {
      state.personalDataStep.cardValidationStyleNum = action.payload;
    },
    PERSONAL_DATA_SET_PREFERENCE_LANGUAGE: (state, action) => {
      state.personalDataStep.preferenceLanguage = action.payload;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncActions.COMPANY_GET_COMPANY.pending, (state) => {
      state.companyStep.searchButtonLoading = true;
    }),
    builder.addCase(
      asyncActions.COMPANY_GET_COMPANY.fulfilled,
      (state, action) => {
        if (!action.payload) {
          return state;
        }

        state.companyStep.company = action.payload.company;
        state.companyStep.searchButtonLoading = false;
        state.companyStep.foundCompany = true;
        state.companyStep.showSuggestCompanyScreen = false;
        state.companyStep.hideSuggestCompanyButtonForPendingCompanies = false;
      },
    ),
    builder.addCase(
      asyncActions.COMPANY_GET_COMPANY.rejected,
      (state, action) => {
        if (
          action.payload &&
          (action.payload as any).i18n === 'error-company-solicitation-pending'
        ) {
          state.companyStep.hideSuggestCompanyButtonForPendingCompanies = true;
        } else {
          state.companyStep.hideSuggestCompanyButtonForPendingCompanies = false;
          state.companyStep.showSuggestCompanyScreen = true;
        }

        state.companyStep.searchButtonLoading = false;
        state.companyStep.foundCompany = false;
      },
    ),
    builder.addCase(
      asyncActions.GET_REQUEST_ACCESS_COUNTRY_LIST.fulfilled,
      (state, action) => {
        state.companyStep.countryList = action.payload.countriesList;
      },
    ),
    builder.addCase(asyncActions.REQUEST_NEW_COMPANY.fulfilled, (state) => {
      state.concluded = true;
    }),
    builder.addCase(asyncActions.CONCLUDE_REQUEST_ACCESS.fulfilled, (state) => {
      state.concluded = true;
    }),
    builder.addCase(
      asyncActions.CONCLUDE_REQUEST_ACCESS_AS_SOLICITATION.fulfilled,
      (state) => {
        state.concluded = true;
      },
    ),
  ],
});
