import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PagingTableWithExpansion } from '../../../../components/PagingTableWithExpansion/PagingTableWithExpansion';
import { ConcludedRequestModal } from '../../../../components/ConcludedRequestModal/ConcludedRequestModal';

import { ReactComponent as RedBar } from '../../../../assets/red_bar_icon.svg';
import { Filter } from '../../../../components/Filter/Filter';
import { ShowFiltersButton } from '../../../../components/ShowFiltersButton/ShowFiltersButton';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  asyncActions,
  ConcludedRequestsSlice,
} from './ConcludedRequests.Slice';
import { scrollToTopFunction } from '../../../../helpers/useScrollToTop/useScrollToTop';

export const ConcludedRequests = () => {
  const concludedRequestsState = useSelector(
    (state: RootState) => state.ConcludedRequests,
  );
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  useEffect(() => {
    if (concludedRequestsState.listing.tableLoading) {
      return;
    }

    if (!concludedRequestsState.listing.refresh) {
      return;
    }

    let queryString = `page=${
      concludedRequestsState.listing.pageIndex - 1
    }&size=${concludedRequestsState.listing.pageSize}`;

    if (concludedRequestsState.listing.defaultSortBehaviourActive) {
      queryString += `&sort=date,desc`;
    }

    if (concludedRequestsState.listing.orderQueryString) {
      queryString += `${concludedRequestsState.listing.orderQueryString}`;
    }

    if (concludedRequestsState.listing.filterQueryString) {
      if (concludedRequestsState.filter.isFilteringByStatus) {
        queryString = `${
          concludedRequestsState.listing.filterQueryString
        }&page=${concludedRequestsState.listing.pageIndex - 1}&size=${
          concludedRequestsState.listing.pageSize
        }`;

        if (concludedRequestsState.listing.defaultSortBehaviourActive) {
          queryString += `&sort=date,desc`;
        }

        if (concludedRequestsState.listing.orderQueryString) {
          queryString += `${concludedRequestsState.listing.orderQueryString}`;
        }
      } else {
        queryString += `${concludedRequestsState.listing.filterQueryString}`;
      }
    }

    dispatch(asyncActions.GET_CONCLUDED_REQUESTS_LIST(queryString));
  }, [
    dispatch,
    concludedRequestsState.listing.pageIndex,
    concludedRequestsState.listing.pageSize,
    concludedRequestsState.listing.refresh,
    concludedRequestsState.listing.tableLoading,
    concludedRequestsState.filter.isFilteringByStatus,
    concludedRequestsState.listing.filterQueryString,
    concludedRequestsState.listing.defaultSortBehaviourActive,
    concludedRequestsState.listing.orderQueryString,
  ]);

  const filterFields = [
    {
      fieldTitle: t('administrative-panel-requests-filter-request-type'),
      fieldType: 'select',
      fieldProperty: 'type',
      fieldValue: concludedRequestsState.filter.requestType,
      selectOptions: [
        {
          id: 1,
          name: t('administrative-panel-requests-filter-option-new-user'),
        },
        {
          id: 2,
          name: t('administrative-panel-requests-filter-option-new-company'),
        },
      ],
    },
    {
      fieldTitle: t('administrative-panel-requests-filter-name'),
      fieldType: 'input',
      fieldProperty: 'name',
      fieldValue: concludedRequestsState.filter.name,
    },
    {
      fieldTitle: t('administrative-panel-requests-filter-email'),
      fieldType: 'input',
      fieldProperty: 'email',
      fieldValue: concludedRequestsState.filter.email,
    },
    {
      fieldTitle: t('administrative-panel-requests-filter-date-title'),
      fieldType: 'datePicker',
      fieldProperty: 'date',
      fieldValue: concludedRequestsState.filter.date,
    },
    {
      fieldTitle: t('administrative-panel-requests-filter-request-status'),
      fieldType: 'select',
      fieldProperty: 'status',
      fieldValue: concludedRequestsState.filter.status,
      selectOptions: [
        {
          id: 1,
          name: t('administrative-panel-requests-accepted'),
        },
        {
          id: 2,
          name: t('administrative-panel-requests-accepted-automatically'),
        },
        {
          id: 3,
          name: t('administrative-panel-requests-refused'),
        },
      ],
    },
  ];

  const [columnsWidth, setColumnsWidth] = useState({
    nameColumnWidth: 12,
    emailColumnWidth: 12,
    dateColumnWidth: 12,
    analyzedByColumnWidth: 12,
  });

  const resizeHandlerSpeed = 0.028;
  const resizeColumns = (
    columnName:
      | 'nameColumnWidth'
      | 'emailColumnWidth'
      | 'dateColumnWidth'
      | 'analyzedByColumnWidth',
    movement: number,
  ) => {
    setColumnsWidth({
      ...columnsWidth,
      [columnName]: (columnsWidth[columnName] += movement * resizeHandlerSpeed),
    });
  };

  const columnsCursor = [
    {
      columnTitle: t('administrative-panel-requests-table-col-name'),
      columnProperty: 'name',
      width: columnsWidth.nameColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('nameColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-requests-table-col-email'),
      columnProperty: 'email',
      width: columnsWidth.emailColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('emailColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-requests-table-col-date-title'),
      columnProperty: 'date',
      width: columnsWidth.dateColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('dateColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-requests-table-col-analized-by'),
      columnProperty: 'analyzedBy',
      width: columnsWidth.analyzedByColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('analyzedByColumnWidth', event.movementX);
      },
    },
  ];

  const toggleFilter = () => {
    dispatch(ConcludedRequestsSlice.actions.TOGGLE_FILTER());
  };

  const submitConcludedRequestsFilter = (fields: any) => {
    const filterQueryParams = Object.entries(fields).reduce(
      (acc, [key, value]: [any, any]) => {
        switch (key) {
          case 'type':
            dispatch(
              ConcludedRequestsSlice.actions.FILTER_SET_REQUEST_TYPE(value),
            );
            break;
          case 'name':
            dispatch(
              ConcludedRequestsSlice.actions.FILTER_SET_STATUS_NAME(value),
            );
            break;
          case 'email':
            dispatch(
              ConcludedRequestsSlice.actions.FILTER_SET_STATUS_EMAIL(value),
            );
            break;
          case 'date':
            dispatch(ConcludedRequestsSlice.actions.FILTER_SET_DATE(value));
            break;
          case 'status':
            dispatch(
              ConcludedRequestsSlice.actions.FILTER_SET_STATUS_TYPE(value),
            );
            dispatch(
              ConcludedRequestsSlice.actions.SET_FILTERING_BY_STATUS(true),
            );
            break;
          default:
            break;
        }

        if (!value) {
          return acc;
        }

        if (key === 'type') {
          acc += `&${key}=${value === 1 ? 'USER' : 'COMPANY'}` as any;
          return acc;
        }

        if (key === 'date') {
          acc += `&${key}=${value.format('YYYY-MM-DD')}` as any;
          return acc;
        }
        if (key === 'status') {
          let statusQuery;

          switch (value) {
            case 1:
              statusQuery = 'ACCEPTED';
              break;
            case 2:
              statusQuery = 'AUTOMATICALLY_APPROVED';
              break;
            case 3:
              statusQuery = 'UNACCEPTED';
              break;
            default:
              break;
          }

          acc += `&${key}=${statusQuery}` as any;
          return acc;
        }

        acc += `&${key}=${String(value).trim()}` as any;
        return acc;
      },
      '',
    );

    dispatch(
      ConcludedRequestsSlice.actions.FILTER_SET_QUERY_STRING(filterQueryParams),
    );
  };

  const onChangePage = (pageIndex: number) => {
    if (!pageIndex) {
      return;
    }

    scrollToTopFunction('concluded-requests-area');
    dispatch(ConcludedRequestsSlice.actions.CHANGE_PAGE(pageIndex));
  };

  const onChangeSort = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    let sortBy = '';

    if (!order || !field) {
      return dispatch(
        ConcludedRequestsSlice.actions.ORDER_SET_QUERY_STRING(sortBy),
      );
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;

    switch (fieldToQueryParam) {
      case 'status':
      case 'date':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam}`
            : `${fieldToQueryParam},desc`;
        break;

      default:
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
    }

    return dispatch(
      ConcludedRequestsSlice.actions.ORDER_SET_QUERY_STRING(`&sort=${sortBy}`),
    );
  };

  const defaultSortBehaviour = {
    columnProperty: 'date',
    disableDefaultSortBehaviour: () => {},
    order: 'descend',
  };

  const getRequestById = (id: any) => {
    dispatch(asyncActions.GET_CONCLUDED_REQUEST_BY_ID(id));
  };

  const dataSource = () => {
    return concludedRequestsState.listing.dataSource.map((x) => {
      if (x.analyzedBy === 'system') {
        return {
          ...x,
          analyzedBy: t('administrative-panel-requests-accepted-by-system'),
        };
      }
      return x;
    });
  };

  return (
    <div id="concluded-requests-area">
      <ConcludedRequestModal />
      <Row style={{ marginTop: '1em' }}>
        <RedBar />
      </Row>
      <Row>
        <h1>{t('administrative-panel-concluded-requests-page-title')}</h1>
      </Row>
      <Row justify="end">
        <ShowFiltersButton
          onToggle={toggleFilter}
          openedFilter={!concludedRequestsState.filter.openedFilter}
        />
      </Row>
      <Row>
        <Col span={24}>
          <Filter
            clearFilterFields={() => {
              dispatch(ConcludedRequestsSlice.actions.FILTER_CLEAR_FIELDS());
            }}
            fields={filterFields}
            openedFilter={concludedRequestsState.filter.openedFilter}
            submitFilterFields={submitConcludedRequestsFilter}
          />
        </Col>
      </Row>
      <Row justify="start">
        <Col span={24}>
          <PagingTableWithExpansion
            emptyText={t('administrative-panel-empty-table')}
            dataSource={dataSource()}
            tableLoading={concludedRequestsState.listing.tableLoading}
            columns={columnsCursor}
            options={[]}
            pageSize={concludedRequestsState.listing.pageSize}
            pageIndex={concludedRequestsState.listing.pageIndex}
            numberOfItems={concludedRequestsState.listing.totalNumberOfElements}
            onChangePage={onChangePage}
            onChangeSort={onChangeSort}
            onChangeOptionsVisibility={() => {}}
            rowClickAction={(record: any) => getRequestById(record.id)}
            selectRowsToGivenTab={() => {}}
            selectIdFromRowsSelected={() => {}}
            isPendingTable={false}
            defaultSortBehaviour={defaultSortBehaviour}
            disableOptionsColumn
            disableSelectionColumn
          />
        </Col>
      </Row>
    </div>
  );
};
