import { Button } from 'antd';
import React from 'react';

import './ExportSelectedButton.scss';

import { ExportOutlined } from '@ant-design/icons';

interface IExportSelectedButton {
  numberOfItemsSelected: number | undefined;
  exportButtonLabel: string;
  singularUserLabel: string;
  pluralUserLabel: string;
  openExportModal: Function;
}

export const ExportSelectedButton: React.FC<IExportSelectedButton> = ({
  numberOfItemsSelected,
  exportButtonLabel,
  singularUserLabel,
  pluralUserLabel,
  openExportModal,
}: IExportSelectedButton) => {
  const showSingularOrPluralButton = () => {
    if (numberOfItemsSelected) {
      if (numberOfItemsSelected === 1) {
        return `${exportButtonLabel} ${numberOfItemsSelected} ${singularUserLabel}`;
      }
      if (numberOfItemsSelected > 1) {
        return `${exportButtonLabel} ${numberOfItemsSelected}  ${pluralUserLabel}`;
      }
    }
  };

  const showExportIcon = () => {
    if (numberOfItemsSelected && numberOfItemsSelected > 0) {
      return <ExportOutlined className="export-icon-button" />;
    }
  };

  const showOrHideExportButton = () => {
    if (numberOfItemsSelected === 0) {
      return <div />;
    }

    return (
      <>
        <Button
          id="export-selected-companies"
          shape="round"
          className="ExportSelectedButton"
          onClick={() => {
            openExportModal();
          }}
        >
          {showSingularOrPluralButton()?.toUpperCase()} {showExportIcon()}
        </Button>
      </>
    );
  };

  return showOrHideExportButton();
};
