import { securedBaseApi } from './api.config';

export interface ILogsList {
  content: ILogsItem[];
  totalElements: number;
}

export interface ILogsItem {
  type: string;
  createdAt: string;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    fullName: string;
  };
  dataType: string;
  detail: string;
}

export const administrativePanelLogsApi = {
  getLogs: (queryParams: string) => {
    return securedBaseApi.get<ILogsList>(`events${queryParams}`);
  },
};
