import { Button, Col, Form, Modal, Row } from 'antd';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { WarningOutlined } from '@ant-design/icons';

import './RequestCompanyOrUserModal.scss';

export interface ICompanyOrUserRequestModal {
  showModal: boolean;
  closeModal: Function;
  title: string;
  description: string;
  requestModalDataLeft?: IDataFields[];
  requestModalDataRight?: IDataFields[];
  approvePendingOrConcludedRequest: Function;
  createByForm: Function;
  approvePendingOrConcludedText: string;
}

interface IDataFields {
  name: string;
  currentValue?: any;
  id: string;
  propName?: string;
}

export const RequestCompanyOrUserModal: React.FC<
  ICompanyOrUserRequestModal
> = ({
  showModal,
  closeModal,
  title,
  description,
  requestModalDataLeft,
  requestModalDataRight,
  approvePendingOrConcludedRequest,
  createByForm,
  approvePendingOrConcludedText,
}: ICompanyOrUserRequestModal) => {
  const { t } = useTranslation();

  const generateFormItems = (field: any) => {
    return (
      <Row>
        <Col span={24} className="request-form-item">
          <Form.Item label={field.name} className="request-form-item">
            <div className="request-form-item-input">
              {field.currentValue
                ? field.currentValue
                : `${t(
                    'administrative-panel-pending-field-without-information-initial',
                  )}${field.propName}${t(
                    'administrative-panel-pending-field-without-information-final',
                  )}`}
            </div>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const generateColumns = (field: any) => {
    if (!field) {
      return;
    }

    return (
      <Fragment>
        <Col span={10}>{generateFormItems(field)}</Col>
      </Fragment>
    );
  };

  const generateContents = () => {
    const generatedContent = [];
    for (;;) {
      const leftColumnItem = requestModalDataLeft!.shift();
      const rightColumnItem = requestModalDataRight!.shift();

      if (!leftColumnItem && !rightColumnItem) {
        return generatedContent;
      }

      generatedContent.push(
        <Row className="request-form">
          {generateColumns(leftColumnItem)}
          {generateColumns(rightColumnItem)}
        </Row>,
      );
    }
  };

  return (
    <Modal
      className="request-modal"
      title={title}
      open={showModal}
      onCancel={() => closeModal()}
      footer={null}
    >
      <Form layout="vertical" className="request-modal-form">
        <Form.Item>
          <Row className="description" justify="center" align="middle">
            <Col span={24} className="description-title">
              <WarningOutlined />
              {t('administrative-panel-alredy-exists-description-alert')}
            </Col>
            <Col span={24} className="description-content">
              {description}
            </Col>
          </Row>
        </Form.Item>
        {generateContents()}
        <Form.Item>
          <Row className="form-approve-buttons">
            <Col span={11}>
              <Button
                className="register-form-button"
                htmlType="submit"
                onClick={() => createByForm()}
              >
                {t('administrative-panel-alredy-exists-send-by-form')}
              </Button>
            </Col>
            <Col span={11} offset={1}>
              <Button
                className="register-button"
                htmlType="submit"
                onClick={() => approvePendingOrConcludedRequest()}
              >
                {approvePendingOrConcludedText}
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row className="form-cancel-button">
            <Button
              className="cancel-button"
              type="link"
              onClick={() => closeModal()}
            >
              {t('administrative-panel-alredy-exists-cancel')}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
