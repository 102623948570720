import { Button, Col, Row, Divider } from 'antd';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { ReactComponent as SwapIcon } from '../../../../assets/swap.svg';
import { RootState, store } from '../../../../store/store';
import { PermissionsByRepositories } from '../PermissionsByRepositories/PermissionsByRepositories';
import { PermissionsListingSlice } from './PermissionsListing.Slice';
import { PermissionsByCompanies } from '../PermissionsByCompanies/PermissionsByCompanies';
import { CancelOrSaveChanges } from './CancelOrSaveChanges/CancelOrSaveChanges';
import { PermissionsByCompaniesSlice } from '../PermissionsByCompanies/PermissionsByCompanies.Slice';
import { PermissionsByRepositoriesSlice } from '../PermissionsByRepositories/PermissionsByRepositories.Slice';

import './PermissionsListing.scss';
import { showModal } from '../../../../helpers/showModal/showModal';

export const PermissionsListing: React.FC = () => {
  const permissionsListingState = useSelector(
    (state: RootState) => state.permissions,
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(PermissionsListingSlice.actions.RESET_TO_INITIAL_STATE());
      dispatch(PermissionsByCompaniesSlice.actions.RESET_TO_ORIGINAL_STATE());
      dispatch(
        PermissionsByRepositoriesSlice.actions.RESET_TO_ORIGINAL_STATE(),
      );
    };
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!permissionsListingState.refreshEntityOnRight) {
      return;
    }

    dispatch(
      PermissionsByCompaniesSlice.actions.REFRESH_TABLE_AND_RESET_CHANGES(),
    );
    dispatch(
      PermissionsByRepositoriesSlice.actions.REFRESH_TABLE_AND_RESET_CHANGES(),
    );

    dispatch(PermissionsListingSlice.actions.REFRESHED_TABLE_ON_RIGHT());
  }, [
    dispatch,
    permissionsListingState.refreshEntityOnRight,
    permissionsListingState.permissionTables,
  ]);

  useEffect(() => {
    if (permissionsListingState.shouldLoadOnLeft) {
      return;
    }

    switch (permissionsListingState.permissionTables[0].tableType) {
      case 'companies':
        dispatch(
          PermissionsListingSlice.actions.SHOULD_LOAD_ON_LEFT('companies'),
        );
        break;

      case 'repositories':
        dispatch(
          PermissionsListingSlice.actions.SHOULD_LOAD_ON_LEFT('repositories'),
        );
        break;

      default:
        break;
    }
  }, [
    dispatch,
    permissionsListingState.permissionTables,
    permissionsListingState.shouldLoadOnLeft,
  ]);

  const showTables = () => {
    return permissionsListingState.permissionTables.map((table) => {
      return generatePermissionTable(table.tableType);
    });
  };

  const generatePermissionTable = (tableType: 'companies' | 'repositories') => {
    switch (tableType) {
      case 'companies':
        return <PermissionsByCompanies />;

      case 'repositories':
        return <PermissionsByRepositories />;

      default:
        return undefined;
    }
  };

  const showCancelOrSaveChanges = () => {
    if (
      store.getState().permissionsByCompanies.listing.showCancelOrSaveChanges ||
      store.getState().permissionsByRepositories.listing.showCancelOrSaveChanges
    ) {
      return true;
    }
    return false;
  };

  const changeTables = () => {
    dispatch(PermissionsListingSlice.actions.CHANGE_TABLES_ORDER());
    dispatch(PermissionsByCompaniesSlice.actions.RESET_TO_ORIGINAL_STATE());
    dispatch(PermissionsByRepositoriesSlice.actions.RESET_TO_ORIGINAL_STATE());
  };

  const changeTablesOrderHandler = () => {
    if (showCancelOrSaveChanges()) {
      showModal(
        'warning',
        t('administrative-panel-permissions-cancel-button-modal-title'),
        t('administrative-panel-permissions-cancel-button-modal-message'),
        () => {
          changeTables();
        },
        () => {},
        t('administrative-panel-permissions-cancel-button-modal-accept'),
        t('administrative-panel-permissions-cancel-button-modal-refuse'),
      );
    } else {
      changeTables();
    }
  };

  const showChangesInPermissions = () => {
    if (showCancelOrSaveChanges()) {
      return true;
    }

    return false;
  };

  return (
    <Row className="PermissionsListing">
      <Row className="label-and-buttons" justify="space-between">
        <Col className="repositories-and-companies-label-container">
          <p className="repositories-and-companies-label">
            {t(permissionsListingState.permissionTables[0].tableTitle)} x{' '}
            {t(permissionsListingState.permissionTables[1].tableTitle)}
          </p>
        </Col>

        <Col className="change-permissions-tables-button-container">
          <Button
            id="toggle-view-button"
            onClick={changeTablesOrderHandler}
            className="change-permissions-tables-button"
            icon={<SwapIcon />}
            shape="round"
          >
            {t('administrative-panel-permissions-companies-table-view-label')}
            {t(permissionsListingState.permissionTables[1].tableTitle)} x{' '}
            {t(permissionsListingState.permissionTables[0].tableTitle)}
          </Button>
        </Col>
      </Row>

      <Divider className="permissions-listing-divider" />

      <CancelOrSaveChanges
        tableType={permissionsListingState.permissionTables[1].tableType}
        visible={showChangesInPermissions}
      />

      <Row className="permissions-tables-container" justify="space-between">
        {showTables()}
      </Row>
    </Row>
  );
};
