import { nonSecuredBaseApi } from './api.config';

export interface IPopUpDTO {
  id: number | undefined;
  active: boolean;
  type: string | undefined;
  createdAt?: Date;
  updatedAt?: Date;
  contents: IModalContents[];
}

export interface IModalContents {
  id: number | undefined;
  title: string | undefined;
  description: string | undefined;
  language: string | undefined;
}

export const activeModalApi = {
  getPublicPopUp: () => {
    return nonSecuredBaseApi.get<IPopUpDTO>('/public/pop-up');
  },
};
