import React, { Fragment } from 'react';
import { Col, Input, Row, Button, Form } from 'antd';
import './NewRegisterNews.scss';

import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';
import { FormInstance } from 'antd/lib/form';

interface INewRegisterNews {
  newsTitle?: string;
  newsSubtitle?: string;
  newsLinkNews: string;
  newsBackground: any;
  newsBackgroundMenu: any[];
  selectedBackground: string;
  initialValues?: object;
  showEditMode?: boolean;
  onChangeBackgroundNewsClickFunction: Function;
  onRegisterClickFunction: Function;
  onCancelClickFunction: Function;
  editorRef: React.MutableRefObject<FormInstance | null>;
}

export const NewRegisterNews: React.FC<INewRegisterNews> = ({
  newsTitle,
  newsSubtitle,
  newsLinkNews,
  newsBackground,
  newsBackgroundMenu,
  selectedBackground,
  initialValues,
  showEditMode,
  onChangeBackgroundNewsClickFunction,
  onRegisterClickFunction,
  onCancelClickFunction,
  editorRef,
}: INewRegisterNews) => {
  const { t } = useTranslation();
  const setSelectableBackground = (key: string) => {
    if (key === selectedBackground) {
      return (
        <div
          style={{
            width: '100%',
            height: '5px',
            backgroundColor: '#41b4d2',
            margin: '5px 0px',
          }}
        />
      );
    }
  };

  const onGenerateMenuOptionsOfBackground = () => {
    return newsBackgroundMenu.map((x) => (
      <Col
        key={x.key}
        span={6}
        onClick={() => onChangeBackgroundNewsClickFunction(x.key)}
      >
        <div>
          <img
            key={x.key}
            src={x.backgroundImage}
            alt="Menu Background"
            style={{
              objectFit: 'cover',
              height: '100%',
              width: '100%',
              cursor: 'pointer',
            }}
          />
        </div>
        {setSelectableBackground(x.key)}
      </Col>
    ));
  };

  const isValidURL = (link: string) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ); // fragment locator
    return !!pattern.test(link);
  };

  const generateFormKey = () => {
    if (initialValues) {
      return `${initialValues.toString()}${showEditMode}${uniqid()}`;
    }
  };
  // Return of the layout of the new news creation.
  return (
    <Fragment>
      <Form
        ref={editorRef}
        className="NewRegisterNews"
        layout="vertical"
        key={generateFormKey()}
        initialValues={initialValues}
        onFinish={(values) => onRegisterClickFunction(values)}
      >
        <div className="new_register-news-container">
          <img
            alt="Header news"
            src={newsBackground}
            className="new-register-card-img"
          />
          <div className="new-register-news-data-container">
            <div className="new-register-news-blue-horizontal-line" />
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: t(
                    'administrative-panel-news-tab-card-title-required-field',
                  ),
                },
              ]}
            >
              <Input
                id="newNewsTitle"
                bordered={false}
                value={newsTitle}
                className="new-register-news-title"
                placeholder={t('administrative-panel-news-tab-card-title')}
              />
            </Form.Item>
            <Form.Item
              name="subtitle"
              rules={[
                {
                  required: true,
                  message: t(
                    'administrative-panel-news-tab-card-subtitle-required-field',
                  ),
                },
              ]}
            >
              <Input
                id="newNewsSubtitle"
                bordered={false}
                value={newsSubtitle}
                placeholder={t('administrative-panel-news-tab-card-subtitle')}
                className="new-register-news-subtitle"
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item
          label={t('administrative-panel-news-tab-menu-background-label')}
          className="new-register-news-text-label"
        >
          <Row gutter={16}>{onGenerateMenuOptionsOfBackground()}</Row>
        </Form.Item>
        <Form.Item
          label={t('administrative-panel-news-tab-link')}
          className="new-register-news-text-label"
          name="link"
          rules={[
            {
              required: true,
              message: t('administrative-panel-news-tab-link-requires-field'),
            },
            () => ({
              validator(rule, value) {
                const fieldValue = value;

                if (!value) {
                  return Promise.resolve();
                }

                if (isValidURL(fieldValue)) {
                  return Promise.resolve();
                }

                return Promise.reject(t('administrative-panel-news-wrong-url'));
              },
            }),
          ]}
        >
          <Input id="newNewsLink" value={newsLinkNews} />
        </Form.Item>
        <Form.Item>
          <Row style={{ float: 'right' }}>
            <Button
              type="link"
              className="new-register-news-button-cancel"
              onClick={() => onCancelClickFunction()}
            >
              {t('administrative-panel-cancel-button')}
            </Button>
            <Button className="new-register-news-button-save" htmlType="submit">
              {t('administrative-panel-register-button')}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Fragment>
  );
};
