import React from 'react';
import { Row, Col, Form, Input, Button, Popover } from 'antd';

import { useTranslation } from 'react-i18next';
import { PasswordValidationPopOver } from '../RequestAccess/PersonalDataForm/PasswordValidationPopOver/CardPasswordValidation';
import './CreatePasswordForm.scss';

interface ICreatePasswordForm {
  submitNewPassword: Function;
  showModalToGoBackToLogin: Function;

  onChangePassword: Function;
  onChangePasswordConfirmation: Function;

  passwordVerificationFunction: Function;

  cardValidationStyleLowerCase: string;
  cardValidationStyleUpperCase: string;
  cardValidationStyleNumber: string;
  cardValidationStyleLength: string;
}

export const CreatePasswordForm: React.FC<ICreatePasswordForm> = ({
  submitNewPassword,
  showModalToGoBackToLogin,
  onChangePassword,
  passwordVerificationFunction,
  onChangePasswordConfirmation,
  cardValidationStyleLowerCase,
  cardValidationStyleUpperCase,
  cardValidationStyleNumber,
  cardValidationStyleLength,
}: ICreatePasswordForm) => {
  const { t } = useTranslation();

  return (
    <Row className="CreatePasswordForm">
      <Col className="create-password-form">
        <Row>
          <Col className="left-column">
            <p> {t('create-password-instructions')}</p>
          </Col>
        </Row>
        <Row>
          <Col className="left-column-form">
            <Form
              layout="vertical"
              onFinish={(values) => submitNewPassword(values)}
            >
              <Form.Item
                label={t('create-password-input-label')}
                name="password"
                className="password-input-label"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: ' ',
                  },
                  () => ({
                    validator(rule, password) {
                      const passwordNonExists = !password;
                      const passwordNotValid =
                        !passwordVerificationFunction(password);

                      if (passwordNonExists || passwordNotValid) {
                        return Promise.reject(
                          new Error(t('create-password-not-secured-password')),
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Row>
                  <Col span={24} className="password-input-data">
                    <Popover
                      id="passwordPopover"
                      content={
                        <PasswordValidationPopOver
                          styleLet={cardValidationStyleLowerCase}
                          styleCap={cardValidationStyleUpperCase}
                          styleNum={cardValidationStyleNumber}
                          styleLen={cardValidationStyleLength}
                        />
                      }
                      trigger={['click', 'focus']}
                      placement="right"
                    >
                      <Input.Password
                        size="middle"
                        id="password-input"
                        maxLength={64}
                        placeholder={t('create-password-placeholder-password')}
                        onChange={(password) =>
                          onChangePassword(password.target.value)
                        }
                      />
                    </Popover>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label={t('create-password-confirm-input-label')}
                className="password-input-label"
                name="passwordConfirmation"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: t('create-password-confirm-required'),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, confirmPassword) {
                      if (
                        !confirmPassword ||
                        getFieldValue('password') === confirmPassword
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          t('create-password-observation-not-equal-passwords'),
                        ),
                      );
                    },
                  }),
                ]}
                hasFeedback
              >
                <Row>
                  <Col span={24}>
                    <Input.Password
                      size="middle"
                      id="password-confirmation-input"
                      maxLength={64}
                      placeholder={t(
                        'create-password-placeholder-confirm-password',
                      )}
                      onChange={(passwordConfirmation) =>
                        onChangePasswordConfirmation(
                          passwordConfirmation.target.value,
                        )
                      }
                    ></Input.Password>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <Row className="left-column-form-buttons">
                  <Col>
                    <Button
                      name="cancelCreatePasswordButton"
                      className="cancel-create-password-button"
                      type="link"
                      onClick={() => showModalToGoBackToLogin()}
                    >
                      {t('create-password-cancel-button')}
                    </Button>
                    <Button
                      name="confirmCreatePasswordButton"
                      className="confirm-create-password-button"
                      htmlType="submit"
                      shape="round"
                    >
                      {t('create-password-submit-button')}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
