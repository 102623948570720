import React from 'react';
import { Row, Col, Form, Input, Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import './PersonalDataForm.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PasswordValidationPopOver } from './PasswordValidationPopOver/CardPasswordValidation';

interface IPersonalDataForm {
  avatarBefore: Function;
  avatarChange: any;
  avatarPreview: Function;
  avatarLoading: boolean;
  avatar: any;
  deleteAvatar: Function;

  name: string;
  onNameChange: Function;

  surname: string;
  onSurnameChange: Function;

  email: string;
  onEmailChange: Function;
  companyDomain: string;

  emailConfirmation: string;
  onEmailConfirmationChange: Function;
  emailConfirmationMessage: string;

  password: string;
  onPasswordChange: Function;

  passwordConfirmation: string;
  onPasswordConfirmationChange: Function;
  passwordConfirmationMessage: string;

  cardValidationStyleLet: string;
  cardValidationStyleCap: string;
  cardValidationStyleNum: string;
  cardValidationStyleLen: string;
}

export const PersonalDataForm: React.FC<IPersonalDataForm> = ({
  name,
  onNameChange,
  surname,
  onSurnameChange,
  email,
  onEmailChange,
  companyDomain,
  emailConfirmation,
  onEmailConfirmationChange,
  emailConfirmationMessage,
  password,
  onPasswordChange,
  passwordConfirmation,
  onPasswordConfirmationChange,
  passwordConfirmationMessage,
  cardValidationStyleLet,
  cardValidationStyleCap,
  cardValidationStyleNum,
  cardValidationStyleLen,
}: IPersonalDataForm) => {
  const { t } = useTranslation();

  const showEmailConfirmationMessage = () => {
    if (emailConfirmationMessage) {
      return (
        <p className="email-confirmation-message">{emailConfirmationMessage}</p>
      );
    }
  };

  const showPasswordConfirmationMessage = () => {
    if (passwordConfirmationMessage) {
      return (
        <p className="password-confirmation-message">
          {passwordConfirmationMessage}
        </p>
      );
    }
  };

  const getRulesBasedInCompanyDomain = () => {
    if (companyDomain) {
      return [
        { required: true, message: t('personal-data-email-required') },
        {
          message: getEmailValidationMessageIfDomainExists(),
          pattern: new RegExp(
            `^.{1,}@${companyDomain.replace(/[.]/gm, '\\.')}$`,
            'gm',
          ),
        },
      ];
    }

    return [
      { required: true, message: t('login-validation-email') },
      { type: 'email', message: t('login-validation-email-type') },
    ];
  };

  const getEmailValidationMessageIfDomainExists = () => {
    if (!companyDomain) {
      return t('personal-data-email-invalid-general-format');
    }

    return `${t('personal-data-email-invalid-format')}${companyDomain}`;
  };

  return (
    <Row className="RequestAccessForm">
      <Col span={19}>
        <Form className="request-access-form-data" layout="vertical">
          <Form.Item
            name="name"
            rules={[
              { required: true, message: t('personal-data-name-required') },
            ]}
          >
            <Row>
              <Col span={24}>
                <p className="request-access-form-label">
                  {t('personal-data-name')}*
                </p>
                <Input
                  name="name"
                  value={name}
                  placeholder={t('personal-data-name')}
                  size="middle"
                  onChange={(data) => onNameChange(data.target.value)}
                  maxLength={40}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="middle name"
            rules={[
              { required: true, message: t('personal-data-lastname-required') },
            ]}
          >
            <Row>
              <Col span={24}>
                <p className="request-access-form-label">
                  {t('personal-data-lastname')}*
                </p>
                <Input
                  name="lastName"
                  value={surname}
                  placeholder={t('personal-data-lastname')}
                  size="middle"
                  onChange={(data) => onSurnameChange(data.target.value)}
                  maxLength={40}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            validateTrigger="onBlur"
            name="e-mail"
            rules={[
              ...(getRulesBasedInCompanyDomain() as any),
              () => ({
                validator(rule, value) {
                  if (!value || value.split('@').shift().length <= 64) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t('login-validation-email-type'));
                },
              }),
            ]}
          >
            <Row>
              <Col span={24}>
                <p className="request-access-form-label">
                  {t('personal-data-email')}*
                </p>
                <Input
                  name="email"
                  value={email}
                  placeholder={t('personal-data-email')}
                  size="middle"
                  onChange={(data) => onEmailChange(data.target.value)}
                  maxLength={254}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="e-mail confirmation"
            rules={[
              {
                required: true,
                message: t('personal-data-email-confirmation-required'),
              },
            ]}
          >
            <Row>
              <Col span={24}>
                <p className="request-access-form-label">
                  {t('personal-data-email-confimation')}*
                </p>
                <Input
                  name="emailConfirmation"
                  value={emailConfirmation}
                  placeholder={t('personal-data-email-confimation')}
                  size="middle"
                  onChange={(data) =>
                    onEmailConfirmationChange(data.target.value)
                  }
                  maxLength={254}
                />
                {showEmailConfirmationMessage()}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t('personal-data-password-required'),
              },
            ]}
          >
            <Row>
              <Col span={24}>
                <p className="request-access-form-label">
                  {t('personal-data-password')}*
                </p>
                <Popover
                  id="passwordPopover"
                  content={
                    <PasswordValidationPopOver
                      styleLet={cardValidationStyleLet}
                      styleCap={cardValidationStyleCap}
                      styleNum={cardValidationStyleNum}
                      styleLen={cardValidationStyleLen}
                    />
                  }
                  trigger={['hover', 'focus']}
                  placement="right"
                >
                  <Input.Password
                    name="password"
                    value={password}
                    placeholder={t('personal-data-password')}
                    size="middle"
                    autoComplete="new-password"
                    onChange={(data) => onPasswordChange(data.target.value)}
                    maxLength={64}
                  />
                </Popover>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="password confirmation"
            rules={[
              {
                required: true,
                message: t('personal-data-password-confirmation-required'),
              },
            ]}
          >
            <Row>
              <Col span={24}>
                <p className="request-access-form-label">
                  {t('personal-data-password-confirmation')}*
                </p>
                <Input.Password
                  name="passwordConfirmation"
                  placeholder={t('personal-data-password-confirmation')}
                  size="middle"
                  value={passwordConfirmation}
                  autoComplete="new-password"
                  onChange={(data) =>
                    onPasswordConfirmationChange(data.target.value)
                  }
                  maxLength={64}
                />
                {showPasswordConfirmationMessage()}
              </Col>
            </Row>
          </Form.Item>
          <Row className="observation-text-row">
            <Col className="observation-text">
              <InfoCircleOutlined className="observation-icon" />
              {t('personal-data-email-observation-text')}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};
