import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { administrativePanelPendingRequestsApi } from '../../../../services/administrative-panel-pending-requests.api';
// eslint-disable-next-line import/no-cycle
import { store } from '../../../../store/store';

export interface IPendingRequests {
  showRequestModal: boolean;
  filter: IPendingRequestsFilter;
  rowId: number | undefined;
  listing: IPendingRequestsListing;
  requestResponseId: IPendingRequestResponseById;
  refuseStatus: boolean;
}

interface IPendingRequestsFilter {
  openedFilter: boolean;
  requestType: string | undefined;
  name: string | undefined;
  email: string | undefined;
  date: any;
}

interface IPendingRequestsListing {
  dataSource: any[];
  refresh: boolean;
  tableLoading: boolean;
  pageSize: number;
  pageIndex: number;
  totalNumberOfElements: number;
  filterQueryString: string;
  orderQueryString: string;
  idsInDataBase: number[] | undefined;
  firstListingHasAlreadyOccured: boolean;
  defaultSortBehaviourActive: boolean;
}

interface IPendingRequestResponseById {
  type: string | undefined;
  id: number | undefined;
  dateOfOrder: string;
  status: string | undefined;
  name: string | undefined;
  email: string | undefined;
  company: {
    id: number | undefined;
    name: string | undefined;
    address: string | undefined;
    country: {
      name: string | undefined;
      companyIdentifierType: string | undefined;
    };
    identifier: string | undefined;
  };
  user: {
    email: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    company: {
      name: string | undefined;
      address: string | undefined;
      country: {
        name: string | undefined;
      };
      identifier: string | undefined;
    };
  };
}

const initialState: IPendingRequests = {
  showRequestModal: false,
  refuseStatus: false,
  filter: {
    openedFilter: false,
    requestType: undefined,
    name: undefined,
    email: undefined,
    date: undefined,
  },
  rowId: undefined,
  listing: {
    dataSource: [],
    refresh: true,
    tableLoading: false,
    pageSize: 15,
    pageIndex: 1,
    totalNumberOfElements: 0,
    filterQueryString: '',
    orderQueryString: '',
    idsInDataBase: undefined,
    firstListingHasAlreadyOccured: false,
    defaultSortBehaviourActive: true,
  },
  requestResponseId: {
    type: undefined,
    id: undefined,
    dateOfOrder: '',
    status: undefined,
    name: undefined,
    email: undefined,
    company: {
      id: undefined,
      name: undefined,
      address: undefined,
      country: {
        name: undefined,
        companyIdentifierType: undefined,
      },
      identifier: undefined,
    },
    user: {
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      company: {
        name: undefined,
        address: undefined,
        country: {
          name: undefined,
        },
        identifier: undefined,
      },
    },
  },
};

export const asyncActions = {
  GET_PENDING_REQUESTS_LIST: createAsyncThunk(
    'GET_PENDING_REQUESTS_LIST',
    async (queryParams: string) => {
      const pendingRequests =
        await administrativePanelPendingRequestsApi.getPendingRequests(
          queryParams,
        );

      return pendingRequests.data;
    },
  ),
  GET_PENDING_REQUEST_BY_ID: createAsyncThunk(
    'GET_PENDING_REQUEST_BY_ID',
    async (payload: any) => {
      const pendingIdResponse =
        await administrativePanelPendingRequestsApi.getPedingRequestById(
          payload,
        );

      return {
        pendingIdResponse: pendingIdResponse.data,
      };
    },
  ),
  APPROVE_OR_REFUSE_PENDING_REQUEST: createAsyncThunk(
    'APPROVE_OR_REFUSE_PENDING_REQUEST',
    async (payload: any) => {
      const approvedOrRefusedBy = {
        analyzedBy: `${store.getState().login.user?.firstName} (${
          store.getState().login.user?.email
        })`,
      };

      switch (payload.approve_or_refuse) {
        case 'approve':
          await administrativePanelPendingRequestsApi.approveRequest(
            payload.pendingRequestId,
            approvedOrRefusedBy,
          );
          break;

        case 'refuse':
          await administrativePanelPendingRequestsApi.refuseRequest(
            payload.pendingRequestId,
            approvedOrRefusedBy,
          );
          break;

        default:
          break;
      }
    },
  ),
};

export const PendingRequestsSlice = createSlice({
  name: 'PendingRequestsSlice',
  initialState,
  reducers: {
    PENDING_REQUESTS_BACK_TO_ORIGINAL_STATE: () => {
      return {
        ...initialState,
      };
    },
    SHOW_OR_CLOSE_REQUEST_MODAL: (state, action) => {
      state.showRequestModal = action.payload;
    },
    TOGGLE_FILTER: (state) => {
      if (state.filter.openedFilter) {
        state.filter.openedFilter = false;
      } else {
        state.filter.openedFilter = true;
      }
    },
    FILTER_SET_REQUEST_TYPE: (state, action) => {
      state.filter.requestType = action.payload;
    },
    FILTER_SET_DATE: (state, action) => {
      state.filter.date = action.payload;
    },
    FILTER_SET_NAME: (state, action) => {
      state.filter.name = action.payload;
    },
    FILTER_SET_EMAIL: (state, action) => {
      state.filter.email = action.payload;
    },
    REQUESTS_SET_SELECTED_ROWS: (state, action) => {
      state.rowId = action.payload;
    },
    FILTER_SET_QUERY_STRING: (state, action) => {
      state.listing.pageIndex = initialState.listing.pageIndex;
      state.listing.filterQueryString = action.payload;
      state.listing.refresh = true;
    },
    ORDER_SET_QUERY_STRING: (state, action) => {
      state.listing.defaultSortBehaviourActive = false;
      state.listing.orderQueryString = action.payload;
      state.listing.refresh = true;
    },
    FILTER_CLEAR_FIELDS: (state) => {
      if (state.listing.filterQueryString) {
        state.listing.pageIndex = initialState.listing.pageIndex;
      }
      state.listing.filterQueryString = initialState.listing.filterQueryString;
      state.filter = {
        ...initialState.filter,
        openedFilter: state.filter.openedFilter,
      };
      state.listing.refresh = true;
    },
    CHANGE_PAGE: (state, action) => {
      state.listing.pageIndex = action.payload;
      state.listing.refresh = true;
    },
    REFRESH_LISTING: (state) => {
      state.listing.refresh = true;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncActions.GET_PENDING_REQUESTS_LIST.pending, (state) => {
      state.listing.tableLoading = true;
    }),
    builder.addCase(
      asyncActions.GET_PENDING_REQUESTS_LIST.fulfilled,
      (state, action) => {
        state.listing.refresh = false;
        state.listing.tableLoading = false;

        state.listing.dataSource = action.payload.content;
        state.listing.totalNumberOfElements = action.payload.totalElements;
        state.listing.idsInDataBase = action.payload.idsInDataBase;
        state.listing.firstListingHasAlreadyOccured = true;
      },
    ),
    builder.addCase(
      asyncActions.GET_PENDING_REQUESTS_LIST.rejected,
      (state) => {
        state.listing.refresh = false;
        state.listing.tableLoading = false;
      },
    ),
    builder.addCase(
      asyncActions.GET_PENDING_REQUEST_BY_ID.fulfilled,
      (state, action) => {
        const requestIdResponse = action.payload.pendingIdResponse;

        state.requestResponseId.id = requestIdResponse.id;
        state.requestResponseId.type = requestIdResponse.type;
        state.requestResponseId.dateOfOrder = requestIdResponse.createdAt;
        state.requestResponseId.name = requestIdResponse.name;
        state.requestResponseId.email = requestIdResponse.email;
        state.requestResponseId.status = requestIdResponse.status;

        if (requestIdResponse.type === 'COMPANY') {
          state.requestResponseId.company.name = requestIdResponse.company.name;
          state.requestResponseId.company.identifier =
            requestIdResponse.company.identifier;
          state.requestResponseId.company.country.name =
            requestIdResponse.company.country.name;
          state.requestResponseId.company.address =
            requestIdResponse.company.address;
        }

        if (requestIdResponse.type === 'USER') {
          state.requestResponseId.user.firstName =
            requestIdResponse.user.firstName;
          state.requestResponseId.user.lastName =
            requestIdResponse.user.lastName;
          state.requestResponseId.user.company.identifier =
            requestIdResponse.user.company.identifier;
          state.requestResponseId.user.company.country.name =
            requestIdResponse.user.company.country.name;
          state.requestResponseId.user.company.name =
            requestIdResponse.user.company.name;
        }
        state.showRequestModal = true;
      },
    ),
    builder.addCase(
      asyncActions.APPROVE_OR_REFUSE_PENDING_REQUEST.pending,
      (state) => {
        state.listing.tableLoading = true;
      },
    ),
    builder.addCase(
      asyncActions.APPROVE_OR_REFUSE_PENDING_REQUEST.fulfilled,
      (state) => {
        state.listing.tableLoading = false;
        state.listing.refresh = true;
        state.refuseStatus = true;

        if (state.showRequestModal) {
          state.showRequestModal = false;
        }
      },
    ),
  ],
});
