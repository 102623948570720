import PTLocale from 'antd/lib/date-picker/locale/pt_BR';
import ESLocale from 'antd/lib/date-picker/locale/es_ES';

export const getDatePickerDateFormatsBasedOnLocale = (currentLanguage: any) => {
  switch (currentLanguage) {
    case 'en':
      return undefined;

    case 'pt':
    case 'es':
      return 'DD-MM-YYYY';

    default:
      return undefined;
  }
};

export const getDatePickerLocale = (currentLanguage: any) => {
  switch (currentLanguage) {
    case 'en':
      return undefined;

    case 'pt':
      return PTLocale;
    case 'es':
      return ESLocale;

    default:
      return undefined;
  }
};

export const getDatePickerClassForTranslationBasedOnLocale = (
  currentLanguage: any,
) => {
  switch (currentLanguage) {
    case 'en':
      return undefined;

    case 'pt':
      return 'pt-datepicker';
    case 'es':
      return 'es-datepicker';

    default:
      return undefined;
  }
};

export const changeDatePickerMonthLabelBasedOnLocale = (
  currentLanguage: any,
) => {
  const datePickerHeader = document.querySelector('.ant-picker-month-btn');

  if (!datePickerHeader) {
    return;
  }

  switch (currentLanguage) {
    case 'pt':
      switch (datePickerHeader.innerHTML) {
        case 'Feb':
          datePickerHeader.innerHTML = 'Fev';
          break;

        case 'Apr':
          datePickerHeader.innerHTML = 'Abr';
          break;

        case 'May':
          datePickerHeader.innerHTML = 'Mai';
          break;

        case 'Aug':
          datePickerHeader.innerHTML = 'Ago';
          break;

        case 'Sep':
          datePickerHeader.innerHTML = 'Set';
          break;

        case 'Oct':
          datePickerHeader.innerHTML = 'Out';
          break;

        case 'Dec':
          datePickerHeader.innerHTML = 'Dez';
          break;

        default:
          break;
      }

      break;

    case 'es':
      switch (datePickerHeader.innerHTML) {
        case 'Jan':
          datePickerHeader.innerHTML = 'Ene';
          break;

        case 'Apr':
          datePickerHeader.innerHTML = 'Abr';
          break;

        case 'May':
          datePickerHeader.innerHTML = 'Mayo';
          break;

        case 'Aug':
          datePickerHeader.innerHTML = 'Ago';
          break;

        case 'Sep':
          datePickerHeader.innerHTML = 'Sept';
          break;

        case 'Dec':
          datePickerHeader.innerHTML = 'Dic';
          break;

        default:
          break;
      }
      break;

    default:
      return undefined;
  }
};
