import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { termsRequestsApi } from '../../services/terms-requests.api';

export interface IModalToUserAcceptTermInitialState {
  termsModalVisible: boolean;
  userRecentlyAccepted: boolean;
  modalContent: IModalToUserAcceptTermContent;
  language: IModalToUserAcceptTermLanguage;
}

interface IModalToUserAcceptTermLanguage {
  selectedLanguage: undefined | 'pt' | 'en' | 'es';
}

interface IModalToUserAcceptTermContent {
  version: string;
  ptContent: string;
  enContent: string;
  esContent: string;
}

const initialState: IModalToUserAcceptTermInitialState = {
  termsModalVisible: false,
  userRecentlyAccepted: false,
  language: {
    selectedLanguage: undefined,
  },
  modalContent: {
    version: '',
    ptContent: '',
    esContent: '',
    enContent: '',
  },
};

export const ModalToUserAcceptTermAsyncActions = {
  GET_TERM_TO_MODAL: createAsyncThunk('GET_TERM_TO_MODAL', async () => {
    const activeTermResponse = await termsRequestsApi.getActiveTerms();

    return activeTermResponse.data;
  }),
  USER_ACCEPT_TERMS_VIA_MODAL: createAsyncThunk(
    'USER_ACCEPT_TERMS_VIA_MODAL',
    async () => {
      await termsRequestsApi.userAcceptTermsViaModal();
    },
  ),
};

export const ModalToUserAcceptTermSlice = createSlice({
  name: 'ModalToUserAcceptTermSlice',
  initialState,
  reducers: {
    SET_MODAL_VISIBILITY: (state, action) => {
      state.termsModalVisible = action.payload;
    },
    SET_SELECTED_LANGUAGE: (state, action) => {
      state.language.selectedLanguage = action.payload;
    },
    BACK_TO_ORIGINAL_STATE: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(
      ModalToUserAcceptTermAsyncActions.USER_ACCEPT_TERMS_VIA_MODAL.fulfilled,
      (state) => {
        state.userRecentlyAccepted = true;
        state.termsModalVisible = false;
      },
    ),
    builder.addCase(
      ModalToUserAcceptTermAsyncActions.GET_TERM_TO_MODAL.fulfilled,
      (state, action) => {
        const { payload } = action;

        if (payload.content[0]) {
          state.modalContent.ptContent =
            payload.content[0].contents.find(
              (x: any) => x.language === 'PORTUGUESE',
            )?.description || '';

          state.modalContent.enContent =
            payload.content[0].contents.find(
              (x: any) => x.language === 'ENGLISH',
            )?.description || '';

          state.modalContent.esContent =
            payload.content[0].contents.find(
              (x: any) => x.language === 'SPANISH',
            )?.description || '';

          state.modalContent.version = payload.content[0].version;
          state.termsModalVisible = true;
        } else {
          state.termsModalVisible = false;
        }
      },
    ),
  ],
});
