import { store } from '../../store/store';

export const myProfileChangesChecker = () => {
  const name = (document.getElementById('name') as HTMLInputElement).value;
  const lastName = (document.getElementById('lastName') as HTMLInputElement)
    .value;

  const originalName = store.getState().login.user?.firstName;
  const originalLastName = store.getState().login.user?.lastName;

  const changesInPassword = () => {
    if (store.getState().myProfile.showChangePasswordForm) {
      const originalPassword = (
        document.getElementById('originalPassword') as HTMLInputElement
      ).value;
      const newPassword = (
        document.getElementById('newPassword') as HTMLInputElement
      ).value;
      const confirmNewPassword = (
        document.getElementById('confirmNewPassword') as HTMLInputElement
      ).value;
      return originalPassword || newPassword || confirmNewPassword || false;
    }
    return false;
  };

  return (
    name !== originalName ||
    lastName !== originalLastName ||
    changesInPassword() ||
    false
  );
};
