import { store } from '../../store/store';

export const checkChangesInRequestCompany = () => {
  const nameInput = (document.getElementById('companyName') as HTMLInputElement)
    .value;

  const addressInput = (
    document.getElementById('companyAdress') as HTMLInputElement
  ).value;

  const countrySelect =
    store.getState().CompanyAdministrativePanel.companies.companyRegister
      .countryId;

  const identifierInput = (
    document.getElementById('cnpjTaxId') as HTMLInputElement
  ).value;

  const responsibleEmailInput = (
    document.getElementById('responsibleEmail') as HTMLInputElement
  ).value;

  if (
    nameInput ||
    addressInput ||
    countrySelect ||
    identifierInput ||
    responsibleEmailInput
  ) {
    return true;
  }
  return false;
};
