import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { administrativePanelNewsApi } from '../../../services/administrative-panel-news';

interface ICarouselSlice {
  data: any[];
  loadedActivatedNews: boolean;
}

const initialState: ICarouselSlice = {
  data: [],
  loadedActivatedNews: false,
};

export const carouselAsyncActions = {
  GET_ACTIVATED_NEWS_FOR_CAROUSEL: createAsyncThunk(
    'GET_ACTIVATED_NEWS_FOR_CAROUSEL',
    async () => {
      const newsActivated =
        await administrativePanelNewsApi.getNewsActivatedToCarousel();
      return newsActivated.data;
    },
  ),
};

export const CarouselSlice = createSlice({
  name: 'CarouselSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => [
    builder.addCase(
      carouselAsyncActions.GET_ACTIVATED_NEWS_FOR_CAROUSEL.fulfilled,
      (state, action) => {
        if (
          state.data.map((x) => x.id).toString() ===
          action.payload.map((x) => x.id).toString()
        ) {
          return;
        }

        state.data = action.payload;
        state.loadedActivatedNews = true;
      },
    ),
    builder.addCase(
      carouselAsyncActions.GET_ACTIVATED_NEWS_FOR_CAROUSEL.rejected,
      (state) => {
        state.loadedActivatedNews = true;
      },
    ),
  ],
});
