import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Filter } from '../../../../components/Filter/Filter';
import { PendingRequestModal } from '../../../../components/PendingRequestModal/PendingRequestModal';

import { ReactComponent as RedBar } from '../../../../assets/red_bar_icon.svg';
import { ShowFiltersButton } from '../../../../components/ShowFiltersButton/ShowFiltersButton';
import { PagingTableWithExpansion } from '../../../../components/PagingTableWithExpansion/PagingTableWithExpansion';
import { AppDispatch, RootState } from '../../../../store/store';
import { asyncActions, PendingRequestsSlice } from './PendingRequests.Slice';
import { scrollToTopFunction } from '../../../../helpers/useScrollToTop/useScrollToTop';
import { RequestTrackerSlice } from '../../../RequestTracker/RequestTracker.Slice';
import { ConcludedRequestsSlice } from '../ConcludedRequests/ConcludedRequests.Slice';

export const PendingRequests = () => {
  const pendingRequestsState = useSelector(
    (state: RootState) => state.PendingRequests,
  );
  const pendingRequestTracker = useSelector(
    (state: RootState) => state.requestTracker.numberOfPendingRequests,
  );

  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation();

  useEffect(() => {
    if (pendingRequestsState.listing.tableLoading) {
      return;
    }

    if (!pendingRequestsState.listing.refresh) {
      return;
    }

    let queryString = `?status=PENDING&page=${
      pendingRequestsState.listing.pageIndex - 1
    }&size=${pendingRequestsState.listing.pageSize}`;

    if (pendingRequestsState.listing.filterQueryString) {
      queryString += `${pendingRequestsState.listing.filterQueryString}`;
    }

    if (pendingRequestsState.listing.defaultSortBehaviourActive) {
      queryString += `&sort=date,desc`;
    }

    if (pendingRequestsState.listing.orderQueryString) {
      queryString += `${pendingRequestsState.listing.orderQueryString}`;
    }

    dispatch(asyncActions.GET_PENDING_REQUESTS_LIST(queryString));
  }, [
    dispatch,
    pendingRequestsState.listing.pageIndex,
    pendingRequestsState.listing.pageSize,
    pendingRequestsState.listing.refresh,
    pendingRequestsState.listing.tableLoading,
    pendingRequestsState.listing.filterQueryString,
    pendingRequestsState.listing.orderQueryString,
    pendingRequestsState.listing.defaultSortBehaviourActive,
  ]);

  useEffect(() => {
    if (!pendingRequestsState.listing.firstListingHasAlreadyOccured) {
      return;
    }

    dispatch(
      RequestTrackerSlice.actions.UPDATE_NUMBER_OF_PENDING_REQUESTS_AFTER_LISTING(
        pendingRequestsState.listing.totalNumberOfElements,
      ),
    );

    dispatch(
      ConcludedRequestsSlice.actions.REFRESH_LIST_AFTER_CHANGES_IN_NUMBER_OF_PENDING_REQUESTS(),
    );
  }, [
    dispatch,
    pendingRequestsState.listing.firstListingHasAlreadyOccured,
    pendingRequestsState.listing.totalNumberOfElements,
  ]);

  useEffect(() => {
    if (!pendingRequestsState.listing.firstListingHasAlreadyOccured) {
      return;
    }

    if (
      pendingRequestTracker !==
      pendingRequestsState.listing.totalNumberOfElements
    ) {
      dispatch(PendingRequestsSlice.actions.REFRESH_LISTING());
    }
  }, [
    dispatch,
    pendingRequestsState.listing.firstListingHasAlreadyOccured,
    pendingRequestTracker,
    pendingRequestsState.listing.totalNumberOfElements,
  ]);

  const filterFields = [
    {
      fieldTitle: t('administrative-panel-requests-filter-request-type'),
      fieldType: 'select',
      fieldProperty: 'type',
      fieldValue: pendingRequestsState.filter.requestType,
      selectOptions: [
        {
          id: 1,
          name: t('administrative-panel-requests-filter-option-new-user'),
        },
        {
          id: 2,
          name: t('administrative-panel-requests-filter-option-new-company'),
        },
      ],
    },
    {
      fieldTitle: t('administrative-panel-requests-filter-name'),
      fieldType: 'input',
      fieldProperty: 'name',
      fieldValue: pendingRequestsState.filter.name,
    },
    {
      fieldTitle: t('administrative-panel-requests-filter-email'),
      fieldType: 'input',
      fieldProperty: 'email',
      fieldValue: pendingRequestsState.filter.email,
    },
    {
      fieldTitle: t('administrative-panel-requests-filter-date-title'),
      fieldType: 'datePicker',
      fieldProperty: 'date',
      fieldValue: pendingRequestsState.filter.date,
    },
  ];

  const toggleFilter = () => {
    dispatch(PendingRequestsSlice.actions.TOGGLE_FILTER());
  };

  const [columnsWidth, setColumnsWidth] = useState({
    nameColumnWidth: 12,
    emailColumnWidth: 12,
    dateColumnWidth: 12,
  });

  const resizeHandlerSpeed = 0.028;
  const resizeColumns = (
    columnName: 'nameColumnWidth' | 'emailColumnWidth' | 'dateColumnWidth',
    movement: number,
  ) => {
    setColumnsWidth({
      ...columnsWidth,
      [columnName]: (columnsWidth[columnName] += movement * resizeHandlerSpeed),
    });
  };

  const columnsCursor = [
    {
      columnTitle: t('administrative-panel-requests-table-col-name'),
      columnProperty: 'name',
      width: columnsWidth.nameColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('nameColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-requests-table-col-email'),
      columnProperty: 'email',
      width: columnsWidth.emailColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('emailColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-requests-table-col-date-title'),
      columnProperty: 'date',
      width: columnsWidth.dateColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('dateColumnWidth', event.movementX);
      },
    },
  ];

  const defaultSortBehaviour = {
    columnProperty: 'date',
    disableDefaultSortBehaviour: () => {},
    order: 'descend',
  };

  const getRequestById = (id: any) => {
    dispatch(asyncActions.GET_PENDING_REQUEST_BY_ID(id));
  };

  const submitPendingRequestsFilter = (fields: any) => {
    const filterQueryParams = Object.entries(fields).reduce(
      (acc, [key, value]: [any, any]) => {
        switch (key) {
          case 'type':
            dispatch(
              PendingRequestsSlice.actions.FILTER_SET_REQUEST_TYPE(value),
            );
            break;
          case 'name':
            dispatch(PendingRequestsSlice.actions.FILTER_SET_NAME(value));
            break;
          case 'email':
            dispatch(PendingRequestsSlice.actions.FILTER_SET_EMAIL(value));
            break;
          case 'date':
            dispatch(PendingRequestsSlice.actions.FILTER_SET_DATE(value));
            break;
          default:
            break;
        }

        if (!value) {
          return acc;
        }

        if (key === 'type') {
          acc += `&${key}=${value === 1 ? 'USER' : 'COMPANY'}` as any;
          return acc;
        }

        if (key === 'date') {
          acc += `&${key}=${value.format('YYYY-MM-DD')}` as any;
          return acc;
        }

        acc += `&${key}=${String(value).trim()}` as any;
        return acc;
      },
      '',
    );

    dispatch(
      PendingRequestsSlice.actions.FILTER_SET_QUERY_STRING(filterQueryParams),
    );
  };

  const onChangePage = (pageIndex: number) => {
    if (!pageIndex) {
      return;
    }

    scrollToTopFunction('pending-requests-area');
    dispatch(PendingRequestsSlice.actions.CHANGE_PAGE(pageIndex));
  };

  const onChangeSort = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    let sortBy = '';

    if (!order || !field) {
      return dispatch(
        PendingRequestsSlice.actions.ORDER_SET_QUERY_STRING(sortBy),
      );
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;

    switch (fieldToQueryParam) {
      default:
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam}`
            : `${fieldToQueryParam},desc`;
    }

    return dispatch(
      PendingRequestsSlice.actions.ORDER_SET_QUERY_STRING(`&sort=${sortBy}`),
    );
  };

  return (
    <div id="pending-requests-area">
      <PendingRequestModal />
      <Row style={{ marginTop: '1em' }}>
        <RedBar />
      </Row>
      <Row>
        <h1>{t('administrative-panel-pending-requests-page-title')}</h1>
      </Row>
      <Row justify="end">
        <ShowFiltersButton
          onToggle={toggleFilter}
          openedFilter={!pendingRequestsState.filter.openedFilter}
        />
      </Row>
      <Row>
        <Col span={24}>
          <Filter
            clearFilterFields={() => {
              dispatch(PendingRequestsSlice.actions.FILTER_CLEAR_FIELDS());
            }}
            fields={filterFields}
            openedFilter={pendingRequestsState.filter.openedFilter}
            submitFilterFields={submitPendingRequestsFilter}
          />
        </Col>
      </Row>
      <Row justify="start">
        <Col span={24}>
          <PagingTableWithExpansion
            emptyText={t('administrative-panel-empty-table')}
            dataSource={pendingRequestsState.listing.dataSource}
            tableLoading={pendingRequestsState.listing.tableLoading}
            columns={columnsCursor}
            options={[]}
            pageSize={pendingRequestsState.listing.pageSize}
            pageIndex={pendingRequestsState.listing.pageIndex}
            numberOfItems={pendingRequestsState.listing.totalNumberOfElements}
            onChangePage={onChangePage}
            onChangeSort={onChangeSort}
            onChangeOptionsVisibility={() => {}}
            rowClickAction={(record: any) => getRequestById(record.id)}
            selectRowsToGivenTab={() => {}}
            selectIdFromRowsSelected={() => {}}
            isPendingTable
            disableOptionsColumn
            disableSelectionColumn
            defaultSortBehaviour={defaultSortBehaviour}
          />
        </Col>
      </Row>
    </div>
  );
};
