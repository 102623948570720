import React, { useRef } from 'react';

import uniqid from 'uniqid';

import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import './ReactQuillEditor.scss';
import { messageNotification } from '../../helpers/messageNotification/messageNotification';
import { t } from 'i18next';
import { pageApi } from '../../services/page.api';

interface IReactQuillEditor {
  initialValue: string;
  readonly?: boolean;
  canUploadImage?: boolean;
  uploadImageAsFile?: boolean;
  onChangeHandler?: (content: string) => void;
}

interface IToolbarConfig {
  container: any[];
  handlers?: {
    image: () => void;
  };
}

export const ReactQuillEditor: React.FC<IReactQuillEditor> = ({
  initialValue,
  readonly,
  canUploadImage,
  uploadImageAsFile,
  onChangeHandler,
}: IReactQuillEditor) => {
  const quillRef = useRef<any>(null);

  const imageUploadErrorNotification = () => {
    messageNotification.errorMessage(
      t('documentation-upload-image-error-notification-title'),
      t('documentation-upload-image-error-notification-content'),
    );
  };

  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      const file: any = input && input.files ? input.files[0] : null;
      const formData = new FormData();
      formData.append('image', file, `${uniqid()}${file.name}`);

      const editor = quillRef?.current?.getEditor();
      if (editor == null) {
        imageUploadErrorNotification();
        return;
      }
      pageApi
        .uploadImageInPage(formData)
        .then((res) => {
          const range = editor.getSelection(true);
          editor.insertEmbed(range.index, 'image', res.data.uri);
        })
        .catch(() => {
          imageUploadErrorNotification();
        });
    };
  };

  const createModules = () => {
    const colors = [
      '#000000',
      '#FFFFFF',
      '#4A4F55',
      '#41B4D2',
      '#F08791',
      '#F5AF82',
      '#46B8A6',
    ];

    const toolbarConfig: IToolbarConfig = {
      container: [
        [{ header: [1, 2, false] }],
        [{ size: [] }, { background: colors }, { color: colors }],
        [{ align: [] }],
        ['bold', 'italic', 'underline'],
        canUploadImage
          ? ['image', 'code', { list: 'bullet' }, { list: 'ordered' }, 'link']
          : ['code', { list: 'bullet' }, { list: 'ordered' }, 'link'],
      ],
    };

    if (uploadImageAsFile) {
      toolbarConfig.handlers = {
        image: imageHandler,
      };
    }

    return {
      toolbar: readonly ? false : toolbarConfig,
    };
  };

  const createFormats = () => {
    const baseFormats = [
      'header',
      'size',
      'background',
      'color',
      'align',
      'bold',
      'italic',
      'underline',
      'code',
      'list',
      'link',
    ];

    return canUploadImage ? ['image', ...baseFormats] : baseFormats;
  };

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      modules={createModules()}
      formats={createFormats()}
      value={initialValue}
      readOnly={readonly}
      id={readonly ? 'readonly-editor' : undefined}
      style={readonly ? {} : { height: 500 }}
      onChange={(content, _delta, _source, editor) => {
        // The Quill Editor still outputs some HTML even when the editor is empty,
        // so we check the length to know if it is empty.
        if (onChangeHandler) {
          onChangeHandler(editor.getLength() > 1 ? content : '');
        }
      }}
    />
  );
};
