import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { termsRequestsApi } from '../../../../services/terms-requests.api';

export interface IRequestAccessTerms {
  language: IRequestAccessTermsLanguage;
  content: IRequestAccessTermsContent;
}

interface IRequestAccessTermsLanguage {
  selectedLanguage: undefined | 'pt' | 'en' | 'es';
}

interface IRequestAccessTermsContent {
  ptContent: string;
  esContent: string;
  enContent: string;
}

const initialState: IRequestAccessTerms = {
  language: {
    selectedLanguage: undefined,
  },
  content: {
    ptContent: '',
    esContent: '',
    enContent: '',
  },
};

export const RequestAccessTermsAsyncActions = {
  GET_TERM: createAsyncThunk('GET_TERM', async () => {
    const requestAccessTermResponse = await termsRequestsApi.getActiveTerms();

    return requestAccessTermResponse.data;
  }),
};

export const RequestAccessTermsSlice = createSlice({
  name: 'RequestAccessTermsSlice',
  initialState,
  reducers: {
    SET_SELECTED_LANGUAGE: (state, action) => {
      state.language.selectedLanguage = action.payload;
    },
    BACK_TO_ORIGINAL_STATE: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      RequestAccessTermsAsyncActions.GET_TERM.fulfilled,
      (state, action) => {
        const { payload } = action;

        if (payload.content[0]) {
          state.content.ptContent =
            payload.content[0].contents.find(
              (x: any) => x.language === 'PORTUGUESE',
            )?.description || '';

          state.content.enContent =
            payload.content[0].contents.find(
              (x: any) => x.language === 'ENGLISH',
            )?.description || '';

          state.content.esContent =
            payload.content[0].contents.find(
              (x: any) => x.language === 'SPANISH',
            )?.description || '';
        }
      },
    );
  },
});
