import { Button } from 'antd';
import React from 'react';

import './DeleteSelectedButton.scss';

import { ReactComponent as Trash } from '../../assets/trash_icon.svg';
import { showModal } from '../../helpers/showModal/showModal';

interface IDeleteSelectedButton {
  numberOfItemsSelected: number | undefined;
  messageConfirmationByGivenTab: string;
  singularContentConfirmationByGivenTab: string;
  pluralContentConfirmationByGivenTab: string;
  deleteOneTitleModal: string;
  deleteOneContentModal: string;
  deleteManyTitleModal: string;
  deleteManyContentModal: string;
  onOkText: string;
  onCancelText: string;
  deleteAction: Function;
}

export const DeleteSelectedButton: React.FC<IDeleteSelectedButton> = ({
  numberOfItemsSelected,
  messageConfirmationByGivenTab,
  singularContentConfirmationByGivenTab,
  pluralContentConfirmationByGivenTab,
  deleteOneTitleModal,
  deleteOneContentModal,
  deleteManyTitleModal,
  deleteManyContentModal,
  onOkText,
  onCancelText,
  deleteAction,
}: IDeleteSelectedButton) => {
  const showSingularOrPluralButton = () => {
    if (numberOfItemsSelected) {
      if (numberOfItemsSelected === 1) {
        return `${messageConfirmationByGivenTab} ${numberOfItemsSelected} ${singularContentConfirmationByGivenTab}`;
      }
      if (numberOfItemsSelected > 1) {
        return `${messageConfirmationByGivenTab} ${numberOfItemsSelected}  ${pluralContentConfirmationByGivenTab}`;
      }
    }
  };

  const showTrashIcon = () => {
    if (numberOfItemsSelected && numberOfItemsSelected > 0) {
      return <Trash className="trash-icon-button" />;
    }
  };

  const deleteManyConfirmModal = () => {
    if (numberOfItemsSelected && numberOfItemsSelected > 0) {
      if (numberOfItemsSelected === 1) {
        showModal(
          'warning',
          `${deleteOneTitleModal}`,
          `${deleteOneContentModal}`,
          () => {
            deleteAction();
          },
          () => {},
          `${onOkText}`,
          `${onCancelText}`,
        );
      }
      if (numberOfItemsSelected > 1) {
        showModal(
          'warning',
          `${deleteManyTitleModal}`,
          `${deleteManyContentModal}`,
          () => {
            deleteAction();
          },
          () => {},
          `${onOkText}`,
          `${onCancelText}`,
        );
      }
    }
  };

  const showOrHideDeleteButton = () => {
    if (numberOfItemsSelected === 0) {
      return <div />;
    }

    return (
      <Button
        id="delete-selected-companies"
        shape="round"
        className="DeleteSelectedButton"
        onClick={() => {
          deleteManyConfirmModal();
        }}
      >
        {showSingularOrPluralButton()?.toUpperCase()} {showTrashIcon()}
      </Button>
    );
  };

  return showOrHideDeleteButton();
};
