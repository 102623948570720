import { securedBaseApi } from './api.config';

export interface IPermissionsWithAccessList {
  content: IPermissionsWithAccessItem[];
  totalElements: number;
}

export interface IPermissionsWithAccessItem {
  name: string;
  id: number;
  accessCount: number;
}

export interface IPermissionsWithViewOrWriteList {
  content: IPermissionsWithViewOrWriteItem[];
  idsInDatabase: Array<number>;
  totalElements: number;
}

export interface IPermissionsWithViewOrWriteItem {
  name: string;
  id: number;
  canRead: boolean;
  canWrite: boolean;

  originalCanRead: boolean;
  originalCanWrite: boolean;
}

export const administrativePanelPermissionsApi = {
  getCompaniesOrRepositoriesWithAccessCount: (queryParams: string) => {
    return securedBaseApi.get<IPermissionsWithAccessList>(
      `permissions/relative-entity?entity=${queryParams}`,
    );
  },
  getCompaniesOrRepositoriesWithViewOrWrite: (queryParams: string) => {
    return securedBaseApi.get<IPermissionsWithViewOrWriteList>(
      `permissions/related-entity?entity=${queryParams}`,
    );
  },
  saveChangesInPermissions: (newPermissions: any) => {
    return securedBaseApi.post('permissions/batch', newPermissions);
  },
};
