import { securedBaseApi } from './api.config';

export interface IMyProfile {
  personalDataRequest: IPersonalDataRequest[];
  passwordRequest: IChangePasswordRequest[];
}

export interface IPersonalDataRequest {
  firstName: string | undefined;
  lastName: string | undefined;
}

export interface IChangePasswordRequest {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}

export const myProfileApi = {
  changePassword: (passwordBody: IChangePasswordRequest) => {
    return securedBaseApi.patch(`/me/change_password`, passwordBody);
  },
  setNewPersonalData: (newPersonalData: IPersonalDataRequest) => {
    return securedBaseApi.patch(`/me`, newPersonalData);
  },
};
