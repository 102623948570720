/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Row, Breadcrumb, Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import './PageResult.scss';

interface IPageResult {
  goToBeadcrumb: Function;
  searchResult: any;
  focusedWord: string;
}

export const PageResult: React.FC<IPageResult> = ({
  goToBeadcrumb,
  searchResult,
  focusedWord,
}: IPageResult) => {
  const { t } = useTranslation();

  const onGenerateTitle = (value: string) => {
    if (!focusedWord) {
      return <span style={{ color: '#373737' }}>{value}</span>;
    }

    const ocorrencias = [];
    let searchDeOcorrencias = true;
    let indexDoSearch = 0;

    while (searchDeOcorrencias) {
      const ocorrencia = value
        .toLowerCase()
        .indexOf(focusedWord.toLowerCase(), indexDoSearch);

      if (ocorrencia !== -1) {
        indexDoSearch = ocorrencia + 1;
        ocorrencias.push(ocorrencia);
      } else {
        searchDeOcorrencias = false;
      }
    }

    const remadeString = [];
    let indexOfRemakingString = 0;

    ocorrencias.forEach((x) => {
      const nonOcorrence = value.slice(indexOfRemakingString, x);

      if (nonOcorrence) {
        remadeString.push(
          <span style={{ color: '#373737' }}>{nonOcorrence}</span>,
        );
      }

      const ocorrence = value.slice(x, x + focusedWord.length);
      if (ocorrence) {
        remadeString.push(
          <span style={{ color: '#41B4D2' }}>{ocorrence}</span>,
        );
      }

      indexOfRemakingString = x + focusedWord.length;
    });

    if (indexOfRemakingString !== value.length) {
      const finalNonOcurrence = value.slice(indexOfRemakingString);
      remadeString.push(
        <span style={{ color: '#373737' }}>{finalNonOcurrence}</span>,
      );
    }

    return <span key={value}>{remadeString}</span>;
  };

  const onGenerateResultList = () => {
    if (searchResult.content?.length === 0) {
      return (
        <div>
          <Row justify="center">
            <Col>
              <Result
                status="404"
                title={t('documentation-listing-search-not-found-title')}
                subTitle={t('documentation-listing-search-not-found-subtitle')}
              />
            </Col>
          </Row>
        </div>
      );
    }
    return searchResult.content?.map((x: any) => {
      const searchItem = x.page.slug;
      return (
        <div key={searchItem} className="documentation-result">
          <Row>
            <Col span={2}></Col>
            <Col span={22}>
              <div className="result-card">
                <Button
                  type="link"
                  name="result-title"
                  className="result-title"
                  onClick={() => goToBeadcrumb(searchItem)}
                >
                  {onGenerateTitle(x.title)}
                </Button>
                <br />
                <Button type="link" className="result-description">
                  {x.description}
                </Button>
                <Breadcrumb separator=">" className="result-path">
                  <Breadcrumb.Item href="">
                    {t('documentation')}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => goToBeadcrumb(searchItem)}>
                    {x.title}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </Col>
          </Row>
          <hr className="result-horizontal-line" />
        </div>
      );
    });
  };

  return (
    <div className="PageResult" id="page-result">
      {onGenerateResultList()}
    </div>
  );
};
