import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginFormApi } from '../../services/login-form.api';
import { ILoginRequest } from '../../interfaces/services-interfaces/ILoginRequest';
import { IMeResponse } from '../../interfaces/services-interfaces/IMeResponse';

interface ILoginInitialState {
  loggedIn: boolean;
  authenticated: boolean;
  loginLoading: boolean;
  user?: IMeResponse;
  prefLanguage: boolean;
  finishedLoginAttempt: boolean;
}

const initialState: ILoginInitialState = {
  loggedIn: false,
  authenticated: false,
  loginLoading: false,
  user: undefined,
  prefLanguage: false,
  finishedLoginAttempt: false,
};

export const asyncActions = {
  LOGIN: createAsyncThunk('LOGIN', async (payload: ILoginRequest) => {
    const loginResult = await loginFormApi.login(payload);

    return {
      token: loginResult.headers.authorization,
    };
  }),
  ME: createAsyncThunk('ME', async () => {
    const meResult = await loginFormApi.me();

    return {
      user: meResult.data,
    };
  }),
  QUICKLOGIN: createAsyncThunk('QUICKLOGIN', async () => {
    const meResult = await loginFormApi.me();

    return {
      user: meResult.data,
    };
  }),
  SOFTLOGIN: createAsyncThunk('SOFTLOGIN', async () => {
    const softLoginResult = await loginFormApi.softLogin();

    return {
      user: softLoginResult.data,
    };
  }),
};

export const LoginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    LOGOUT: (state) => {
      state.loggedIn = false;
      state.authenticated = false;
      state.user = undefined;
      localStorage.removeItem('token');
      localStorage.removeItem('authorization');
    },
    LOGGEDIN: (state, action) => {
      state.loggedIn = action.payload.loggedState;
    },
    LOGINLOADING: (state, action) => {
      state.loginLoading = action.payload.loadingState;
    },
    HASPREFERENCELANGUAGE: (state, action) => {
      state.prefLanguage = action.payload;
    },
    CHANGELANGUAGEPREFERENCEDONE: (state) => {
      state.prefLanguage = false;
    },
    SET_TERM_AS_ACCEPTED_FOR_USER: (state) => {
      if (state.user) {
        state.user.termV3Accepted = true;
      }
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncActions.LOGIN.pending, (state) => {
      state.loginLoading = true;
    }),
    builder.addCase(asyncActions.LOGIN.fulfilled, (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.authenticated = true;
      state.loginLoading = false;
    }),
    builder.addCase(asyncActions.LOGIN.rejected, (state) => {
      state.loginLoading = false;
    }),
    builder.addCase(asyncActions.ME.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.finishedLoginAttempt = true;
    }),
    builder.addCase(asyncActions.ME.rejected, (state) => {
      state.finishedLoginAttempt = true;
      state.loggedIn = false;
      state.authenticated = false;
      state.user = undefined;
      state.prefLanguage = false;
      localStorage.removeItem('token');
    }),
    builder.addCase(asyncActions.QUICKLOGIN.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.authenticated = true;
      state.loggedIn = true;
      state.finishedLoginAttempt = true;
    }),
    builder.addCase(asyncActions.QUICKLOGIN.rejected, (state) => {
      state.finishedLoginAttempt = true;
    }),
    builder.addCase(asyncActions.SOFTLOGIN.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.authenticated = true;
      state.loggedIn = true;
      state.finishedLoginAttempt = true;
    }),
    builder.addCase(asyncActions.SOFTLOGIN.rejected, (state) => {
      state.finishedLoginAttempt = true;
    }),
  ],
});
