import { securedBaseApi } from './api.config';

export interface IPendingRequestsResponse {
  content: IPendingRequest[];
  totalElements: number;
  idsInDataBase: number[] | undefined;
}

export interface IPendingRequest {
  id: number;
  status: string;
  type: string;
  createdAt: Date;
  company: IPendingRequestCompany;
}

interface IPendingRequestCompany {
  id: number;
  identifier: string;
  name: string;
  email: string;
  updatedAt: Date;
  address: string;
  country: IPendingRequestCompanyCountry;
}

interface IPendingRequestCompanyCountry {
  id: number;
  name: string;
  code: string;
  companyIdentifierType: string;
}

export interface IPendingRequestResponseById {
  type: string;
  id: number;
  status: string;
  name: string;
  email: string;
  createdAt: string;
  company: {
    id: number;
    name: string;
    address: string;
    country: {
      id: number;
      code: string;
      name: string;
      companyIdentifierType: string;
    };
    identifier: string;
  };
  user: {
    email: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    company: {
      name: string | undefined;
      address: string | undefined;
      country: {
        name: string | undefined;
      };
      identifier: string | undefined;
    };
  };
}

export const administrativePanelPendingRequestsApi = {
  getPendingRequests: (queryParams: string) => {
    return securedBaseApi.get<IPendingRequestsResponse>(
      `/solicitations${queryParams}`,
    );
  },
  getPedingRequestById: (id: number) => {
    return securedBaseApi.get<IPendingRequestResponseById>(
      `/solicitations/${id}`,
    );
  },
  approveRequest: (requestId: string, approvedOrRefusedBy: any) => {
    return securedBaseApi.patch(
      `/solicitations/${requestId}/approve`,
      approvedOrRefusedBy,
    );
  },
  refuseRequest: (requestId: string, approvedOrRefusedBy: any) => {
    return securedBaseApi.patch(
      `/solicitations/${requestId}/refuse`,
      approvedOrRefusedBy,
    );
  },
};
