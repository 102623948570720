import React, { Fragment } from 'react';
import { Form, Select, Col, Row, Button, Card, Input } from 'antd';
import { IMaskInput } from 'react-imask';
import './CompanyForm.scss';
import { useTranslation } from 'react-i18next';

import { SuggestCompanyModal } from '../../SuggestCompanyModal/SuggestCompanyModal';
import { ICountriesList } from '../../../services/administrative-panel-companies';

interface ICompanyForm {
  showCompanyData: any;
  showIntroductionParagraph: Function;
  idNumber: any;
  inputCnpjOrTaxIdDisabled: boolean;
  inputCnpjOrTaxIdMask: string;
  placeholder: string;
  companyIdentifierType: string;
  company: ICompany | undefined;
  onChange: Function;
  onSelect: Function;
  onNextStep: Function;
  clickSendButton: Function;
  searchButtonDisabled: boolean;
  searchButtonLoading: boolean;
  searchCompanyFailed: boolean | undefined;
  countryList: ICountriesList[];
  selectedCountry: number | undefined;
  suggestCompanyModalVisibility: boolean;
  hideSuggestCompanyButtonForPendingCompanies: boolean;
  toggleSuggestCompanyModal: () => void;
  transferCountryValuesToAdministrativePanelCompanyRegister: () => void;
}

interface ICompany {
  cnpj: string;
  name: string;
  address: string;
  country: ICountry;
}

interface ICountry {
  code: string;
  name: string;
}

export const CompanyForm: React.FC<ICompanyForm> = ({
  showCompanyData,
  showIntroductionParagraph,
  inputCnpjOrTaxIdDisabled,
  placeholder,
  companyIdentifierType,
  onChange,
  idNumber,
  onSelect,
  onNextStep,
  clickSendButton,
  searchButtonDisabled,
  searchButtonLoading,
  searchCompanyFailed,
  countryList,
  selectedCountry,
  hideSuggestCompanyButtonForPendingCompanies,
  suggestCompanyModalVisibility,
  toggleSuggestCompanyModal,
  transferCountryValuesToAdministrativePanelCompanyRegister,
}: ICompanyForm) => {
  const { t } = useTranslation();

  const hideSuggestCompanyButtonForCompaniesWithPendingRequests = () => {
    if (!hideSuggestCompanyButtonForPendingCompanies) {
      return (
        <Fragment>
          <p>{t('request-access-company-search-failed')}</p>
          <p>
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                transferCountryValuesToAdministrativePanelCompanyRegister();
                toggleSuggestCompanyModal();
              }}
            >
              {t('request-access-company-search-failed-suggest-button')}
            </Button>
          </p>
        </Fragment>
      );
    }

    return (
      <p>
        {t(
          'request-access-block-company-suggest-for-companies-that-have-a-pending-request',
        )}
      </p>
    );
  };

  const showSuggestCompanyModal = () => {
    if (searchCompanyFailed === false) {
      return (
        <Row>
          <Col span={24}>
            <Card className="company-search-failed" size="small">
              {hideSuggestCompanyButtonForCompaniesWithPendingRequests()}
            </Card>
          </Col>
        </Row>
      );
    }
  };

  const getCountryDropdownOptions = () => {
    return countryList.map((x) => {
      return (
        <Select.Option key={x.id} value={x.id}>
          {x.name}
        </Select.Option>
      );
    });
  };

  const inputOrMaskedInput = () => {
    if (selectedCountry === 30) {
      return (
        <IMaskInput
          className="ant-input"
          defaultValue={idNumber}
          mask={'00.000.000/0000-00'}
          unmask={true}
          lazy={false}
          onAccept={(value) => {
            onChange(value);
          }}
          name={idNumber}
        />
      );
    }

    return (
      <Input
        maxLength={255}
        placeholder={placeholder}
        onChange={(value) => {
          onChange(value.target.value);
        }}
        disabled={inputCnpjOrTaxIdDisabled}
        value={idNumber}
        allowClear
      />
    );
  };

  return (
    <Form className="CompanyForm" onFinish={() => onNextStep()}>
      <SuggestCompanyModal
        suggestCompanyModalVisibility={suggestCompanyModalVisibility}
        suggestCompanyModalToggle={toggleSuggestCompanyModal}
      />
      {showIntroductionParagraph()}
      <Row style={{ justifyContent: 'space-between' }}>
        <Col span={4}>
          <Form.Item
            className="company-form-input-layout"
            rules={[
              {
                required: true,
                message: 'You must choose an Identifier',
              },
            ]}
          >
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <p className="company-form-label">
                      {t('request-access-company-country')}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={selectedCountry}
                      placeholder={t(
                        'request-access-company-identifier-dropdown-label',
                      )}
                      onSelect={(value: any) => onSelect(value)}
                    >
                      {getCountryDropdownOptions()}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col span={19}>
          <Form.Item
            className="company-form-input-layout"
            rules={[
              {
                required: true,
                message:
                  'You must ve choose an Identifier and filled data about the company',
              },
            ]}
          >
            <Row>
              <Col span={24}>
                <p className="company-form-label">{companyIdentifierType}</p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {inputOrMaskedInput()}
                <Button
                  name="searchButton"
                  className="company-form-search-button"
                  type="primary"
                  shape="round"
                  ghost
                  onClick={() => clickSendButton()}
                  loading={searchButtonLoading}
                  disabled={searchButtonDisabled}
                >
                  {t('request-access-company-search')}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
      {showCompanyData()}
      {showSuggestCompanyModal()}
    </Form>
  );
};
