import { nonSecuredBaseApi, securedBaseApi } from './api.config';

export interface ITermsResponse {
  content: ITermsResponseContent[];
}

interface ITermsResponseContent {
  id: number;
  version: string;
  active: boolean;
  contents: ITermsResponseContentBody[];
  accepted: boolean;
}

interface ITermsResponseContentBody {
  id: number;
  description: string;
  language: string;
}

export const termsRequestsApi = {
  getActiveTerms: () => {
    return nonSecuredBaseApi.get<ITermsResponse>('/public/termsV3?active=true');
  },
  userAcceptTermsViaModal: () => {
    return securedBaseApi.patch('/users/accept-terms');
  },
};
