import React, { Fragment, useCallback, useEffect } from 'react';
import { Button, Col, Divider, Form, Row } from 'antd';

import './Company.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CompanyForm } from '../../../../components/RequestAccess/CompanyForm/CompanyForm';
import { RequestAccessSlice, asyncActions } from '../../RequestAccess.Slice';
import { AppDispatch, RootState } from '../../../../store/store';
import { CompanyAdministrativePanelSlice } from '../../../AdministrativePanel/Companies/CompanyAdministrativePanel.Slice';
import { checkChangesInRequestCompany } from '../../../../helpers/requestCompanyChangeChecker/requestCompanyChangeChecker';
import { showModal } from '../../../../helpers/showModal/showModal';

export const Company: React.FC = () => {
  const requestAccessState = useSelector(
    (state: RootState) => state.requestAccess,
  );

  const requestAccessCompanyState = requestAccessState.companyStep;

  const dispatch = useDispatch<AppDispatch>();
  const { actions } = RequestAccessSlice;
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(asyncActions.GET_REQUEST_ACCESS_COUNTRY_LIST());
  }, [dispatch]);

  useEffect(() => {
    if (!requestAccessCompanyState.foundCompany) {
      if (requestAccessState.enableStep0) {
        dispatch(actions.DISABLE_STEP_0());
      }
      return;
    }

    if (!requestAccessState.enableStep0) {
      dispatch(actions.ENABLE_STEP_0());
    }
  }, [
    dispatch,
    actions,
    requestAccessState.enableStep0,
    requestAccessCompanyState.foundCompany,
  ]);

  const onChange = (data: any) => {
    if (requestAccessCompanyState.companyCnpjOrTaxIdDirtyInput === false) {
      dispatch(actions.COMPANY_SET_DIRTY_CNPJ_OR_TAX_ID_INPUT());
    }

    dispatch(actions.COMPANY_CHANGE_ID_NUMBER(data));
  };

  const onSelect = (option: string) => {
    dispatch(actions.COMPANY_CHANGE_COUNTRY(option));
  };

  const onNextStep = () => {
    dispatch(actions.NEXT_STEP());
  };

  const showIntroductionParagraph = () => {
    if (requestAccessCompanyState.foundCompany) {
      return;
    }

    if (requestAccessCompanyState.showSuggestCompanyScreen) {
      return (
        <Row className="text-row">
          <Col span={24} className="text-col">
            <p>{t('request-access-company-not-found-introduction-text')}</p>
          </Col>
        </Row>
      );
    }

    return (
      <Row className="text-row">
        <Col span={24} className="text-col">
          <p>{t('request-access-company-first-introduction-text')}</p>
          <p>{t('request-access-company-second-introduction-text')}</p>
        </Col>
      </Row>
    );
  };

  const showCompanyData = () => {
    if (
      !requestAccessCompanyState.foundCompany &&
      !requestAccessCompanyState.showSuggestCompanyScreen
    ) {
      return (
        <Fragment>
          <Row justify="center" align="middle">
            <Col span={11} className="request-company-divider">
              <Divider />
            </Col>
            <Col span={2} className="company-divider-title">
              {t('request-access-suggest-company-title')}
            </Col>
            <Col span={11} className="request-company-divider">
              <Divider />
            </Col>
          </Row>
          <Row className="text-row">
            <Col span={24} className="text-col">
              {t('request-access-suggest-company-text')}
            </Col>
            <Row>
              <Button
                type="primary"
                shape="round"
                ghost
                className="request-company-button"
                onClick={() => {
                  dispatch(actions.COMPANY_TOGGLE_SUGGEST_COMPANY_MODAL());
                }}
              >
                {t('request-access-suggest-company-button')}
              </Button>
            </Row>
          </Row>
        </Fragment>
      );
    }
    if (!requestAccessCompanyState.showSuggestCompanyScreen) {
      return (
        <Row>
          <Col>
            <Form.Item className="company-form-input-layout">
              <Row>
                <p className="company-form-label">
                  {t('request-access-company-name')}
                </p>
              </Row>
              <Row>
                <p>{requestAccessCompanyState.company.name}</p>
              </Row>
              <Row>
                <p className="company-form-label">
                  {t('request-access-company-address')}
                </p>
              </Row>
              <Row>
                <p>{requestAccessCompanyState.company.address}</p>
              </Row>
              <Row>
                <p className="company-form-label">
                  {t('request-access-company-country')}
                </p>
              </Row>
              <Row>
                <p>{t(requestAccessCompanyState.company.country.name)}</p>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      );
    }
  };

  const clickSendButton = () => {
    const searchCompanyQueryParam = `identifier=${requestAccessCompanyState.idNumber}&country=${requestAccessCompanyState.company.country.id}`;

    dispatch(asyncActions.COMPANY_GET_COMPANY(searchCompanyQueryParam));
  };

  const transferCountryValuesToAdministrativePanelCompanyRegister =
    useCallback(() => {
      const transferValues = {
        countryId: requestAccessCompanyState.company.country.id,
        idNumber: requestAccessState.companyStep.idNumber,
      };

      dispatch(
        CompanyAdministrativePanelSlice.actions.COMPANY_SUGGEST_RECEIVE_COUNTRY_FROM_REQUEST_ACCESS(
          transferValues,
        ),
      );
    }, [
      dispatch,
      requestAccessCompanyState.company.country.id,
      requestAccessState.companyStep.idNumber,
    ]);

  const countriesListing = () => {
    const countries = requestAccessCompanyState.countryList.map((x) => {
      return {
        ...x,
        name: t(x.name),
      };
    });

    return countries.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
    );
  };

  const setCompanyIdentifierInputTitle = () => {
    const countryId = requestAccessCompanyState.company.country.id;

    if (!countryId) {
      return t(
        'request-access-company-identifier-no-country-selected-label-input',
      );
    }
    if (countryId === 30) {
      return 'CNPJ (Cadastro Nacional de Pessoa Jurídica)';
    }
    if (countryId === 10) {
      return 'CUIT (Clave Única de Identificación Tributaria)';
    }
    if (
      countryId === 26 ||
      countryId === 47 ||
      countryId === 63 ||
      countryId === 89 ||
      countryId === 170
    ) {
      return 'NIT (Número de Identificación Tributaria)';
    }
    if (countryId === 43 || countryId === 233) {
      return 'RUT (Registro Único Tributario)';
    }
    if (countryId === 51) {
      return 'NITE (Número de Identificación Tributaria Especial)';
    }
    if (countryId === 59) {
      return 'RNC (Registro Nacional de Contribuyente)';
    }
    if (
      countryId === 61 ||
      countryId === 159 ||
      countryId === 172 ||
      countryId === 173
    ) {
      return 'RUC (Rol Único de Contribuyentes)';
    }
    if (countryId === 95) {
      return 'RTN (Registro Tributario Nacional)';
    }
    if (countryId === 109) {
      return 'TRN (Taxpayer Registration Number)';
    }
    if (countryId === 142) {
      return 'RFC (Registro Federal de Contribuyentes)';
    }
    if (countryId === 203) {
      return 'NIF (Número de Identificación Fiscal)';
    }
    if (countryId === 230) {
      return 'UTR (Unique Taxpayer Reference)';
    }
    if (countryId === 231) {
      return 'TIN (Tax Identification Number)';
    }
    if (countryId === 237) {
      return 'RIF (Registro Único de Información Fiscal)';
    }

    return t('request-access-company-identifier-default-label-input');
  };

  const showDiscardModal = () => {
    if (requestAccessCompanyState.suggestCompanyModalVisibility) {
      if (checkChangesInRequestCompany()) {
        return showModal(
          'warning',
          t('administrative-panel-discard-changes-title'),
          t('administrative-panel-discard-changes-content'),
          () => {
            dispatch(actions.COMPANY_TOGGLE_SUGGEST_COMPANY_MODAL());
          },
        );
      }
    }
    dispatch(actions.COMPANY_TOGGLE_SUGGEST_COMPANY_MODAL());
  };

  return (
    <CompanyForm
      showCompanyData={showCompanyData}
      showIntroductionParagraph={showIntroductionParagraph}
      idNumber={requestAccessCompanyState.idNumber}
      inputCnpjOrTaxIdDisabled={
        requestAccessCompanyState.inputCnpjOrTaxIdDisabled
      }
      inputCnpjOrTaxIdMask={
        requestAccessCompanyState.companyCnpjOrTaxIdInputMask
      }
      placeholder={requestAccessCompanyState.placeholder}
      companyIdentifierType={setCompanyIdentifierInputTitle()}
      company={requestAccessCompanyState.company}
      onChange={onChange}
      onSelect={onSelect}
      onNextStep={onNextStep}
      clickSendButton={clickSendButton}
      searchButtonDisabled={requestAccessCompanyState.searchButtonDisabled}
      searchButtonLoading={requestAccessCompanyState.searchButtonLoading}
      searchCompanyFailed={!requestAccessCompanyState.showSuggestCompanyScreen}
      countryList={countriesListing()}
      selectedCountry={
        requestAccessCompanyState.company.country.id !== 0
          ? requestAccessCompanyState.company.country.id
          : undefined
      }
      suggestCompanyModalVisibility={
        requestAccessCompanyState.suggestCompanyModalVisibility
      }
      hideSuggestCompanyButtonForPendingCompanies={
        requestAccessCompanyState.hideSuggestCompanyButtonForPendingCompanies
      }
      toggleSuggestCompanyModal={showDiscardModal}
      transferCountryValuesToAdministrativePanelCompanyRegister={
        transferCountryValuesToAdministrativePanelCompanyRegister
      }
    />
  );
};
