import React from 'react';
import { Col, Form, Modal, Row } from 'antd';

import './ConcludedRequestModal.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { ConcludedRequestsSlice } from '../../pages/AdministrativePanel/Requests/ConcludedRequests/ConcludedRequests.Slice';

export const ConcludedRequestModal: React.FC = () => {
  const concludedRequestsState = useSelector(
    (state: RootState) => state.ConcludedRequests,
  );
  const applicationLanguage = useSelector(
    (state: RootState) => state.navBar.defaultLanguage,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeModal = () => {
    dispatch(ConcludedRequestsSlice.actions.SHOW_OR_CLOSE_REQUEST_MODAL(false));
  };

  const formatDateColumn = () => {
    const unformattedDate =
      concludedRequestsState.requestResponseId.dateOfOrder;
    const date = new Date(unformattedDate);

    if (isNaN(date.getTime())) {
      return;
    }

    const dateFormat = new Intl.DateTimeFormat(applicationLanguage, {
      dateStyle: 'long',
      timeStyle: 'long',
    } as Intl.DateTimeFormatOptions);

    const formattedDate = dateFormat.format(date);

    return <span> {formattedDate} </span>;
  };

  const companyFormFieldsOnLeft = [
    {
      name: t('administrative-panel-requests-date'),
      id: 'requestDate',
      currentValue: formatDateColumn(),
      propName: t('administrative-panel-pending-field-date'),
    },
    {
      name: t('administrative-panel-requests-companyName'),
      id: 'requestCompanyName',
      currentValue: concludedRequestsState.requestResponseId.name,
      propName: t('administrative-panel-pending-field-name'),
    },
    {
      name: t('administrative-panel-requests-country'),
      id: 'requestCountry',
      currentValue: t(
        `${concludedRequestsState.requestResponseId.country.name}`,
      ),
      propName: t('administrative-panel-pending-field-country'),
    },
  ];

  const companyFormFieldsOnRight = [
    {
      name: t('administrative-panel-requests-requestors-email'),
      id: 'requestEmail',
      currentValue: concludedRequestsState.requestResponseId.email,
      propName: t('administrative-panel-pending-field-email'),
    },
    {
      name: t('administrative-panel-requests-registration-number'),
      id: 'requestNumber',
      currentValue: concludedRequestsState.requestResponseId.companyIdentifier,
      propName: t('administrative-panel-pending-field-identifier'),
    },
    {
      name: t('administrative-panel-requests-address'),
      id: 'requestAddress',
      currentValue: concludedRequestsState.requestResponseId.companyAddress,
      propName: t('administrative-panel-pending-field-address'),
    },
  ];

  const userFormFieldsOnLeft = [
    {
      name: t('administrative-panel-requests-date'),
      id: 'requestDate',
      currentValue: formatDateColumn(),
      propName: t('administrative-panel-pending-field-date'),
    },
    {
      name: t('administrative-panel-requests-firstname'),
      id: 'requestFirstName',
      currentValue: concludedRequestsState.requestResponseId.name,
      propName: t('administrative-panel-pending-field-name'),
    },
    {
      name: t('administrative-panel-requests-company'),
      id: 'requestAssociatedCompany',
      currentValue: concludedRequestsState.requestResponseId.companyName,
      propName: t('administrative-panel-pending-field-company'),
    },
    {
      name: t('administrative-panel-requests-country'),
      id: 'requestCountry',
      currentValue: t(
        concludedRequestsState.requestResponseId.country.name as string,
      ),
      propName: t('administrative-panel-pending-field-country'),
    },
  ];

  const userFormFieldsOnRight = [
    {
      name: t('administrative-panel-requests-requestors-email'),
      id: 'requestEmail',
      currentValue: concludedRequestsState.requestResponseId.email,
      propName: t('administrative-panel-pending-field-email'),
    },
    {
      name: t('administrative-panel-requests-lastname'),
      id: 'requestResponsibleLastName',
      currentValue: concludedRequestsState.requestResponseId.lastName,
      propName: t('administrative-panel-pending-field-lastname'),
    },
    {
      name: t('administrative-panel-requests-registration-number'),
      id: 'requestCompanyNumber',
      currentValue: concludedRequestsState.requestResponseId.companyIdentifier,
      propName: t('administrative-panel-pending-field-identifier'),
    },
  ];

  const generateFormItems = (field: any) => {
    return (
      <Row>
        <Col span={24} className="request-form-item">
          <Form.Item label={field.name} className="request-form-item">
            <div className="request-form-item-input">
              {field.currentValue
                ? field.currentValue
                : `${t(
                    'administrative-panel-pending-field-without-information-initial',
                  )}${field.propName}${t(
                    'administrative-panel-pending-field-without-information-final',
                  )}`}
            </div>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const generateColumns = (field: any, modalColumn: 'left' | 'right') => {
    if (!field) {
      return;
    }

    return (
      <Col span={9} offset={modalColumn === 'left' ? 0 : 6}>
        {generateFormItems(field)}
      </Col>
    );
  };

  const generateContents = () => {
    let leftColumn: Array<any> = [];
    let rightColumn: Array<any> = [];

    switch (concludedRequestsState.requestResponseId.type) {
      case 'COMPANY':
        leftColumn = [...companyFormFieldsOnLeft];
        rightColumn = [...companyFormFieldsOnRight];
        break;

      case 'USER':
        leftColumn = [...userFormFieldsOnLeft];
        rightColumn = [...userFormFieldsOnRight];
        break;

      default:
        break;
    }

    const generatedContent = [];

    for (;;) {
      const valueFromLeftColumn = leftColumn.shift();
      const valueFromRightColumn = rightColumn.shift();

      if (!valueFromLeftColumn && !valueFromRightColumn) {
        return generatedContent;
      }

      generatedContent.push(
        <Row className="request-form">
          {generateColumns(valueFromLeftColumn, 'left')}
          {generateColumns(valueFromRightColumn, 'right')}
        </Row>,
      );
    }
  };

  return (
    <Modal
      title={
        concludedRequestsState.requestResponseId.type === 'USER'
          ? t('administrative-panel-concluded-request-user')
          : t('administrative-panel-concluded-request-company')
      }
      className="concluded-request-modal"
      footer={null}
      open={concludedRequestsState.showRequestModal}
      onCancel={() => closeModal()}
    >
      <Form layout="vertical" className="request-modal-form">
        {generateContents()}
      </Form>
    </Modal>
  );
};
