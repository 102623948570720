import { Button, Col, Row } from 'antd';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

import './PopUp.scss';
import { messageNotification } from '../../../../helpers/messageNotification/messageNotification';
import { showModal } from '../../../../helpers/showModal/showModal';
import { TPopUp } from '../../../../services/administrative-panel-pop-up';
import { AppDispatch, RootState } from '../../../../store/store';
import { PopUpLanguageSelection } from './LanguageSelection/PopUpLanguageSelection';
import { PopUpAsyncActions, PopUpSlice } from './PopUp.Slice';
import { PopUpListingSlice } from '../PopUpListing/PopUpListing.Slice';
import { PopUpEditor } from './PopUpEditor/PopUpEditor';

export const PopUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const parsedUrl = queryString.parse(window.location.search);
  const popUpState = useSelector((state: RootState) => state.popUp);
  const headerRef = useRef(null);
  const contentRef = useRef(null);
  const typeRef = useRef(null);

  // == useEffects ==
  useEffect(() => {
    if (!popUpState.finishedCreatingOrEditing) {
      return;
    }
    dispatch(PopUpListingSlice.actions.REFRESH_LIST());
    navigate('/administrative-panel?tab=pop-up');
  }, [popUpState.finishedCreatingOrEditing, history, dispatch]);

  useEffect(() => {
    if (parsedUrl.id) {
      dispatch(PopUpAsyncActions.GET_POP_UP(parsedUrl.id as unknown as number));
    }
  }, [dispatch, parsedUrl.id]);

  useEffect(() => {
    return () => {
      dispatch(PopUpSlice.actions.RESET_TO_INITIAL_STATE());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!popUpState.shouldValidateForm) {
      return;
    }

    const createOrUpdate = (popUpDTO: any) => {
      const action = parsedUrl.id
        ? PopUpAsyncActions.UPDATE_POP_UP(popUpDTO)
        : PopUpAsyncActions.CREATE_A_NEW_POP_UP(popUpDTO);
      dispatch(action);
    };

    /**
     * Performs business logic validation on the PopUp Form. It checks if any field is empty and if all languages
     * were updated. If all passes it just returns 'ok'.
     *
     * @param form Current state of the PopUp Form
     * @returns Validation status string
     */
    const validateForm = (
      form: TPopUp,
    ): 'empty-content' | 'partially-fulfilled' | 'ok' | 'no-type' => {
      let isFormValid = false;

      isFormValid = !!form.pt.header && form.pt.header.length > 0;
      isFormValid =
        isFormValid && !!form.pt.content && form.pt.content.length > 0;

      isFormValid =
        isFormValid && !!form.en.header && form.en.header.length > 0;
      isFormValid =
        isFormValid && !!form.en.content && form.en.content.length > 0;

      isFormValid =
        isFormValid && !!form.es.header && form.es.header.length > 0;
      isFormValid =
        isFormValid && !!form.es.content && form.es.content.length > 0;

      if (!isFormValid) {
        return 'empty-content';
      }

      const hasPtChanged =
        form.pt.header !== popUpState.original.pt.header ||
        form.pt.content !== popUpState.original.pt.content;
      const hasEnChanged =
        form.en.header !== popUpState.original.en.header ||
        form.en.content !== popUpState.original.en.content;
      const hasEsChanged =
        form.es.header !== popUpState.original.es.header ||
        form.es.content !== popUpState.original.es.content;

      if (
        (hasPtChanged && hasEnChanged && hasEsChanged) ||
        (!hasPtChanged && !hasEnChanged && !hasEsChanged)
      ) {
        return 'ok';
      }

      if (form.type === null) {
        return 'no-type';
      }

      return 'partially-fulfilled';
    };

    const result = validateForm(popUpState.form);

    if (result === 'empty-content') {
      dispatch(PopUpSlice.actions.STOP_FORM_VALIDATION());
      return messageNotification.errorMessage(
        t('administrative-panel-language-content-validation'),
        t('pop-up-edit-validation-content'),
      );
    }

    if (!popUpState.form.type) {
      dispatch(PopUpSlice.actions.STOP_FORM_VALIDATION());
      return messageNotification.errorMessage(t('pop-up-edit-validation-icon'));
    }

    const popUpDTO = _.cloneDeep(popUpState.original);
    const popUpForm = _.cloneDeep(popUpState.form);

    popUpDTO.type = popUpForm.type;
    popUpDTO.active = popUpForm.active;

    popUpDTO.pt.header = popUpForm.pt.header;
    popUpDTO.pt.content = popUpForm.pt.content;

    popUpDTO.en.header = popUpForm.en.header;
    popUpDTO.en.content = popUpForm.en.content;

    popUpDTO.es.header = popUpForm.es.header;
    popUpDTO.es.content = popUpForm.es.content;

    if (
      result === 'partially-fulfilled' &&
      !popUpState.finishedCreatingOrEditing
    ) {
      showModal(
        'warning',
        t('pop-up-edit-validation-not-made-in-all-languages-title'),
        t('pop-up-edit-validation-not-made-in-all-languages-content'),
        () => {
          createOrUpdate(popUpDTO);
        },
        () => {
          dispatch(PopUpSlice.actions.STOP_FORM_VALIDATION());
        },
        `${t('documentation-edit-okText')}`,
        `${t('documentation-edit-cancelText')}`,
      );
    } else if (!popUpState.finishedCreatingOrEditing) {
      createOrUpdate(popUpDTO);
    }
  }, [dispatch, popUpState, t, parsedUrl.id]);

  // == Handlers ==
  const onSubmit = () => {
    dispatch(
      PopUpSlice.actions.START_FORM_VALIDATION_AND_SAVE_EDITOR_CURRENT_VALUE({
        editorContent: {
          header: headerRef.current,
          content: contentRef.current,
        },
        type: typeRef.current,
      }),
    );
  };

  return (
    <Row className="PopUp">
      <Col span={24}>
        <Row>
          <Col className="buttons-area" offset={18} span={4}>
            <span
              className="cancel-button"
              onClick={() => {
                navigate(-1);
              }}
            >
              {t('terms-create-or-edit-cancel-button')}
            </span>
            <Button type="primary" ghost shape="round" onClick={onSubmit}>
              {t('terms-create-or-edit-save-button')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={2}>
            <PopUpLanguageSelection
              headerRef={headerRef}
              contentRef={contentRef}
              typeRef={typeRef}
            />
          </Col>
          <Col offset={2} span={18}>
            <PopUpEditor
              headerRef={headerRef}
              contentRef={contentRef}
              typeRef={typeRef}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
