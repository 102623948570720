import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { NewRegister } from '../../../../components/NewRegister/NewRegister';
import {
  CompanyAdministrativePanelSlice,
  asyncActions,
} from '../CompanyAdministrativePanel.Slice';
import { asyncActions as requestAccessAsyncActions } from '../../../RequestAccess/RequestAccess.Slice';
import { RequestCompanyOrUserModal } from '../../../../components/RequestCompanyOrUserModal/RequestCompanyOrUserModal';
import { asyncActions as pendingRequestAsyncActions } from '../../Requests/PendingRequests/PendingRequests.Slice';
import { asyncActions as concludedRequestAsyncActions } from '../../Requests/ConcludedRequests/ConcludedRequests.Slice';

import { emailValidation } from '../../../../helpers/regexCollection/regexCollection';
import { AppDispatch, RootState } from '../../../../store/store';
import { checkChangesInCompanyRegisterOrEdit } from '../../../../helpers/newRegisterChangeChecker/newRegisterChangeChecker';
import { showModal } from '../../../../helpers/showModal/showModal';

interface INewCompany {
  requestAccessMode?: boolean;
  requestAccessModeToggleModal?: () => void;
  checkChanges?: () => void;
}

export const NewCompany: React.FC<INewCompany> = ({
  requestAccessMode = false,
  requestAccessModeToggleModal = () => {},
}) => {
  const companiesState = useSelector(
    (state: RootState) => state.CompanyAdministrativePanel,
  );

  const { companyRegister } = companiesState.companies;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { actions } = CompanyAdministrativePanelSlice;
  const { t } = useTranslation();

  const parsedURL = queryString.parse(window.location.search);
  const companyId = Number(parsedURL.id as string);

  const applicationLanguage = useSelector(
    (state: RootState) => state.navBar.defaultLanguage,
  );

  useEffect(() => {
    dispatch(asyncActions.COUNTRIES_LIST());
  }, [dispatch]);

  useEffect(() => {
    if (requestAccessMode) {
      return;
    }
    if (!companyId || isNaN(companyId)) {
      return;
    }

    dispatch(asyncActions.GET_COMPANY_BY_ID(companyId));
  }, [dispatch, companyId, requestAccessMode]);

  useEffect(() => {
    if (companiesState.companies.showCompaniesListing) {
      navigate('/administrative-panel/?tab=companies');
    }
  }, [dispatch, companiesState, history]);

  useEffect(() => {
    return () => {
      dispatch(actions.COMPANY_BACK_TO_ORIGINAL_STATE_PERSISTING_FILTER());
    };
  }, [dispatch, actions]);

  useEffect(() => {
    const companyIdentifier =
      companiesState.companies.companyRegister.companyIdentifierType;
    if (!companyIdentifier) {
      return;
    }

    dispatch(actions.COMPANY_ENABLE_MASK_NUMBER_INPUT({ companyIdentifier }));
  }, [
    actions,
    dispatch,
    companiesState.companies.companyRegister.companyIdentifierType,
  ]);

  useEffect(() => {
    if (companyRegister.responsibleEmail) {
      if (companyRegister.responsibleEmail.match(emailValidation)) {
        if (companyRegister.emailIsValid !== true) {
          dispatch(actions.SET_VALIDATION_EMAIL(true));
        }
      } else if (companyRegister.emailIsValid !== false) {
        dispatch(actions.SET_VALIDATION_EMAIL(false));
      }
    }
  }, [
    actions,
    dispatch,
    companyRegister.responsibleEmail,
    companyRegister.emailIsValid,
  ]);

  const onChangeCountry = (newCountryId: number) => {
    dispatch(actions.COMPANY_REGISTER_SET_COUNTRY_ID(newCountryId));
  };

  const countriesListing = () => {
    const countries = companyRegister.countriesList.map((x) => {
      return {
        ...x,
        name: t(x.name),
      };
    });

    return countries.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
    );
  };

  const setCompanyIdentifierInputTitle = () => {
    const { countryId, companyIdentifierType } =
      companiesState.companies.companyRegister;

    if (!countryId) {
      return t(
        'administrative-panel-new-company-no-country-selected-label-input',
      );
    }

    if (companyIdentifierType === 'CNPJ' || countryId === 30) {
      return 'CNPJ (Cadastro Nacional de Pessoa Jurídica)';
    }
    if (companyIdentifierType === 'CUIT' || countryId === 10) {
      return 'CUIT (Clave Única de Identificación Tributaria)';
    }
    if (
      companyIdentifierType === 'NIT' ||
      countryId === 26 ||
      countryId === 47 ||
      countryId === 63 ||
      countryId === 89 ||
      countryId === 170
    ) {
      return 'NIT (Número de Identificación Tributaria)';
    }
    if (
      companyIdentifierType === 'RUT' ||
      countryId === 43 ||
      countryId === 233
    ) {
      return 'RUT (Registro Único Tributario)';
    }
    if (companyIdentifierType === 'NITE' || countryId === 51) {
      return 'NITE (Número de Identificación Tributaria Especial)';
    }
    if (companyIdentifierType === 'RNC' || countryId === 59) {
      return 'RNC (Registro Nacional de Contribuyente)';
    }
    if (
      companyIdentifierType === 'RUC' ||
      countryId === 61 ||
      countryId === 159 ||
      countryId === 172 ||
      countryId === 173
    ) {
      return 'RUC (Rol Único de Contribuyentes)';
    }
    if (companyIdentifierType === 'RTN' || countryId === 95) {
      return 'RTN (Registro Tributario Nacional)';
    }
    if (companyIdentifierType === 'TRN' || countryId === 109) {
      return 'TRN (Taxpayer Registration Number)';
    }
    if (companyIdentifierType === 'RFC' || countryId === 142) {
      return 'RFC (Registro Federal de Contribuyentes)';
    }
    if (companyIdentifierType === 'NIF' || countryId === 203) {
      return 'NIF (Número de Identificación Fiscal)';
    }
    if (companyIdentifierType === 'UTR' || countryId === 230) {
      return 'UTR (Unique Taxpayer Reference)';
    }
    if (companyIdentifierType === 'TIN' || countryId === 231) {
      return 'TIN (Tax Identification Number)';
    }
    if (companyIdentifierType === 'RIF' || countryId === 237) {
      return 'RIF (Registro Único de Información Fiscal)';
    }

    return t('administrative-panel-new-company-default-identifier-input-title');
  };

  const CompanyAddLeft = [
    {
      name: t('administrative-panel-company-name'),
      type: 'input',
      size: 11,
      onChangeEvent: () => {},
      id: 'companyName',
      currentValue: companiesState.companies.companyRegister.name,
    },
    {
      name: [t('administrative-panel-company-country')],
      type: ['select'],
      size: [11],
      onChangeEvent: [onChangeCountry],
      selectId: t('administrative-panel-company-select-country-placeholder'),
      currentValue: [companiesState.companies.companyRegister.countryId],
      options: [countriesListing()],
      id: 'companyCountry',
    },
    {
      name: `${t('administrative-panel-new-company-responsible-email')}`,
      type: 'companyEmail',
      size: 11,
      onChangeEvent: () => {},
      id: 'responsibleEmail',
      currentValue: companiesState.companies.companyRegister.responsibleEmail,
      validationCheck: companiesState.companies.companyRegister.emailIsValid,
    },
  ];

  const CompanyAddRight = [
    {
      name: t('administrative-panel-company-address'),
      type: 'input',
      size: 11,
      onChangeEvent: () => {},
      id: 'companyAdress',
      currentValue: companiesState.companies.companyRegister.address,
    },
    {
      name: setCompanyIdentifierInputTitle(),
      type:
        companiesState.companies.companyRegister.countryId === 30
          ? 'CNPJmaskedInput'
          : 'identifierInput',
      size: 11,
      onChangeEvent: () => {},
      currentValue: companiesState.companies.companyRegister.identifierInput,
      id: 'cnpjTaxId',
      disabled:
        companiesState.companies.companyRegister.inputCnpjOrTaxIdDisabled,
      placeholder: ' ',
    },
  ];

  const newCompanyRegister = (values: any) => {
    const newCompanyBody = {
      address: values.companyAdress.trim(),
      country: { id: values.companyCountry },
      emailDomain: values.responsibleEmail.split('@').pop(),
      name: values.companyName.trim(),
      responsibleEmail: values.responsibleEmail.trim(),
      identifier: values.cnpjTaxId.trim(),
    };

    if (requestAccessMode) {
      return dispatch(
        requestAccessAsyncActions.REQUEST_NEW_COMPANY({
          company: newCompanyBody,
        }),
      );
    }
    if (!companiesState.companies.showEditMode) {
      dispatch(asyncActions.REGISTER_NEW_COMPANY(newCompanyBody));
    } else {
      dispatch(
        asyncActions.UPDATE_COMPANY({
          id: companyId,
          payload: newCompanyBody,
        }),
      );
    }

    dispatch(actions.SAVE_FORM_VALUES_TO_MODAL(values));
  };

  const initialValues = {
    companyName: companiesState.companies.companyRegister.name,
    companyAdress: companiesState.companies.companyRegister.address,
    companyCountry: companiesState.companies.companyRegister.countryId,
    responsibleEmail: companiesState.companies.companyRegister.responsibleEmail,
    cnpjTaxId: companiesState.companies.companyRegister.identifierInput,
  };

  const saveValuesOnChangingCountry = (formValues: any) => {
    dispatch(
      actions.COMPANY_REGISTER_SAVE_VALUES_ON_CHANGING_COUNTRY(formValues),
    );
  };

  const checkChanges = () => {
    const changes = checkChangesInCompanyRegisterOrEdit(
      companiesState.companies.showEditMode ? 'edit' : 'create',
    );

    if (changes) {
      return showModal(
        'warning',
        t('administrative-panel-discard-changes-title'),
        t('administrative-panel-discard-changes-content'),
        () => {
          navigate(`/administrative-panel?tab=companies`);
        },
      );
    }

    navigate(`/administrative-panel?tab=companies`);
  };

  const formatDateColumn = () => {
    const unformattedDate = companiesState.companies.requestData.dateOfOrder;
    const date = new Date(unformattedDate);

    if (isNaN(date.getTime())) {
      return;
    }

    const dateFormat = new Intl.DateTimeFormat(applicationLanguage, {
      dateStyle: 'long',
      timeStyle: 'long',
    } as Intl.DateTimeFormatOptions);

    const formattedDate = dateFormat.format(date);

    return <span> {formattedDate} </span>;
  };

  const requestModalDataLeft = [
    {
      name: t('administrative-panel-requests-date'),
      id: 'requestDate',
      currentValue: formatDateColumn(),
      propName: t('administrative-panel-pending-field-date'),
    },
    {
      name: t('administrative-panel-requests-companyName'),
      id: 'requestCompanyName',
      currentValue: companiesState.companies.requestData.name,
      propName: t('administrative-panel-pending-field-name'),
    },
    {
      name: t('administrative-panel-requests-country'),
      id: 'requestCountry',
      currentValue: t(`${companiesState.companies.requestData.country}`),
      propName: t('administrative-panel-pending-field-country'),
    },
  ];

  const requestModalDataRight = [
    {
      name: t('administrative-panel-requests-requestors-email'),
      id: 'requestEmail',
      currentValue: companiesState.companies.requestData.responsibleEmail,
      propName: t('administrative-panel-pending-field-email'),
    },
    {
      name: t('administrative-panel-requests-registration-number'),
      id: 'requestNumber',
      currentValue: companiesState.companies.requestData.identifier,
      propName: t('administrative-panel-pending-field-identifier'),
    },
    {
      name: t('administrative-panel-requests-address'),
      id: 'requestAddress',
      currentValue: companiesState.companies.requestData.address,
      propName: t('administrative-panel-pending-field-address'),
    },
  ];

  const approvePendingOrConcludedText = () => {
    if (companiesState.companies.pendingCompany) {
      return t(
        'administrative-panel-alredy-exists-approve-pending-solicitation',
      );
    }
    return t('administrative-panel-alredy-exists-approve-refused-solicitation');
  };

  const closeModal = () => {
    dispatch(actions.CLOSE_OR_OPEN_PENDING_MODAL(false));
  };

  const approvePendingOrConcludedRequest = () => {
    if (companiesState.companies.pendingCompany) {
      dispatch(
        pendingRequestAsyncActions.APPROVE_OR_REFUSE_PENDING_REQUEST({
          approve_or_refuse: 'approve',
          pendingRequestId: companiesState.companies.requestData.requestId,
        }),
      );
    }
    if (companiesState.companies.refusedCompany) {
      dispatch(
        concludedRequestAsyncActions.APPROVE_OR_REFUSE_CONCLUDED_REQUEST({
          approve_or_refuse: 'approve',
          concludedRequestId: companiesState.companies.requestData.requestId,
        }),
      );
    }
    navigate('/administrative-panel?tab=companies');
  };

  const createByForm = () => {
    const valuesOnForm = companiesState.companies.companyRegister;
    const newCompanyBody = {
      address: valuesOnForm.address.trim(),
      country: { id: valuesOnForm.countryId },
      emailDomain: valuesOnForm.responsibleEmail.split('@').pop(),
      name: valuesOnForm.name.trim(),
      responsibleEmail: valuesOnForm.responsibleEmail.trim(),
      identifier: valuesOnForm.identifierInput!.trim(),
    };

    if (!companiesState.companies.pendingCompany) {
      dispatch(
        asyncActions.REGISTER_COMPANY_WITH_A_SOLICITATION_HISTORY(
          newCompanyBody,
        ),
      );
    }

    if (companiesState.companies.pendingCompany) {
      dispatch(
        asyncActions.APPROVE_OR_REFUSE_PENDING_REQUEST({
          approve_or_refuse: 'refuse',
          pendingRequestId: companiesState.companies.requestData.requestId,
          newCompanyBody,
        }),
      );
    }
  };

  return (
    <Col span={requestAccessMode ? 24 : undefined}>
      <NewRegister
        dataRegisterLeftColumn={CompanyAddLeft}
        dataRegisterRightColumn={CompanyAddRight}
        newRegister={newCompanyRegister}
        requestAccessMode={requestAccessMode}
        pushTo="?tab=companies"
        checkChangesAndGoBack={checkChanges}
        requestAccessModeToggleModal={requestAccessModeToggleModal}
        initialValues={initialValues}
        showEditMode={companiesState.companies.showEditMode}
        saveValuesOnChange={saveValuesOnChangingCountry}
      />
      <RequestCompanyOrUserModal
        title={
          companiesState.companies.pendingCompany
            ? t('administrative-panel-alredy-exists-pending-title')
            : t('administrative-panel-alredy-exists-refused-title')
        }
        description={
          companiesState.companies.pendingCompany
            ? t('administrative-panel-alredy-exists-pending-company')
            : t('administrative-panel-alredy-exists-refused-company')
        }
        requestModalDataLeft={requestModalDataLeft}
        requestModalDataRight={requestModalDataRight}
        showModal={companiesState.companies.showRequestModal}
        closeModal={closeModal}
        approvePendingOrConcludedRequest={() =>
          approvePendingOrConcludedRequest()
        }
        createByForm={createByForm}
        approvePendingOrConcludedText={approvePendingOrConcludedText()}
      />
    </Col>
  );
};
