import { Col } from 'antd';
import React, { Fragment, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { NewRegister } from '../../../../components/NewRegister/NewRegister';
import { AppDispatch, RootState } from '../../../../store/store';
import { UsersSlice, asyncActions } from '../Users.Slice';
import { asyncActions as asyncActionsCompanies } from '../../Companies/CompanyAdministrativePanel.Slice';
import { asyncActions as pendingRequestAsyncActions } from '../../Requests/PendingRequests/PendingRequests.Slice';
import { asyncActions as concludedRequestAsyncActions } from '../../Requests/ConcludedRequests/ConcludedRequests.Slice';
import { checkChangesInUserRegisterOrEdit } from '../../../../helpers/newRegisterChangeChecker/newRegisterChangeChecker';
import { showModal } from '../../../../helpers/showModal/showModal';
import { RequestCompanyOrUserModal } from '../../../../components/RequestCompanyOrUserModal/RequestCompanyOrUserModal';

export const NewUser: React.FC = () => {
  const userState = useSelector((state: RootState) => state.users);
  const user = useSelector((state: RootState) => state.users.newRegister);
  const company = useSelector(
    (state: RootState) => state.CompanyAdministrativePanel.companies,
  );

  const applicationLanguage = useSelector(
    (state: RootState) => state.navBar.defaultLanguage,
  );

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { actions } = UsersSlice;

  const parsedURL = queryString.parse(window.location.search);
  const userId = Number(parsedURL.id as string);

  useEffect(() => {
    return () => {
      dispatch(actions.USER_BACK_TO_ORIGINAL_STATE_PERSISTING_FILTER());
    };
  }, [dispatch, actions]);

  useEffect(() => {
    dispatch(asyncActionsCompanies.COMPANY_LIST_FOR_USERS());
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(actions.SET_EDIT_MODE());
    }
  }, [dispatch, userId, actions]);

  useEffect(() => {
    if (userState.showEditMode) {
      if (isNaN(userId)) {
        return navigate('/administrative-panel?tab=users');
      }

      dispatch(asyncActions.GET_USER_BY_ID(userId));
    }
  }, [dispatch, userId, userState.showEditMode, history]);

  useEffect(() => {
    if (!userState.userExist) {
      return navigate('/administrative-panel?tab=users');
    }
  }, [history, userState.userExist]);

  useEffect(() => {
    if (userState.returnToAllUsers) {
      return navigate('/administrative-panel?tab=users');
    }
  }, [history, userState.returnToAllUsers]);

  const onChangeCompany = (newCompany: number) => {
    const companyById = company.dataForUsers.find((x) => x.id === newCompany);

    if (companyById) {
      dispatch(
        actions.SET_USER_COMPANY({
          companyId: newCompany,
          countryId: companyById.country.id,
          countryName: companyById.country.name,
        }),
      );
    }
  };

  const UserAddLeft = [
    {
      name: t('administrative-panel-users-tab-name'),
      type: 'input',
      size: 11,
      onChangeEvent: () => {},
      id: 'firstName',
      currentValue: user.firstName,
    },
    {
      name: [t('administrative-panel-users-tab-company')],
      type: ['select'],
      size: [11],
      onChangeEvent: [onChangeCompany],
      selectId: t('administrative-panel-users-tab-select-company-placeholder'),
      currentValue: [user.company.id],
      options: [company.dataForUsers],
      id: 'userCompany',
    },
    {
      name: t('administrative-panel-users-tab-email'),
      type: 'email',
      size: 11,
      onChangeEvent: () => {},
      id: 'userEmail',
      currentValue: user.email,
      validationCheck: user.emailIsValid,
      disabled: userState.showEditMode,
    },
    {
      name: [t('administrative-panel-users-tab-user-type')],
      type: ['select'],
      size: [11],
      onChangeEvent: [
        (userType: string) => {
          dispatch(actions.SET_TYPE_OF_USER(userType));
        },
      ],
      selectId: t('administrative-panel-users-tab-user-type-placeholder'),
      currentValue: [user.userTypeOrFunction],
      options: [
        [
          {
            id: 'commonUser',
            name: t(
              'administrative-panel-users-tab-user-type-commonUser-option',
            ),
          },
          {
            id: 'administrator',
            name: t(
              'administrative-panel-users-tab-user-type-administrator-option',
            ),
          },
        ],
      ],
      id: 'userType',
    },
  ];

  const UserAddRight = [
    {
      name: t('administrative-panel-users-tab-lastName'),
      type: 'input',
      size: 11,
      onChangeEvent: () => {},
      id: 'lastName',
      currentValue: user.lastName,
    },
    {
      name: t('administrative-panel-users-tab-country'),
      type: 'input',
      size: 11,
      onChangeEvent: () => {},
      currentValue: t(`${user.country.name}`),
      id: 'userCountry',
      disabled: true,
    },
    {
      name: t('administrative-panel-users-tab-email-confirmation'),
      type: 'confirm-email',
      size: 11,
      onChangeEvent: () => {},
      id: 'confirmEmail',
      currentValue: user.confirmEmail,
      validationCheck: user.confirmEmailIsEqual,
      mustBeEqualToFieldWithId: 'userEmail',
      hidden: userState.showEditMode,
    },
  ];

  const roleUserValidation = (values: any) => {
    if (
      values.userType ===
      t('administrative-panel-users-tab-user-type-commonUser-option')
    ) {
      return 'U';
    }

    return 'A';
  };

  const newUserRegister = (values: any) => {
    const newUserBody = {
      country: { id: user.country.id },
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.userEmail,
      confirmEmail: values.confirmEmail,
      password: '123456',
      passwordConfirmation: '123456',
      role: roleUserValidation(values),
      company: { id: values.userCompany },
      termV3Accepted: userState.newRegister.termAccepted,
    } as any;

    if (!userState.showEditMode) {
      return dispatch(asyncActions.NEW_USER(newUserBody));
    }

    newUserBody.confirmEmail = undefined;
    newUserBody.password = undefined;
    newUserBody.passwordConfirmation = undefined;

    return dispatch(
      asyncActions.UPDATE_USER({
        id: userId,
        payload: newUserBody,
      }),
    );
  };

  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    userCompany: user.company.id,
    userEmail: user.email,
    confirmEmail: user.confirmEmail,
    userType: user.currentValueTypeOrFunctionUser,
    userCountry: t(user.country.name),
  };

  const saveValuesOnChangingCompany = (formValues: any) => {
    dispatch(actions.USER_REGISTER_SAVE_VALUES_ON_CHANGING_COMPANY(formValues));
  };

  const checkChanges = () => {
    const changes = checkChangesInUserRegisterOrEdit(
      userState.showEditMode ? 'edit' : 'create',
    );

    if (changes) {
      return showModal(
        'warning',
        t('administrative-panel-discard-changes-title'),
        t('administrative-panel-discard-changes-content'),
        () => {
          navigate(`/administrative-panel?tab=users`);
        },
      );
    }

    navigate(`/administrative-panel?tab=users`);
  };

  const formatDateColumn = () => {
    const unformattedDate = userState.requestData.dateOfOrder;
    const date = new Date(unformattedDate);

    if (isNaN(date.getTime())) {
      return;
    }

    const dateFormat = new Intl.DateTimeFormat(applicationLanguage, {
      dateStyle: 'long',
      timeStyle: 'long',
    } as Intl.DateTimeFormatOptions);

    const formattedDate = dateFormat.format(date);

    return <span> {formattedDate} </span>;
  };

  const requestModalDataLeft = [
    {
      name: t('administrative-panel-requests-date'),
      id: 'requestDate',
      currentValue: formatDateColumn(),
      propName: t('administrative-panel-pending-field-date'),
    },
    {
      name: t('administrative-panel-requests-companyName'),
      id: 'requestCompanyName',
      currentValue: userState.requestData.companyName,
      propName: t('administrative-panel-pending-field-name'),
    },
    {
      name: t('administrative-panel-requests-country'),
      id: 'requestCountry',
      currentValue: t(`${userState.requestData.country}`),
      propName: t('administrative-panel-pending-field-country'),
    },
  ];

  const requestModalDataRight = [
    {
      name: t('administrative-panel-requests-requestors-email'),
      id: 'requestEmail',
      currentValue: userState.requestData.email,
      propName: t('administrative-panel-pending-field-email'),
    },
    {
      name: t('administrative-panel-requests-registration-number'),
      id: 'requestNumber',
      currentValue: userState.requestData.companyIdentifier,
      propName: t('administrative-panel-pending-field-identifier'),
    },
    {
      name: t('administrative-panel-requests-address'),
      id: 'requestAddress',
      currentValue: userState.requestData.companyAddress,
      propName: t('administrative-panel-pending-field-address'),
    },
  ];

  const closeModal = () => {
    dispatch(actions.CLOSE_PENDING_MODAL());
  };

  const approvePendingOrConcludedRequest = () => {
    if (userState.pendingUser) {
      dispatch(
        pendingRequestAsyncActions.APPROVE_OR_REFUSE_PENDING_REQUEST({
          approve_or_refuse: 'approve',
          pendingRequestId: userState.requestData.requestId,
        }),
      );
    }
    if (userState.refusedUser) {
      dispatch(
        concludedRequestAsyncActions.APPROVE_OR_REFUSE_CONCLUDED_REQUEST({
          approve_or_refuse: 'approve',
          concludedRequestId: userState.requestData.requestId,
        }),
      );
    }
    navigate('/administrative-panel?tab=users');
  };

  const roleUserInCreateByForm = (values: any) => {
    if (
      values === t('administrative-panel-users-tab-user-type-commonUser-option')
    ) {
      return 'U';
    }

    return 'A';
  };

  const createByForm = () => {
    const valuesOnForm = userState.newRegister;
    const newUserBody = {
      country: { id: valuesOnForm.country.id },
      firstName: valuesOnForm.firstName,
      lastName: valuesOnForm.lastName,
      email: valuesOnForm.email,
      confirmEmail: valuesOnForm.confirmEmail,
      password: '123456',
      passwordConfirmation: '123456',
      role: roleUserInCreateByForm(valuesOnForm.currentValueTypeOrFunctionUser),
      company: { id: valuesOnForm.company.id },
      termV3Accepted: false,
    } as any;

    if (!userState.pendingUser) {
      dispatch(
        asyncActions.REGISTER_USER_WITH_A_SOLICITATION_HISTORY(newUserBody),
      );
    }
    if (userState.pendingUser) {
      dispatch(
        asyncActions.APPROVE_OR_REFUSE_PENDING_USER_REQUEST({
          approve_or_refuse: 'refuse',
          pendingRequestId: userState.requestData.requestId,
          newUserBody,
        }),
      );
    }
  };

  const approvePendingOrConcludedText = () => {
    if (userState.pendingUser) {
      return t(
        'administrative-panel-alredy-exists-approve-pending-solicitation',
      );
    }
    return t('administrative-panel-alredy-exists-approve-refused-solicitation');
  };

  return (
    <Fragment>
      <Col>
        <NewRegister
          dataRegisterLeftColumn={UserAddLeft}
          dataRegisterRightColumn={UserAddRight}
          newRegister={newUserRegister}
          pushTo="?tab=users"
          checkChangesAndGoBack={checkChanges}
          initialValues={initialValues}
          saveValuesOnChange={saveValuesOnChangingCompany}
          showEditMode={userState.showEditMode}
        />
        <RequestCompanyOrUserModal
          title={
            userState.pendingUser
              ? t('administrative-panel-alredy-exists-pending-title')
              : t('administrative-panel-alredy-exists-refused-title')
          }
          description={
            userState.pendingUser
              ? t('administrative-panel-alredy-exists-pending-user')
              : t('administrative-panel-alredy-exists-refused-user')
          }
          requestModalDataLeft={requestModalDataLeft}
          requestModalDataRight={requestModalDataRight}
          showModal={userState.showRequestModal}
          closeModal={closeModal}
          approvePendingOrConcludedRequest={() =>
            approvePendingOrConcludedRequest()
          }
          createByForm={createByForm}
          approvePendingOrConcludedText={approvePendingOrConcludedText()}
        />
      </Col>
    </Fragment>
  );
};
