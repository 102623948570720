import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PersonalDataForm } from '../../../../components/RequestAccess/PersonalDataForm/PersonalDataForm';
import { RequestAccessSlice } from '../../RequestAccess.Slice';
import { RootState } from '../../../../store/store';
import { messageNotification } from '../../../../helpers/messageNotification/messageNotification';
import { emailValidation } from '../../../../helpers/regexCollection/regexCollection';

export const PersonalData: React.FC = () => {
  const requestAccessState = useSelector(
    (state: RootState) => state.requestAccess,
  );
  const requestAccessPersonalDataState = requestAccessState.personalDataStep;

  const { actions } = RequestAccessSlice;
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      requestAccessPersonalDataState.name.length > 0 &&
      requestAccessPersonalDataState.surname.length > 0 &&
      requestAccessPersonalDataState.email ===
        requestAccessPersonalDataState.emailConfirmation &&
      requestAccessPersonalDataState.isEmailValid &&
      requestAccessPersonalDataState.password ===
        requestAccessPersonalDataState.passwordConfirmation &&
      requestAccessPersonalDataState.isPasswordValid
    ) {
      if (requestAccessState.enableStep1 === false) {
        dispatch(actions.ENABLE_STEP_1());
      }
    } else if (requestAccessState.enableStep1 === true) {
      dispatch(actions.DISABLE_STEP_1());
    }
  }, [
    actions,
    dispatch,
    requestAccessState.enableStep1,
    requestAccessPersonalDataState,
  ]);

  useEffect(() => {
    if (requestAccessState.companyStep.company.emailDomain) {
      const emailDomainFormattedToRegex =
        requestAccessState.companyStep.company.emailDomain.replace(
          /[.]/gm,
          '\\.',
        );
      const companyDomainRegex = new RegExp(
        `^.{1,}@${emailDomainFormattedToRegex}$`,
        'gm',
      );

      if (requestAccessPersonalDataState.email.match(companyDomainRegex)) {
        if (requestAccessPersonalDataState.isEmailValid !== true) {
          dispatch(actions.PERSONAL_DATA_SET_EMAIL_AS_VALID_OR_INVALID(true));
        }
      } else if (requestAccessPersonalDataState.isEmailValid !== false) {
        dispatch(actions.PERSONAL_DATA_SET_EMAIL_AS_VALID_OR_INVALID(false));
      }

      return;
    }

    if (requestAccessPersonalDataState.email.match(emailValidation)) {
      if (requestAccessPersonalDataState.isEmailValid !== true) {
        dispatch(actions.PERSONAL_DATA_SET_EMAIL_AS_VALID_OR_INVALID(true));
      }
    } else if (requestAccessPersonalDataState.isEmailValid !== false) {
      dispatch(actions.PERSONAL_DATA_SET_EMAIL_AS_VALID_OR_INVALID(false));
    }
  }, [
    actions,
    dispatch,
    requestAccessPersonalDataState.email,
    requestAccessPersonalDataState.isEmailValid,
    requestAccessState.companyStep.company.emailDomain,
  ]);

  useEffect(() => {
    const passwordValidationParams = [
      requestAccessPersonalDataState.cardValidationStyleLet,
      requestAccessPersonalDataState.cardValidationStyleCap,
      requestAccessPersonalDataState.cardValidationStyleNum,
      requestAccessPersonalDataState.cardValidationStyleLen,
    ];

    if (passwordValidationParams.every((x) => x === 'valid')) {
      if (requestAccessPersonalDataState.isPasswordValid === false) {
        dispatch(actions.PERSONAL_DATA_SET_PASSWORD_AS_VALID_OR_INVALID(true));
      }
    } else if (requestAccessPersonalDataState.isPasswordValid === true) {
      dispatch(actions.PERSONAL_DATA_SET_PASSWORD_AS_VALID_OR_INVALID(false));
    }
  }, [
    actions,
    dispatch,
    requestAccessPersonalDataState.isPasswordValid,
    requestAccessPersonalDataState.cardValidationStyleLet,
    requestAccessPersonalDataState.cardValidationStyleCap,
    requestAccessPersonalDataState.cardValidationStyleNum,
    requestAccessPersonalDataState.cardValidationStyleLen,
  ]);

  const onChangeAvatar = (file: any) => {
    if (
      requestAccessPersonalDataState.loadingAvatar !== true &&
      file.file.status === 'uploading'
    ) {
      dispatch(actions.PERSONAL_DATA_LOADING_AVATAR({ loadingAvatar: true }));
      const reader = new FileReader();
      reader.readAsDataURL(file.file.originFileObj);
      reader.onloadend = () => {
        dispatch(
          actions.PERSONAL_DATA_CHANGE_AVATAR({ avatar: reader.result }),
        );
      };
      dispatch(actions.PERSONAL_DATA_LOADING_AVATAR({ loadingAvatar: false }));
    }
  };

  const onBeforeUploadAvatar = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      messageNotification.errorMessage(
        'Formato de imagem',
        'Você somente pode adicionar imagens em formato JPG/PNG',
      );
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      messageNotification.errorMessage(
        'Tamanho de imagem',
        'A imagem tem que ser menor que 2MB!',
      );
    }

    return isJpgOrPng && isLt2M;
  };

  const onPreviewAvatar = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const deleteAvatar = () => {
    dispatch(actions.PERSONAL_DATA_DELETE_AVATAR());
  };

  const onNameChange = (data: string) => {
    dispatch(actions.PERSONAL_DATA_SET_NAME(data));
  };

  const onSurnameChange = (data: string) => {
    dispatch(actions.PERSONAL_DATA_SET_SURNAME(data));
  };

  const onEmailChange = (data: string) => {
    dispatch(actions.PERSONAL_DATA_SET_EMAIL(data));
  };

  const onEmailConfirmationChange = (data: string) => {
    dispatch(actions.PERSONAL_DATA_SET_EMAIL_CONFIRMATION(data));
  };

  const onPasswordChange = (data: string) => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;

    dispatch(actions.PERSONAL_DATA_SET_PASSWORD(data));

    if (data.match(lowerCaseLetters)) {
      dispatch(actions.PERSONAL_DATA_SET_LOWER_CASE_VALIDATION('valid'));
    } else {
      dispatch(actions.PERSONAL_DATA_SET_LOWER_CASE_VALIDATION('invalid'));
    }

    if (data.match(upperCaseLetters)) {
      dispatch(actions.PERSONAL_DATA_SET_UPPER_CASE_VALIDATION('valid'));
    } else {
      dispatch(actions.PERSONAL_DATA_SET_UPPER_CASE_VALIDATION('invalid'));
    }

    if (data.match(numbers)) {
      dispatch(actions.PERSONAL_DATA_SET_NUMBER_VALIDATION('valid'));
    } else {
      dispatch(actions.PERSONAL_DATA_SET_NUMBER_VALIDATION('invalid'));
    }

    if (data.length >= 8) {
      dispatch(actions.PERSONAL_DATA_SET_LENGTH_VALIDATION('valid'));
    } else {
      dispatch(actions.PERSONAL_DATA_SET_LENGTH_VALIDATION('invalid'));
    }
  };

  const onPasswordConfirmationChange = (data: string) => {
    dispatch(actions.PERSONAL_DATA_SET_PASSWORD_CONFIRMATION(data));
  };

  return (
    <PersonalDataForm
      avatarBefore={onBeforeUploadAvatar}
      avatarChange={onChangeAvatar}
      avatarLoading={requestAccessPersonalDataState.loadingAvatar}
      avatarPreview={onPreviewAvatar}
      avatar={requestAccessPersonalDataState.avatar}
      deleteAvatar={deleteAvatar}
      name={requestAccessPersonalDataState.name}
      onNameChange={onNameChange}
      surname={requestAccessPersonalDataState.surname}
      onSurnameChange={onSurnameChange}
      email={requestAccessPersonalDataState.email}
      onEmailChange={onEmailChange}
      companyDomain={requestAccessState.companyStep.company.emailDomain}
      emailConfirmation={requestAccessPersonalDataState.emailConfirmation}
      onEmailConfirmationChange={onEmailConfirmationChange}
      emailConfirmationMessage={
        requestAccessPersonalDataState.emailComparisonMessage
      }
      password={requestAccessPersonalDataState.password}
      onPasswordChange={onPasswordChange}
      passwordConfirmation={requestAccessPersonalDataState.passwordConfirmation}
      onPasswordConfirmationChange={onPasswordConfirmationChange}
      passwordConfirmationMessage={
        requestAccessPersonalDataState.passwordComparisonMessage
      }
      cardValidationStyleLet={
        requestAccessPersonalDataState.cardValidationStyleLet
      }
      cardValidationStyleCap={
        requestAccessPersonalDataState.cardValidationStyleCap
      }
      cardValidationStyleNum={
        requestAccessPersonalDataState.cardValidationStyleNum
      }
      cardValidationStyleLen={
        requestAccessPersonalDataState.cardValidationStyleLen
      }
    />
  );
};
