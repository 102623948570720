import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EditPencil } from '../../../../assets/popover-icons/edit.svg';
import { ReactComponent as TrashCan } from '../../../../assets/popover-icons/trash-can.svg';
import { Filter } from '../../../../components/Filter/Filter';
import { DeleteSelectedButton } from '../../../../components/DeleteSelectedButton/DeleteSelectedButton';
import {
  IDefaultSortBehaviour,
  PagingTable,
} from '../../../../components/PagingTable/PagingTable';
import { ShowFiltersButton } from '../../../../components/ShowFiltersButton/ShowFiltersButton';
import { showModal } from '../../../../helpers/showModal/showModal';
import { scrollToTopFunction } from '../../../../helpers/useScrollToTop/useScrollToTop';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  CompanyAdministrativePanelSlice,
  asyncActions,
} from '../CompanyAdministrativePanel.Slice';
import './CompanyListing.scss';

export const CompanyListing: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const companiesState = useSelector(
    (state: RootState) => state.CompanyAdministrativePanel,
  );

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(
        CompanyAdministrativePanelSlice.actions.COMPANY_BACK_TO_ORIGINAL_STATE_PERSISTING_FILTER(),
      );
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(asyncActions.COUNTRIES_LIST());
  }, [dispatch]);

  useEffect(() => {
    if (!companiesState.companies.shouldRefresh) {
      return;
    }

    if (companiesState.companies.tableLoading) {
      return;
    }

    let queryString = `?page=${companiesState.companies.pageIndex - 1}&size=${
      companiesState.companies.pageSize
    }`;

    if (companiesState.companies.companyQueryString.length > 0) {
      queryString = `${queryString}&${companiesState.companies.companyQueryString.join(
        '&',
      )}`;
    }

    if (companiesState.companies.sortBy) {
      queryString = `${queryString}&${companiesState.companies.sortBy}`;
    }

    if (companiesState.companies.companyFirstQueryBehavior) {
      queryString = `${queryString}&sort=name,ignoreCase`;
    }

    dispatch(asyncActions.COMPANIES_LIST(queryString));
  }, [dispatch, companiesState]);

  const toggleFilter = () => {
    if (companiesState.showFilter === '') {
      dispatch(
        CompanyAdministrativePanelSlice.actions.FILTER_STATUS_CHANGE('1'),
      );
    } else {
      dispatch(
        CompanyAdministrativePanelSlice.actions.FILTER_STATUS_CHANGE(''),
      );
    }
  };

  const [columnsWidth, setColumnsWidth] = useState({
    nameColumnWidth: 4.2,
    emailDomainColumnWidth: 4.2,
    taxIdColumnWidth: 4.2,
    adressColumnWidth: 4.2,
    countryColumnWidth: 3.5,
  });

  const resizeHandlerSpeed = 0.028;
  const resizeColumns = (
    columnName:
      | 'nameColumnWidth'
      | 'emailDomainColumnWidth'
      | 'taxIdColumnWidth'
      | 'adressColumnWidth'
      | 'countryColumnWidth',
    movement: number,
  ) => {
    setColumnsWidth({
      ...columnsWidth,
      [columnName]: (columnsWidth[columnName] += movement * resizeHandlerSpeed),
    });
  };
  const companyListingCursor = [
    {
      columnTitle: t('administrative-panel-company-name'),
      columnProperty: 'name',
      width: columnsWidth.nameColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('nameColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-company-email-domain'),
      columnProperty: 'emailDomain',
      width: columnsWidth.emailDomainColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('emailDomainColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-company-CNPJ-TaxID-identifier'),
      columnProperty: 'identifier',
      width: columnsWidth.taxIdColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('taxIdColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-company-address'),
      columnProperty: 'address',
      className: 'address-column',
      width: columnsWidth.adressColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('adressColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-company-country'),
      columnProperty: 'countryName',
      width: columnsWidth.countryColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('countryColumnWidth', event.movementX);
      },
    },
  ];

  const showDeleteConfirmationModal = (id: number) => {
    showModal(
      'warning',
      `${t('administrative-panel-delete-company-warning-title')}`,
      `${t('administrative-panel-delete-company-warning-content')}`,
      () => {
        dispatch(asyncActions.DELETE_ONE_COMPANY(id));
      },
      () => {},
      `${t('administrative-panel-okText-delete')}`,
      `${t('administrative-panel-cancelText-delete')}`,
    );
  };

  const getCompanyById = (id: any) => {
    navigate(`/administrative-panel/add_or_edit?id=${id}&tab=companies`);
  };

  const mockedOptions = [
    {
      optionName: t('administrative-panel-companies-table-edit-option'),
      optionIcon: <EditPencil />,
      optionOnClick: (id: number) => {
        getCompanyById(id);
      },
    },
    {
      optionName: t('administrative-panel-companies-table-delete-option'),
      optionIcon: <TrashCan />,
      optionOnClick: (id: number) => showDeleteConfirmationModal(id),
    },
  ];

  const countriesListing = () => {
    const countries =
      companiesState.companies.companyRegister.countriesList.map((x) => {
        return {
          ...x,
          name: t(x.name),
        };
      });

    return countries.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
    );
  };

  const fields = [
    {
      fieldTitle: t('administrative-panel-filter-by-name'),
      fieldType: 'input',
      fieldProperty: 'name',
      fieldValue: companiesState.companies.filter.filterByName,
    },
    {
      fieldTitle: t('administrative-panel-filter-by-email-domain'),
      fieldType: 'input',
      fieldProperty: 'emailDomain',
      fieldValue: companiesState.companies.filter.filterByEmailDomain,
    },
    {
      fieldTitle: t('administrative-panel-filter-by-identifier'),
      fieldType: 'input',
      fieldProperty: 'identifier',
      fieldValue: companiesState.companies.filter.filterByIdentifier,
    },
    {
      fieldTitle: t('administrative-panel-filter-by-address'),
      fieldType: 'input',
      fieldProperty: 'address',
      fieldValue: companiesState.companies.filter.filterByAddress,
    },
    {
      fieldTitle: t('administrative-panel-filter-by-country'),
      fieldType: 'select',
      fieldProperty: 'countryId',
      fieldValue: companiesState.companies.filter.filterByCountry,
      selectOptions: countriesListing(),
    },
  ];

  const onChangePage = (pageIndex: number) => {
    scrollToTopFunction('new-company-button');
    dispatch(
      CompanyAdministrativePanelSlice.actions.COMPANIES_CHANGE_PAGE(pageIndex),
    );
  };

  const defaultSortBehaviour: IDefaultSortBehaviour = {
    columnProperty: 'name',
    order: 'ascend',
    disableDefaultSortBehaviour: () => {
      if (companiesState.companies.companyFirstQueryBehavior) {
        dispatch(
          CompanyAdministrativePanelSlice.actions.COMPANIES_DISABLE_DEFAULT_SORT_BEHAVIOUR(),
        );
      }
    },
  };

  const dispatchSortBy = (sortBy: string) => {
    dispatch(
      CompanyAdministrativePanelSlice.actions.COMPANIES_CHANGE_SORT(
        sortBy ? `sort=${sortBy}` : '',
      ),
    );
  };

  const onChangeSort = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    let sortBy = '';

    if (!order || !field) {
      return dispatchSortBy(sortBy);
    }

    if (defaultSortBehaviour) {
      defaultSortBehaviour.disableDefaultSortBehaviour();
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;

    switch (fieldToQueryParam) {
      case 'name':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      case 'identifier':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      case 'address':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      case 'countryName':
        sortBy = order === 'ascend' ? 'country' : `country,desc`;
        return dispatchSortBy(sortBy);

      default:
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);
    }
  };

  const clearFilterFields = () => {
    dispatch(
      CompanyAdministrativePanelSlice.actions.COMPANIES_CLEAR_FILTER_FIELDS(),
    );
  };

  const selectRowsToCompanies = (x: any) => {
    dispatch(
      CompanyAdministrativePanelSlice.actions.COMPANIES_CREATED_SET_SELECTED_ROWS(
        x,
      ),
    );
  };

  const selectIdFromRowsInCompanies = (x: any) => {
    dispatch(
      CompanyAdministrativePanelSlice.actions.COMPANIES_CREATED_SET_ID_TO_DELETE_COMPANIES(
        x,
      ),
    );
  };

  const deleteSelectedCompanies = (x: any) => {
    dispatch(asyncActions.DELETE_MANY_COMPANIES(x));
  };

  const submitFilter = (values: any) => {
    const queryParams: string[] = [];
    Object.keys(values).forEach((key) => {
      switch (key) {
        case 'name':
          dispatch(
            CompanyAdministrativePanelSlice.actions.COMPANIES_FILTER_SET_NAME(
              values[key],
            ),
          );
          break;
        case 'emailDomain':
          dispatch(
            CompanyAdministrativePanelSlice.actions.COMPANIES_FILTER_SET_EMAIL_DOMAIN(
              values[key],
            ),
          );
          break;
        case 'identifier':
          dispatch(
            CompanyAdministrativePanelSlice.actions.COMPANIES_FILTER_SET_IDENTIFIER(
              values[key],
            ),
          );
          break;
        case 'adress':
          dispatch(
            CompanyAdministrativePanelSlice.actions.COMPANIES_FILTER_SET_ADDRESS(
              values[key],
            ),
          );
          break;
        case 'countryId':
          dispatch(
            CompanyAdministrativePanelSlice.actions.COMPANIES_FILTER_SET_COUNTRY(
              values[key],
            ),
          );
          break;
        default:
          break;
      }

      if (values[key]) {
        queryParams.push(`${key}=${values[key]}`);
      }
    });
    dispatch(
      CompanyAdministrativePanelSlice.actions.COMPANIES_SET_FILTER_QUERY_STRING(
        queryParams,
      ),
    );
  };

  const dataSource = () => {
    return companiesState.companies.data.map((x) => {
      return {
        ...x,
        countryName: t(x.country.name),
      };
    });
  };

  return (
    <Row className="CompanyListing">
      <Row className="buttons-filters" justify="space-between">
        <Col span={3}>
          <Button
            id="new-company-button"
            className="company-listing-button-new-company"
            onClick={() =>
              navigate('/administrative-panel/add_or_edit?tab=companies')
            }
          >
            {t('administrative-panel-new-company-button-label')}
          </Button>
        </Col>
        <Col pull={6}>
          <DeleteSelectedButton
            numberOfItemsSelected={
              companiesState.companies.selectedRowToDeleteCompanies
            }
            messageConfirmationByGivenTab={`${t(
              'administrative-panel-delete-button',
            )}`}
            singularContentConfirmationByGivenTab={`${t(
              'administrative-panel-one-company',
            )}`}
            pluralContentConfirmationByGivenTab={`${t(
              'administrative-panel-many-companies',
            )}`}
            deleteOneTitleModal={`${t(
              'administrative-panel-delete-company-warning-title',
            )}`}
            deleteOneContentModal={`${t(
              'administrative-panel-delete-company-warning-content',
            )}`}
            deleteManyTitleModal={`${t(
              'administrative-panel-delete-many-companies-warning-title',
            )}`}
            deleteManyContentModal={`${t(
              'administrative-panel-delete-many-companies-warning-content',
            )}`}
            onOkText={`${t('administrative-panel-okText-delete')}`}
            onCancelText={`${t('administrative-panel-cancelText-delete')}`}
            deleteAction={() =>
              deleteSelectedCompanies(
                companiesState.companies.deleteManyCompaniesIds!,
              )
            }
          />
        </Col>
        <ShowFiltersButton
          onToggle={toggleFilter}
          openedFilter={companiesState.showFilter === ''}
        />
      </Row>
      <Row className="filter-collapse-area">
        <Col span={24}>
          <Filter
            clearFilterFields={clearFilterFields}
            openedFilter={companiesState.showFilter !== ''}
            fields={fields}
            submitFilterFields={submitFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PagingTable
            emptyText={t('administrative-panel-empty-table')}
            dataSource={dataSource()}
            tableLoading={companiesState.companies.tableLoading}
            columns={companyListingCursor}
            options={mockedOptions}
            pageSize={companiesState.companies.pageSize}
            pageIndex={companiesState.companies.pageIndex}
            numberOfItems={companiesState.companies.numberOfItems}
            onChangePage={onChangePage}
            onChangeSort={onChangeSort}
            rowClickAction={(record: any) => getCompanyById(record.id)}
            selectRowsToGivenTab={(x: any) => selectRowsToCompanies(x)}
            selectIdFromRowsSelected={(x: any) =>
              selectIdFromRowsInCompanies(x)
            }
          />
        </Col>
      </Row>
    </Row>
  );
};
