import React from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

export const IE11FallbackPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Result
      status="500"
      title={t('ie-fallback-title')}
      subTitle={t('ie-fallback-description')}
    />
  );
};
