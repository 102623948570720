import { nonSecuredBaseApi } from './api.config';
import { IForgotPasswordRequest } from '../interfaces/services-interfaces/IForgotPasswordRequest';
import { IForgotPasswordResponse } from '../interfaces/services-interfaces/IForgotPasswordResponse';

export const forgotPasswordApi = {
  forgotPassword: (forgotPasswordBody: IForgotPasswordRequest) => {
    return nonSecuredBaseApi.post<IForgotPasswordResponse>(
      'public/forgot_password',
      forgotPasswordBody,
    );
  },
};
