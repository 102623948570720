import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { administrativePanelPermissionsApi } from '../../../../services/administrative-panel-permissions';
import { messageNotification } from '../../../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../../../helpers/unhookedTranslation/unhookedTranslation';

interface IPermissionsListingInitialState {
  shouldLoadOnLeft: 'companies' | 'repositories' | undefined;
  refreshEntityOnRight: boolean;
  permissionTables: ITableSettings[];
}

interface ITableSettings {
  tableTitle: string;
  tableType: 'companies' | 'repositories';
}

export interface IChangesInPermissions {
  changes: {
    entity: 'companies' | 'repositories';

    idsInDatabase: Array<number>;

    cachedChanges: undefined | Map<number, any>;

    totalOfChanges: number;
    alreadyHasChange: number[];
    entitiesWithAddedViewRights: number[];
    entitiesWithAddedEditRights: number[];

    entitiesWithRemovedViewRights: number[];
    entitiesWithRemovedEditRights: number[];
  };
}

const initialState: IPermissionsListingInitialState = {
  shouldLoadOnLeft: undefined,
  refreshEntityOnRight: false,
  permissionTables: JSON.parse(
    localStorage.getItem('permissionsListingUsersPreferredOrder') as any,
  ) ?? [
    {
      tableTitle: 'administrative-panel-permissions-companies-table-label',
      tableType: 'companies',
    },
    {
      tableTitle: 'administrative-panel-permissions-repositories-table-label',
      tableType: 'repositories',
    },
  ],
};

export const PermissionsListingSliceAsyncActions = {
  SAVE_CHANGES_IN_PERMISSIONS: createAsyncThunk(
    'SAVE_CHANGES_IN_PERMISSIONS',
    async (payload: any) => {
      await administrativePanelPermissionsApi.saveChangesInPermissions(payload);
    },
  ),
};

export const PermissionsListingSlice = createSlice({
  name: 'PermissionListingSlice',
  initialState,
  reducers: {
    SHOULD_LOAD_ON_LEFT: (state, action) => {
      state.shouldLoadOnLeft = action.payload;
    },
    CHANGE_TABLES_ORDER: (state) => {
      state.shouldLoadOnLeft = undefined;
      state.permissionTables = state.permissionTables.reverse();

      localStorage.setItem(
        'permissionsListingUsersPreferredOrder',
        JSON.stringify(state.permissionTables),
      );
    },
    REFRESHED_TABLE_ON_RIGHT: (state) => {
      state.refreshEntityOnRight = false;
    },
    RESET_TO_INITIAL_STATE: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(
      PermissionsListingSliceAsyncActions.SAVE_CHANGES_IN_PERMISSIONS.fulfilled,
      (state) => {
        state.refreshEntityOnRight = true;

        messageNotification.successMessage(
          unhookedTranslation(
            'administrative-panel-permissions-change-permissions-response-title-success',
          ),
          unhookedTranslation(
            'administrative-panel-permissions-change-permissions-response-content-success',
          ),
        );
      },
    ),
    builder.addCase(
      PermissionsListingSliceAsyncActions.SAVE_CHANGES_IN_PERMISSIONS.rejected,
      () => {
        messageNotification.errorMessage(
          unhookedTranslation(
            'administrative-panel-permissions-change-permissions-response-title-error',
          ),
          unhookedTranslation(
            'administrative-panel-permissions-change-permissions-response-content-error',
          ),
        );
      },
    ),
  ],
});
