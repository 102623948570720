import { showModal } from '../showModal/showModal';
import { unhookedTranslation } from '../unhookedTranslation/unhookedTranslation';
import { asyncActions } from '../../pages/AdministrativePanel/Users/Users.Slice';
import { store } from '../../store/store';

export const showBlockOrUnblockUserConfirmationModal = (
  id: number,
  rowItem: any,
) => {
  if (rowItem.blocked) {
    showModal(
      'warning',
      `${unhookedTranslation(
        'administrative-panel-unblock-user-warning-title',
      )}`,
      `${unhookedTranslation(
        'administrative-panel-unblock-user-warning-content',
      )}`,
      () => {
        store.dispatch(asyncActions.UNBLOCK_USER(id));
      },
      () => {},
      `${unhookedTranslation('administrative-panel-unblock-user-okText')}`,
      `${unhookedTranslation('administrative-panel-unblock-user-cancelText')}`,
    );
  } else {
    showModal(
      'warning',
      `${unhookedTranslation('administrative-panel-block-user-warning-title')}`,
      `${unhookedTranslation(
        'administrative-panel-block-user-warning-content',
      )}`,
      () => {
        store.dispatch(asyncActions.BLOCK_USER(id));
      },
      () => {},
      `${unhookedTranslation('administrative-panel-block-user-okText')}`,
      `${unhookedTranslation('administrative-panel-block-user-cancelText')}`,
    );
  }
};
