import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { administrativePanelPendingRequestsApi } from '../../services/administrative-panel-pending-requests.api';

interface IRequestTracker {
  numberOfPendingRequests: number;
}

const initialState: IRequestTracker = {
  numberOfPendingRequests: 0,
};

export const asyncActions = {
  GET_NUMBER_OF_PENDING_REQUESTS: createAsyncThunk(
    'GET_NUMBER_OF_PENDING_REQUESTS',
    async () => {
      const pendingRequests =
        await administrativePanelPendingRequestsApi.getPendingRequests(
          '?status=PENDING&page=1&size=0',
        );

      return pendingRequests.data.totalElements;
    },
  ),
};

export const RequestTrackerSlice = createSlice({
  name: 'RequestTrackerSlice',
  initialState,
  reducers: {
    RESET_NUMBER_OF_PENDING_REQUESTS: (state) => {
      state.numberOfPendingRequests = initialState.numberOfPendingRequests;
    },
    UPDATE_NUMBER_OF_PENDING_REQUESTS_AFTER_LISTING: (state, action) => {
      state.numberOfPendingRequests = action.payload;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(
      asyncActions.GET_NUMBER_OF_PENDING_REQUESTS.fulfilled,
      (state, action) => {
        state.numberOfPendingRequests = action.payload;
      },
    ),
  ],
});
