import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IngenicoWorldSvg } from '../../assets/ingenico_world.svg';

import './IngenicoWorld.scss';

interface IIngenicoWorld {
  children: JSX.Element;
  title: string;
  showThanks?: undefined | boolean;
}

export const IngenicoWorld = ({
  children,
  title,
  showThanks,
}: IIngenicoWorld) => {
  const { t } = useTranslation();
  const generateThanks = () => {
    if (!showThanks) {
      return;
    }

    return (
      <Row className="thanks-area">
        <Col>
          <p className="thanks-area-label">{t('ingenico-word-thanks')}</p>
          <p>{t('ingenico-word-ingenico-team')}</p>
        </Col>
      </Row>
    );
  };

  return (
    <Row className="IngenicoWorld">
      <Col className="left-column" span={11}>
        <Row>
          <Col span={24} className="left-column-header">
            <hr className="left-column-header-divider" />
            <h3 className="left-column-header-title"> {title} </h3>
          </Col>
        </Row>
        {children}
        {generateThanks()}
      </Col>
      <Col className="ingenico-world" offset={1} span={12}>
        <IngenicoWorldSvg />
      </Col>
    </Row>
  );
};
