import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { asyncActions } from './RequestTracker.Slice';

export const RequestTracker = () => {
  const user = useSelector((state: RootState) => state.login.user);
  const dispatch = useDispatch<AppDispatch>();
  const [poolingInterval, setPoolingInterval] = useState<any>();

  const resetPoolingInterval = useCallback(() => {
    if (poolingInterval) {
      clearInterval(poolingInterval);
      setPoolingInterval(undefined);
    }
  }, [poolingInterval, setPoolingInterval]);

  useEffect(() => {
    if (!user) {
      resetPoolingInterval();
      return;
    }

    if (user.role !== 'A') {
      resetPoolingInterval();
      return;
    }

    if (!poolingInterval) {
      dispatch(asyncActions.GET_NUMBER_OF_PENDING_REQUESTS());

      setPoolingInterval(
        setInterval(() => {
          dispatch(asyncActions.GET_NUMBER_OF_PENDING_REQUESTS());
        }, 15000),
      );
    }
  }, [
    user,
    poolingInterval,
    setPoolingInterval,
    resetPoolingInterval,
    dispatch,
  ]);

  return <Fragment />;
};
