import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { messageNotification } from '../../../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../../../helpers/unhookedTranslation/unhookedTranslation';
import {
  administrativePanelPopUpApi,
  IPopUpDTO,
  POP_UP_LANGUAGE_MAP,
  TPopUp,
  TPopUpDTO,
} from '../../../../services/administrative-panel-pop-up';

export interface IAdministrativePanelPopUpState {
  isContentLoaded: boolean;
  selectedLanguage: undefined | 'pt' | 'en' | 'es';
  hasChanged: boolean;
  shouldValidateForm: boolean;
  original: TPopUpDTO;
  form: TPopUp;
  modalIsVisible: boolean;
  finishedCreatingOrEditing: boolean;
}

export const initialState: IAdministrativePanelPopUpState = {
  isContentLoaded: false,
  hasChanged: false,
  shouldValidateForm: false,
  selectedLanguage: 'pt',
  original: {
    id: 0,
    active: true,
    type: 'DEPLOY',
    modifiedBy: ' ',
    pt: {
      id: 0,
      content: '',
      header: '',
    },
    en: {
      id: 0,
      content: '',
      header: '',
    },
    es: {
      id: 0,
      content: '',
      header: '',
    },
  },
  form: {
    active: true,
    type: null,
    pt: {
      content: '',
      header: '',
    },
    en: {
      content: '',
      header: '',
    },
    es: {
      content: '',
      header: '',
    },
  },
  modalIsVisible: true,
  finishedCreatingOrEditing: false,
};

export const PopUpAsyncActions = {
  GET_POP_UP: createAsyncThunk('GET_POP_UP', async (popUpId: number) => {
    const res = await administrativePanelPopUpApi.getPopUp(popUpId);

    return res.data;
  }),
  CREATE_A_NEW_POP_UP: createAsyncThunk(
    'CREATE_A_NEW_POP_UP',
    async (updatedPopUp: TPopUpDTO) => {
      const payload: IPopUpDTO = {
        id: updatedPopUp.id,
        active: updatedPopUp.active,
        type: updatedPopUp.type,
        modifiedBy: updatedPopUp.modifiedBy,
        contents: [
          {
            id: updatedPopUp.pt.id,
            language: 'PORTUGUESE',
            title: updatedPopUp.pt.header,
            description: updatedPopUp.pt.content,
          },
          {
            id: updatedPopUp.en.id,
            language: 'ENGLISH',
            title: updatedPopUp.en.header,
            description: updatedPopUp.en.content,
          },
          {
            id: updatedPopUp.es.id,
            language: 'SPANISH',
            title: updatedPopUp.es.header,
            description: updatedPopUp.es.content,
          },
        ],
      };

      const res = await administrativePanelPopUpApi.createPopUp(payload);

      return res.data;
    },
  ),
  UPDATE_POP_UP: createAsyncThunk(
    'UPDATE_POP_UP',
    async (updatedPopUp: TPopUpDTO) => {
      const payload: IPopUpDTO = {
        id: updatedPopUp.id,
        active: updatedPopUp.active,
        type: updatedPopUp.type,
        modifiedBy: updatedPopUp.modifiedBy,
        contents: [
          {
            id: updatedPopUp.pt.id,
            language: 'PORTUGUESE',
            title: updatedPopUp.pt.header,
            description: updatedPopUp.pt.content,
          },
          {
            id: updatedPopUp.en.id,
            language: 'ENGLISH',
            title: updatedPopUp.en.header,
            description: updatedPopUp.en.content,
          },
          {
            id: updatedPopUp.es.id,
            language: 'SPANISH',
            title: updatedPopUp.es.header,
            description: updatedPopUp.es.content,
          },
        ],
      };

      const res = await administrativePanelPopUpApi.updatePopUp(payload);

      return res.data;
    },
  ),
};

export const PopUpSlice = createSlice({
  name: 'popUpSlice',
  initialState,
  reducers: {
    SET_SELECTED_LANGUAGE: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    RESET_TO_INITIAL_STATE: () => {
      return initialState;
    },
    DISCARD_CHANGES: (state) => {
      state.hasChanged = false;
      state.form.active = state.original.active;
      state.form.type = state.original.type;
      state.form.pt.content = state.original.pt.content;
      state.form.pt.header = state.original.pt.header;
      state.form.en.content = state.original.en.content;
      state.form.en.header = state.original.en.header;
      state.form.es.content = state.original.es.content;
      state.form.es.header = state.original.es.header;
    },
    CHANGE_SELECTED_LANGUAGE_AND_SAVE_EDITOR_CURRENT_VALUE: (state, action) => {
      switch (state.selectedLanguage) {
        case 'pt':
          state.form.pt = action.payload.editorContent;
          break;

        case 'en':
          state.form.en = action.payload.editorContent;
          break;

        case 'es':
          state.form.es = action.payload.editorContent;
          break;

        default:
          break;
      }

      state.selectedLanguage = action.payload.newLanguage;
    },
    UPDATE_CHANGED_AND_SAVE_EDITOR_CURRENT_VALUE: (state, action) => {
      switch (state.selectedLanguage) {
        case 'pt':
          state.form.pt = action.payload.editorContent;
          break;

        case 'en':
          state.form.en = action.payload.editorContent;
          break;

        case 'es':
          state.form.es = action.payload.editorContent;
          break;

        default:
          break;
      }

      state.selectedLanguage = action.payload.newLanguage;
      state.form.type = action.payload.type;
      state.hasChanged = action.payload.hasChanged;
    },
    START_FORM_VALIDATION_AND_SAVE_EDITOR_CURRENT_VALUE: (state, action) => {
      switch (state.selectedLanguage) {
        case 'pt':
          state.form.pt = action.payload.editorContent;
          break;

        case 'en':
          state.form.en = action.payload.editorContent;
          break;

        case 'es':
          state.form.es = action.payload.editorContent;
          break;

        default:
          break;
      }

      state.form.type = action.payload.type;
      state.shouldValidateForm = true;
    },
    STOP_FORM_VALIDATION: (state) => {
      state.shouldValidateForm = false;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(PopUpAsyncActions.GET_POP_UP.fulfilled, (state, action) => {
      updatePopUpState(state, action);
      state.isContentLoaded = true;
    }),
    builder.addCase(PopUpAsyncActions.UPDATE_POP_UP.fulfilled, (state) => {
      state.isContentLoaded = true;
      state.hasChanged = false;
      state.shouldValidateForm = false;
      state.finishedCreatingOrEditing = true;
      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-update-pop-up-title'),
        unhookedTranslation('administrative-panel-update-pop-up-content'),
      );
    }),
    builder.addCase(
      PopUpAsyncActions.CREATE_A_NEW_POP_UP.fulfilled,
      (state) => {
        state.finishedCreatingOrEditing = true;

        messageNotification.successMessage(
          unhookedTranslation('pop-up-successfuly-created-title'),
          unhookedTranslation('pop-up-successfuly-created-content'),
        );
      },
    ),
  ],
});

/** Aux Function to update PopUp state */
const updatePopUpState = (
  state: IAdministrativePanelPopUpState,
  action: PayloadAction<IPopUpDTO>,
) => {
  const mappedRes = mapResToState(action.payload);

  state.original = mappedRes;
  state.form = {
    active: mappedRes.active,
    type: mappedRes.type,
    pt: {
      header: mappedRes.pt.header,
      content: mappedRes.pt.content,
    },
    en: {
      header: mappedRes.en.header,
      content: mappedRes.en.content,
    },
    es: {
      header: mappedRes.es.header,
      content: mappedRes.es.content,
    },
  };
};

/** Maps Backend Response to State */
const mapResToState = (res: IPopUpDTO): TPopUpDTO => {
  const { type, active, id } = res;
  const languagesContents = res.contents.reduce((acc: any, cur: any) => {
    const languageKey = POP_UP_LANGUAGE_MAP.get(cur.language) as string;

    return {
      ...acc,
      [languageKey]: {
        id: cur.id,
        header: cur.title,
        content: cur.description,
      },
    };
  }, {});

  return {
    id,
    active,
    type,
    ...languagesContents,
  };
};
