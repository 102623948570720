import { securedBaseApi } from './api.config';

export type TPopUpLanguages = 'pt' | 'en' | 'es';
export type TPopUpType = 'ALERT' | 'INFO' | 'DEPLOY' | null;

export type TPopUp = {
  active: boolean;
  type: TPopUpType;
} & {
  [key in TPopUpLanguages]: {
    header: string;
    content: string;
  };
};

export type TPopUpDTO = {
  id: number;
  active: boolean;
  type: TPopUpType;
  modifiedBy: string;
} & {
  [key in TPopUpLanguages]: {
    id: number;
    header: string;
    content: string;
  };
};

/**
 * Back-End interface for PopUp
 */
export interface IPopUpDTO {
  id: number;
  active: boolean;
  type: TPopUpType;
  createdAt?: Date;
  updatedAt?: Date;
  contents: {
    id: number;
    title: string;
    description: string;
    language: string;
  }[];
  modifiedBy: string;
}

interface IPopUpById {
  id: number;
  version: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
  accepted: boolean;
  contents: IPopUpByIdContents[];
  modifiedBy: string;
}

interface IPopUpByIdContents {
  id: number;
  description: string;
  language: 'PORTUGUESE' | 'ENGLISH' | 'SPANISH';
  createdAt: Date;
  updatedAt: Date;
  modifiedBy: string;
}

interface IPopUpList {
  content: IPopUpById[];
  totalElements: number;
}

/**
 * Dictionary of BackEnd languages based on TPopUpLanguages.
 * Used to map response into State object.
 */
export const POP_UP_LANGUAGE_MAP = new Map<string, TPopUpLanguages>([
  ['PORTUGUESE', 'pt'],
  ['ENGLISH', 'en'],
  ['SPANISH', 'es'],
]);

export const POP_UP_LANGUAGE_MAP_INVERTED = new Map<TPopUpLanguages, string>([
  ['pt', 'PORTUGUESE'],
  ['en', 'ENGLISH'],
  ['es', 'SPANISH'],
]);

export const administrativePanelPopUpApi = {
  getPopUp: (popUpId: number) => {
    return securedBaseApi.get<IPopUpDTO>(`/pop-up/${popUpId}`);
  },
  updatePopUp: (updatedPopUp: IPopUpDTO) => {
    return securedBaseApi.put<IPopUpDTO>(
      `/pop-up/${updatedPopUp.id}`,
      updatedPopUp,
    );
  },

  getPopUpList: (queryParams: string) => {
    return securedBaseApi.get<IPopUpList>(`/pop-up${queryParams}`);
  },
  createPopUp: (newPopUp: IPopUpDTO) => {
    return securedBaseApi.post<IPopUpDTO>('/pop-up', newPopUp);
  },
  activatePopUp: (popUpId: number) => {
    return securedBaseApi.patch(`/pop-up/${popUpId}/activate`);
  },
  deactivatePopUp: (popUpId: number) => {
    return securedBaseApi.patch(`/pop-up/${popUpId}/deactivate`);
  },
  deletePopUp: (popUpId: number) => {
    return securedBaseApi.delete(`/pop-up/${popUpId}`);
  },
};
