import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DeleteSelectedButton } from '../../../../components/DeleteSelectedButton/DeleteSelectedButton';
import { Filter } from '../../../../components/Filter/Filter';
import { PagingTable } from '../../../../components/PagingTable/PagingTable';
import { ShowFiltersButton } from '../../../../components/ShowFiltersButton/ShowFiltersButton';

import { ReactComponent as EditPencil } from '../../../../assets/popover-icons/edit.svg';
import { ReactComponent as Padlock } from '../../../../assets/popover-icons/padlock.svg';
import { ReactComponent as TrashCan } from '../../../../assets/popover-icons/trash-can.svg';

import './NewsListing.scss';
import { AppDispatch, RootState } from '../../../../store/store';
import { NewsSlice, asyncActions } from '../News.Slice';
import { scrollToTopFunction } from '../../../../helpers/useScrollToTop/useScrollToTop';
import { showModal } from '../../../../helpers/showModal/showModal';
import { carouselAsyncActions } from '../../../Home/Carousel/Carousel.Slice';
import { ActiveNews } from './ActiveNews/ActiveNews';

export const NewsListing: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const newsListState = useSelector((state: RootState) => state.news.list);

  useEffect(() => {
    if (!newsListState.shouldRefresh) {
      return;
    }

    if (newsListState.tableLoading) {
      return;
    }

    let queryString = `?page=${newsListState.pageIndex - 1}&size=${
      newsListState.pageSize
    }`;

    if (newsListState.queryString.length > 0) {
      queryString = `${queryString}${newsListState.queryString}`;
    }

    if (newsListState.defaultSortBehaviourActive) {
      queryString += `&sort=active`;
    }

    if (newsListState.sortBy) {
      queryString = `${queryString}${newsListState.sortBy}`;
    }

    dispatch(asyncActions.NEWS_LIST(queryString));
    dispatch(carouselAsyncActions.GET_ACTIVATED_NEWS_FOR_CAROUSEL());
  }, [dispatch, newsListState]);

  useEffect(() => {
    return () => {
      dispatch(
        NewsSlice.actions.NEWS_BACK_TO_ORIGINAL_STATE_PERSISTING_FILTER(),
      );
    };
  }, [dispatch]);

  const [columnsWidth, setColumnsWidth] = useState({
    titleColumnWidth: 4.2,
    subtitleColumnWidth: 4.2,
    linkColumnWidth: 4.2,
  });

  const resizeHandlerSpeed = 0.015;
  const resizeColumns = (
    columnName: 'titleColumnWidth' | 'subtitleColumnWidth' | 'linkColumnWidth',
    movement: number,
  ) => {
    setColumnsWidth({
      ...columnsWidth,
      [columnName]: (columnsWidth[columnName] += movement * resizeHandlerSpeed),
    });
  };

  const newsColumnCursor = [
    {
      disableSort: true,
      columnTitle: t('news-listing-table-title'),
      columnProperty: 'titleLanguage',
      width: columnsWidth.titleColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('titleColumnWidth', event.movementX);
      },
    },
    {
      disableSort: true,
      columnTitle: t('news-listing-table-subtitle'),
      columnProperty: 'subtitleLanguage',
      width: columnsWidth.subtitleColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('subtitleColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('news-listing-table-link'),
      columnProperty: 'link',
      width: columnsWidth.linkColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('linkColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-pop-up-modified-by'),
      columnProperty: 'modifiedBy',
      width: columnsWidth.titleColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('titleColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-pop-up-last-modified'),
      columnProperty: 'updatedAt',
      width: columnsWidth.titleColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('titleColumnWidth', event.movementX);
      },
    },
  ];

  const newsFilterCursor = [
    {
      fieldTitle: t('administrative-panel-pop-up-modified-by'),
      fieldType: 'input',
      fieldProperty: 'modifiedBy',
      fieldValue: newsListState.filter.filterByModifiedBy,
    },
    {
      fieldTitle: t('news-listing-table-link'),
      fieldType: 'input',
      fieldProperty: 'link',
      fieldValue: newsListState.filter.filterByLink,
    },
    {
      fieldTitle: 'Status',
      fieldType: 'select',
      fieldProperty: 'active',
      fieldValue: newsListState.filter.filterByActive,
      selectOptions: [
        { name: t('news-listing-table-status-active'), id: true },
        { name: t('news-listing-table-status-inactive'), id: false },
      ],
    },
  ];

  const newsColumnOptions = [
    {
      optionName: t('administrative-panel-news-table-column-edit-option'),
      optionIcon: <EditPencil />,
      optionOnClick: (id: number) => {
        getNewsById(id);
      },
    },
    {
      optionName: t(
        'administrative-panel-news-table-column-activate-deactivate',
      ),
      optionIcon: <Padlock />,
      optionOnClick: (id: number, rowItem: any) => {
        showEnableOrDisableNewsConfirmationModal(id, rowItem.active);
      },
    },
    {
      optionName: t('administrative-panel-users-table-column-delete-option'),
      optionIcon: <TrashCan />,
      optionOnClick: (id: number) => showDeleteConfirmationModal(id),
    },
  ];

  const getNewsById = (id: number) => {
    navigate(`/administrative-panel/add_or_edit?id=${id}&tab=news`);
  };

  const toggleFilter = () => {
    if (newsListState.showFilter === '') {
      dispatch(NewsSlice.actions.FILTER_STATUS_CHANGE('1'));
    } else {
      dispatch(NewsSlice.actions.FILTER_STATUS_CHANGE(''));
    }
  };

  const dispatchSortBy = (sortBy: string) => {
    dispatch(NewsSlice.actions.NEWS_SORT_BY(sortBy ? `sort=${sortBy}` : ''));
  };

  const onChangePage = (pageIndex: number) => {
    if (!pageIndex || pageIndex === newsListState.pageIndex) {
      return;
    }

    scrollToTopFunction();
    dispatch(NewsSlice.actions.NEWS_CHANGE_PAGE(pageIndex));
  };

  const onChangeSort = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    if (!order || !field) {
      return dispatchSortBy('');
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;
    let sortBy = '&sort=';

    if (field === 'active' || field === 'updatedAt') {
      sortBy +=
        order === 'ascend'
          ? `${fieldToQueryParam}`
          : `${fieldToQueryParam},desc`;

      return dispatchSortBy(sortBy);
    }

    sortBy +=
      order === 'ascend'
        ? `${fieldToQueryParam}`
        : `${fieldToQueryParam},desc,ignoreCase`;

    dispatch(NewsSlice.actions.NEWS_SORT_BY(`&sort=${sortBy}`));
  };

  const selectRowsToNews = (x: any) => {
    dispatch(NewsSlice.actions.NEWS_CREATED_SET_SELECTED_ROWS(x));
  };

  const selectIdFromRowsInNews = (x: any) => {
    dispatch(NewsSlice.actions.NEWS_CREATED_SET_ID_TO_DELETE_NEWS(x));
  };

  const deleteSelectedNews = () => {
    dispatch(asyncActions.DELETE_MANY_NEWS(newsListState.deleteManyNewsIds));
  };

  const clearFilterFields = () => {
    dispatch(NewsSlice.actions.CLEAR_FILTER_FIELDS());
  };

  const submitFilter = (values: any) => {
    const queryParams = Object.entries(values).reduce((acc, [key, value]) => {
      switch (key) {
        case 'modifiedBy':
          dispatch(NewsSlice.actions.NEWS_FILTER_SET_MODIFIED_BY(values[key]));
          break;
        case 'link':
          dispatch(NewsSlice.actions.NEWS_FILTER_SET_LINK(values[key]));
          break;
        case 'active':
          dispatch(NewsSlice.actions.NEWS_FILTER_SET_STATUS(values[key]));
          break;
        default:
          break;
      }

      if (value !== '' && value !== null && value !== undefined) {
        acc += `&${key}=${String(value).trim()}`;
      }

      return acc;
    }, '');

    dispatch(NewsSlice.actions.NEWS_SET_FILTER_QUERY_STRING(queryParams));
  };

  const showDeleteConfirmationModal = (id: number) => {
    showModal(
      'warning',
      `${t('administrative-panel-delete-news-warning-title')}`,
      `${t('administrative-panel-delete-news-warning-content')}`,
      () => {
        dispatch(asyncActions.DELETE_ONE_NEWS(id));
      },
      () => {},
      `${t('administrative-panel-okText-delete')}`,
      `${t('administrative-panel-cancelText-delete')}`,
    );
  };

  const showEnableOrDisableNewsConfirmationModal = (
    id: number,
    isActive: boolean,
  ) => {
    showModal(
      'warning',
      `${t(
        isActive
          ? 'administrative-panel-disable-news-warning-title'
          : 'administrative-panel-enable-news-warning-title',
      )}`,
      `${t(
        isActive
          ? 'administrative-panel-disable-news-warning-content'
          : 'administrative-panel-enable-news-warning-content',
      )}`,
      () => {
        if (isActive) {
          dispatch(asyncActions.DEACTIVATE_NEWS(id));
        } else {
          dispatch(asyncActions.ACTIVATE_NEWS(id));
        }
      },
      () => {},
      `${t(
        isActive
          ? 'administrative-panel-disable-news-okText'
          : 'administrative-panel-enable-news-okText',
      )}`,
      `${t(
        isActive
          ? 'administrative-panel-disable-news-cancelText'
          : 'administrative-panel-enable-news-cancelText',
      )}`,
    );
  };

  return (
    <Row className="NewsListing">
      <Col span={24}>
        <Row>
          <span className="news_listing-news-text-label">
            {t('news-listing-activated-news-label')}
          </span>
        </Row>
        <ActiveNews />
        <Row className="buttons-and-filter-button">
          <Col span={3}>
            <Button
              id="new-news-button"
              shape="round"
              onClick={() =>
                navigate('/administrative-panel/add_or_edit?tab=news')
              }
            >
              {t('administrative-panel-new-news-button-label')}
            </Button>
          </Col>
          <Col span={6}>
            <DeleteSelectedButton
              numberOfItemsSelected={newsListState.selectedRowToDeleteNews}
              messageConfirmationByGivenTab={`${t(
                'administrative-panel-delete-button',
              )}`}
              singularContentConfirmationByGivenTab={`${t(
                'news-listing-delete-one-news',
              )}`}
              pluralContentConfirmationByGivenTab={`${t(
                'news-listing-delete-many-news',
              )}`}
              deleteOneTitleModal={`${t(
                'news-listing-delete-single-news-warning-title',
              )}`}
              deleteOneContentModal={`${t(
                'news-listing-delete-single-news-warning-content',
              )}`}
              deleteManyTitleModal={`${t(
                'news-listing-delete-many-news-warning-title',
              )}`}
              deleteManyContentModal={`${t(
                'news-listing-delete-many-news-warning-content',
              )}`}
              onOkText={`${t('administrative-panel-okText-delete')}`}
              onCancelText={`${t('administrative-panel-cancelText-delete')}`}
              deleteAction={deleteSelectedNews}
            />
          </Col>
          <ShowFiltersButton
            onToggle={toggleFilter}
            openedFilter={newsListState.showFilter === ''}
            offset={12}
          />
        </Row>
        <Row>
          <Col span={24}>
            <Filter
              clearFilterFields={clearFilterFields}
              openedFilter={newsListState.showFilter !== ''}
              fields={newsFilterCursor}
              submitFilterFields={submitFilter}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <PagingTable
              emptyText={t('administrative-panel-empty-table')}
              dataSource={newsListState.data}
              tableLoading={newsListState.tableLoading}
              columns={newsColumnCursor}
              options={newsColumnOptions}
              pageSize={newsListState.pageSize}
              pageIndex={newsListState.pageIndex}
              numberOfItems={newsListState.numberOfItems}
              onChangePage={onChangePage}
              onChangeSort={onChangeSort}
              rowClickAction={(record: any) => getNewsById(record.id)}
              selectRowsToGivenTab={(x: any) => selectRowsToNews(x)}
              selectIdFromRowsSelected={(x: any) => selectIdFromRowsInNews(x)}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
