import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { IngenicoWorld } from '../../../components/IngenicoWorld/IngenicoWorld';
import './ConcludedForgotPassword.scss';

export const ConcludedForgotPassword = () => {
  const { t } = useTranslation();
  return (
    <Col pull={1} span={22} push={1}>
      <IngenicoWorld title={t('ingenico-word-request-response')} showThanks>
        <Row className="ConcludedForgotPassword">
          <Col>
            <p className="concluded-forgot-password-message">
              {t('forgot-password-message-warning')}
            </p>
          </Col>
        </Row>
      </IngenicoWorld>
    </Col>
  );
};
