import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExportSelectedButton } from '../ExportSelectedButton/ExportSelectedButton';
import { Filter } from '../../../../components/Filter/Filter';
import {
  IDefaultSortBehaviour,
  PagingTable,
} from '../../../../components/PagingTable/PagingTable';
import { ShowFiltersButton } from '../../../../components/ShowFiltersButton/ShowFiltersButton';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  asyncActions,
  ExportMode,
  MailingListSlice,
} from '../MailingList.Slice';
import { asyncActions as asyncActionsCompanies } from '../../Companies/CompanyAdministrativePanel.Slice';
import './MailingList.scss';
import { scrollToTopFunction } from '../../../../helpers/useScrollToTop/useScrollToTop';
import { ExportModal } from '../ExportModal/ExportModal';
import { IUserData } from '../../../../helpers/mailingList/IUserData';

export const MailingList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const mailingListState = useSelector((state: RootState) => state.mailingList);
  const usersListState = useSelector(
    (state: RootState) => state.mailingList.list,
  );
  const companyState = useSelector(
    (state: RootState) => state.CompanyAdministrativePanel,
  );

  useEffect(() => {
    dispatch(asyncActionsCompanies.COMPANY_LIST_FOR_USERS());
    dispatch(asyncActionsCompanies.COUNTRIES_LIST());
  }, [dispatch]);

  useEffect(() => {
    if (!usersListState.shouldRefresh) {
      return;
    }

    if (usersListState.tableLoading) {
      return;
    }

    let queryString = `?page=${usersListState.pageIndex - 1}&size=${
      usersListState.pageSize
    }`;

    if (usersListState.userQueryString.length > 0) {
      queryString = `${queryString}&${usersListState.userQueryString.join('&')}`;
    }

    if (usersListState.sortBy) {
      queryString = `${queryString}&${usersListState.sortBy}`;
    }

    if (usersListState.userFirstQueryBehaviour) {
      queryString = `${queryString}&sort=firstName,ignoreCase`;
    }

    dispatch(asyncActions.USERS_MAILING_LIST(queryString));
  }, [dispatch, usersListState]);

  const [columnsWidth, setColumnsWidth] = useState({
    firstNameColumnWidth: 4.2,
    lastNameColumnWidth: 4.2,
    emailColumnWidth: 4.2,
    companyColumnWidth: 4.2,
    countryColumnWidth: 3.5,
    lastInteractionColumnWidth: 3.5,
  });

  const resizeHandlerSpeed = 0.028;
  const resizeColumns = (
    columnName:
      | 'firstNameColumnWidth'
      | 'lastNameColumnWidth'
      | 'emailColumnWidth'
      | 'companyColumnWidth'
      | 'countryColumnWidth'
      | 'lastInteractionColumnWidth',
    movement: number,
  ) => {
    setColumnsWidth({
      ...columnsWidth,
      [columnName]: (columnsWidth[columnName] += movement * resizeHandlerSpeed),
    });
  };
  const mailingListCursor = [
    {
      columnTitle: t('administrative-panel-mailing-list-tab-name'),
      columnProperty: 'firstName',
      width: columnsWidth.firstNameColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('firstNameColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-mailing-list-tab-lastName'),
      columnProperty: 'lastName',
      width: columnsWidth.lastNameColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('lastNameColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-mailing-list-tab-email'),
      columnProperty: 'email',
      width: columnsWidth.emailColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('emailColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-mailing-list-tab-company'),
      columnProperty: ['company', 'name'],
      width: columnsWidth.companyColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('companyColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-mailing-list-tab-country'),
      columnProperty: ['countryName'],
      width: columnsWidth.countryColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('countryColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-mailing-list-last-interaction'),
      columnProperty: 'latestEventDate',
      width: columnsWidth.lastInteractionColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('lastInteractionColumnWidth', event.movementX);
      },
    },
  ];

  const toggleFilter = () => {
    if (mailingListState.showFilter === '') {
      dispatch(MailingListSlice.actions.FILTER_STATUS_CHANGE('1'));
    } else {
      dispatch(MailingListSlice.actions.FILTER_STATUS_CHANGE(''));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(
        MailingListSlice.actions.USER_BACK_TO_ORIGINAL_STATE_PERSISTING_FILTER(),
      );
    };
  }, [dispatch]);

  const onChangePage = (pageIndex: number) => {
    scrollToTopFunction('export-all-users-button');
    dispatch(MailingListSlice.actions.USERS_CHANGE_PAGE(pageIndex));
  };

  const selectRowsToUsers = (x: any) => {
    dispatch(MailingListSlice.actions.USERS_SET_SELECTED_ROWS(x));
  };

  const selectIdFromRowsInUsers = (x: any) => {
    dispatch(MailingListSlice.actions.USERS_SET_ID_TO_EXPORT_USERS(x));
  };

  const clearFilterFields = () => {
    dispatch(MailingListSlice.actions.CLEAR_FILTER_FIELDS());
  };

  const defaultSortBehaviour: IDefaultSortBehaviour = {
    columnProperty: 'firstName',
    order: 'ascend',
    disableDefaultSortBehaviour: () => {
      if (usersListState.userFirstQueryBehaviour) {
        dispatch(MailingListSlice.actions.DISABLE_DEFAULT_SORT_BEHAVIOUR());
      }
    },
  };

  const dispatchSortBy = (sortBy: string) => {
    dispatch(
      MailingListSlice.actions.USERS_SORT_BY(sortBy ? `sort=${sortBy}` : ''),
    );
  };

  const onChangeSort = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    let sortBy = '';

    if (!order || !field) {
      return dispatchSortBy(sortBy);
    }

    if (defaultSortBehaviour) {
      defaultSortBehaviour.disableDefaultSortBehaviour();
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;

    switch (fieldToQueryParam) {
      case 'firstName':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      case 'lastName':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      case 'company':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam}.name,ignoreCase`
            : `${fieldToQueryParam}.name,desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      case 'countryName':
        sortBy = order === 'ascend' ? 'country' : `country,desc`;
        return dispatchSortBy(sortBy);

      case 'blocked':
        sortBy +=
          order === 'ascend'
            ? `${fieldToQueryParam}`
            : `${fieldToQueryParam},desc`;

        return dispatchSortBy(sortBy);

      case 'latestEventDate':
        // The response property from the backend is `latestEventDate`, but the query requires `latestEvent`
        sortBy =
          order === 'ascend'
            ? `latestEvent,ignoreCase`
            : `latestEvent,desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      default:
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);
    }
  };

  const submitFilter = (values: any) => {
    const queryParams: string[] = [];
    Object.keys(values).forEach((key) => {
      switch (key) {
        case 'firstName':
          dispatch(MailingListSlice.actions.FILTER_SET_NAME(values[key]));
          break;
        case 'lastName':
          dispatch(MailingListSlice.actions.FILTER_SET_LASTNAME(values[key]));
          break;
        case 'companyId':
          dispatch(MailingListSlice.actions.FILTER_SET_COMPANY(values[key]));
          break;
        case 'countryId':
          dispatch(MailingListSlice.actions.FILTER_SET_COUNTRY(values[key]));
          break;
        case 'latestEventDate':
          dispatch(
            MailingListSlice.actions.FILTER_SET_LAST_LOGIN_INTERACTION(
              values[key],
            ),
          );
          break;
        case 'blocked':
          dispatch(MailingListSlice.actions.FILTER_SET_ACTIVE(values[key]));
          break;
        default:
          break;
      }

      if (values[key]) {
        if (key === 'latestEventDate') {
          // Formatted dates are values that represent an array with interval dates
          const initialFormattedDate = values[key][0].format('YYYY-MM-DD');
          const finalFormattedDate = values[key][1].format('YYYY-MM-DD');

          queryParams.push(
            `startDate=${initialFormattedDate}&endDate=${finalFormattedDate}`,
          );
        }
        if (key === 'blocked') {
          // If selected all return
          if (values[key] === 1) return;

          // If selected blocked true (key === 3)
          const blockedParam = values[key] === 3;

          queryParams.push(`${key}=${blockedParam}`);
        } else {
          queryParams.push(`${key}=${values[key]}`);
        }
      }
    });

    dispatch(MailingListSlice.actions.SET_FILTER_QUERY_STRING(queryParams));
  };

  const countriesListing = () => {
    const countries = companyState.companies.companyRegister.countriesList.map(
      (x) => {
        return {
          ...x,
          name: t(x.name),
        };
      },
    );

    return countries.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
    );
  };

  const fields = [
    {
      fieldTitle: t('administrative-panel-filter-by-name'),
      fieldType: 'input',
      fieldProperty: 'firstName',
      fieldValue: usersListState.filter.filterByFirstName,
    },
    {
      fieldTitle: t('administrative-panel-filter-by-lastname'),
      fieldType: 'input',
      fieldProperty: 'lastName',
      fieldValue: usersListState.filter.filterByLastName,
    },
    {
      fieldTitle: t('administrative-panel-filter-by-company'),
      fieldType: 'select',
      fieldProperty: 'companyId',
      fieldValue: usersListState.filter.filterByCompany,
      selectOptions: companyState.companies.dataForUsers,
    },
    {
      fieldTitle: t('administrative-panel-filter-by-country'),
      fieldType: 'select',
      fieldProperty: 'countryId',
      fieldValue: usersListState.filter.filterByCountry,
      selectOptions: countriesListing(),
    },
    {
      fieldTitle: t('administrative-panel-filter-last-interaction'),
      fieldType: 'rangePicker',
      fieldProperty: 'latestEventDate',
      fieldValue: usersListState.filter.filterByLastInteraction,
    },
    {
      fieldTitle: t('mailing-listing-table-status'),
      fieldType: 'select',
      fieldProperty: 'blocked',
      fieldValue: usersListState.filter.filterByStatus,
      selectOptions: [
        {
          id: 1,
          name: t('mailing-listing-all-status'),
        },
        {
          id: 2,
          name: t('mailing-listing-unblocked-status'),
        },
        {
          id: 3,
          name: t('mailing-listing-blocked-status'),
        },
      ],
    },
  ];

  const dataSource = () => {
    return usersListState.data.map((x) => {
      return {
        ...x,
        countryName: t(x.country.name),
      };
    });
  };

  const hideExportModal = () => {
    dispatch(
      MailingListSlice.actions.SET_SHOW_EXPORT_MODAL({
        isVisible: false,
        exportMode: ExportMode.All,
      }),
    );
  };

  const exportAllUsers = () => {
    dispatch(asyncActions.GET_ALL_USERS());
  };

  const exportFilteredUsers = () => {
    if (mailingListState.list.exportManyUsersIds) {
      dispatch(
        asyncActions.GET_USERS_BY_ID(mailingListState.list.exportManyUsersIds),
      );
    }
  };

  const prepareUserData = () => {
    let userData;

    if (mailingListState.exportMode === ExportMode.Filtered) {
      userData = usersListState.data;
      const userIdsToExport = usersListState.exportManyUsersIds;
      userData = userData.filter((x) => userIdsToExport?.includes(x.id));
    } else {
      if (mailingListState.users) {
        userData = mailingListState.users.content;
      }
    }

    const mappedUserData = userData?.map(({ email, firstName, lastName }) => ({
      email,
      firstName,
      lastName,
    }));

    return mappedUserData;
  };

  const exportXlsx = () => {
    const userData: IUserData[] | undefined = prepareUserData();
    if (userData) {
      dispatch(asyncActions.EXPORT_XLSX(userData));
    }
  };

  const exportCsv = () => {
    const userData: IUserData[] | undefined = prepareUserData();
    if (userData) {
      dispatch(asyncActions.EXPORT_CSV(userData));
    }
  };

  const exportToClipboard = () => {
    const userData: IUserData[] | undefined = prepareUserData();
    if (userData) {
      dispatch(asyncActions.EXPORT_TO_CLIPBOARD(userData));
    }
  };

  return (
    <Row className="MailingList">
      <Row className="buttons-filters" justify="space-between">
        <Col span={3}>
          <Button
            id="export-all-users-button"
            className="mailing-list-button-export-all-users"
            onClick={() => {
              exportAllUsers();
            }}
            disabled={!usersListState.data.length}
            loading={mailingListState.isLoadingAllUsers}
          >
            {t('administrative-panel-export-all-users-button-label')}
          </Button>
        </Col>
        <Col pull={6}>
          <ExportSelectedButton
            numberOfItemsSelected={usersListState.selectedRowToExportUsers}
            exportButtonLabel={`${t('administrative-panel-export-button')}`}
            singularUserLabel={`${t('administrative-panel-one-user')}`}
            pluralUserLabel={`${t('administrative-panel-many-users')}`}
            openExportModal={exportFilteredUsers}
          />
          <ExportModal
            showModal={mailingListState.showExportModal}
            exportMode={mailingListState.exportMode}
            numberOfItemsSelected={mailingListState.exportUserCount}
            exportType={mailingListState.exportType}
            isExporting={mailingListState.isExporting}
            onCancel={() => hideExportModal()}
            onExportXlsx={() => exportXlsx()}
            onExportCsv={() => exportCsv()}
            onExportToClipboard={() => exportToClipboard()}
          />
        </Col>
        <ShowFiltersButton
          onToggle={toggleFilter}
          openedFilter={mailingListState.showFilter === ''}
        />
      </Row>
      <Row className="filter-collapse-area">
        <Col span={24}>
          <Filter
            clearFilterFields={clearFilterFields}
            openedFilter={mailingListState.showFilter !== ''}
            fields={fields}
            submitFilterFields={submitFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PagingTable
            emptyText={t('administrative-panel-empty-table')}
            dataSource={dataSource()}
            tableLoading={usersListState.tableLoading}
            columns={mailingListCursor}
            options={[]}
            pageSize={usersListState.pageSize}
            pageIndex={usersListState.pageIndex}
            numberOfItems={usersListState.numberOfItems}
            onChangePage={onChangePage}
            onChangeSort={onChangeSort}
            rowClickAction={() => {}}
            selectRowsToGivenTab={(x: any) => selectRowsToUsers(x)}
            selectIdFromRowsSelected={(x: any) => selectIdFromRowsInUsers(x)}
          />
        </Col>
      </Row>
    </Row>
  );
};
