import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './ShowFiltersButton.scss';

interface IShowFiltersButton {
  openedFilter: boolean;
  onToggle: () => void;
  offset?: number;
}

export const ShowFiltersButton: React.FC<IShowFiltersButton> = ({
  openedFilter,
  onToggle,
  offset,
}: IShowFiltersButton) => {
  const { t } = useTranslation();

  if (openedFilter) {
    return (
      <Col span={3} offset={offset} className="ShowFiltersButton">
        <Button
          id="filter-button"
          name="openFilterButton"
          type="link"
          onClick={onToggle}
          className="company-listing-button-link-filter"
        >
          {t('administrative-panel-open-filter-button')}
          <DownOutlined />
        </Button>
      </Col>
    );
  }
  return (
    <Col span={3} offset={offset} className="ShowFiltersButton">
      <Button
        name="closeFilterButton"
        type="link"
        onClick={onToggle}
        className="company-listing-button-link-filter"
      >
        {t('administrative-panel-close-filter-button')}
        <UpOutlined />
      </Button>
    </Col>
  );
};
