import { Col, Divider, Row } from 'antd';
import React, { Fragment } from 'react';
import { ConcludedRequests } from './ConcludedRequests/ConcludedRequests';
import { PendingRequests } from './PendingRequests/PendingRequests';

export const Requests = () => {
  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <PendingRequests />
        </Col>
      </Row>
      <Row>
        <Divider />
      </Row>
      <Row>
        <Col span={24}>
          <ConcludedRequests />
        </Col>
      </Row>
    </Fragment>
  );
};
