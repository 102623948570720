import { nonSecuredBaseApi } from './api.config';

export interface ICreatePasswordRequest {
  password: string;
  passwordConfirmation: string;
}

export const createPasswordApi = {
  createPassword: (
    token: string,
    createPasswordBody: ICreatePasswordRequest,
  ) => {
    return nonSecuredBaseApi.post(
      `public/change_password/${token}`,
      createPasswordBody,
    );
  },
  validateToken: (token: string) => {
    return nonSecuredBaseApi.get(`public/change_password/${token}/verify`);
  },
};
