import { Modal, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewCompany } from '../../pages/AdministrativePanel/Companies/NewCompany/NewCompany';

interface ISuggestCompanyModal {
  suggestCompanyModalVisibility: boolean;
  suggestCompanyModalToggle: () => void;
}

export const SuggestCompanyModal: React.FC<ISuggestCompanyModal> = ({
  suggestCompanyModalVisibility,
  suggestCompanyModalToggle,
}: ISuggestCompanyModal) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('request-access-company-search-failed-suggest-button')}
      visible={suggestCompanyModalVisibility}
      footer={null}
      onCancel={suggestCompanyModalToggle}
      destroyOnClose
      width="60vw"
    >
      <Row>
        <NewCompany
          requestAccessMode
          requestAccessModeToggleModal={suggestCompanyModalToggle}
        />
      </Row>
    </Modal>
  );
};
