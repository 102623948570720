import { nonSecuredBaseApi } from './api.config';

export const activateUserApi = {
  activateAccount: (token: string) => {
    return nonSecuredBaseApi.post(`/public/activate-account`, token, {
      headers: {
        'Content-Type': 'text/plain',
      },
    });
  },
};
