import { Modal, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid';
import { getEditorToPreview } from '../../../../../helpers/getEditorToPreview/getEditorToPreview';
import { RootState } from '../../../../../store/store';
import { PopUpListingSlice } from '../PopUpListing.Slice';

export const PreviewPopUp = () => {
  const previewPopUpState = useSelector(
    (state: RootState) => state.popUpListing.previewPopUp,
  );
  const navbarState = useSelector((state: RootState) => state.navBar);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!navbarState.loadedDefaultLanguage) {
      return;
    }

    if (previewPopUpState.previewPopUpLanguage) {
      return;
    }

    dispatch(
      PopUpListingSlice.actions.SET_PREVIEW_POP_UP_LANGUAGE(
        navbarState.defaultLanguage,
      ),
    );
  }, [
    dispatch,
    navbarState.loadedDefaultLanguage,
    previewPopUpState.previewPopUpLanguage,
    navbarState.defaultLanguage,
  ]);

  return (
    <Modal
      title={t('administrative-panel-tabs-pop-up')}
      visible={!!previewPopUpState.id}
      destroyOnClose
      centered
      onCancel={() => {
        dispatch(PopUpListingSlice.actions.CLOSE_PREVIEW_POP_UP());
      }}
      footer={null}
    >
      <Tabs
        key={`${previewPopUpState.previewPopUpLanguage}${uniqid()}`}
        activeKey={previewPopUpState.previewPopUpLanguage}
        onChange={(activeKey) => {
          dispatch(
            PopUpListingSlice.actions.SET_PREVIEW_POP_UP_LANGUAGE(activeKey),
          );
        }}
        centered
      >
        <Tabs.TabPane tab={t('request-access-terms-pt-tab')} key="pt">
          <span style={{ fontWeight: 'bold' }}>
            {getEditorToPreview(previewPopUpState.ptTitle)}
          </span>
          {getEditorToPreview(previewPopUpState.ptContent)}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('request-access-terms-en-tab')} key="en">
          <span style={{ fontWeight: 'bold' }}>
            {getEditorToPreview(previewPopUpState.enTitle)}
          </span>
          {getEditorToPreview(previewPopUpState.enContent)}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('request-access-terms-es-tab')} key="es">
          <span style={{ fontWeight: 'bold' }}>
            {getEditorToPreview(previewPopUpState.esTitle)}
          </span>
          {getEditorToPreview(previewPopUpState.esContent)}
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};
