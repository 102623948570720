import React from 'react';
import './CardPasswordValidation.scss';
import { useTranslation } from 'react-i18next';

interface IPasswordValidationPopOver {
  styleLet: string;
  styleCap: string;
  styleNum: string;
  styleLen: string;
}

export const PasswordValidationPopOver: React.FC<
  IPasswordValidationPopOver
> = ({
  styleLet,
  styleCap,
  styleNum,
  styleLen,
}: IPasswordValidationPopOver) => {
  const { t } = useTranslation();

  return (
    <div className="CardPasswordValidation">
      <div className="card-validate-password">
        <h4> {t('password-validation-popover-title')}</h4>
        <p id="letter" className={styleLet}>
          {t('password-validation-popover-one-char')}
          <b> {t('password-validation-popover-lowercase-rule-bold')}</b>
        </p>
        <p id="capital" className={styleCap}>
          {t('password-validation-popover-one-char')}
          <b> {t('password-validation-popover-uppercase-rule-bold')}</b>
        </p>
        <p id="number" className={styleNum}>
          {t('password-validation-popover-at-least')}
          <b> {t('password-validation-popover-number-rule-bold')}</b>
        </p>
        <p id="length" className={styleLen}>
          {t('password-validation-popover-minimum')}
          <b> {t('password-validation-popover-length-rule-bold')}</b>
        </p>
      </div>
    </div>
  );
};
