import { notification } from 'antd';
import uniqid from 'uniqid';

import './messageNotification.scss';

export const messageNotification = {
  successMessage: (title: string, content?: string) => {
    const successNotification = notification;
    const key = uniqid();

    notification.success({
      key,
      message: title,
      description: content,
      className: 'success-message-notification',
      onClick: () => successNotification.close(key),
      duration: 8,
    });
  },
  warningMessage: (title: string, content: string) => {
    const warningNotification = notification;
    const key = uniqid();

    notification.warning({
      key,
      message: title,
      description: content,
      duration: 8,
      className: 'warning-message-notification',
      onClick: () => warningNotification.close(key),
    });
  },
  errorMessage: (title: string, content?: string) => {
    const errorNotification = notification;
    const key = uniqid();
    errorNotification.error({
      key,
      message: title,
      description: content,
      duration: 8,
      className: 'error-message-notification',
      onClick: () => errorNotification.close(key),
    });
  },
};
