import { Input, InputRef } from 'antd';
import React, { useEffect, useRef } from 'react';

import './InputWithSearch.scss';

interface IInputWithSearch {
  placeholder: string;
  onSearch: Function;
  value: string;
}

export const InputWithSearch: React.FC<IInputWithSearch> = ({
  placeholder,
  onSearch,
  value,
}: IInputWithSearch) => {
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    if (value) {
      searchInput.current?.focus();
    }
  }, [value]);

  const getFocusedClassWhenInputHasValue = () => {
    return value
      ? 'InputWithSearch ant-input-affix-wrapper-focused'
      : 'InputWithSearch';
  };

  return (
    <div key={value}>
      <Input.Search
        className={getFocusedClassWhenInputHasValue()}
        type="text"
        placeholder={placeholder}
        onChange={(event) => onSearch(event.target.value)}
        defaultValue={value}
        ref={searchInput}
      />
    </div>
  );
};
