export const openDevPortalRedirectUrl = (
  transientToken: string,
  redirectUrl: string,
) => {
  const repositoryPath =
    process.env.REACT_APP_PATH_TO_REPOSITORY || 'http://localhost:3001';

  if (
    repositoryPath?.includes('venturus') ||
    repositoryPath?.includes('ingenico')
  ) {
    return window.open(
      `${process.env.REACT_APP_PATH_TO_REPOSITORY}/repository?trnsnt=${transientToken}&redirectUrl=${redirectUrl}`,
      '_blank',
    );
  }

  window.open(
    `${repositoryPath}?trnsnt=${transientToken}&redirectUrl=${redirectUrl}`,
    '_blank',
  );
};
