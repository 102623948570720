import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';

interface ILangugeDropdown {
  languageDropdown: string | undefined;
  onChangeLanguageDropdown: (languageKey: string) => void;
  onSelectLanguageDropdown: () => void;
  getLanguageDropdownOptions: () => JSX.Element | JSX.Element[];
}

export const LanguageDropdown: React.FC<ILangugeDropdown> = ({
  languageDropdown,
  onChangeLanguageDropdown,
  onSelectLanguageDropdown,
  getLanguageDropdownOptions,
}: ILangugeDropdown) => {
  const { t } = useTranslation();

  return (
    <Select
      style={{ width: '10em' }}
      placeholder={t('create-documentation-language-dropdown-placeholder')}
      className="select-language-dropdown"
      defaultValue={languageDropdown}
      onChange={onChangeLanguageDropdown}
      key={`
    ${languageDropdown}
    ${uniqid()}`}
      onSelect={onSelectLanguageDropdown}
    >
      {getLanguageDropdownOptions()}
    </Select>
  );
};
