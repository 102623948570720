import { Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { asyncPageActions } from '../../pages/PagesList/CreateReadEditPages/CreateReadEditPage.Slice';
import { AppDispatch, RootState } from '../../store/store';
import './PageSearch.scss';

// This conversion is necessary because the pages backend expects the full name of the language in uppercase, while the navbar stores the current language as an abbreviation.
const languageMap: { [key: string]: string } = {
  en: 'ENGLISH',
  es: 'SPANISH',
  pt: 'PORTUGUESE',
};

export const PageSearch: React.FC = () => {
  const navbarState = useSelector((state: RootState) => state.navBar);
  const { Search } = Input;
  const { t } = useTranslation();
  const defaultLanguage = languageMap[navbarState.defaultLanguage];

  const dispatch = useDispatch<AppDispatch>();

  const setTitleToSearchPage = (value: string) => {
    dispatch(
      asyncPageActions.FIND_PAGE_BY_TITLE({
        language: defaultLanguage,
        title: value,
      }),
    );
  };

  return (
    <Search
      type="text"
      name="search-button"
      className="search-button"
      placeholder={t(`documentation-page-listing-search`)}
      onSearch={(x) => setTitleToSearchPage(x)}
    />
  );
};
