import { Button, Col, Form, Input, Row, Select, Space, Spin } from 'antd';
import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import uniqid from 'uniqid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as RedBar } from '../../../assets/red_bar_icon.svg';
import { ReactComponent as VerticalRedBar } from '../../../assets/vertical_red_bar.svg';
import { ReactComponent as ReturnSymbol } from '../../../assets/return_symbol.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit_symbol_white.svg';
import { AppDispatch, RootState, store } from '../../../store/store';

import {
  asyncPageActions,
  CreateReadEditPageSlice,
} from './CreateReadEditPage.Slice';

import { messageNotification } from '../../../helpers/messageNotification/messageNotification';
import { showModal } from '../../../helpers/showModal/showModal';

import './CreateReadEditPage.scss';
import {
  IPageBackendDTO,
  IPageResponseContents,
} from '../../../services/createReadEditPage.api';
import { PageForm } from './PageForm/PageForm';
import { PageReactQuillWrapper } from './PageReactQuillWrapper/PageReactQuillWrapper';
import { useScrollToTop } from '../../../helpers/useScrollToTop/useScrollToTop';
import { LanguageDropdown } from '../../../components/LanguageDropdown/LanguageDropdown';

// This conversion is necessary because the backend expects the full name of the language in uppercase, while the navbar stores the current language as an abbreviation.
const languageMap: { [key: string]: string } = {
  en: 'ENGLISH',
  es: 'SPANISH',
  pt: 'PORTUGUESE',
};

export const CreateReadEditPagesCreatePages: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { Option } = Select;
  const { t } = useTranslation();

  const { slug } = useParams();

  const location = useLocation();
  const pageMode = location.state?.mode ? location.state.mode : 'read';
  const pageId = location.state?.id;

  const pageModeState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageMode,
  );
  const pageSlug = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageSlug,
  );
  const pagesState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pages,
  );
  const pageResponseState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageObjectResponse,
  );
  const pageResponseLoadingState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageResponseLoading,
  );
  const pageRejectedState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageRejected,
  );

  const loginState = useSelector((state: RootState) => state.login);
  const navbarState = useSelector((state: RootState) => state.navBar);

  const slugInputRef = useRef(null);
  const formRef = useRef(null);

  useScrollToTop(
    pageModeState === 'edit'
      ? 'return-button-row'
      : 'create-read-edit-pages-root',
  );

  useEffect(() => {
    return () => {
      dispatch(
        CreateReadEditPageSlice.actions.BACK_TO_ORIGINAL_STATE_PERSISTING_ID_FROM_UPDATED_PAGE(),
      );
    };
  }, [dispatch]);

  // Hook to check if the page mode is valid
  useEffect(() => {
    if (pageMode !== 'create' && pageMode !== 'edit' && pageMode !== 'read') {
      navigate('created-pages');
    }

    dispatch(CreateReadEditPageSlice.actions.PAGE_SET_PAGE_MODE(pageMode));
  }, [dispatch, history, pageMode]);

  useEffect(() => {
    if (!pageModeState) {
      return;
    }

    if (pageModeState !== 'create') {
      if (!slug) {
        return;
      }

      dispatch(CreateReadEditPageSlice.actions.PAGE_SET_SLUG(slug));

      if (pageModeState === 'edit') {
        if (!pageId) {
          return;
        }

        dispatch(asyncPageActions.GET_PAGE_TO_UPDATE(pageId));
        return;
      }

      dispatch(
        asyncPageActions.GET_PAGE_BY_SLUG({
          slug,
        }),
      );
    }
  }, [pageModeState, dispatch, slug, history]);

  useEffect(() => {
    if (!pageRejectedState) {
      return;
    }

    navigate('/created-pages');
  }, [pageRejectedState, history]);

  // Hook to define the page content based on the language selected in the navbar
  useEffect(() => {
    if (!navbarState.loadedDefaultLanguage) {
      return;
    }

    const defaultLanguage = languageMap[navbarState.defaultLanguage];

    if (pageModeState === 'create' && !pagesState.languageDropdown) {
      dispatch(
        CreateReadEditPageSlice.actions.SET_DROPDOWN_LANGUAGE(defaultLanguage),
      );
      return;
    }

    if (!pageResponseState) {
      return;
    }

    if (pageMode !== 'read' && pagesState.languageDropdown) {
      return;
    }

    // Define the default page content according to the language selected in the navbar, if it exists
    const existingContentInPreferenceLanguage =
      pageResponseState.pageContents.find(
        (x) => x.language === defaultLanguage,
      );

    if (existingContentInPreferenceLanguage) {
      dispatch(
        CreateReadEditPageSlice.actions.SET_DROPDOWN_LANGUAGE(defaultLanguage),
      );
      return;
    }

    // If the page content does not exist in the language selected in the navbar, select the content in English
    const existingContentInEnglish = pageResponseState.pageContents.find(
      (x) => x.language === 'ENGLISH',
    );

    if (existingContentInEnglish) {
      dispatch(
        CreateReadEditPageSlice.actions.SET_DROPDOWN_LANGUAGE('ENGLISH'),
      );
      return;
    }

    // If the content in English does not exist, select the content at index 0 as a fallback
    const fallBackExistingContent = pageResponseState.pageContents[0].language;

    dispatch(
      CreateReadEditPageSlice.actions.SET_DROPDOWN_LANGUAGE(
        fallBackExistingContent,
      ),
    );
  }, [
    navbarState.defaultLanguage,
    navbarState.loadedDefaultLanguage,
    pagesState.languageDropdown,
    pageResponseState,
    pageModeState,
    dispatch,
  ]);

  useEffect(() => {
    if (!pagesState.finishedCreatingPage) {
      return;
    }

    if (pageMode === 'edit') {
      messageNotification.successMessage(
        t('page-edit-success-notification-title'),
        t('page-edit-success-notification-content'),
      );
    } else if (pageMode === 'create') {
      messageNotification.successMessage(
        t('page-create-success-notification-title'),
        t('page-create-success-notification-content'),
      );
    }

    dispatch(CreateReadEditPageSlice.actions.FINISH_CREATING_PAGE());

    navigate(`pages/${pageSlug}`);
  });

  const pageHasContent = useCallback(() => {
    return (
      pagesState.pageInPortuguese.title ||
      pagesState.pageInPortuguese.description ||
      pagesState.pageInPortuguese.contents ||
      pagesState.pageInEnglish.title ||
      pagesState.pageInEnglish.description ||
      pagesState.pageInEnglish.contents ||
      pagesState.pageInSpanish.title ||
      pagesState.pageInSpanish.description ||
      pagesState.pageInSpanish.contents
    );
  }, [
    pageSlug,
    pagesState.pageInPortuguese,
    pagesState.pageInEnglish,
    pagesState.pageInSpanish,
  ]);

  const askToUserIfThePageHasBeenUpdatedInEveryLanguage = useCallback(() => {
    const changesInLanguages: Array<'no-changes-were-made' | 'different'> = [];

    if (pagesState.pageInPortuguese.title) {
      const pageInPortugueseFromResponse = pageResponseState?.pageContents.find(
        (x) => x.language === 'PORTUGUESE',
      );

      if (pageInPortugueseFromResponse) {
        if (
          pageInPortugueseFromResponse?.title ===
            pagesState.pageInPortuguese.title &&
          pageInPortugueseFromResponse.subtitle ===
            pagesState.pageInPortuguese.description &&
          pageInPortugueseFromResponse.mainContent ===
            pagesState.pageInPortuguese.contents
        ) {
          changesInLanguages.push('no-changes-were-made');
        } else {
          changesInLanguages.push('different');
        }
      }
    }

    if (pagesState.pageInEnglish.title) {
      const pageInEnglishFromResponse = pageResponseState?.pageContents.find(
        (x) => x.language === 'ENGLISH',
      );

      if (pageInEnglishFromResponse) {
        if (
          pageInEnglishFromResponse?.title === pagesState.pageInEnglish.title &&
          pageInEnglishFromResponse?.subtitle ===
            pagesState.pageInEnglish.description &&
          pageInEnglishFromResponse?.mainContent ===
            pagesState.pageInEnglish.contents
        ) {
          changesInLanguages.push('no-changes-were-made');
        } else {
          changesInLanguages.push('different');
        }
      }
    }

    if (pagesState.pageInSpanish.title) {
      const pageInSpanishFromResponse = pageResponseState?.pageContents.find(
        (x) => x.language === 'SPANISH',
      );

      if (pageInSpanishFromResponse) {
        if (
          pageInSpanishFromResponse?.title === pagesState.pageInSpanish.title &&
          pageInSpanishFromResponse?.subtitle ===
            pagesState.pageInSpanish.description &&
          pageInSpanishFromResponse?.mainContent ===
            pagesState.pageInSpanish.contents
        ) {
          changesInLanguages.push('no-changes-were-made');
        } else {
          changesInLanguages.push('different');
        }
      }
    }

    const everyPageWasChanged = changesInLanguages.every(
      (x) => x === 'different',
    );

    const everyPageWasNotChanged = changesInLanguages.every(
      (x) => x === 'no-changes-were-made',
    );

    return everyPageWasChanged || everyPageWasNotChanged;
  }, [pageResponseState, pagesState]);

  useEffect(() => {
    if (!pagesState.startPageValidation) {
      return;
    }

    if (!pageHasContent()) {
      dispatch(CreateReadEditPageSlice.actions.STOP_PAGE_VALIDATING());
      return messageNotification.warningMessage(
        t('page-create-error-notification-title'),
        t('page-create-error-notification-content'),
      );
    }

    if (!pageSlug) {
      dispatch(CreateReadEditPageSlice.actions.STOP_PAGE_VALIDATING());
      return messageNotification.warningMessage(
        t('page-create-error-invalid-slug-notification-title'),
        t('page-create-error-invalid-slug-notification-content'),
      );
    } else if (!pagesState.isValidToCreateOrUpdatePageInEnglish) {
      dispatch(CreateReadEditPageSlice.actions.STOP_PAGE_VALIDATING());
      return messageNotification.warningMessage(
        t('page-create-error-invalid-english-content-notification-title'),
        t('page-create-error-invalid-page-content-notification-content'),
      );
    } else if (!pagesState.isValidToCreateOrUpdatePageInPortuguese) {
      dispatch(CreateReadEditPageSlice.actions.STOP_PAGE_VALIDATING());
      return messageNotification.warningMessage(
        t('page-create-error-invalid-portuguese-content-notification-title'),
        t('page-create-error-invalid-page-content-notification-content'),
      );
    } else if (!pagesState.isValidToCreateOrUpdatePageInSpanish) {
      dispatch(CreateReadEditPageSlice.actions.STOP_PAGE_VALIDATING());
      return messageNotification.warningMessage(
        t('page-create-error-invalid-spanish-content-notification-title'),
        t('page-create-error-invalid-page-content-notification-content'),
      );
    }

    const pageContents: Array<IPageResponseContents> = [];

    if (pagesState.pageInPortuguese.title) {
      pageContents.push({
        title: pagesState.pageInPortuguese.title,
        subtitle: pagesState.pageInPortuguese.description,
        mainContent: pagesState.pageInPortuguese.contents,
        language: pagesState.pageInPortuguese.language,
      } as IPageResponseContents);
    }

    if (pagesState.pageInEnglish.title) {
      pageContents.push({
        title: pagesState.pageInEnglish.title,
        subtitle: pagesState.pageInEnglish.description,
        mainContent: pagesState.pageInEnglish.contents,
        language: pagesState.pageInEnglish.language,
      } as IPageResponseContents);
    }

    if (pagesState.pageInSpanish.title) {
      pageContents.push({
        title: pagesState.pageInSpanish.title,
        subtitle: pagesState.pageInSpanish.description,
        mainContent: pagesState.pageInSpanish.contents,
        language: pagesState.pageInSpanish.language,
      } as IPageResponseContents);
    }

    const newPageOrNewUpdatedPage = {
      slug: pageSlug,
      active: true,
      pageContents: pageContents,
    } as IPageBackendDTO;

    if (pageModeState === 'edit') {
      const pageWasCorrectlyUpdated =
        askToUserIfThePageHasBeenUpdatedInEveryLanguage();

      if (pageWasCorrectlyUpdated) {
        dispatch(
          asyncPageActions.UPDATE_A_PAGE({
            id: pageResponseState?.id,
            payload: newPageOrNewUpdatedPage,
          }),
        );
        return;
      }

      showModal(
        'warning',
        t('page-edit-not-made-in-all-languages-title'),
        t('page-edit-not-made-in-all-languages-content'),
        () => {
          dispatch(
            asyncPageActions.UPDATE_A_PAGE({
              id: pageResponseState?.id,
              payload: newPageOrNewUpdatedPage,
            }),
          );
        },
        () => {
          dispatch(CreateReadEditPageSlice.actions.STOP_PAGE_VALIDATING());
        },
        `${t('page-edit-okText')}`,
        `${t('page-edit-cancelText')}`,
      );
      return;
    }

    dispatch(asyncPageActions.CREATE_A_NEW_PAGE(newPageOrNewUpdatedPage));
  }, [
    pageSlug,
    pagesState,
    pageModeState,
    pageResponseState,
    t,
    dispatch,
    pageHasContent,
    askToUserIfThePageHasBeenUpdatedInEveryLanguage,
  ]);

  useEffect(() => {
    if (!pageResponseState) {
      return;
    }

    if (!pagesState.languageDropdown) {
      return;
    }
  }, [pagesState.languageDropdown, dispatch, pageResponseState]);

  const setHeaderTitle = () => {
    if (pageModeState === 'read') {
      return <Row className="empty-header-row-style" />;
    }

    if (pageModeState === 'edit') {
      return (
        <Row className="header-row-style">
          <Col span={24}>
            <RedBar />
            <h1>
              <strong> {t('page-edit-button-label')}</strong>
            </h1>
          </Col>
        </Row>
      );
    }

    if (pageModeState === 'create') {
      return (
        <Row className="header-row-style">
          <Col span={24}>
            <RedBar />
            <h1>
              <strong>{t('create-page-header-documentation-title')}</strong>
            </h1>
          </Col>
        </Row>
      );
    }
  };

  const showPublishCancelButtons = () => {
    if (pageModeState !== 'read') {
      return (
        <Row className="publish-edit-cancel-buttons">
          <Col span={24}>
            <Row className="publish-buttons-row-style">
              <Col span={24}>
                <Form.Item>
                  <Button
                    id="publish-button"
                    type="primary"
                    shape="round"
                    className="button-style"
                    htmlType="submit"
                  >
                    {t('page-send-button')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row className="button-row-style">
              <Col span={24}>
                <Form.Item>
                  <Button
                    id="cancel-button"
                    type="link"
                    shape="round"
                    className="button-style"
                    onClick={() => {
                      returnOrShowModalOfDiscardChanges();
                    }}
                  >
                    {pageModeState === 'create'
                      ? t('create-page-cancel-creation-button')
                      : t('create-page-cancel-edit-button')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
  };

  const showEditButton = () => {
    if (
      loginState.loggedIn &&
      loginState.user?.role === 'A' &&
      pageModeState === 'read'
    ) {
      return (
        <Row className="publish-edit-cancel-buttons">
          <Col span={24}>
            <Row className="publish-edit-buttons-row-style">
              <Col span={24}>
                <Form.Item>
                  <Button
                    id="edit-button"
                    type="primary"
                    shape="round"
                    className="button-style"
                    onClick={() => {
                      navigate(`/pages/${pageResponseState?.slug}`, {
                        state: { mode: 'edit', id: pageResponseState?.id },
                      });
                    }}
                  >
                    <EditIcon className="edit-icon" />
                    {t('page-edit-button-label')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
  };

  const submitNewPage = () => {
    const formInstance = formRef.current as unknown as FormInstance;

    if (formInstance) {
      const values = formInstance.getFieldsValue();

      // Remove spaces and accents from slug
      const formattedSlug = (slugInputRef.current as any).input.value
        .trim()
        .replaceAll(' ', '-')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      dispatch(
        CreateReadEditPageSlice.actions.UPDATE_PAGE_STATE_AND_START_VALIDATION({
          pageTitle: values.title.trim(),
          pageDescription: values.description.trim(),
          pageSlug: formattedSlug,
        }),
      );
    }
  };

  const returnOrShowModalOfDiscardChanges = () => {
    const pageContents = pagesState;

    const formInstance = formRef.current as unknown as FormInstance;

    if (formInstance) {
      const formValues = formInstance.getFieldsValue();
      const editorContent =
        store.getState().createReadEditPageSlice.editorOutputOnEditing;

      if (pageModeState === 'read') {
        navigate(store.getState().pathTracker.pagesPreviousPath);
      }

      if (pageModeState === 'create') {
        if (
          formValues.title.trim() ||
          formValues.description.trim() ||
          editorContent ||
          pageContents.pageInPortuguese.title ||
          pageContents.pageInPortuguese.description ||
          pageContents.pageInPortuguese.contents ||
          pageContents.pageInEnglish.title ||
          pageContents.pageInEnglish.description ||
          pageContents.pageInEnglish.contents ||
          pageContents.pageInSpanish.title ||
          pageContents.pageInSpanish.description ||
          pageContents.pageInSpanish.contents
        ) {
          return showModal(
            'warning',
            t('page-confirmation-cancel-editing-title'),
            t('page-confirmation-cancel-editing-content'),
            () => navigate(store.getState().pathTracker.pagesPreviousPath),
          );
        }

        return navigate(store.getState().pathTracker.pagesPreviousPath);
      }

      if (pageModeState === 'edit') {
        const pagesInEveryLanguage = {
          pageInPt: {
            ...pagesState.pageInPortuguese,
          },
          pageInEn: {
            ...pagesState.pageInEnglish,
          },
          pageInSp: {
            ...pagesState.pageInSpanish,
          },
        };

        switch (pagesState.languageDropdown) {
          case 'PORTUGUESE':
            pagesInEveryLanguage.pageInPt.title = formValues.title.trim();
            pagesInEveryLanguage.pageInPt.description =
              formValues.description.trim();
            if (editorContent) {
              pagesInEveryLanguage.pageInPt.contents = editorContent;
            }

            break;

          case 'ENGLISH':
            pagesInEveryLanguage.pageInEn.title = formValues.title.trim();
            pagesInEveryLanguage.pageInEn.description =
              formValues.description.trim();
            if (editorContent) {
              pagesInEveryLanguage.pageInEn.contents = editorContent;
            }

            break;

          case 'SPANISH':
            pagesInEveryLanguage.pageInSp.title = formValues.title.trim();
            pagesInEveryLanguage.pageInSp.description =
              formValues.description.trim();
            if (editorContent) {
              pagesInEveryLanguage.pageInSp.contents = editorContent;
            }
            break;

          default:
            break;
        }

        let showModalValidation = false;

        const pageResponseInPt = pageResponseState?.pageContents.find(
          (x) => x.language === 'PORTUGUESE',
        );
        if (pageResponseInPt) {
          if (
            pageResponseInPt.title !== pagesInEveryLanguage.pageInPt.title ||
            pageResponseInPt.subtitle !==
              pagesInEveryLanguage.pageInPt.description ||
            pageResponseInPt.mainContent !==
              pagesInEveryLanguage.pageInPt.contents
          ) {
            showModalValidation = true;
          }
        } else if (
          pagesInEveryLanguage.pageInPt.title ||
          pagesInEveryLanguage.pageInPt.description ||
          pagesInEveryLanguage.pageInPt.contents
        ) {
          showModalValidation = true;
        }

        const pageResponseInEn = pageResponseState?.pageContents.find(
          (x) => x.language === 'ENGLISH',
        );
        if (pageResponseInEn) {
          if (
            pageResponseInEn.title !== pagesInEveryLanguage.pageInEn.title ||
            pageResponseInEn.subtitle !==
              pagesInEveryLanguage.pageInEn.description ||
            pageResponseInEn.mainContent !==
              pagesInEveryLanguage.pageInEn.contents
          ) {
            showModalValidation = true;
          }
        } else if (
          pagesInEveryLanguage.pageInEn.title ||
          pagesInEveryLanguage.pageInEn.description ||
          pagesInEveryLanguage.pageInEn.contents
        ) {
          showModalValidation = true;
        }

        const pageResponseInEs = pageResponseState?.pageContents.find(
          (x) => x.language === 'SPANISH',
        );
        if (pageResponseInEs) {
          if (
            pageResponseInEs.title !== pagesInEveryLanguage.pageInSp.title ||
            pageResponseInEs.subtitle !==
              pagesInEveryLanguage.pageInSp.description ||
            pageResponseInEs.mainContent !==
              pagesInEveryLanguage.pageInSp.contents
          ) {
            showModalValidation = true;
          }
        } else if (
          pagesInEveryLanguage.pageInSp.title ||
          pagesInEveryLanguage.pageInSp.description ||
          pagesInEveryLanguage.pageInSp.contents
        ) {
          showModalValidation = true;
        }

        if (showModalValidation) {
          return showModal(
            'warning',
            t('page-confirmation-cancel-editing-title'),
            t('page-confirmation-cancel-editing-content'),
            () => {
              dispatch(
                CreateReadEditPageSlice.actions.BACK_TO_ORIGINAL_STATE_PERSISTING_ID_FROM_UPDATED_PAGE(),
              );
              navigate(store.getState().pathTracker.pagesPreviousPath);
            },
          );
        }

        dispatch(
          CreateReadEditPageSlice.actions.BACK_TO_ORIGINAL_STATE_PERSISTING_ID_FROM_UPDATED_PAGE(),
        );
        navigate(store.getState().pathTracker.pagesPreviousPath);
      }
    }
  };

  const onSelectLanguageDropdown = () => {
    const formInstance = formRef.current as unknown as FormInstance;

    if (formInstance) {
      const languages = formInstance.getFieldsValue();

      switch (languages.selectLanguage) {
        case 'PORTUGUESE':
          formInstance.setFieldsValue({
            title: pagesState.pageInPortuguese.title,
            description: pagesState.pageInPortuguese.description,
          });
          break;
        case 'ENGLISH':
          formInstance.setFieldsValue({
            title: pagesState.pageInEnglish.title,
            description: pagesState.pageInEnglish.description,
          });
          break;
        case 'SPANISH':
          formInstance.setFieldsValue({
            title: pagesState.pageInSpanish.title,
            description: pagesState.pageInSpanish.description,
          });
          break;
        default:
          break;
      }
    }
  };

  const onChangeLanguageDropdown = (languageKey: string) => {
    const formInstance = formRef.current as unknown as FormInstance;

    if (formInstance) {
      const values = formInstance.getFieldsValue();
      const prevLanguage = pagesState.languageDropdown;
      const existingValuesInForm = {
        language: prevLanguage,
        pageSlug: (slugInputRef.current as any).input.value,
        pageTitle: values.title,
        pageDescription: values.description,
        newLanguage: languageKey,
      };

      // Saving previous selected Language values on State
      dispatch(
        CreateReadEditPageSlice.actions.SAVE_PAGE_CONTENT(existingValuesInForm),
      );

      if (prevLanguage !== languageKey) {
        switch (languageKey) {
          case 'PORTUGUESE':
            formInstance.setFieldsValue({
              title: pagesState.pageInPortuguese.title,
              description: pagesState.pageInPortuguese.description,
            });
            break;
          case 'ENGLISH':
            formInstance.setFieldsValue({
              title: pagesState.pageInEnglish.title,
              description: pagesState.pageInEnglish.description,
            });
            break;
          case 'SPANISH':
            formInstance.setFieldsValue({
              title: pagesState.pageInSpanish.title,
              description: pagesState.pageInSpanish.description,
            });
            break;
          default:
            break;
        }
      }
    }
  };

  const showSlug = () => {
    const readonly = pageModeState === 'read';

    return (
      <Row className="language-row-style">
        <Col span={24}>
          <Row align="bottom">
            <Space>
              <b>{t('page-slug-input-label')}</b>
              <small>{t('page-slug-input-label-description')}</small>
            </Space>
          </Row>
          <Row align="bottom" className="dropdown-language">
            <Col className="vertical-red-bar">
              <VerticalRedBar />
            </Col>
            <Col span={21} offset={1}>
              <Input
                id="page-slug-input"
                ref={slugInputRef}
                maxLength={50}
                defaultValue={pageSlug}
                className={
                  readonly ? 'description-input-read-only' : 'description-input'
                }
                readOnly={readonly}
                bordered={!readonly}
                placeholder={t('page-slug-input-placeholder')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const getLanguageDropdownOptions = () => {
    if (pageModeState !== 'read') {
      return (
        <Fragment>
          <Option value="PORTUGUESE">
            {t('create-page-language-dropdown-pt')}
          </Option>
          <Option value="ENGLISH">
            {t('create-page-language-dropdown-en')}
          </Option>
          <Option value="SPANISH">
            {t('create-page-language-dropdown-es')}
          </Option>
        </Fragment>
      );
    }

    const availableLanguagesToReadGivenDoc: Array<JSX.Element> = [];

    if (pagesState.pageInPortuguese.title) {
      availableLanguagesToReadGivenDoc.push(
        <Option key="language-dropdown-option-pt" value="PORTUGUESE">
          {t('create-page-language-dropdown-pt')}
        </Option>,
      );
    }

    if (pagesState.pageInEnglish.title) {
      availableLanguagesToReadGivenDoc.push(
        <Option key="language-dropdown-option-en" value="ENGLISH">
          {t('create-page-language-dropdown-en')}
        </Option>,
      );
    }

    if (pagesState.pageInSpanish.title) {
      availableLanguagesToReadGivenDoc.push(
        <Option key="language-dropdown-option-es" value="SPANISH">
          {t('create-page-language-dropdown-es')}
        </Option>,
      );
    }

    return availableLanguagesToReadGivenDoc;
  };

  return (
    <Col
      pull={1}
      span={22}
      push={1}
      className="CreatePages"
      id="create-read-edit-pages-root"
    >
      <Form
        layout="horizontal"
        wrapperCol={{ span: 24 }}
        size="large"
        onFinish={submitNewPage}
        ref={formRef}
        key={`
          ${uniqid()}
          ${pagesState.pageTitleInitialValue}${
            pagesState.pageDescriptionInitialValue
          }`}
        initialValues={{
          title: pagesState.pageTitleInitialValue,
          description: pagesState.pageDescriptionInitialValue,
        }}
      >
        <Row id="return-button-row">
          <Col span={24}>
            <Form.Item>
              <Button
                type="link"
                className="return-button"
                onClick={() => returnOrShowModalOfDiscardChanges()}
              >
                <ReturnSymbol className="return-symbol" />
                {t('page-back-button-label')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row className="editor-content-row-style">
          <Col span={24}>
            {setHeaderTitle()}
            <Row justify="center">
              {loginState.loggedIn && loginState.user?.role === 'A' && (
                <Col span={5}>
                  {showSlug()}
                  {pageModeState !== 'read' && (
                    <Row className="language-row-style">
                      <Col span={24}>
                        <Row>
                          <strong>
                            {t('create-page-language-dropdown-title')}
                          </strong>
                        </Row>
                        <Row align="bottom" className="dropdown-language">
                          <Col className="vertical-red-bar">
                            <VerticalRedBar />
                          </Col>
                          <Col span={21} offset={1}>
                            <Form.Item name="selectLanguage">
                              <LanguageDropdown
                                languageDropdown={pagesState.languageDropdown}
                                onChangeLanguageDropdown={
                                  onChangeLanguageDropdown
                                }
                                onSelectLanguageDropdown={
                                  onSelectLanguageDropdown
                                }
                                getLanguageDropdownOptions={
                                  getLanguageDropdownOptions
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  {showEditButton()}
                  {showPublishCancelButtons()}
                </Col>
              )}
              <Col span={19}>
                <Row className="input-form-editor">
                  <Col span={22} offset={2}>
                    <Spin
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      spinning={pageResponseLoadingState}
                      size="large"
                    >
                      <PageForm
                        pageMode={pageModeState}
                        loading={pageResponseLoadingState}
                      />
                      <PageReactQuillWrapper
                        pageMode={pageModeState}
                        editorInitialValue={pagesState.pageEditorInitialValue}
                        loading={pageResponseLoadingState}
                      />
                    </Spin>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Col>
  );
};
