import {
  nonSecuredBaseApi,
  securedBaseApi,
  softSecuredBaseApi,
} from './api.config';
import { ILoginRequest } from '../interfaces/services-interfaces/ILoginRequest';
import { ILoginResponse } from '../interfaces/services-interfaces/ILoginResponse';
import { IMeResponse } from '../interfaces/services-interfaces/IMeResponse';

export const loginFormApi = {
  login: (loginBody: ILoginRequest) => {
    return nonSecuredBaseApi.post<ILoginResponse>('/login', loginBody);
  },
  softLogin: () => {
    return softSecuredBaseApi.get<IMeResponse>('/me');
  },
  me: () => {
    return securedBaseApi.get<IMeResponse>('/me');
  },
  languagePost: (lng: string) => {
    return securedBaseApi.patch<string>(
      '/me/preference_language',
      `{"preferenceLanguage":"${lng}"}`,
    );
  },
};
