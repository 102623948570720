import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Background01 from '../../../../../assets/news-background-01.svg';
import Background02 from '../../../../../assets/news-background-02.svg';
import Background03 from '../../../../../assets/news-background-03.svg';
import Background04 from '../../../../../assets/news-background-04.svg';
import { POP_UP_LANGUAGE_MAP_INVERTED } from '../../../../../services/administrative-panel-pop-up';
import { RootState } from '../../../../../store/store';
import { uniqueId } from 'lodash';

const banner01Key = 'bannerBackground01';
const banner02Key = 'bannerBackground02';
const banner03Key = 'bannerBackground03';
const banner04Key = 'bannerBackground04';

export const ActiveNews = () => {
  const carouselState = useSelector((state: RootState) => state.carousel);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const applicationLanguage = useSelector(
    (state: RootState) => state.navBar.defaultLanguage,
  );

  const languageValue = Array.from(POP_UP_LANGUAGE_MAP_INVERTED).find(
    ([value]) => applicationLanguage === value,
  );

  const getNewsById = (id: number) => {
    navigate(`/administrative-panel/add_or_edit?id=${id}&tab=news`);
  };

  const whichBackgroundSelected = (key: string) => {
    switch (key) {
      case banner01Key:
        return Background01;
      case banner02Key:
        return Background02;
      case banner03Key:
        return Background03;
      case banner04Key:
        return Background04;
      default:
        return Background01;
    }
  };

  const onGenerateActivatedNewsCard = () => {
    const cardActivated = carouselState.data.map((x) => {
      return (
        <Col
          key={uniqueId()}
          style={{ height: '100%', padding: ' 3px' }}
          span={6}
          onClick={() => getNewsById(Number(x.id))}
        >
          <div className="news_listing-container">
            <img
              src={whichBackgroundSelected(x.image)}
              alt="Menu Background"
              className="news_listing-card-img"
            />
            <div className="news_listing-data-container">
              <Row>
                <span className="news_listing-title">
                  {
                    x.contents.find(
                      (e: any) =>
                        e.language ===
                        (languageValue ? languageValue[1] : 'PORTUGUESE'),
                    ).title
                  }
                </span>
              </Row>
              <Row>
                <span className="news_listing-subtitle">
                  {
                    x.contents.find(
                      (e: any) =>
                        e.language ===
                        (languageValue ? languageValue[1] : 'PORTUGUESE'),
                    ).subtitle
                  }
                </span>
              </Row>
            </div>
          </div>
        </Col>
      );
    });

    cardActivated.push(
      <Col style={{ height: '100%', padding: ' 3px' }} span={6}>
        <div className="news_listing-blank">
          <div className="news_listing-data-container">
            <span className="news_listing-blank-title">
              {t('news-listing-activated-news-blank')}
            </span>
          </div>
        </div>
      </Col>,
    );

    return cardActivated;
  };

  return <Row>{onGenerateActivatedNewsCard()}</Row>;
};
