import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { messageNotification } from '../../../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../../../helpers/unhookedTranslation/unhookedTranslation';
import { administrativePanelTermsApi } from '../../../../services/administrative-panel-terms';

interface ITermListingSlice {
  filter: ITermListingSliceFilter;
  listing: ITermListingSliceListing;
  previewTerm: ITermListingSlicePreview;
}

interface ITermListingSliceFilter {
  openedFilter: boolean;
  requestType: string | undefined;
  name: string | undefined;
  email: string | undefined;
  version: string | undefined;
  active: string | undefined;
  date: any;
}

interface ITermListingSliceListing {
  dataSource: any[];
  refresh: boolean;
  tableLoading: boolean;
  pageSize: number;
  pageIndex: number;
  totalNumberOfElements: number;
  filterQueryString: string;
  orderQueryString: string;
  idsInDataBase: number[] | undefined;
  firstListingHasAlreadyOccured: boolean;
  defaultSortBehaviourActive: boolean;
}

interface ITermListingSlicePreview {
  previewTermLanguage: string;
  version: string;
  ptContent: string;
  enContent: string;
  esContent: string;
}

const initialState: ITermListingSlice = {
  filter: {
    openedFilter: false,
    requestType: undefined,
    name: undefined,
    email: undefined,
    date: undefined,
    version: undefined,
    active: undefined,
  },
  listing: {
    dataSource: [],
    refresh: true,
    tableLoading: false,
    pageSize: 15,
    pageIndex: 1,
    totalNumberOfElements: 0,
    filterQueryString: '',
    orderQueryString: '',
    idsInDataBase: undefined,
    firstListingHasAlreadyOccured: false,
    defaultSortBehaviourActive: true,
  },
  previewTerm: {
    previewTermLanguage: '',
    version: '',
    ptContent: '',
    enContent: '',
    esContent: '',
  },
};

export const TermsListingAsyncActions = {
  GET_TERM_LIST: createAsyncThunk(
    'GET_TERM_LIST',
    async (queryParams: string) => {
      const termList =
        await administrativePanelTermsApi.getTermList(queryParams);
      return termList.data;
    },
  ),
  ACTIVATE_TERM: createAsyncThunk('ACTIVATE_TERM', async (termId: number) => {
    await administrativePanelTermsApi.activateTerm(termId);
  }),
  DELETE_TERM: createAsyncThunk('DELETE_TERM', async (termId: number) => {
    await administrativePanelTermsApi.deleteTerm(termId);
  }),
};

export const TermsListingSlice = createSlice({
  name: 'TermsListingSlice',
  initialState,
  reducers: {
    TOGGLE_FILTER: (state) => {
      if (state.filter.openedFilter) {
        state.filter.openedFilter = false;
      } else {
        state.filter.openedFilter = true;
      }
    },
    FILTER_SET_QUERY_STRING: (state, action) => {
      state.listing.pageIndex = initialState.listing.pageIndex;
      state.listing.filterQueryString = action.payload;
      state.listing.refresh = true;
    },
    ORDER_SET_QUERY_STRING: (state, action) => {
      state.listing.defaultSortBehaviourActive = false;
      state.listing.orderQueryString = action.payload;
      state.listing.refresh = true;
    },
    FILTER_CLEAR_FIELDS: (state) => {
      if (state.listing.filterQueryString) {
        state.listing.pageIndex = initialState.listing.pageIndex;
      }
      state.listing.filterQueryString = initialState.listing.filterQueryString;
      state.listing.refresh = true;
      state.filter = initialState.filter;
    },
    TERMS_FILTER_SET_VERSION: (state, action) => {
      state.filter.version = action.payload;
    },
    TERMS_FILTER_SET_ACTIVE: (state, action) => {
      state.filter.active = action.payload;
    },
    CHANGE_PAGE: (state, action) => {
      state.listing.pageIndex = action.payload;
      state.listing.refresh = true;
    },
    RESET_TO_ORIGINAL_STATE: () => {
      return initialState;
    },
    RESET_TO_ORIGINAL_STATE_PERSISTING_FILTER: (state) => {
      return {
        ...initialState,
        filter: state.filter,
        listing: state.listing,
      };
    },
    SET_PREVIEW_TERM_LANGUAGE: (state, action) => {
      state.previewTerm.previewTermLanguage = action.payload;
    },
    OPEN_PREVIEW_TERM: (state, action) => {
      state.previewTerm.version = action.payload.version;

      state.previewTerm.ptContent = action.payload.contents.find(
        (x: any) => x.language === 'PORTUGUESE',
      ).description;
      state.previewTerm.enContent = action.payload.contents.find(
        (x: any) => x.language === 'ENGLISH',
      ).description;
      state.previewTerm.esContent = action.payload.contents.find(
        (x: any) => x.language === 'SPANISH',
      ).description;
    },
    CLOSE_PREVIEW_TERM: (state) => {
      state.previewTerm = initialState.previewTerm;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(TermsListingAsyncActions.GET_TERM_LIST.pending, (state) => {
      state.listing.tableLoading = true;
    }),
    builder.addCase(
      TermsListingAsyncActions.GET_TERM_LIST.fulfilled,
      (state, action) => {
        state.listing.dataSource = action.payload.content;
        state.listing.totalNumberOfElements = action.payload.totalElements;

        state.listing.refresh = false;
        state.listing.tableLoading = false;
      },
    ),
    builder.addCase(
      TermsListingAsyncActions.ACTIVATE_TERM.fulfilled,
      (state) => {
        state.listing.refresh = true;
      },
    ),
    builder.addCase(TermsListingAsyncActions.DELETE_TERM.fulfilled, (state) => {
      state.listing.refresh = true;

      messageNotification.successMessage(
        unhookedTranslation('terms-successfuly-deleted-term-title'),
        unhookedTranslation('terms-successfuly-deleted-term-content'),
      );
    }),
  ],
});
