import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createPasswordApi,
  ICreatePasswordRequest,
} from '../../services/create-password.api';

export interface INewPasswordValidation {
  password: string;
  passwordConfirmation: string;

  cardValidationStyleLowerCase: string;
  cardValidationStyleUpperCase: string;
  cardValidationStyleNumber: string;
  cardValidationStyleLength: string;
  passwordValidation: boolean;

  concludedPage: boolean;

  updatePasswordResponse: ICreatePasswordBodyAndToken[];
  invalidToken: boolean;
}

interface ICreatePasswordBodyAndToken {
  token: string;
  body: ICreatePasswordRequest;
}

const initialState: INewPasswordValidation = {
  password: '',
  passwordConfirmation: '',

  cardValidationStyleLowerCase: 'invalid',
  cardValidationStyleUpperCase: 'invalid',
  cardValidationStyleNumber: 'invalid',
  cardValidationStyleLength: 'invalid',
  passwordValidation: false,

  concludedPage: false,

  updatePasswordResponse: [],
  invalidToken: false,
};

export const asyncActions = {
  CREATEPASSWORD: createAsyncThunk(
    'CREATEPASSWORD',
    async (payload: ICreatePasswordBodyAndToken) => {
      await createPasswordApi.createPassword(payload.token, payload.body);
    },
  ),
  VALIDATE_TOKEN: createAsyncThunk('VALIDATE_TOKEN', async (token: string) => {
    await createPasswordApi.validateToken(token);
  }),
};

export const CreatePasswordSlice = createSlice({
  name: 'createPasswordSlice',
  initialState,
  reducers: {
    SET_NEW_PASSWORD: (state, action) => {
      state.password = action.payload;
    },
    SET_PASSWORD_CONFIRMATION: (state, action) => {
      state.passwordConfirmation = action.payload;
    },
    SET_PASSWORD_VALIDATION: (state, action) => {
      state.passwordValidation = action.payload;
    },
    SET_LOWER_CASE_VALIDATION: (state, action) => {
      state.cardValidationStyleLowerCase = action.payload;
    },
    SET_UPPER_CASE_VALIDATION: (state, action) => {
      state.cardValidationStyleUpperCase = action.payload;
    },
    SET_NUMBER_VALIDATION: (state, action) => {
      state.cardValidationStyleNumber = action.payload;
    },
    SET_LENGTH_VALIDATION: (state, action) => {
      state.cardValidationStyleLength = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.CREATEPASSWORD.fulfilled, (state) => {
      state.concludedPage = true;
    });
    builder.addCase(asyncActions.VALIDATE_TOKEN.fulfilled, (state) => {
      state.invalidToken = false;
    });
    builder.addCase(asyncActions.VALIDATE_TOKEN.rejected, (state) => {
      state.invalidToken = true;
    });
  },
});
