/* eslint-disable import/no-unresolved */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  administrativePanelCompaniesApi,
  ICountriesList,
  ICompanyResponse,
} from '../../../services/administrative-panel-companies';
import { ITab } from '../AdministrativePanel.Slice';
import { ICompanyListItem } from '../../../interfaces/services-interfaces/ICompanyList';
import { messageNotification } from '../../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../../helpers/unhookedTranslation/unhookedTranslation';
import { administrativePanelPendingRequestsApi } from '../../../services/administrative-panel-pending-requests.api';
// eslint-disable-next-line import/no-cycle
import { store } from '../../../store/store';
import axios from 'axios';

export interface ICompanyAdministrativePanelInitialState {
  showFilter: string;
  companies: ICompaniesTab;
}

export interface ICompaniesTab extends ITab<ICompanyListItem> {
  dataForUsers: ICompanyListItem[];
  showCompaniesListing: boolean;
  showEditMode: boolean;
  startedEditCompany: boolean;
  companyRegister: INewCompany;
  filter: ICompaniesFilter;
  companyQueryString: string[];
  companyFirstQueryBehavior: boolean;
  companyList: ICompanyResponse[];
  companyByIdResponse: ICompanyResponse | undefined;
  companyIdToUpdate: number | undefined;
  selectedRowToDeleteCompanies?: number | any | undefined;
  deleteManyCompaniesIds?: number[] | undefined;
  deleteOneCompanyId?: number | undefined;
  notDeletedCompany?: number | number[] | undefined;
  idsInDatabase: number[] | undefined;
  showRequestModal: boolean;
  pendingCompany: boolean;
  refusedCompany: boolean;
  requestData: IRequestCompanyExistsData;
}

export interface ICompaniesFilter {
  filterByName: string;
  filterByIdentifier: string;
  filterByAddress: string;
  filterByCountry: number | undefined;
  filterByEmailDomain: string;
}

export interface INewCompany {
  name: string;
  address: string;
  companyIdentifierType: string | undefined;
  identifierInput: string | undefined;
  countriesList: ICountriesList[];
  countryId: number | undefined;
  companyCnpjOrTaxIdInputMask: string;
  responsibleEmail: string;
  emailDomain: string | undefined;
  originalName: string;
  originalAddress: string;
  originalCountryId: number | undefined;
  originalResponsibleEmail: string;
  originalIdentifierInput: string;
  inputCnpjOrTaxIdDisabled: boolean;
  emailIsValid: boolean;
  registerButtonDisabled: boolean;
  cancelButton: boolean;
}

export interface IRequestCompanyExistsData {
  requestId: number | undefined;
  companyId: number | undefined;
  name: string;
  address: string;
  identifier: string | undefined;
  country: number | undefined;
  responsibleEmail: string;
  emailDomain: string | undefined;
  dateOfOrder: string;
}

const initialState: ICompanyAdministrativePanelInitialState = {
  showFilter: '1',
  companies: {
    shouldRefresh: true,
    showCompaniesListing: false,
    showEditMode: false,
    startedEditCompany: false,
    data: [],
    dataForUsers: [],
    tableLoading: false,
    pageSize: 15,
    pageIndex: 1,
    numberOfItems: 0,
    sortBy: undefined,
    filter: {
      filterByName: '',
      filterByAddress: '',
      filterByCountry: undefined,
      filterByIdentifier: '',
      filterByEmailDomain: '',
    },
    companyQueryString: [],
    companyFirstQueryBehavior: true,
    companyList: [],
    companyByIdResponse: undefined,
    companyIdToUpdate: undefined,
    companyRegister: {
      name: '',
      address: '',
      companyIdentifierType: undefined,
      identifierInput: undefined,
      countriesList: [],
      countryId: undefined,
      companyCnpjOrTaxIdInputMask: '*',
      responsibleEmail: '',
      emailDomain: '',
      originalName: '',
      originalAddress: '',
      originalCountryId: undefined,
      originalResponsibleEmail: '',
      originalIdentifierInput: '',
      emailIsValid: false,
      inputCnpjOrTaxIdDisabled: true,
      registerButtonDisabled: true,
      cancelButton: true,
    },
    selectedRowToDeleteCompanies: undefined,
    deleteManyCompaniesIds: undefined,
    deleteOneCompanyId: undefined,
    notDeletedCompany: undefined,
    idsInDatabase: undefined,
    showRequestModal: false,
    pendingCompany: false,
    refusedCompany: false,
    requestData: {
      requestId: undefined,
      companyId: undefined,
      dateOfOrder: '',
      name: '',
      address: '',
      identifier: '',
      country: undefined,
      responsibleEmail: '',
      emailDomain: '',
    },
  },
};

export const asyncActions = {
  COMPANIES_LIST: createAsyncThunk(
    'COMPANIES_LIST',
    async (queryParams: string) => {
      const result =
        await administrativePanelCompaniesApi.getCompaniesList(queryParams);
      const dataToList = result.data.content.map((x, i) => {
        return {
          ...x,
          identifierValue: x.identifier,
          indexInStore: i,
        };
      });
      return {
        dataToList,
        totalElements: result.data.totalElements,
        idsInDatabase: result.data.idsInDatabase,
      };
    },
  ),
  COMPANY_LIST_FOR_USERS: createAsyncThunk(
    'COMPANY_LIST_FOR_USERS',
    async () => {
      const result = await administrativePanelCompaniesApi.getCompaniesList('');

      const dataToList = result.data.content.map((x, i) => {
        return {
          ...x,
          identifierValue: x.identifier,
          indexInStore: i,
        };
      });
      return {
        dataToList,
      };
    },
  ),
  DELETE_ONE_COMPANY: createAsyncThunk(
    'DELETE_ONE_COMPANY',
    async (id: number) => {
      const companyDelete =
        await administrativePanelCompaniesApi.deleteOneCompany(id);

      return {
        deleteOneCompanyId: companyDelete.data,
      };
    },
  ),
  DELETE_MANY_COMPANIES: createAsyncThunk(
    'DELETE_MANY_COMPANIES',
    async (id: number[]) => {
      const companiesDelete =
        await administrativePanelCompaniesApi.deleteManyCompanies(id);

      return {
        deleteManyCompaniesIds: companiesDelete.data,
      };
    },
  ),
  COUNTRIES_LIST: createAsyncThunk('COUNTRIES_LIST', async () => {
    const countriesListResult =
      await administrativePanelCompaniesApi.getCountriesList();
    return {
      countriesList: countriesListResult.data.content,
    };
  }),
  REGISTER_NEW_COMPANY: createAsyncThunk(
    'REGISTER_NEW_COMPANY',
    async (payload: any, { dispatch, rejectWithValue }) => {
      try {
        const newCompanyRegisterResponse =
          await administrativePanelCompaniesApi.newCompanyRegister(payload);
        dispatch(asyncActions.COMPANIES_LIST(''));
        return newCompanyRegisterResponse.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          return rejectWithValue(error.response.data);
        }
      }
    },
  ),
  APPROVE_OR_REFUSE_PENDING_REQUEST: createAsyncThunk(
    'APPROVE_OR_REFUSE_PENDING_REQUEST',
    async (payload: any, asyncThunkApi) => {
      const approvedOrRefusedBy = {
        analyzedBy: `${store.getState().login.user?.firstName} (${
          store.getState().login.user?.email
        })`,
      };

      switch (payload.approve_or_refuse) {
        case 'approve':
          await administrativePanelPendingRequestsApi.approveRequest(
            payload.pendingRequestId,
            approvedOrRefusedBy,
          );
          break;

        case 'refuse':
          await administrativePanelPendingRequestsApi.refuseRequest(
            payload.pendingRequestId,
            approvedOrRefusedBy,
          );
          break;

        default:
          break;
      }

      asyncThunkApi.dispatch(
        asyncActions.REGISTER_COMPANY_WITH_A_SOLICITATION_HISTORY(
          payload.newCompanyBody,
        ),
      );
    },
  ),
  REGISTER_COMPANY_WITH_A_SOLICITATION_HISTORY: createAsyncThunk(
    'REGISTER_COMPANY_WITH_A_SOLICITATION_HISTORY',
    async (payload: any) => {
      const newCompanyRegisterResponse =
        await administrativePanelCompaniesApi.newCompanyRegisterWithAHistorySolicitation(
          payload,
        );
      return newCompanyRegisterResponse.data;
    },
  ),
  GET_COMPANY_BY_ID: createAsyncThunk(
    'GET_COMPANY_BY_ID',
    async (payload: any) => {
      const companyResponse =
        await administrativePanelCompaniesApi.getCompanyById(payload);

      return {
        companyByIdResponse: companyResponse.data,
      };
    },
  ),
  UPDATE_COMPANY: createAsyncThunk('UPDATE_COMPANY', async (arg: any) => {
    await administrativePanelCompaniesApi.updateCompanyById(
      arg.id,
      arg.payload,
    );
  }),
};

export const CompanyAdministrativePanelSlice = createSlice({
  name: 'administrativePanelSlice',
  initialState,
  reducers: {
    TAB_CHANGE: (state) => {
      state.companies.selectedRowToDeleteCompanies = undefined;
      state.companies.showEditMode = false;
    },
    DISABLE_EDIT_MODE: (state) => {
      state.companies.showEditMode = initialState.companies.showEditMode;
      state.companies.companyRegister.name =
        initialState.companies.companyRegister.name;
      state.companies.companyRegister.address =
        initialState.companies.companyRegister.address;
      state.companies.companyRegister.countryId =
        initialState.companies.companyRegister.countryId;
      state.companies.companyRegister.identifierInput =
        initialState.companies.companyRegister.identifierInput;
    },
    FILTER_STATUS_CHANGE: (state, action) => {
      state.showFilter = action.payload;
    },
    COMPANIES_CHANGE_PAGE: (state, action) => {
      state.companies.pageIndex = action.payload;
    },
    COMPANIES_CHANGE_SORT: (state, action) => {
      state.companies.shouldRefresh = true;
      state.companies.sortBy = action.payload;
    },
    COMPANIES_DISABLE_DEFAULT_SORT_BEHAVIOUR: (state) => {
      state.companies.companyFirstQueryBehavior = false;
    },
    COMPANIES_SET_AS_REFRESHED: (state) => {
      state.companies.shouldRefresh = false;
      state.companies.showCompaniesListing = true;
    },
    COMPANIES_FILTER_SET_NAME: (state, action) => {
      state.companies.filter.filterByName = action.payload;
    },
    COMPANIES_FILTER_SET_ADDRESS: (state, action) => {
      state.companies.filter.filterByAddress = action.payload;
    },
    COMPANIES_FILTER_SET_IDENTIFIER: (state, action) => {
      state.companies.filter.filterByIdentifier = action.payload;
    },
    COMPANIES_FILTER_SET_COUNTRY: (state, action) => {
      state.companies.filter.filterByCountry = action.payload;
    },
    COMPANIES_FILTER_SET_EMAIL_DOMAIN: (state, action) => {
      state.companies.filter.filterByEmailDomain = action.payload;
    },
    COMPANIES_CLEAR_FILTER_FIELDS: (state) => {
      if (state.companies.companyQueryString.length) {
        state.companies.pageIndex = initialState.companies.pageIndex;
      }
      state.companies.filter = initialState.companies.filter;
      state.companies.companyQueryString =
        initialState.companies.companyQueryString;
      state.companies.shouldRefresh = true;
    },
    COMPANIES_SET_FILTER_QUERY_STRING: (state, action) => {
      state.companies.companyQueryString = action.payload;
      state.companies.shouldRefresh = true;
      state.companies.pageIndex = initialState.companies.pageIndex;
    },
    COMPANY_REGISTER_SET_NAME: (state, action) => {
      state.companies.companyRegister.name = action.payload;
    },
    COMPANY_REGISTER_SET_ADDRESS: (state, action) => {
      state.companies.companyRegister.address = action.payload;
    },
    COMPANY_REGISTER_SET_RESPONSIBLE_EMAIL: (state, action) => {
      state.companies.companyRegister.responsibleEmail = action.payload;

      state.companies.companyRegister.emailDomain =
        state.companies.companyRegister.responsibleEmail.split('@').pop();
    },
    COMPANY_REGISTER_SET_EMAIL_DOMAIN: (state, action) => {
      state.companies.companyRegister.emailDomain = action.payload;
    },
    SET_VALIDATION_EMAIL: (state, action) => {
      state.companies.companyRegister.emailIsValid = action.payload;
    },
    COMPANY_REGISTER_SET_IDENTIFIER_INPUT: (state, action) => {
      state.companies.companyRegister.identifierInput = action.payload;
      if (state.companies.showEditMode) {
        state.companies.startedEditCompany = true;
      }
    },
    COMPANY_REGISTER_SET_COUNTRY_ID: (state, action) => {
      state.companies.companyRegister.countryId = action.payload;
      state.companies.companyRegister.inputCnpjOrTaxIdDisabled = false;

      state.companies.companyRegister.identifierInput =
        initialState.companies.companyRegister.identifierInput;
    },
    COMPANY_ENABLE_MASK_NUMBER_INPUT: (state, action) => {
      state.companies.companyRegister.inputCnpjOrTaxIdDisabled = false;

      if (action.payload.companyIdentifier === 'cnpj') {
        state.companies.companyRegister.companyCnpjOrTaxIdInputMask =
          '11.111.111/1111-11';
      }
    },
    COMPANY_ENABLE_REGISTER_BUTTON: (state) => {
      state.companies.companyRegister.registerButtonDisabled = false;
    },
    COMPANY_DISABLE_REGISTER_BUTTON: (state) => {
      state.companies.companyRegister.identifierInput = '';
      state.companies.companyRegister.registerButtonDisabled = true;
    },
    COMPANY_REGISTER_SAVE_VALUES_ON_CHANGING_COUNTRY: (state, action) => {
      state.companies.companyRegister.name = action.payload.companyName;
      state.companies.companyRegister.responsibleEmail =
        action.payload.responsibleEmail;
      state.companies.companyRegister.address = action.payload.companyAdress;
      state.companies.companyRegister.companyIdentifierType =
        action.payload.cnpjTaxId;
    },
    COMPANY_BACK_TO_ORIGINAL_STATE: (state) => {
      return {
        ...initialState,
        idFromLoadedCompanyToUpdate: state.companies.companyIdToUpdate,
      };
    },
    COMPANY_BACK_TO_ORIGINAL_STATE_PERSISTING_FILTER: (state) => {
      return {
        ...initialState,
        idFromLoadedCompanyToUpdate: state.companies.companyIdToUpdate,
        showFilter: state.showFilter,
        companies: {
          ...state.companies,
          companyRegister: initialState.companies.companyRegister,
          showCompaniesListing: initialState.companies.showCompaniesListing,
        },
      };
    },
    COMPANY_SUGGEST_RECEIVE_COUNTRY_FROM_REQUEST_ACCESS: (state, action) => {
      state.companies.companyRegister.countryId = action.payload.countryId;

      if (state.companies.companyRegister.countryId === 30) {
        state.companies.companyRegister.companyCnpjOrTaxIdInputMask =
          '11.111.111/1111-11';
      }

      state.companies.companyRegister.inputCnpjOrTaxIdDisabled = false;
      state.companies.companyRegister.identifierInput = action.payload.idNumber;
    },
    COMPANIES_CREATED_SET_SELECTED_ROWS: (state, action) => {
      const companiesIdDataSource = state.companies.idsInDatabase?.map(
        (x) => x,
      );

      const payloadKeyId = action.payload.map((y: any) => y.id);

      if (companiesIdDataSource) {
        const payloadKeyExist = companiesIdDataSource.filter((x) =>
          payloadKeyId.includes(x),
        );
        state.companies.selectedRowToDeleteCompanies = payloadKeyExist.length;
      }
    },
    COMPANIES_CREATED_SET_ID_TO_DELETE_COMPANIES: (state, action) => {
      const companiesIdDataSource = state.companies.idsInDatabase?.map(
        (x) => x,
      );

      const payloadKeyId = action.payload.map((y: any) => y);

      if (companiesIdDataSource) {
        const payloadKeyExist = companiesIdDataSource.filter((x) =>
          payloadKeyId.includes(x),
        );
        state.companies.deleteManyCompaniesIds = payloadKeyExist;
      }
    },
    CLOSE_OR_OPEN_PENDING_MODAL: (state, action) => {
      state.companies.showRequestModal = action.payload;
    },
    SAVE_FORM_VALUES_TO_MODAL: (state, action) => {
      state.companies.companyRegister.name = action.payload.companyName;
      state.companies.companyRegister.address = action.payload.companyAdress;
      state.companies.companyRegister.responsibleEmail =
        action.payload.responsibleEmail;
      state.companies.companyRegister.identifierInput =
        action.payload.cnpjTaxId;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncActions.COMPANIES_LIST.pending, (state) => {
      state.companies.tableLoading = true;
    }),
    builder.addCase(asyncActions.COMPANIES_LIST.rejected, (state) => {
      state.companies.tableLoading = false;
    }),
    builder.addCase(asyncActions.COMPANIES_LIST.fulfilled, (state, action) => {
      state.companies.tableLoading = false;
      state.companies.numberOfItems = action.payload.totalElements;
      state.companies.data = action.payload.dataToList;
      state.companies.idsInDatabase = action.payload.idsInDatabase;
      state.companies.shouldRefresh = false;
    }),
    builder.addCase(
      asyncActions.COMPANY_LIST_FOR_USERS.fulfilled,
      (state, action) => {
        state.companies.dataForUsers = action.payload.dataToList;
      },
    ),
    builder.addCase(asyncActions.DELETE_ONE_COMPANY.fulfilled, (state) => {
      state.companies.shouldRefresh = true;
      if (state.companies.selectedRowToDeleteCompanies !== undefined) {
        state.companies.selectedRowToDeleteCompanies -= 1;
      }

      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-delete-company-title'),
        unhookedTranslation('administrative-panel-delete-company-content'),
      );
    }),
    builder.addCase(
      asyncActions.DELETE_MANY_COMPANIES.fulfilled,
      (state, action) => {
        state.companies.shouldRefresh = true;
        if (!action.payload.deleteManyCompaniesIds.errors) {
          if (
            state.companies.deleteManyCompaniesIds &&
            state.companies.deleteManyCompaniesIds.length === 1
          ) {
            state.companies.selectedRowToDeleteCompanies = undefined;
            messageNotification.successMessage(
              unhookedTranslation('administrative-panel-delete-company-title'),
              unhookedTranslation(
                'administrative-panel-delete-company-content',
              ),
            );
          } else {
            state.companies.selectedRowToDeleteCompanies = undefined;
            messageNotification.successMessage(
              unhookedTranslation('administrative-panel-delete-company-title'),
              unhookedTranslation(
                'administrative-panel-delete-many-companies-content',
              ),
            );
          }
        }
        if (
          action.payload.deleteManyCompaniesIds.errors &&
          state.companies.deleteManyCompaniesIds
        ) {
          if (action.payload.deleteManyCompaniesIds.identifiers.length > 1) {
            state.companies.selectedRowToDeleteCompanies =
              action.payload.deleteManyCompaniesIds.identifiers.length;

            messageNotification.warningMessage(
              unhookedTranslation('warning-companies-some-related-users-title'),
              unhookedTranslation(
                'warning-companies-some-related-users-content',
              ),
            );
          }

          if (action.payload.deleteManyCompaniesIds.identifiers.length === 1) {
            state.companies.selectedRowToDeleteCompanies =
              action.payload.deleteManyCompaniesIds.identifiers.length;
            messageNotification.warningMessage(
              unhookedTranslation('warning-companies-some-related-users-title'),
              unhookedTranslation(
                'warning-companies-singular-related-users-content',
              ),
            );
          }
        }
      },
    ),
    builder.addCase(asyncActions.COUNTRIES_LIST.fulfilled, (state, action) => {
      state.companies.companyRegister.countriesList =
        action.payload.countriesList;
    }),
    builder.addCase(asyncActions.REGISTER_NEW_COMPANY.fulfilled, (state) => {
      state.companies.showCompaniesListing = true;
      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-register-company-title'),
        unhookedTranslation('administrative-panel-register-company-content'),
      );
    }),
    builder.addCase(
      asyncActions.REGISTER_NEW_COMPANY.rejected,
      (state, action) => {
        const rejectedBody = action.payload as any;
        if (
          rejectedBody.i18n === 'error-company-with-refused-solicitation' ||
          rejectedBody.i18n === 'error-company-with-pending-solicitation'
        ) {
          if (rejectedBody.i18n === 'error-company-with-refused-solicitation') {
            state.companies.refusedCompany = true;
          }
          if (rejectedBody.i18n === 'error-company-with-pending-solicitation') {
            state.companies.pendingCompany = true;
          }
          state.companies.showRequestModal = true;
          state.companies.requestData.companyId =
            rejectedBody.content.company.id;
          state.companies.requestData.requestId = rejectedBody.content.id;
          state.companies.requestData.name = rejectedBody.content.company.name;
          state.companies.requestData.address =
            rejectedBody.content.company.address;
          state.companies.requestData.responsibleEmail =
            rejectedBody.content.company.responsibleEmail;
          state.companies.requestData.dateOfOrder =
            rejectedBody.content.createdAt;
          state.companies.requestData.identifier =
            rejectedBody.content.company.identifier;
          state.companies.requestData.country =
            rejectedBody.content.company.country.name;
        }
      },
    ),
    builder.addCase(
      asyncActions.GET_COMPANY_BY_ID.fulfilled,
      (state, action) => {
        const companyData = state.companies.companyRegister;
        const companyResponse = action.payload.companyByIdResponse;

        companyData.name = companyResponse.name;
        if (companyResponse.name) {
          companyData.originalName = companyResponse.name;
        } else companyData.originalName = '';

        companyData.countryId = companyResponse.country.id;
        companyData.originalCountryId = companyResponse.country.id;

        companyData.address = companyResponse.address;
        if (companyResponse.address) {
          companyData.originalAddress = companyResponse.address;
        } else companyData.originalAddress = '';

        companyData.responsibleEmail = companyResponse.responsibleEmail;
        if (companyResponse.responsibleEmail) {
          companyData.originalResponsibleEmail =
            companyResponse.responsibleEmail;
        } else companyData.responsibleEmail = '';

        companyData.emailDomain = companyResponse.emailDomain;
        state.companies.companyIdToUpdate = companyResponse.id;

        switch (companyResponse.country.companyIdentifierType) {
          case 'CNPJ':
            companyData.companyIdentifierType = 'cnpj';
            companyData.companyCnpjOrTaxIdInputMask = '11.111.111/1111-11';
            break;
          default:
            companyData.companyIdentifierType =
              companyResponse.country.companyIdentifierType;
            break;
        }

        companyData.identifierInput = companyResponse.identifier;

        if (companyResponse.identifier) {
          companyData.originalIdentifierInput = companyResponse.identifier;
        } else companyData.originalIdentifierInput = '';

        state.companies.showCompaniesListing = false;
        state.companies.showEditMode = true;
        state.companies.companyRegister.inputCnpjOrTaxIdDisabled = false;
      },
    ),
    builder.addCase(asyncActions.UPDATE_COMPANY.fulfilled, (state) => {
      state.companies.showCompaniesListing = true;
      state.companies.startedEditCompany = false;
      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-update-company-title'),
        unhookedTranslation('administrative-panel-update-company-content'),
      );
    }),
    builder.addCase(
      asyncActions.REGISTER_COMPANY_WITH_A_SOLICITATION_HISTORY.fulfilled,
      (state) => {
        state.companies.showCompaniesListing = true;
        messageNotification.successMessage(
          unhookedTranslation('administrative-panel-update-company-title'),
          unhookedTranslation('administrative-panel-update-company-content'),
        );
      },
    ),
  ],
});
