import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { pageApi } from '../../services/page.api';
import {
  createReadEditPageApi,
  IListPagesBackendDTO,
} from '../../services/createReadEditPage.api';
import { messageNotification } from '../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../helpers/unhookedTranslation/unhookedTranslation';

export interface IPage {
  loadingSearchedPage: boolean;
  pageList: IListPagesBackendDTO | undefined;
  getPageLoading: boolean;
  navigateToPageDetails: boolean;
  searchPageTitle: any;
  pageResult: boolean;
  idsInDatabase: number[] | undefined;
  showAllPages: boolean;
  reloadCreatedPages: boolean;
  previousPath: string;
  pageDoesNotExist: boolean;

  // Pages Created
  pagesCreatedCollapseFilters: boolean;
  pagesCreatedFirstQueryBehaviour: boolean;
  pagesCreatedFilterQueryString: string[];
  pagesCreatedOrderQueryString: string;
  pagesCreatedFilterByPageName: string;
  pagesCreatedFilterByModifiedBy: string;
  pagesCreatedPageIndex: number;
  pagesCreatedPageSize: number;
  pagesCreatedSelectedRowToDelete?: number | undefined;
  pagesCreatedDeleteManyPages?: number[] | undefined;
  pagesCreatedDeleteOnePage?: number;
  pagesCreatedTableLoading: boolean;
}

const initialState: IPage = {
  loadingSearchedPage: false,
  pageDoesNotExist: false,
  pageList: undefined,
  idsInDatabase: undefined,
  getPageLoading: false,
  navigateToPageDetails: false,
  pagesCreatedFirstQueryBehaviour: true,
  pagesCreatedCollapseFilters: false,
  pagesCreatedFilterByPageName: '',
  pagesCreatedFilterByModifiedBy: '',
  pagesCreatedFilterQueryString: [],
  pagesCreatedOrderQueryString: '',
  pagesCreatedPageIndex: 1,
  pagesCreatedPageSize: 15,
  pagesCreatedSelectedRowToDelete: undefined,
  pagesCreatedDeleteManyPages: undefined,
  pagesCreatedDeleteOnePage: undefined,
  pagesCreatedTableLoading: false,
  searchPageTitle: '',
  pageResult: false,
  showAllPages: true,
  reloadCreatedPages: true,
  previousPath: 'created-pages',
};

export const asyncActionsCreatedPages = {
  GET_PAGE_LIST_WITH_FILTER: createAsyncThunk(
    'GET_PAGE_LIST_WITH_FILTER',
    async (arg: any) => {
      const resultPageList =
        await createReadEditPageApi.getAllPagesWhileFiltering(
          arg.language,
          arg.queryString,
        );

      return {
        pageListResult: resultPageList.data,
        idsInDatabase: resultPageList.data.idsInDatabase,
      };
    },
  ),
  INFORM_THAT_SOMEONE_IS_UPDATING: createAsyncThunk(
    'INFORM_THAT_SOMEONE_IS_UPDATING',
    async (arg: any) => {
      await pageApi.someoneIsUpdating(arg.id, arg.isUpdating);
    },
  ),
  FIND_PAGE_BY_TITLE: createAsyncThunk(
    'FIND_PAGE_BY_TITLE',
    async (arg: any) => {
      const pageResponse = await createReadEditPageApi.findPageByTitle(
        arg.language,
        arg.title,
      );

      return {
        title: arg.title,
        pageObjectResponse: pageResponse.data,
      };
    },
  ),
  DELETE_ONE_PAGE: createAsyncThunk('DELETE_ONE_PAGE', async (id: number) => {
    const oneId = await createReadEditPageApi.deleteOnePage(id);
    return {
      pagesCreatedDeleteOnePage: oneId.data,
    };
  }),
  DELETE_MANY_PAGES: createAsyncThunk(
    'DELETE_MANY_PAGES',
    async (id: number[]) => {
      const pageDelete = await createReadEditPageApi.deleteManyPages(id);

      return {
        pagesCreatedDeleteManyPages: pageDelete.data,
      };
    },
  ),
};

export const PageSlice = createSlice({
  name: 'PageSlice',
  initialState,
  reducers: {
    PAGE_SET_NAVIGATION: (state, action) => {
      state.navigateToPageDetails = action.payload;
    },
    PAGES_CREATED_COLLAPSE_FILTERS: (state, action) => {
      state.pagesCreatedCollapseFilters = action.payload;
    },
    PAGES_CREATED_SET_FILTER_BY_PAGE_NAME: (state, action) => {
      state.pagesCreatedFilterByPageName = action.payload;
    },
    PAGES_CREATED_SET_FILTER_BY_MODIFIED_BY: (state, action) => {
      state.pagesCreatedFilterByModifiedBy = action.payload;
    },
    PAGES_CREATED_CLEAN_FILTER_FIELDS: (state) => {
      state.pagesCreatedFilterByPageName =
        initialState.pagesCreatedFilterByPageName;
      state.pagesCreatedFilterByModifiedBy =
        initialState.pagesCreatedFilterByModifiedBy;
      state.pagesCreatedFilterQueryString =
        initialState.pagesCreatedFilterQueryString;
      state.reloadCreatedPages = true;
    },
    PAGES_CREATED_SET_FILTER_QUERY_STRING: (state, action) => {
      state.pagesCreatedFilterQueryString = action.payload;
      state.reloadCreatedPages = true;
      state.pagesCreatedPageIndex = initialState.pagesCreatedPageIndex;
    },
    PAGES_CREATED_SET_ORDER_QUERY_STRING: (state, action) => {
      state.reloadCreatedPages = true;
      state.pagesCreatedOrderQueryString = action.payload;
    },
    PAGES_CREATED_FILTER_DATA_SOURCE: (state, action) => {
      state.pagesCreatedFilterByPageName = action.payload.name;
      state.pagesCreatedFilterByModifiedBy = action.payload.updatedBy;
    },
    PAGES_CREATED_CHANGE_PAGE_INDEX: (state, action) => {
      state.reloadCreatedPages = true;
      state.pagesCreatedPageIndex = action.payload;
    },
    PAGES_CREATED_SET_SELECTED_ROWS: (state, action) => {
      const pageIdDataSource = state.idsInDatabase?.map((x) => x);

      const payloadKeyId = action.payload.map((y: any) => y.page.id);

      if (pageIdDataSource) {
        const payloadKeyExist = pageIdDataSource.filter((x) =>
          payloadKeyId.includes(x),
        );

        state.pagesCreatedSelectedRowToDelete = payloadKeyExist.length;
      }
    },
    PAGES_CREATED_SET_ID_TO_DELETE_PAGES: (state, action) => {
      const payloadKeyId = action.payload.map((y: any) => y);
      const pageIdDataSource = state.idsInDatabase?.map((x) => x);

      if (pageIdDataSource) {
        const payloadKeyExist = pageIdDataSource.filter((x) =>
          payloadKeyId.includes(x),
        );

        state.pagesCreatedDeleteManyPages = payloadKeyExist;
      }
    },
    PAGES_CREATED_DISABLE_DEFAULT_SORT_BEHAVIOUR: (state) => {
      state.pagesCreatedFirstQueryBehaviour = false;
    },
    PAGE_BACK_TO_ORIGINAL_STATE: () => {
      return initialState;
    },
    PAGE_SEARCH_BY_TITLE: (state, action) => {
      state.searchPageTitle = action.payload;
    },
    PAGE_SEARCH_RESULTS: (state) => {
      state.pageResult = true;
      state.showAllPages = false;
    },
    BACK_TO_ALL_PAGES_LISTING: (state) => {
      state.showAllPages = true;
    },
    STOP_TO_RELOAD_PAGES_LISTING: (state) => {
      state.reloadCreatedPages = false;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(
      asyncActionsCreatedPages.GET_PAGE_LIST_WITH_FILTER.pending,
      (state) => {
        state.pagesCreatedTableLoading = true;
      },
    ),
    builder.addCase(
      asyncActionsCreatedPages.GET_PAGE_LIST_WITH_FILTER.rejected,
      (state) => {
        state.pagesCreatedTableLoading = false;
      },
    ),
    builder.addCase(
      asyncActionsCreatedPages.GET_PAGE_LIST_WITH_FILTER.fulfilled,
      (state, action) => {
        const pageList = action.payload.pageListResult;
        pageList.content = pageList.content.map((x) => {
          return {
            ...x,
            modifiedAt: x.modifiedAt ? x.modifiedAt : x.createdAt,
            updatedByName: x.page.modifiedBy
              ? x.page.modifiedBy
              : x.page.createdBy,
          };
        });
        state.pagesCreatedTableLoading = false;
        state.pageList = pageList;
        state.reloadCreatedPages = false;
        state.idsInDatabase = action.payload.idsInDatabase;
      },
    ),
    builder.addCase(
      asyncActionsCreatedPages.DELETE_ONE_PAGE.fulfilled,
      (state) => {
        state.reloadCreatedPages = true;
        if (state.pagesCreatedSelectedRowToDelete !== undefined) {
          state.pagesCreatedSelectedRowToDelete -= 1;
        }

        messageNotification.successMessage(
          unhookedTranslation('pages-created-delete-one-documentation'),
          unhookedTranslation('pages-created-delete-one-documentation-content'),
        );
      },
    ),
    builder.addCase(
      asyncActionsCreatedPages.DELETE_ONE_PAGE.rejected,
      (state) => {
        state.reloadCreatedPages = true;
      },
    ),
    builder.addCase(
      asyncActionsCreatedPages.FIND_PAGE_BY_TITLE.pending,
      (state) => {
        state.loadingSearchedPage = true;
      },
    ),
    builder.addCase(
      asyncActionsCreatedPages.FIND_PAGE_BY_TITLE.fulfilled,
      (state, action) => {
        state.searchPageTitle = action.payload.title;
        state.showAllPages = false;
        state.loadingSearchedPage = false;
      },
    ),
    builder.addCase(
      asyncActionsCreatedPages.DELETE_MANY_PAGES.fulfilled,
      (state) => {
        state.reloadCreatedPages = true;
        state.pagesCreatedSelectedRowToDelete = undefined;
        if (state.pagesCreatedDeleteManyPages?.length === 1) {
          messageNotification.successMessage(
            unhookedTranslation('pages-created-delete-one-documentation'),
            unhookedTranslation(
              'pages-created-delete-one-documentation-content',
            ),
          );
        } else {
          messageNotification.successMessage(
            unhookedTranslation('pages-created-delete-many-documentation'),
            unhookedTranslation(
              'pages-created-delete-many-content-documentation',
            ),
          );
        }
      },
    ),
  ],
});
