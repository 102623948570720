import { Col, Row } from 'antd';
import React from 'react';
import ReactQuill from 'react-quill-new';
import './getEditorToPreview.scss';
import 'react-quill-new/dist/quill.snow.css';

const modules = {
  toolbar: false,
};

export const getEditorToPreview = (editorContent?: string) => {
  return (
    <Row>
      <Col span={24} style={{ overflow: 'auto' }}>
        <ReactQuill
          id="preview-editor"
          theme="snow"
          modules={modules}
          value={editorContent}
          readOnly={true}
        />
      </Col>
    </Row>
  );
};
