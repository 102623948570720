import { Row, Col, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EyeFilled } from '@ant-design/icons';
import { Filter } from '../../../../components/Filter/Filter';
import { PagingTable } from '../../../../components/PagingTable/PagingTable';
import { ShowFiltersButton } from '../../../../components/ShowFiltersButton/ShowFiltersButton';
import { scrollToTopFunction } from '../../../../helpers/useScrollToTop/useScrollToTop';
import { AppDispatch, RootState } from '../../../../store/store';

import { ReactComponent as EditPencil } from '../../../../assets/popover-icons/edit.svg';
import { ReactComponent as Padlock } from '../../../../assets/popover-icons/padlock.svg';
import { ReactComponent as TrashCan } from '../../../../assets/popover-icons/trash-can.svg';

import './TermsListing.scss';
import {
  TermsListingAsyncActions,
  TermsListingSlice,
} from './TermListing.Slice';
import { PreviewTerm } from './PreviewTerm/PreviewTerm';
import { showModal } from '../../../../helpers/showModal/showModal';

export const TermsListing: React.FC = () => {
  const termListingState = useSelector(
    (state: RootState) => state.termsListing.listing,
  );
  const termListingFilterState = useSelector(
    (state: RootState) => state.termsListing.filter,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(
        TermsListingSlice.actions.RESET_TO_ORIGINAL_STATE_PERSISTING_FILTER(),
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (termListingState.tableLoading) {
      return;
    }

    if (!termListingState.refresh) {
      return;
    }

    let queryString = `?page=${termListingState.pageIndex - 1}&size=${
      termListingState.pageSize
    }`;

    if (termListingState.filterQueryString) {
      queryString += `${termListingState.filterQueryString}`;
    }

    if (termListingState.defaultSortBehaviourActive) {
      queryString += `&sort=active,desc`;
    }

    if (termListingState.orderQueryString) {
      queryString += `${termListingState.orderQueryString}`;
    }

    dispatch(TermsListingAsyncActions.GET_TERM_LIST(queryString));
  }, [
    dispatch,
    termListingState.refresh,
    termListingState.tableLoading,
    termListingState.pageIndex,
    termListingState.pageSize,
    termListingState.filterQueryString,
    termListingState.orderQueryString,
    termListingState.defaultSortBehaviourActive,
  ]);

  const [columnsWidth, setColumnsWidth] = useState({
    versionColumnWidth: 4.2,
  });

  const resizeHandlerSpeed = 0.011;
  const resizeColumns = (
    columnName: 'versionColumnWidth',
    movement: number,
  ) => {
    setColumnsWidth({
      ...columnsWidth,
      [columnName]: (columnsWidth[columnName] += movement * resizeHandlerSpeed),
    });
  };
  const termColumnCursor = [
    {
      columnTitle: t('term-listing-table_version'),
      columnProperty: 'version',
      width: columnsWidth.versionColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('versionColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-pop-up-last-modified'),
      columnProperty: 'updatedAt',
      width: columnsWidth.versionColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('versionColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-pop-up-modified-by'),
      columnProperty: 'modifiedBy',
      width: columnsWidth.versionColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('versionColumnWidth', event.movementX);
      },
    },
  ];

  const termColumnOptions = [
    {
      optionName: t('terms-actions-preview-term'),
      optionIcon: (
        <EyeFilled
          style={{ color: '#41B4D2', margin: '0', marginBottom: '-0.2rem' }}
        />
      ),
      optionOnClick: (termId: any, termItem: any) => {
        dispatch(TermsListingSlice.actions.OPEN_PREVIEW_TERM(termItem));
      },
    },
    {
      optionName: t('terms-actions-edit-term'),
      optionIcon: <EditPencil />,
      optionOnClick: (termId: number) => {
        navigate(`/administrative-panel/add_or_edit?tab=terms&id=${termId}`);
      },
      optionDisableVerification: (termId: number, term: any) => {
        return term.active || term.accepted;
      },
      optionDisabledMessage: t('terms-modal-actions-edit-disabled-message'),
    },
    {
      optionId: 'activate-or-deactivate-term',
      optionName: t('terms-actions-activate-term'),
      optionIcon: <Padlock />,
      optionOnClick: (termId: number) => {
        showModal(
          'warning',
          `${t('terms-modal-activate-modal-title')}`,
          `${t('terms-modal-activate-modal-content')}`,
          () => {
            dispatch(TermsListingAsyncActions.ACTIVATE_TERM(termId));
          },
          () => {},
          `${t('terms-modal-activate-modal-ok-title')}`,
          `${t('terms-modal-activate-modal-cancel-title')}`,
        );
      },
      optionDisableVerification: (termId: number, term: any) => {
        return term.active;
      },
    },
    {
      optionName: t('terms-actions-delete-term'),
      optionIcon: <TrashCan />,
      optionOnClick: (termId: number) => {
        showModal(
          'warning',
          t('terms-modal-delete-term-title'),
          t('terms-modal-delete-term-content'),
          () => {
            dispatch(TermsListingAsyncActions.DELETE_TERM(termId));
          },
          () => {},
          t('modal-delete-ok-button'),
          t('modal-delete-cancel-button'),
        );
      },
      optionDisableVerification: (termId: number, term: any) => {
        return term.active || term.accepted;
      },
      optionDisabledMessage: t('terms-modal-actions-delete-disabled-message'),
    },
  ];

  const onChangePage = (pageIndex: number) => {
    if (!pageIndex || pageIndex === termListingState.pageIndex) {
      return;
    }

    dispatch(TermsListingSlice.actions.CHANGE_PAGE(pageIndex));
    scrollToTopFunction();
  };

  const dispatchSortBy = (sortBy: string) => {
    dispatch(TermsListingSlice.actions.ORDER_SET_QUERY_STRING(sortBy));
  };

  const onChangeSort = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    if (!order || !field) {
      return dispatchSortBy('');
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;

    let sortBy = '&sort=';

    if (field === 'active' || field === 'updatedAt') {
      sortBy +=
        order === 'ascend'
          ? `${fieldToQueryParam}`
          : `${fieldToQueryParam},desc`;

      return dispatchSortBy(sortBy);
    }

    sortBy +=
      order === 'ascend'
        ? `${fieldToQueryParam},ignoreCase`
        : `${fieldToQueryParam},desc,ignoreCase`;

    dispatchSortBy(sortBy);
  };

  const toggleFilter = () => {
    dispatch(TermsListingSlice.actions.TOGGLE_FILTER());
  };

  const clearFilterFields = () => {
    dispatch(TermsListingSlice.actions.FILTER_CLEAR_FIELDS());
  };

  const termFilterCursor = [
    {
      fieldTitle: t('term-listing-table_version'),
      fieldType: 'input',
      fieldProperty: 'version',
      fieldValue: termListingFilterState.version,
    },
    {
      fieldTitle: t('term-listing-table-status'),
      fieldType: 'select',
      fieldProperty: 'active',
      fieldValue: termListingFilterState.active,
      selectOptions: [
        {
          id: 1,
          name: t('terms-listing-active-status'),
        },
        {
          id: 2,
          name: t('terms-listing-inactive-status'),
        },
      ],
    },
  ];

  const submitFilter = (values: any) => {
    const queryParams = Object.entries(values).reduce((acc, [key, value]) => {
      switch (key) {
        case 'active':
          dispatch(TermsListingSlice.actions.TERMS_FILTER_SET_ACTIVE(value));
          break;
        case 'version':
          dispatch(TermsListingSlice.actions.TERMS_FILTER_SET_VERSION(value));
          break;
        default:
          break;
      }

      if (value !== '' && value !== null && value !== undefined) {
        if (key === 'active') {
          const activeParam = value === 1;
          acc += `&${key}=${activeParam}`;
          return acc;
        }

        acc += `&${key}=${String(value).trim()}`;
      }

      return acc;
    }, '');

    dispatch(TermsListingSlice.actions.FILTER_SET_QUERY_STRING(queryParams));
  };

  return (
    <Row className="TermsListing">
      <PreviewTerm />
      <Row className="buttons-filters" justify="space-between">
        <Col span={3}>
          <Button
            id="new-term-button"
            className="terms-listing-button-new-term"
            onClick={() =>
              navigate('/administrative-panel/add_or_edit?tab=terms')
            }
          >
            {t('administrative-panel-terms-new-term-button')}
          </Button>
        </Col>
        <Col pull={6}>{/* <DeleteSelectedButton /> */}</Col>
        <ShowFiltersButton
          onToggle={toggleFilter}
          openedFilter={!termListingFilterState.openedFilter}
          offset={12}
        />
      </Row>
      <Row className="filter-collapse-area">
        <Col span={24}>
          <Filter
            clearFilterFields={clearFilterFields}
            openedFilter={termListingFilterState.openedFilter}
            fields={termFilterCursor}
            submitFilterFields={submitFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PagingTable
            emptyText={t('administrative-panel-empty-table')}
            dataSource={termListingState.dataSource}
            tableLoading={termListingState.tableLoading}
            columns={termColumnCursor}
            options={termColumnOptions}
            pageSize={termListingState.pageSize}
            pageIndex={termListingState.pageIndex}
            numberOfItems={termListingState.totalNumberOfElements}
            onChangePage={onChangePage}
            onChangeSort={onChangeSort}
            rowClickAction={(record: any) => {
              dispatch(TermsListingSlice.actions.OPEN_PREVIEW_TERM(record));
            }}
            selectRowsToGivenTab={() => {}}
            selectIdFromRowsSelected={() => {}}
            disableSelectionColumn
          />
        </Col>
      </Row>
    </Row>
  );
};
