import React from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import './ForgotPasswordForm.scss';

interface IForgotPasswordForm {
  forgotPasswordLoading: boolean;
  submitForgotPassword: Function;
  goBackToLogin: Function;
}

// add new rule for forgot password.
export const ForgotPasswordForm: React.FC<IForgotPasswordForm> = ({
  forgotPasswordLoading,
  goBackToLogin,
  submitForgotPassword,
}: IForgotPasswordForm) => {
  const { t } = useTranslation();

  return (
    <Row className="ForgotPasswordForm">
      <Col>
        <Row>
          <Col>
            <p className="left-column-content">
              {t('forgot-password-instructions')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="left-column-form">
            <Form onFinish={(values) => submitForgotPassword(values)}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('forgot-password-validation-email'),
                  },
                  { type: 'email', message: t('login-validation-email-type') },
                  () => ({
                    validator(rule, value) {
                      if (!value || value.split('@').shift().length <= 64) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t('login-validation-email-type'));
                    },
                  }),
                ]}
              >
                <Row>
                  <Col span={24}>
                    <p className="email-input-outline-label">
                      {t('forgot-password-label-email')}
                    </p>
                    <Input
                      name="emailInput"
                      placeholder={t('forgot-password-placeholder-email')}
                      maxLength={254}
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <Row className="left-column-form-buttons">
                  <Col>
                    <Button
                      name="cancelForgotPasswordButton"
                      className="left-column-form-buttons-cancel"
                      type="link"
                      onClick={() => goBackToLogin()}
                    >
                      {t('forgot-password-back-button')}
                    </Button>
                    <Button
                      name="forgotPasswordButton"
                      shape="round"
                      htmlType="submit"
                      loading={forgotPasswordLoading}
                    >
                      {t('forgot-password-submit-button')}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
