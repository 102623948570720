import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { messageNotification } from '../../../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../../../helpers/unhookedTranslation/unhookedTranslation';
import { administrativePanelPopUpApi } from '../../../../services/administrative-panel-pop-up';

interface IPopUpListingSlice {
  filter: IPopUpListingSliceFilter;
  listing: IPopUpListingSliceListing;
  previewPopUp: IPopUpListingSlicePreview;
}

interface IPopUpListingSliceFilter {
  openedFilter: boolean;
  requestType: string | undefined;
  name: string | undefined;
  email: string | undefined;
  id: number | undefined;
  active: string | undefined;
  modifiedBy: string | undefined;
  date: any;
}

interface IPopUpListingSliceListing {
  dataSource: any[];
  refresh: boolean;
  tableLoading: boolean;
  pageSize: number;
  pageIndex: number;
  totalNumberOfElements: number;
  filterQueryString: string;
  orderQueryString: string;
  idsInDataBase: number[] | undefined;
  firstListingHasAlreadyOccured: boolean;
  defaultSortBehaviourActive: boolean;
}

interface IPopUpListingSlicePreview {
  previewPopUpLanguage: string;
  id?: number;
  ptContent: string;
  enContent: string;
  esContent: string;
  ptTitle: string;
  enTitle: string;
  esTitle: string;
}

const initialState: IPopUpListingSlice = {
  filter: {
    openedFilter: false,
    requestType: undefined,
    name: undefined,
    email: undefined,
    date: undefined,
    id: undefined,
    modifiedBy: undefined,
    active: undefined,
  },
  listing: {
    dataSource: [],
    refresh: true,
    tableLoading: false,
    pageSize: 15,
    pageIndex: 1,
    totalNumberOfElements: 0,
    filterQueryString: '',
    orderQueryString: '',
    idsInDataBase: undefined,
    firstListingHasAlreadyOccured: false,
    defaultSortBehaviourActive: true,
  },
  previewPopUp: {
    previewPopUpLanguage: '',
    ptContent: '',
    enContent: '',
    esContent: '',
    ptTitle: '',
    enTitle: '',
    esTitle: '',
  },
};

export const PopUpListingAsyncActions = {
  GET_POP_UP_LIST: createAsyncThunk(
    'GET_POP_UP_LIST',
    async (queryParams: string) => {
      const popUpList =
        await administrativePanelPopUpApi.getPopUpList(queryParams);
      return popUpList.data;
    },
  ),
  ACTIVATE_POP_UP: createAsyncThunk(
    'ACTIVATE_POP_UP',
    async (popUpId: number) => {
      await administrativePanelPopUpApi.activatePopUp(popUpId);
    },
  ),
  DEACTIVATE_POP_UP: createAsyncThunk(
    'DEACTIVATE_POP_UP',
    async (popUpId: number) => {
      await administrativePanelPopUpApi.deactivatePopUp(popUpId);
    },
  ),
  DELETE_POP_UP: createAsyncThunk('DELETE_POP_UP', async (popUpId: number) => {
    await administrativePanelPopUpApi.deletePopUp(popUpId);
  }),
};

export const PopUpListingSlice = createSlice({
  name: 'PopUpsListingSlice',
  initialState,
  reducers: {
    TOGGLE_FILTER: (state) => {
      if (state.filter.openedFilter) {
        state.filter.openedFilter = false;
      } else {
        state.filter.openedFilter = true;
      }
    },
    FILTER_SET_QUERY_STRING: (state, action) => {
      state.listing.pageIndex = initialState.listing.pageIndex;
      state.listing.filterQueryString = action.payload;
      state.listing.refresh = true;
    },
    ORDER_SET_QUERY_STRING: (state, action) => {
      state.listing.defaultSortBehaviourActive = false;
      state.listing.orderQueryString = action.payload;
      state.listing.refresh = true;
    },
    FILTER_CLEAR_FIELDS: (state) => {
      if (state.listing.filterQueryString) {
        state.listing.pageIndex = initialState.listing.pageIndex;
      }
      state.listing.filterQueryString = initialState.listing.filterQueryString;
      state.listing.refresh = true;
      state.filter = initialState.filter;
    },
    POP_UP_FILTER_SET_ID: (state, action) => {
      state.filter.id = action.payload;
    },
    POP_UP_FILTER_SET_ACTIVE: (state, action) => {
      state.filter.active = action.payload;
    },
    CHANGE_PAGE: (state, action) => {
      state.listing.pageIndex = action.payload;
      state.listing.refresh = true;
    },
    RESET_TO_ORIGINAL_STATE: () => {
      return initialState;
    },
    RESET_TO_ORIGINAL_STATE_PERSISTING_FILTER: (state) => {
      return {
        ...initialState,
        filter: state.filter,
        listing: state.listing,
      };
    },
    SET_PREVIEW_POP_UP_LANGUAGE: (state, action) => {
      state.previewPopUp.previewPopUpLanguage = action.payload;
    },
    OPEN_PREVIEW_POP_UP: (state, action) => {
      state.previewPopUp.id = action.payload.id;

      state.previewPopUp.ptContent = action.payload.contents.find(
        (x: any) => x.language === 'PORTUGUESE',
      ).description;
      state.previewPopUp.enContent = action.payload.contents.find(
        (x: any) => x.language === 'ENGLISH',
      ).description;
      state.previewPopUp.esContent = action.payload.contents.find(
        (x: any) => x.language === 'SPANISH',
      ).description;
      state.previewPopUp.ptTitle = action.payload.contents.find(
        (x: any) => x.language === 'PORTUGUESE',
      ).title;
      state.previewPopUp.enTitle = action.payload.contents.find(
        (x: any) => x.language === 'ENGLISH',
      ).title;
      state.previewPopUp.esTitle = action.payload.contents.find(
        (x: any) => x.language === 'SPANISH',
      ).title;
    },
    CLOSE_PREVIEW_POP_UP: (state) => {
      state.previewPopUp = initialState.previewPopUp;
    },
    REFRESH_LIST: (state) => {
      state.listing.refresh = true;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(
      PopUpListingAsyncActions.GET_POP_UP_LIST.pending,
      (state) => {
        state.listing.tableLoading = true;
      },
    ),
    builder.addCase(
      PopUpListingAsyncActions.GET_POP_UP_LIST.fulfilled,
      (state, action) => {
        state.listing.dataSource = action.payload.content;
        state.listing.totalNumberOfElements = action.payload.totalElements;

        state.listing.refresh = false;
        state.listing.tableLoading = false;
      },
    ),
    builder.addCase(
      PopUpListingAsyncActions.ACTIVATE_POP_UP.fulfilled,
      (state) => {
        state.listing.refresh = true;
      },
    ),
    builder.addCase(
      PopUpListingAsyncActions.DEACTIVATE_POP_UP.fulfilled,
      (state) => {
        state.listing.refresh = true;
      },
    ),
    builder.addCase(
      PopUpListingAsyncActions.DELETE_POP_UP.fulfilled,
      (state) => {
        state.listing.refresh = true;

        messageNotification.successMessage(
          unhookedTranslation('pop-up-successfuly-deleted-title'),
          unhookedTranslation('pop-up-successfuly-deleted-content'),
        );
      },
    ),
  ],
});
