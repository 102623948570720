import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  administrativePanelUsersApi,
  IUserList,
} from '../../../services/administrative-panel-users';
import { IUsersTab } from '../../../interfaces/IUsersTab';
import {
  exportToClipboard,
  generateCsv,
  generateXlsx,
} from '../../../helpers/mailingList/mailingListHelpers';
import { IUserData } from '../../../helpers/mailingList/IUserData';
import { messageNotification } from '../../../helpers/messageNotification/messageNotification';
import { t } from 'i18next';

export enum ExportMode {
  All,
  Some,
  Filtered,
}

export enum ExportType {
  CSV,
  XLSX,
  Clipboard,
}

export interface IAdministrativePanelMailingListState {
  showFilter: string;
  showEditMode: boolean;
  returnToAllUsers: boolean;
  list: IUsersTab;
  users: IUserList | undefined;
  userExist: boolean;
  showExportModal: boolean;
  exportMode: ExportMode;
  exportUserCount: number;
  exportType: ExportType;
  isExporting: boolean;
  isLoadingAllUsers: boolean;
}

const initialState: IAdministrativePanelMailingListState = {
  showFilter: '1',
  showEditMode: false,
  list: {
    data: [],
    companiesData: [],
    pageIndex: 1,
    numberOfItems: 0,
    idsInDatabase: undefined,
    pageSize: 15,
    shouldRefresh: true,
    tableLoading: false,
    sortBy: undefined,
    selectedRowToExportUsers: undefined,
    exportManyUsersIds: undefined,
    filter: {
      filterByCompany: undefined,
      filterByCountry: undefined,
      filterByEmail: '',
      filterByFirstName: '',
      filterByLastName: '',
      filterByLastInteraction: undefined,
      filterByStatus: undefined,
    },
    userQueryString: [],
    userFirstQueryBehaviour: true,
  },
  users: undefined,
  userExist: true,
  returnToAllUsers: false,
  showExportModal: false,
  exportMode: ExportMode.All,
  exportUserCount: 0,
  exportType: ExportType.CSV,
  isExporting: false,
  isLoadingAllUsers: false,
};

export const asyncActions = {
  USERS_MAILING_LIST: createAsyncThunk(
    'USERS_MAILING_LIST',
    async (queryParams: string) => {
      const result =
        await administrativePanelUsersApi.getUsersList(queryParams);
      const dataToList = result.data.content.map((x, i) => {
        return {
          ...x,
          indexInStore: i,
        };
      });
      return {
        dataToList,
        totalElements: result.data.totalElements,
        idsInDatabase: result.data.idsInDatabase,
      };
    },
  ),
  GET_ALL_USERS: createAsyncThunk('GET_ALL_USERS', async () => {
    const result =
      await administrativePanelUsersApi.getUsersList(`?page=-1&size=100000`);

    return {
      users: result.data,
    };
  }),
  GET_USER_BY_ID: createAsyncThunk('GET_USER_BY_ID', async (payload: any) => {
    const userResponse = await administrativePanelUsersApi.getUserById(payload);

    return {
      userResponse: userResponse.data,
    };
  }),
  GET_USERS_BY_ID: createAsyncThunk('GET_USERS_BY_ID', async (payload: any) => {
    const result = await administrativePanelUsersApi.getUserListById(payload);

    return {
      users: result.data,
    };
  }),
  EXPORT_CSV: createAsyncThunk('EXPORT_CSV', async (userData: IUserData[]) => {
    const csvEncodedUri = await generateCsv(userData);

    return {
      encodeUri: csvEncodedUri,
    };
  }),
  EXPORT_XLSX: createAsyncThunk(
    'EXPORT_XLSX',
    async (userData: IUserData[]) => {
      const xlsxUri = await generateXlsx(userData);

      return {
        uri: xlsxUri,
      };
    },
  ),
  EXPORT_TO_CLIPBOARD: createAsyncThunk(
    'EXPORT_TO_CLIPBOARD',
    async (userData: IUserData[]) => {
      await exportToClipboard(userData);
    },
  ),
};

export const MailingListSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    TAB_CHANGE: (state) => {
      state.showFilter = '';
      state.list.selectedRowToExportUsers = undefined;
    },
    USER_BACK_TO_ORIGINAL_STATE: () => {
      return {
        ...initialState,
      };
    },
    USER_BACK_TO_ORIGINAL_STATE_PERSISTING_FILTER: (state) => {
      return {
        ...initialState,
        showFilter: state.showFilter,
        list: {
          ...state.list,
        },
      };
    },
    USERS_SET_ID_TO_EXPORT_USERS: (state, action) => {
      const payloadKeyId = action.payload.map((y: any) => y);
      const usersIdDataSource = state.list.idsInDatabase?.map((x) => x);

      if (usersIdDataSource) {
        const payloadKeyExist = payloadKeyId.filter((x: any) =>
          usersIdDataSource.includes(x),
        );

        state.list.exportManyUsersIds = payloadKeyExist;
      }
    },
    USERS_SET_SELECTED_ROWS: (state, action) => {
      const usersIdDataSource = state.list.idsInDatabase?.map((x) => x);
      const payloadKeyId = action.payload.map((y: any) => y.id);

      if (usersIdDataSource) {
        const payloadKeyExist = usersIdDataSource.filter((x) =>
          payloadKeyId.includes(x),
        );
        state.list.selectedRowToExportUsers = payloadKeyExist.length;
      }
    },
    DISABLE_DEFAULT_SORT_BEHAVIOUR: (state) => {
      state.list.userFirstQueryBehaviour = false;
    },
    FILTER_STATUS_CHANGE: (state, action) => {
      state.showFilter = action.payload;
    },
    FILTER_SET_NAME: (state, action) => {
      state.list.filter.filterByFirstName = action.payload;
    },
    FILTER_SET_LASTNAME: (state, action) => {
      state.list.filter.filterByLastName = action.payload;
    },
    FILTER_SET_EMAIL: (state, action) => {
      state.list.filter.filterByEmail = action.payload;
    },
    FILTER_SET_COMPANY: (state, action) => {
      state.list.filter.filterByCompany = action.payload;
    },
    FILTER_SET_COUNTRY: (state, action) => {
      state.list.filter.filterByCountry = action.payload;
    },
    FILTER_SET_LAST_LOGIN_INTERACTION: (state, action) => {
      state.list.filter.filterByLastInteraction = action.payload;
    },
    FILTER_SET_ACTIVE: (state, action) => {
      state.list.filter.filterByStatus = action.payload;
    },
    SET_FILTER_QUERY_STRING: (state, action) => {
      state.list.userQueryString = action.payload;
      state.list.shouldRefresh = true;
      state.list.pageIndex = initialState.list.pageIndex;
    },
    CLEAR_FILTER_FIELDS: (state) => {
      if (state.list.userQueryString.length) {
        state.list.pageIndex = initialState.list.pageIndex;
      }
      state.list.filter = initialState.list.filter;
      state.list.userQueryString = initialState.list.userQueryString;
      state.list.shouldRefresh = true;
    },
    USERS_CHANGE_PAGE: (state, action) => {
      state.list.pageIndex = action.payload;
    },
    USERS_SORT_BY: (state, action) => {
      state.list.shouldRefresh = true;
      state.list.sortBy = action.payload;
    },
    SET_SHOW_EXPORT_MODAL: (state, action) => {
      state.showExportModal = action.payload.isVisible;
      state.exportMode = action.payload.exportMode;
      state.exportUserCount = action.payload.userCount;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncActions.USERS_MAILING_LIST.pending, (state) => {
      state.list.tableLoading = true;
    }),
    builder.addCase(asyncActions.USERS_MAILING_LIST.rejected, (state) => {
      state.list.tableLoading = false;
    }),
    builder.addCase(
      asyncActions.USERS_MAILING_LIST.fulfilled,
      (state, action) => {
        state.list.tableLoading = false;
        state.list.numberOfItems = action.payload.totalElements;
        state.list.idsInDatabase = action.payload.idsInDatabase;
        state.list.data = action.payload.dataToList;
        state.list.shouldRefresh = false;

        if (
          !state.list.data.length &&
          state.list.pageIndex !== initialState.list.pageIndex
        ) {
          state.list.pageIndex = initialState.list.pageIndex;
          state.list.shouldRefresh = true;
        }

        const usersIdDataSource = state.list.idsInDatabase?.map((x) => x);
        if (usersIdDataSource) {
          const userExist = usersIdDataSource.filter((x) =>
            state.list.exportManyUsersIds?.includes(x),
          );

          if (
            userExist &&
            state.list.selectedRowToExportUsers &&
            state.list.exportManyUsersIds
          ) {
            state.list.selectedRowToExportUsers! = userExist!.length;
          }
        }
      },
    ),
    builder.addCase(asyncActions.GET_ALL_USERS.pending, (state) => {
      state.isLoadingAllUsers = true;
    }),
    builder.addCase(asyncActions.GET_ALL_USERS.rejected, (state) => {
      state.isLoadingAllUsers = false;
      state.users = undefined;
      messageNotification.errorMessage(
        t('administrative-panel-mailing-list-get-users-error-title'),
        t('administrative-panel-mailing-list-get-users-error-content'),
      );
    }),
    builder.addCase(asyncActions.GET_ALL_USERS.fulfilled, (state, action) => {
      state.users = action.payload.users;
      state.isLoadingAllUsers = false;
      state.showExportModal = true;
      state.isExporting = false;
      state.exportMode = ExportMode.All;
      state.exportUserCount = state.users.content.length;
    }),
    builder.addCase(asyncActions.GET_USER_BY_ID.rejected, (state) => {
      state.userExist = false;
    }),
    builder.addCase(asyncActions.GET_USERS_BY_ID.rejected, (state) => {
      state.users = undefined;
      messageNotification.errorMessage(
        t('administrative-panel-mailing-list-get-users-error-title'),
        t('administrative-panel-mailing-list-get-users-error-content'),
      );
    }),
    builder.addCase(asyncActions.GET_USERS_BY_ID.fulfilled, (state, action) => {
      state.users = action.payload.users;
      state.showExportModal = true;
      state.isExporting = false;
      state.exportMode = ExportMode.Some;
      state.exportUserCount = state.users.content.length;
    }),
    builder.addCase(asyncActions.EXPORT_CSV.pending, (state) => {
      state.exportType = ExportType.CSV;
      state.isExporting = true;
    }),
    builder.addCase(asyncActions.EXPORT_CSV.fulfilled, (state, action) => {
      let link = document.createElement('a');
      link.setAttribute('href', action.payload.encodeUri);
      link.setAttribute('download', 'mailing-list.csv');
      // Necessary for Firefox
      document.body.appendChild(link);
      link.click();

      state.showExportModal = false;
      state.isExporting = false;

      messageNotification.successMessage(
        t('administrative-panel-mailing-list-csv-successfuly-exported-title'),
        t('administrative-panel-mailing-list-csv-successfuly-exported-content'),
      );
    }),
    builder.addCase(asyncActions.EXPORT_XLSX.pending, (state) => {
      state.exportType = ExportType.XLSX;
      state.isExporting = true;
    }),
    builder.addCase(asyncActions.EXPORT_XLSX.fulfilled, (state, action) => {
      let link = document.createElement('a');
      link.setAttribute('href', action.payload.uri);
      link.setAttribute('download', 'mailing-list.xlsx');
      // Necessary for Firefox
      document.body.appendChild(link);
      link.click();

      state.showExportModal = false;
      state.isExporting = false;

      messageNotification.successMessage(
        t('administrative-panel-mailing-list-xlsx-successfuly-exported-title'),
        t(
          'administrative-panel-mailing-list-xlsx-successfuly-exported-content',
        ),
      );
    }),
    builder.addCase(asyncActions.EXPORT_TO_CLIPBOARD.pending, (state) => {
      state.exportType = ExportType.Clipboard;
      state.isExporting = true;
    }),
    builder.addCase(asyncActions.EXPORT_TO_CLIPBOARD.rejected, (state) => {
      state.isExporting = false;

      messageNotification.errorMessage(
        t('administrative-panel-mailing-list-clipboard-error-export-title'),
        t('administrative-panel-mailing-list-clipboard-error-export-content'),
      );
    }),
    builder.addCase(asyncActions.EXPORT_TO_CLIPBOARD.fulfilled, (state) => {
      state.showExportModal = false;
      state.isExporting = false;

      messageNotification.successMessage(
        t(
          'administrative-panel-mailing-list-clipboard-successfuly-exported-title',
        ),
        t(
          'administrative-panel-mailing-list-clipboard-successfuly-exported-content',
        ),
      );
    }),
  ],
});
