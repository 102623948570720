import { combineReducers } from 'redux';
import { enableMapSet } from 'immer';
import { configureStore } from '@reduxjs/toolkit';
import { RequestAccessSlice } from '../pages/RequestAccess/RequestAccess.Slice';
import { LoginSlice } from '../pages/Login/Login.Slice';
import { ForgotPasswordSlice } from '../pages/ForgotPassword/ForgotPassword.Slice';
import { NavbarSlice } from '../pages/.Container/Navbar/Navbar.Slice';
import { AdministrativePanelSlice } from '../pages/AdministrativePanel/AdministrativePanel.Slice';
import { CompanyAdministrativePanelSlice } from '../pages/AdministrativePanel/Companies/CompanyAdministrativePanel.Slice';
import { PageSlice } from '../pages/PagesList/Pages.Slice';
import { CreatePasswordSlice } from '../pages/CreatePassword/CreatePassword.Slice';
import { CreateReadEditPageSlice } from '../pages/PagesList/CreateReadEditPages/CreateReadEditPage.Slice';
import { UsersSlice } from '../pages/AdministrativePanel/Users/Users.Slice';
import { NewsSlice } from '../pages/AdministrativePanel/News/News.Slice';
import { ConcludedRequestsSlice } from '../pages/AdministrativePanel/Requests/ConcludedRequests/ConcludedRequests.Slice';
import { PendingRequestsSlice } from '../pages/AdministrativePanel/Requests/PendingRequests/PendingRequests.Slice';
import { RequestsSlice } from '../pages/AdministrativePanel/Requests/Requests.Slice';
import { RequestTrackerSlice } from '../pages/RequestTracker/RequestTracker.Slice';
import { NewTermsSlice } from '../pages/AdministrativePanel/Terms/NewTerms/NewTerms.Slice';
import { LogsListingSlice } from '../pages/AdministrativePanel/Logs/LogsListing/LogsListing.Slice';
import { RequestAccessTermsSlice } from '../pages/RequestAccess/StepsRequestAccess/Terms/Terms.Slice';
import { TermsListingSlice } from '../pages/AdministrativePanel/Terms/TermsListing/TermListing.Slice';
import { ModalToUserAcceptTermSlice } from '../pages/ModalToUserAcceptTerm/ModalToUserAcceptTerm.Slice';
import { CarouselSlice } from '../pages/Home/Carousel/Carousel.Slice';
import { ActivateUserByEmailSlice } from '../pages/ActivateUserByEmail/ActivateUserByEmail.Slice';
import { PermissionsListingSlice } from '../pages/AdministrativePanel/Permissions/PermissionsListing/PermissionsListing.Slice';
import { PermissionsByRepositoriesSlice } from '../pages/AdministrativePanel/Permissions/PermissionsByRepositories/PermissionsByRepositories.Slice';
import { PermissionsByCompaniesSlice } from '../pages/AdministrativePanel/Permissions/PermissionsByCompanies/PermissionsByCompanies.Slice';
import { MyProfileSlice } from '../pages/MyProfile/MyProfile.Slice';
import { PathTrackerSlice } from '../pages/PathTracker/PathTracker.Slice';
import { PopUpSlice } from '../pages/AdministrativePanel/PopUp/NewPopUp/PopUp.Slice';
import { ActiveModalSlice } from '../pages/Home/ActiveModal.Slice';
import { PopUpListingSlice } from '../pages/AdministrativePanel/PopUp/PopUpListing/PopUpListing.Slice';
import { MailingListSlice } from '../pages/AdministrativePanel/MailingList/MailingList.Slice';

enableMapSet();

const rootReducer = combineReducers({
  login: LoginSlice.reducer,
  navBar: NavbarSlice.reducer,
  forgotPassword: ForgotPasswordSlice.reducer,
  requestAccess: RequestAccessSlice.reducer,
  requestAccessTerms: RequestAccessTermsSlice.reducer,
  administrativePanel: AdministrativePanelSlice.reducer,
  CompanyAdministrativePanel: CompanyAdministrativePanelSlice.reducer,
  ConcludedRequests: ConcludedRequestsSlice.reducer,
  PendingRequests: PendingRequestsSlice.reducer,
  Requests: RequestsSlice.reducer,
  page: PageSlice.reducer,
  createPassword: CreatePasswordSlice.reducer,
  createReadEditPageSlice: CreateReadEditPageSlice.reducer,
  users: UsersSlice.reducer,
  news: NewsSlice.reducer,
  popUp: PopUpSlice.reducer,
  mailingList: MailingListSlice.reducer,
  activeModal: ActiveModalSlice.reducer,
  requestTracker: RequestTrackerSlice.reducer,
  createEditTerms: NewTermsSlice.reducer,
  termsListing: TermsListingSlice.reducer,
  logs: LogsListingSlice.reducer,
  modalToUserAcceptTerm: ModalToUserAcceptTermSlice.reducer,
  carousel: CarouselSlice.reducer,
  activateAccount: ActivateUserByEmailSlice.reducer,
  permissions: PermissionsListingSlice.reducer,
  permissionsByRepositories: PermissionsByRepositoriesSlice.reducer,
  permissionsByCompanies: PermissionsByCompaniesSlice.reducer,
  myProfile: MyProfileSlice.reducer,
  pathTracker: PathTrackerSlice.reducer,
  popUpListing: PopUpListingSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
