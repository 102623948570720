import { Button, Modal } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { ExportMode, ExportType } from '../MailingList.Slice';

interface IExportModal {
  showModal: boolean;
  exportMode: ExportMode;
  numberOfItemsSelected: number | undefined;
  exportType: ExportType;
  isExporting: boolean;
  onCancel: Function;
  onExportXlsx: Function;
  onExportCsv: Function;
  onExportToClipboard: Function;
}

export const ExportModal: React.FC<IExportModal> = ({
  showModal,
  exportMode,
  numberOfItemsSelected,
  exportType,
  isExporting,
  onCancel,
  onExportXlsx,
  onExportCsv,
  onExportToClipboard,
}: IExportModal) => {
  return (
    <Modal
      open={showModal}
      onCancel={() => onCancel()}
      onOk={() => {}}
      title={
        exportMode === ExportMode.All
          ? t('administrative-panel-mailing-list-export-modal-label-all-users')
          : t(
              'administrative-panel-mailing-list-export-modal-label-filtered-users',
            )
      }
      centered
      footer={[
        <Button key="cancel" onClick={() => onCancel()} disabled={isExporting}>
          {t(
            'administrative-panel-mailing-list-export-modal-cancel-button-label',
          )}
        </Button>,
        <Button
          key="clipboard"
          type="primary"
          onClick={() => onExportToClipboard()}
          loading={isExporting && exportType === ExportType.Clipboard}
          disabled={isExporting && exportType !== ExportType.Clipboard}
        >
          {t(
            'administrative-panel-mailing-list-export-modal-clipboard-button-label',
          )}
        </Button>,
        <Button
          key="xlsx"
          type="primary"
          onClick={() => onExportXlsx()}
          loading={isExporting && exportType === ExportType.XLSX}
          disabled={isExporting && exportType !== ExportType.XLSX}
        >
          {t(
            'administrative-panel-mailing-list-export-modal-xlsx-button-label',
          )}
        </Button>,
        <Button
          key="csv"
          type="primary"
          onClick={() => onExportCsv()}
          loading={isExporting && exportType === ExportType.CSV}
          disabled={isExporting && exportType !== ExportType.CSV}
        >
          {t('administrative-panel-mailing-list-export-modal-csv-button-label')}
        </Button>,
      ]}
    >
      <p>
        {t(
          'administrative-panel-mailing-list-export-modal-label-user-count-label',
        )}{' '}
        <b>{numberOfItemsSelected}</b>.
      </p>
      <p>{t('administrative-panel-mailing-list-export-modal-content')}</p>
    </Modal>
  );
};
