import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { administrativePanelPermissionsApi } from '../../../../services/administrative-panel-permissions';
import { IChangesInPermissions } from '../PermissionsListing/PermissionsListing.Slice';

interface IRepositoriesPermissions extends IChangesInPermissions {
  repositoriesFromCompany: undefined | number;
  selectedRepository: undefined | number;
  listing: IRepositoriesPermissionsListing;
  companiesPermissionsResponse: [];
  searchCompany: string;
}

interface IRepositoriesPermissionsListing {
  dataSource: any[];
  originalDataSource: any[];
  selectAllInViewIcon: undefined | boolean;
  selectAllInEditIcon: undefined | boolean;
  showCancelOrSaveChanges: boolean | undefined;
  refresh: boolean;
  tableLoading: boolean;
  pageSize: number;
  pageIndex: number;
  totalPages: number | undefined;
  totalNumberOfElements: number;
  numberOfExtraElements: number;
  filterInput: string;
  sortBy: string | undefined;
  defaultSortBehaviourActive: boolean;
}

const initialState: IRepositoriesPermissions = {
  repositoriesFromCompany: undefined,
  selectedRepository: undefined,
  listing: {
    dataSource: [],
    originalDataSource: [],
    selectAllInViewIcon: undefined,
    selectAllInEditIcon: undefined,
    showCancelOrSaveChanges: false,
    refresh: true,
    tableLoading: false,
    pageSize: 15,
    pageIndex: 1,
    totalPages: undefined,
    totalNumberOfElements: 0,
    numberOfExtraElements: 0,
    filterInput: '',
    sortBy: undefined,
    defaultSortBehaviourActive: true,
  },
  companiesPermissionsResponse: [],
  searchCompany: '',
  changes: {
    entity: 'repositories',
    cachedChanges: undefined,
    idsInDatabase: [],
    totalOfChanges: 0,
    alreadyHasChange: [],
    entitiesWithAddedViewRights: [],
    entitiesWithAddedEditRights: [],
    entitiesWithRemovedViewRights: [],
    entitiesWithRemovedEditRights: [],
  },
};

const cacheChanges = (state: IRepositoriesPermissions) => {
  if (state.changes.cachedChanges === undefined) {
    state.changes.cachedChanges = new Map<number, any>();
  }

  state.listing.dataSource.forEach((x) => {
    state.changes.cachedChanges?.set(x.id as number, x);
  });
};

const countChangesInRepositories = (state: IRepositoriesPermissions) => {
  const changedRepositories = new Set<number>();

  state.listing.dataSource.forEach((x) => {
    if (x.canRead !== x.originalCanRead || x.canWrite !== x.originalCanWrite) {
      changedRepositories.add(x.id);
    } else {
      changedRepositories.delete(x.id);
    }
  });

  const setOfChanges = {
    entitiesWithAddedViewRights: new Set<number>(),
    entitiesWithAddedEditRights: new Set<number>(),
    entitiesWithRemovedViewRights: new Set<number>(),
    entitiesWithRemovedEditRights: new Set<number>(),
  };

  state.listing.dataSource.forEach((x) => {
    if (x.canRead !== x.originalCanRead) {
      if (x.canRead) {
        setOfChanges.entitiesWithAddedViewRights.add(x.id);
      } else {
        setOfChanges.entitiesWithRemovedViewRights.add(x.id);
      }
    } else {
      if (setOfChanges.entitiesWithAddedViewRights.has(x.id)) {
        setOfChanges.entitiesWithAddedViewRights.delete(x.id);
      }

      if (setOfChanges.entitiesWithRemovedViewRights.has(x.id)) {
        setOfChanges.entitiesWithRemovedViewRights.delete(x.id);
      }
    }
  });

  state.listing.dataSource.forEach((x) => {
    if (x.canWrite !== x.originalCanWrite) {
      if (x.canWrite) {
        setOfChanges.entitiesWithAddedEditRights.add(x.id);
      } else {
        setOfChanges.entitiesWithRemovedEditRights.add(x.id);
      }
    } else {
      if (setOfChanges.entitiesWithAddedEditRights.has(x.id)) {
        setOfChanges.entitiesWithAddedEditRights.delete(x.id);
      }

      if (setOfChanges.entitiesWithRemovedEditRights.has(x.id)) {
        setOfChanges.entitiesWithRemovedEditRights.delete(x.id);
      }
    }
  });

  state.changes.totalOfChanges = changedRepositories.size;
  state.changes.entitiesWithAddedViewRights = Array.from(
    setOfChanges.entitiesWithAddedViewRights,
  );
  state.changes.entitiesWithAddedEditRights = Array.from(
    setOfChanges.entitiesWithAddedEditRights,
  );
  state.changes.entitiesWithRemovedViewRights = Array.from(
    setOfChanges.entitiesWithRemovedViewRights,
  );
  state.changes.entitiesWithRemovedEditRights = Array.from(
    setOfChanges.entitiesWithRemovedEditRights,
  );

  if (state.changes.totalOfChanges > 0) {
    state.listing.showCancelOrSaveChanges = true;
  } else state.listing.showCancelOrSaveChanges = false;
};

export const permissionsByRepositoriesAsyncActions = {
  GET_REPOSITORIES_FOR_PERMISSIONS: createAsyncThunk(
    'GET_REPOSITORIES_FOR_PERMISSIONS',
    async (queryParams: string) => {
      const repositoriesList =
        await administrativePanelPermissionsApi.getCompaniesOrRepositoriesWithAccessCount(
          queryParams,
        );

      return repositoriesList.data;
    },
  ),
  GET_PERMISSIONS_FOR_REPOSITORIES: createAsyncThunk(
    'GET_PERMISSIONS_FOR_REPOSITORIES',
    async (queryParams: string) => {
      const permissionsList =
        await administrativePanelPermissionsApi.getCompaniesOrRepositoriesWithViewOrWrite(
          queryParams,
        );

      permissionsList.data.content = permissionsList.data.content.map((x) => ({
        ...x,
        originalCanRead: x.canRead,
        originalCanWrite: x.canWrite,
      }));

      return permissionsList.data;
    },
  ),
};

export const PermissionsByRepositoriesSlice = createSlice({
  name: 'PermissionsByRepositoriesSlice',
  initialState,
  reducers: {
    REPOSITORIES_TABLE_CHANGE_PAGE: (state, action) => {
      state.listing.pageIndex = action.payload;
      state.listing.refresh = true;
    },
    REFRESH_TABLE_AND_RESET_CHANGES: (state) => {
      state.listing.refresh = true;
      state.listing.showCancelOrSaveChanges =
        initialState.listing.showCancelOrSaveChanges;
      state.changes = initialState.changes;
      state.listing.selectAllInViewIcon = undefined;
      state.listing.selectAllInEditIcon = undefined;
      state.listing.filterInput = initialState.listing.filterInput;
    },
    SET_FILTER_INPUT: (state, action) => {
      state.listing.refresh = true;
      state.listing.filterInput = action.payload;
      state.listing.pageIndex = 1;
    },
    SET_REPOSITORIES_FROM_COMPANY: (state, action) => {
      state.repositoriesFromCompany = action.payload;
      state.changes = initialState.changes;
      state.listing.refresh = true;
    },
    SET_SELECTED_REPOSITORY: (state, action) => {
      state.selectedRepository = action.payload;
    },
    SET_SORT_BY_IN_REPOSITORIES_PERMISSIONS: (state, action) => {
      state.listing.sortBy = action.payload;
      state.listing.refresh = true;
    },
    PERMISSIONS_BY_REPOSITORIES_DISABLE_DEFAULT_SORT_BEHAVIOUR: (state) => {
      state.listing.defaultSortBehaviourActive = false;
    },
    SHOW_CANCEL_OR_SAVE_BUTTON: (state, action) => {
      state.listing.showCancelOrSaveChanges = action.payload;
    },
    REPOSITORIES_TABLE_TOGGLE_VIEW_RIGHTS: (state, action) => {
      const selectedRepository = state.listing.dataSource.find(
        (x) => x.id === action.payload,
      );

      if (selectedRepository) {
        selectedRepository.canRead = !selectedRepository.canRead;

        if (!selectedRepository.canRead && selectedRepository.canWrite) {
          selectedRepository.canWrite = false;
        }
      }

      countChangesInRepositories(state);
      cacheChanges(state);
    },
    REPOSITORIES_TABLE_TOGGLE_EDIT_RIGHTS: (state, action) => {
      const selectedRepository = state.listing.dataSource.find(
        (x) => x.id === action.payload,
      );

      if (selectedRepository) {
        selectedRepository.canWrite = !selectedRepository.canWrite;

        if (selectedRepository.canWrite && !selectedRepository.canRead) {
          selectedRepository.canRead = true;
        }
      }

      countChangesInRepositories(state);
      cacheChanges(state);
    },
    REPOSITORIES_TABLE_SET_EDIT_RIGHTS_TO_ALL: (state) => {
      state.listing.selectAllInEditIcon =
        state.listing.selectAllInEditIcon === undefined
          ? true
          : !state.listing.selectAllInEditIcon;

      if (state.listing.selectAllInEditIcon === true) {
        state.listing.selectAllInViewIcon = true;
      }

      state.listing.dataSource = state.listing.dataSource.map((x: any) => {
        return {
          ...x,
          canRead: state.listing.selectAllInViewIcon,
          canWrite: state.listing.selectAllInEditIcon,
        };
      });

      countChangesInRepositories(state);
      cacheChanges(state);
    },
    REPOSITORIES_TABLE_SET_VIEW_RIGHTS_TO_ALL: (state) => {
      state.listing.selectAllInViewIcon =
        state.listing.selectAllInViewIcon === undefined
          ? true
          : !state.listing.selectAllInViewIcon;

      if (state.listing.selectAllInViewIcon === false) {
        state.listing.selectAllInEditIcon = false;
      }

      state.listing.dataSource = state.listing.dataSource.map((x: any) => {
        return {
          ...x,
          canRead: state.listing.selectAllInViewIcon,
          canWrite:
            state.listing.selectAllInEditIcon === false
              ? state.listing.selectAllInEditIcon
              : x.canWrite,
        };
      });

      countChangesInRepositories(state);
      cacheChanges(state);
    },
    RESET_TO_ORIGINAL_STATE: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(
      permissionsByRepositoriesAsyncActions.GET_REPOSITORIES_FOR_PERMISSIONS
        .pending,
      (state) => {
        state.listing.tableLoading = true;
      },
    ),
    builder.addCase(
      permissionsByRepositoriesAsyncActions.GET_REPOSITORIES_FOR_PERMISSIONS
        .fulfilled,
      (state, action) => {
        const payloadResponse = action.payload;
        const originalDataSource = payloadResponse.content.map((x) => x);

        state.listing.dataSource = originalDataSource;
        state.listing.tableLoading = false;
        state.listing.originalDataSource = originalDataSource;
        state.listing.refresh = false;
        state.listing.totalNumberOfElements = payloadResponse.totalElements;
      },
    ),
    builder.addCase(
      permissionsByRepositoriesAsyncActions.GET_REPOSITORIES_FOR_PERMISSIONS
        .rejected,
      (state) => {
        state.listing.tableLoading = false;
        state.listing.refresh = false;
      },
    ),

    builder.addCase(
      permissionsByRepositoriesAsyncActions.GET_PERMISSIONS_FOR_REPOSITORIES
        .pending,
      (state) => {
        state.listing.tableLoading = true;
      },
    ),
    builder.addCase(
      permissionsByRepositoriesAsyncActions.GET_PERMISSIONS_FOR_REPOSITORIES
        .fulfilled,
      (state, action) => {
        const payloadResponse = action.payload;

        state.changes.idsInDatabase = payloadResponse.idsInDatabase;

        let dataSourceListing = payloadResponse.content;

        if (state.listing.selectAllInViewIcon !== undefined) {
          dataSourceListing = dataSourceListing.map((x) => ({
            ...x,
            canRead: state.listing.selectAllInViewIcon as boolean,
          }));
        }

        if (state.listing.selectAllInEditIcon !== undefined) {
          dataSourceListing = dataSourceListing.map((x) => ({
            ...x,
            canWrite: state.listing.selectAllInEditIcon as boolean,
            canRead: state.listing.selectAllInEditIcon as boolean,
          }));
        }

        if (state.changes.cachedChanges) {
          dataSourceListing = dataSourceListing.map((x) => {
            const cachedData = state.changes.cachedChanges?.get(x.id);
            if (cachedData) {
              return cachedData;
            }

            return x;
          });
        }

        state.listing.dataSource = dataSourceListing;

        state.listing.tableLoading = false;
        state.listing.refresh = false;

        if (state.listing.filterInput.length > 0) {
          state.listing.totalNumberOfElements = payloadResponse.totalElements;
          state.listing.numberOfExtraElements =
            initialState.listing.numberOfExtraElements;
        } else if (!state.listing.filterInput.length) {
          state.listing.totalNumberOfElements =
            payloadResponse.totalElements + state.listing.numberOfExtraElements;
          state.listing.numberOfExtraElements = Math.ceil(
            payloadResponse.totalElements / 14,
          );
          state.listing.totalNumberOfElements =
            payloadResponse.totalElements + state.listing.numberOfExtraElements;

          state.listing.totalPages = Math.floor(
            state.listing.numberOfExtraElements +
              state.listing.numberOfExtraElements / 14,
          );
        }
      },
    ),
    builder.addCase(
      permissionsByRepositoriesAsyncActions.GET_PERMISSIONS_FOR_REPOSITORIES
        .rejected,
      (state) => {
        state.listing.tableLoading = false;
      },
    ),
  ],
});
