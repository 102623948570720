import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill-new';

import { RootState } from '../../../../../store/store';

import './TermsEditor.scss';
import 'react-quill-new/dist/quill.snow.css';

interface ITermsEditor {
  editorRef: React.MutableRefObject<any>;
}

export const TermsEditor: React.FC<ITermsEditor> = ({
  editorRef,
}: ITermsEditor) => {
  const termLanguage = useSelector(
    (state: RootState) => state.createEditTerms.language.selectedLanguage,
  );
  const termsValuesInLanguages = useSelector(
    (state: RootState) => state.createEditTerms.form,
  );

  const getEditorDefaultValue = (): string => {
    switch (termLanguage) {
      case 'pt':
        editorRef.current = termsValuesInLanguages.ptContent;
        return termsValuesInLanguages.ptContent;
      case 'en':
        editorRef.current = termsValuesInLanguages.enContent;
        return termsValuesInLanguages.enContent;
      case 'es':
        editorRef.current = termsValuesInLanguages.esContent;
        return termsValuesInLanguages.esContent;

      default:
        return '';
    }
  };

  const colors = [
    '#000000',
    '#FFFFFF',
    '#4A4F55',
    '#41B4D2',
    '#F08791',
    '#F5AF82',
    '#46B8A6',
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [{ size: [] }, { background: colors }, { color: colors }],
      [{ align: [] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'bullet' }, { list: 'ordered' }],
    ],
  };

  const formats = [
    'header',
    'size',
    'background',
    'color',
    'align',
    'bold',
    'italic',
    'underline',
    'list',
  ];

  return (
    <Row className="TermsEditor" id="editor-content">
      <Col span={24}>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={getEditorDefaultValue()}
          onChange={(content) => {
            editorRef.current = content;
          }}
          style={{ height: 500 }}
        />
      </Col>
    </Row>
  );
};
