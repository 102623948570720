import { Col } from 'antd';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  IDefaultSortBehaviour,
  PagingTableSearch,
} from '../../../../components/PagingTableWithSearch/PagingTableSearch';
import { scrollToTopFunction } from '../../../../helpers/useScrollToTop/useScrollToTop';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  permissionsByRepositoriesAsyncActions,
  PermissionsByRepositoriesSlice,
} from './PermissionsByRepositories.Slice';
import { PermissionsByCompaniesSlice } from '../PermissionsByCompanies/PermissionsByCompanies.Slice';
import { showModal } from '../../../../helpers/showModal/showModal';

export const PermissionsByRepositories = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const permissionsListingState = useSelector(
    (state: RootState) => state.permissions,
  );
  const permissionsListingTables = useSelector(
    (state: RootState) => state.permissions.permissionTables,
  );

  const permissionsByRepositoriesState = useSelector(
    (state: RootState) => state.permissionsByRepositories,
  );

  const showCancelOrSaveChangesInCompanies = useSelector(
    (state: RootState) =>
      state.permissionsByCompanies.listing.showCancelOrSaveChanges,
  );

  const { actions } = PermissionsByRepositoriesSlice;

  useEffect(() => {
    // useEffect to Load PermissionsByRepositories Table on Left of Permissions
    if (permissionsListingState.shouldLoadOnLeft !== 'repositories') {
      return;
    }

    if (!permissionsByRepositoriesState.listing.refresh) {
      return;
    }

    if (permissionsByRepositoriesState.listing.tableLoading) {
      return;
    }

    let queryParams = `ARTIFACT&page=${
      permissionsByRepositoriesState.listing.pageIndex - 1
    }&size=${permissionsByRepositoriesState.listing.pageSize}`;

    if (permissionsByRepositoriesState.listing.filterInput !== '') {
      queryParams += `&entityName=${permissionsByRepositoriesState.listing.filterInput}`;
    }

    if (permissionsByRepositoriesState.listing.sortBy) {
      queryParams = `${queryParams}&${permissionsByRepositoriesState.listing.sortBy}`;
    }

    dispatch(
      permissionsByRepositoriesAsyncActions.GET_REPOSITORIES_FOR_PERMISSIONS(
        queryParams,
      ),
    );
  }, [
    dispatch,
    permissionsListingState.shouldLoadOnLeft,
    permissionsByRepositoriesState.listing.refresh,
    permissionsByRepositoriesState.listing.tableLoading,
    permissionsByRepositoriesState.listing.filterInput,
    permissionsByRepositoriesState.listing.sortBy,
    permissionsByRepositoriesState.listing.pageIndex,
    permissionsByRepositoriesState.listing.pageSize,
  ]);

  useEffect(() => {
    // useEffect to Load PermissionsByRepositories Table on Right of Permissions
    if (!permissionsByRepositoriesState.repositoriesFromCompany) {
      return;
    }

    if (!permissionsByRepositoriesState.listing.refresh) {
      return;
    }

    if (
      permissionsListingState.permissionTables[1].tableType !== 'repositories'
    ) {
      return;
    }

    let queryParams = `COMPANY&page=0&size=20000&relativeId=${permissionsByRepositoriesState.repositoriesFromCompany}`;

    if (permissionsByRepositoriesState.listing.filterInput !== '') {
      queryParams += `&entityName=${permissionsByRepositoriesState.listing.filterInput}`;
    }

    if (permissionsByRepositoriesState.listing.sortBy) {
      queryParams = `${queryParams}&${permissionsByRepositoriesState.listing.sortBy}`;
    }

    queryParams += `&relativeId=${permissionsByRepositoriesState.repositoriesFromCompany}`;
    dispatch(
      permissionsByRepositoriesAsyncActions.GET_PERMISSIONS_FOR_REPOSITORIES(
        queryParams,
      ),
    );
  }, [
    dispatch,
    permissionsByRepositoriesState.repositoriesFromCompany,
    permissionsListingState.permissionTables,
    permissionsByRepositoriesState.listing.filterInput,
    permissionsByRepositoriesState.listing.sortBy,
    permissionsByRepositoriesState.listing.refresh,
  ]);

  const permissionToEdit = (record: any) => {
    if (
      record.name ===
      t('administrative-panel-permissions-repositories-first-row')
    ) {
      dispatch(actions.REPOSITORIES_TABLE_SET_EDIT_RIGHTS_TO_ALL());
    } else dispatch(actions.REPOSITORIES_TABLE_TOGGLE_EDIT_RIGHTS(record.id));
  };

  const permissionToView = (record: any) => {
    if (
      record.name ===
      t('administrative-panel-permissions-repositories-first-row')
    ) {
      dispatch(actions.REPOSITORIES_TABLE_SET_VIEW_RIGHTS_TO_ALL());
    } else dispatch(actions.REPOSITORIES_TABLE_TOGGLE_VIEW_RIGHTS(record.id));
  };

  const columnsCursorForAccess = [
    {
      columnTitle: t(
        'administrative-panel-permissions-table-col-repository-name',
      ),
      columnProperty: 'name',
    },
    {
      columnTitle: t('administrative-panel-permissions-table-col-access'),
      columnProperty: 'accessCount',
      disableSort: true,
    },
  ];

  const columnsCursorForEditAndView = [
    {
      columnTitle: t(
        'administrative-panel-permissions-table-col-repository-name',
      ),
      columnProperty: 'name',
    },
    {
      columnTitle: t('administrative-panel-permissions-table-col-view'),
      columnProperty: 'canRead',
      columnAction: (record: any) => permissionToView(record),
    },
    {
      columnTitle: t('administrative-panel-permissions-table-col-edit'),
      columnProperty: 'canWrite',
      columnAction: (record: any) => permissionToEdit(record),
    },
  ];

  const showAccessColumnOrEditAndViewColumns = () => {
    if (
      permissionsListingState.permissionTables[0].tableType === 'repositories'
    ) {
      return columnsCursorForAccess;
    }
    return columnsCursorForEditAndView;
  };

  const onChangePage = (pageIndex: number) => {
    if (pageIndex === permissionsByRepositoriesState.listing.pageIndex) {
      return;
    }
    scrollToTopFunction('toggle-view-button');
    dispatch(actions.REPOSITORIES_TABLE_CHANGE_PAGE(pageIndex));
  };

  const tableColSpan = () => {
    if (
      permissionsListingState.permissionTables[0].tableType === 'repositories'
    ) {
      return 10;
    }
    return 14;
  };

  const getNewDataSource = () => {
    const { dataSource, pageSize, pageIndex } =
      permissionsByRepositoriesState.listing;

    const firstRow = [
      {
        name: t('administrative-panel-permissions-repositories-first-row'),
        firstRow: true,
        canRead: permissionsByRepositoriesState.listing.selectAllInViewIcon,
        canWrite: permissionsByRepositoriesState.listing.selectAllInEditIcon,
        permissionUnselectedViewText: t(
          'administrative-panel-permissions-repositories-tooltip-alert-enable-views',
        ),
        permissionSelectedEditText: t(
          'administrative-panel-permissions-repositories-tooltip-alert-disable-edits',
        ),
        permissionSelectedViewText: t(
          'administrative-panel-permissions-repositories-tooltip-alert-disable-views',
        ),
        permissionUnselectedEditText: t(
          'administrative-panel-permissions-repositories-tooltip-alert-enable-edits',
        ),
      },
    ];

    let toBePagedDataSource = dataSource;

    if (
      permissionsListingTables[1].tableType === 'repositories' &&
      dataSource.length > 0
    ) {
      toBePagedDataSource = toBePagedDataSource.slice(
        pageIndex === 1 ? 0 : (pageIndex - 1) * (pageSize - 1),
        pageIndex * (pageSize - 1),
      );

      if (!permissionsByRepositoriesState.listing.filterInput.length) {
        toBePagedDataSource = firstRow.concat(toBePagedDataSource);
      }
    }

    return toBePagedDataSource;
  };

  const onSearchInputChange = _.debounce((searchParam: string) => {
    dispatch(actions.SET_FILTER_INPUT(searchParam.trim().toLowerCase()));
  }, 500);

  const modalToCancelChanges = (record: any) => {
    showModal(
      'warning',
      t('administrative-panel-permissions-cancel-button-modal-title'),
      t('administrative-panel-permissions-cancel-button-modal-message'),
      () => {
        dispatch(
          PermissionsByCompaniesSlice.actions.SET_COMPANIES_FROM_REPOSITORY(
            record.id as number,
          ),
        );

        dispatch(
          PermissionsByRepositoriesSlice.actions.SET_SELECTED_REPOSITORY(
            record.id as number,
          ),
        );

        dispatch(
          PermissionsByCompaniesSlice.actions.REFRESH_TABLE_AND_RESET_CHANGES(),
        );
      },
      () => {},
      t('administrative-panel-permissions-cancel-button-modal-accept'),
      t('administrative-panel-permissions-cancel-button-modal-refuse'),
    );
  };

  const onClickPermissionsByRepositoriesRow = (record: any) => {
    if (
      permissionsListingState.permissionTables[1].tableType === 'repositories'
    ) {
      return;
    }

    if (showCancelOrSaveChangesInCompanies) {
      return modalToCancelChanges(record);
    }

    dispatch(
      PermissionsByCompaniesSlice.actions.SET_COMPANIES_FROM_REPOSITORY(
        record.id as number,
      ),
    );

    dispatch(
      PermissionsByRepositoriesSlice.actions.SET_SELECTED_REPOSITORY(
        record.id as number,
      ),
    );
    dispatch(
      PermissionsByCompaniesSlice.actions.REFRESH_TABLE_AND_RESET_CHANGES(),
    );
  };

  const defaultSortBehaviour: IDefaultSortBehaviour = {
    columnProperty: 'name',
    order: 'ascend',
    disableDefaultSortBehaviour: () => {
      if (permissionsByRepositoriesState.listing.defaultSortBehaviourActive) {
        dispatch(
          PermissionsByRepositoriesSlice.actions.PERMISSIONS_BY_REPOSITORIES_DISABLE_DEFAULT_SORT_BEHAVIOUR(),
        );
      }
    },
  };

  const dispatchSortBy = (sortBy: string) => {
    dispatch(
      PermissionsByRepositoriesSlice.actions.SET_SORT_BY_IN_REPOSITORIES_PERMISSIONS(
        sortBy ? `sort=${sortBy}` : '',
      ),
    );
  };

  const onChangeSort = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    let sortBy = '';

    if (!order || !field) {
      return dispatchSortBy(sortBy);
    }

    if (defaultSortBehaviour) {
      defaultSortBehaviour.disableDefaultSortBehaviour();
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;

    switch (fieldToQueryParam) {
      case 'name':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      case 'canRead':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},desc`
            : `${fieldToQueryParam}`;
        return dispatchSortBy(sortBy);

      case 'canWrite':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},desc`
            : `${fieldToQueryParam}`;
        return dispatchSortBy(sortBy);

      default:
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);
    }
  };

  return (
    <Col span={tableColSpan()} className="repositories-table-container">
      <div className="repositories-table">
        <PagingTableSearch
          isFirstTable={
            permissionsListingState.permissionTables[0].tableType ===
            'repositories'
          }
          tableEntityLabel={t(
            'administrative-panel-permissions-repositories-table-label',
          )}
          onTableSearch={onSearchInputChange}
          searchInputValue={permissionsByRepositoriesState.listing.filterInput}
          dataSource={getNewDataSource()}
          tableLoading={permissionsByRepositoriesState.listing.tableLoading}
          columns={showAccessColumnOrEditAndViewColumns()}
          pageSize={permissionsByRepositoriesState.listing.pageSize}
          pageIndex={permissionsByRepositoriesState.listing.pageIndex}
          totalPages={permissionsByRepositoriesState.listing.totalPages}
          numberOfItems={
            permissionsByRepositoriesState.listing.totalNumberOfElements
          }
          numberOfExtraItems={
            permissionsByRepositoriesState.listing.numberOfExtraElements
          }
          onChangePage={onChangePage}
          onChangeSort={onChangeSort}
          rowClickAction={onClickPermissionsByRepositoriesRow}
          emptyText={t('permissions-listing-search-not-found-title')}
          disableTable={
            permissionsListingState.permissionTables[1].tableType ===
              'repositories' &&
            !permissionsByRepositoriesState.repositoriesFromCompany
          }
          selectedRow={permissionsByRepositoriesState.selectedRepository}
        />
      </div>
    </Col>
  );
};
