import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { messageNotification } from '../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../helpers/unhookedTranslation/unhookedTranslation';
import {
  myProfileApi,
  IChangePasswordRequest,
  IPersonalDataRequest,
} from '../../services/my-profile.api';

export interface IMyProfileSlice {
  showChangePasswordForm: boolean;
  showEditModeInPersonalForm: boolean;
  refreshPersonalData: boolean;
  nameInForm: string | undefined;
  lastNameInForm: string | undefined;

  cardValidationStyleLowerCase: string;
  cardValidationStyleUpperCase: string;
  cardValidationStyleNumber: string;
  cardValidationStyleLength: string;
  passwordValidation: boolean;
}

const initialState: IMyProfileSlice = {
  showChangePasswordForm: false,
  showEditModeInPersonalForm: false,
  refreshPersonalData: false,
  nameInForm: undefined,
  lastNameInForm: undefined,

  cardValidationStyleLowerCase: 'invalid',
  cardValidationStyleUpperCase: 'invalid',
  cardValidationStyleNumber: 'invalid',
  cardValidationStyleLength: 'invalid',
  passwordValidation: false,
};
export const asyncActionsMyProfile = {
  CHANGE_PASSWORD: createAsyncThunk(
    'CHANGE_PASSWORD',
    async (payload: IChangePasswordRequest) => {
      await myProfileApi.changePassword(payload);
    },
  ),
  SET_NEW_PERSONAL_DATA: createAsyncThunk(
    'SET_NEW_PERSONAL_DATA',
    async (newPersonalData: IPersonalDataRequest) => {
      await myProfileApi.setNewPersonalData(newPersonalData);
    },
  ),
};

export const MyProfileSlice = createSlice({
  name: 'myProfileSlice',
  initialState,
  reducers: {
    RESET_CHANGES_FORM: () => {
      return initialState;
    },
    SHOW_CHANGE_PASSWORD_FORM: (state) => {
      state.showChangePasswordForm = !state.showChangePasswordForm;
    },
    SHOW_EDIT_MODE_IN_PERSONAL_FORM: (state) => {
      state.showEditModeInPersonalForm = !state.showEditModeInPersonalForm;
      if (!state.showEditModeInPersonalForm) {
        state.nameInForm = initialState.nameInForm;
        state.nameInForm = initialState.lastNameInForm;
      }
    },
    SET_PASSWORD_VALIDATION: (state, action) => {
      state.passwordValidation = action.payload;
    },
    SET_LOWER_CASE_VALIDATION: (state, action) => {
      state.cardValidationStyleLowerCase = action.payload;
    },
    SET_UPPER_CASE_VALIDATION: (state, action) => {
      state.cardValidationStyleUpperCase = action.payload;
    },
    SET_NUMBER_VALIDATION: (state, action) => {
      state.cardValidationStyleNumber = action.payload;
    },
    SET_LENGTH_VALIDATION: (state, action) => {
      state.cardValidationStyleLength = action.payload;
    },
    STOP_REFRESH_DATA: (state) => {
      state.refreshPersonalData = false;
    },
    SET_NAME_AND_LASTNAME_FOR_USER: (state, action) => {
      state.nameInForm = action.payload.editedName;
      state.lastNameInForm = action.payload.editedLastName;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(
      asyncActionsMyProfile.CHANGE_PASSWORD.fulfilled,
      (state) => {
        state.showChangePasswordForm = initialState.showChangePasswordForm;
        messageNotification.successMessage(
          unhookedTranslation('my-profile-change-password-response-title'),
          unhookedTranslation('my-profile-change-password-response-content'),
        );
      },
    ),
    builder.addCase(
      asyncActionsMyProfile.SET_NEW_PERSONAL_DATA.fulfilled,
      (state) => {
        state.showEditModeInPersonalForm =
          initialState.showEditModeInPersonalForm;
        state.refreshPersonalData = true;
        state.nameInForm = initialState.nameInForm;
        state.lastNameInForm = initialState.lastNameInForm;

        messageNotification.successMessage(
          unhookedTranslation('my-profile-change-personal-data-response-title'),
          unhookedTranslation(
            'my-profile-change-personal-data-response-content',
          ),
        );
      },
    ),
  ],
});
