import { Row, Col, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EyeFilled } from '@ant-design/icons';
import { Filter } from '../../../../components/Filter/Filter';
import { PagingTable } from '../../../../components/PagingTable/PagingTable';
import { ShowFiltersButton } from '../../../../components/ShowFiltersButton/ShowFiltersButton';
import { scrollToTopFunction } from '../../../../helpers/useScrollToTop/useScrollToTop';
import { AppDispatch, RootState } from '../../../../store/store';

import { ReactComponent as EditPencil } from '../../../../assets/popover-icons/edit.svg';
import { ReactComponent as Padlock } from '../../../../assets/popover-icons/padlock.svg';
import { ReactComponent as TrashCan } from '../../../../assets/popover-icons/trash-can.svg';

import './PopUpListing.scss';
import {
  PopUpListingAsyncActions,
  PopUpListingSlice,
} from './PopUpListing.Slice';
import { PreviewPopUp } from './PreviewPopUp/PreviewPopUp';
import { showModal } from '../../../../helpers/showModal/showModal';
import { getEditorToPreview } from '../../../../helpers/getEditorToPreview/getEditorToPreview';

export const PopUpListing: React.FC = () => {
  const popUpListingState = useSelector(
    (state: RootState) => state.popUpListing.listing,
  );
  const popUpListingFilterState = useSelector(
    (state: RootState) => state.popUpListing.filter,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(PopUpListingSlice.actions.RESET_TO_ORIGINAL_STATE());
    };
  }, [dispatch]);

  useEffect(() => {
    if (popUpListingState.tableLoading) {
      return;
    }

    if (!popUpListingState.refresh) {
      return;
    }

    let queryString = `?page=${popUpListingState.pageIndex - 1}&size=${
      popUpListingState.pageSize
    }`;

    if (popUpListingState.filterQueryString) {
      queryString += `${popUpListingState.filterQueryString}`;
    }

    if (popUpListingState.defaultSortBehaviourActive) {
      queryString += `&sort=active,desc`;
    }

    if (popUpListingState.orderQueryString) {
      queryString += `${popUpListingState.orderQueryString}`;
    }

    dispatch(PopUpListingAsyncActions.GET_POP_UP_LIST(queryString));
  }, [
    dispatch,
    popUpListingState.refresh,
    popUpListingState.tableLoading,
    popUpListingState.pageIndex,
    popUpListingState.pageSize,
    popUpListingState.filterQueryString,
    popUpListingState.orderQueryString,
    popUpListingState.defaultSortBehaviourActive,
  ]);

  const [columnsWidth, setColumnsWidth] = useState({
    versionColumnWidth: 4.2,
  });

  const resizeHandlerSpeed = 0.011;
  const resizeColumns = (
    columnName: 'versionColumnWidth',
    movement: number,
  ) => {
    setColumnsWidth({
      ...columnsWidth,
      [columnName]: (columnsWidth[columnName] += movement * resizeHandlerSpeed),
    });
  };
  const popUpColumnCursor = [
    {
      disableSort: true,
      columnTitle: t('administrative-panel-pop-up-header-placeholder'),
      columnProperty: 'titleLanguage',
      width: columnsWidth.versionColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('versionColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-pop-up-last-modified'),
      columnProperty: 'updatedAt',
      width: columnsWidth.versionColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('versionColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-pop-up-modified-by'),
      columnProperty: 'modifiedBy',
      width: columnsWidth.versionColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('versionColumnWidth', event.movementX);
      },
    },
  ];

  const popUpColumnOptions = [
    {
      optionName: t('administrative-panel-pop-up-table-column-preview'),
      optionIcon: (
        <EyeFilled
          style={{ color: '#41B4D2', margin: '0', marginBottom: '-0.2rem' }}
        />
      ),
      optionOnClick: (popUpId: any, popUpItem: any) => {
        dispatch(PopUpListingSlice.actions.OPEN_PREVIEW_POP_UP(popUpItem));
      },
    },
    {
      optionName: t('administrative-panel-pop-up-table-column-edit-option'),
      optionIcon: <EditPencil />,
      optionOnClick: (popUpId: number) => {
        navigate(`/administrative-panel/add_or_edit?tab=pop-up&id=${popUpId}`);
      },
    },
    {
      optionId: 'activate-or-deactivate-pop-up',
      optionName: t(
        'administrative-panel-pop-up-table-column-activate-deactivate',
      ),
      optionIcon: <Padlock />,
      optionOnClick: (popUpId: number, popUpItem: any) => {
        showModal(
          'warning',
          `${t(
            popUpItem.active
              ? 'pop-up-modal-deactivate-modal-title'
              : 'pop-up-modal-activate-modal-title',
          )}`,
          ``,
          () => {
            if (popUpItem.active) {
              dispatch(PopUpListingAsyncActions.DEACTIVATE_POP_UP(popUpId));
            } else {
              dispatch(PopUpListingAsyncActions.ACTIVATE_POP_UP(popUpId));
            }
          },
          () => {},
          `${t(
            popUpItem.active
              ? 'pop-up-modal-deactivate-button'
              : 'pop-up-modal-activate-button',
          )}`,
          `${t('administrative-panel-cancel-button')}`,
        );
      },
    },
    {
      optionName: t('modal-delete-ok-button'),
      optionIcon: <TrashCan />,
      optionOnClick: (popUpId: number) => {
        showModal(
          'warning',
          t('pop-up-modal-delete-pop-up-title'),
          t('pop-up-modal-delete-pop-up-content'),
          () => {
            dispatch(PopUpListingAsyncActions.DELETE_POP_UP(popUpId));
          },
          () => {},
          t('modal-delete-ok-button'),
          t('modal-delete-cancel-button'),
        );
      },
    },
  ];

  const onChangePage = (pageIndex: number) => {
    if (!pageIndex || pageIndex === popUpListingState.pageIndex) {
      return;
    }

    dispatch(PopUpListingSlice.actions.CHANGE_PAGE(pageIndex));
    scrollToTopFunction();
  };

  const dispatchSortBy = (sortBy: string) => {
    dispatch(PopUpListingSlice.actions.ORDER_SET_QUERY_STRING(sortBy));
  };

  const onChangeSort = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    if (!order || !field) {
      return dispatchSortBy('');
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;

    let sortBy = '&sort=';

    if (field === 'active' || field === 'id' || field === 'updatedAt') {
      sortBy +=
        order === 'ascend'
          ? `${fieldToQueryParam}`
          : `${fieldToQueryParam},desc`;

      return dispatchSortBy(sortBy);
    }

    sortBy +=
      order === 'ascend'
        ? `${fieldToQueryParam},ignoreCase`
        : `${fieldToQueryParam},desc,ignoreCase`;

    dispatchSortBy(sortBy);
  };

  const toggleFilter = () => {
    dispatch(PopUpListingSlice.actions.TOGGLE_FILTER());
  };

  const clearFilterFields = () => {
    dispatch(PopUpListingSlice.actions.FILTER_CLEAR_FIELDS());
  };

  const popUpFilterCursor = [
    {
      fieldTitle: t('administrative-panel-pop-up-status'),
      fieldType: 'select',
      fieldProperty: 'active',
      fieldValue: popUpListingFilterState.active,
      selectOptions: [
        {
          id: 1,
          name: t('active-status'),
        },
        {
          id: 2,
          name: t('inactive-status'),
        },
      ],
    },
    {
      fieldTitle: t('administrative-panel-pop-up-modified-by'),
      fieldType: 'input',
      fieldProperty: 'modifiedBy',
      fieldValue: popUpListingFilterState.modifiedBy,
    },
  ];

  const submitFilter = (values: any) => {
    const queryParams = Object.entries(values).reduce((acc, [key, value]) => {
      switch (key) {
        case 'active':
          dispatch(PopUpListingSlice.actions.POP_UP_FILTER_SET_ACTIVE(value));
          break;
        default:
          break;
      }

      if (value !== '' && value !== null && value !== undefined) {
        if (key === 'active') {
          const activeParam = value === 1;
          acc += `&${key}=${activeParam}`;
          return acc;
        }

        acc += `&${key}=${String(value).trim()}`;
      }

      return acc;
    }, '');

    dispatch(PopUpListingSlice.actions.FILTER_SET_QUERY_STRING(queryParams));
  };

  return (
    <>
      <Row className="PopUpListing">
        <PreviewPopUp />
        <Row className="buttons-filters" justify="space-between">
          <Col span={3}>
            <Button
              id="new-pop-up-button"
              className="pop-up-listing-button-new-pop-up"
              onClick={() =>
                navigate('/administrative-panel/add_or_edit?tab=pop-up')
              }
            >
              {t('administrative-panel-pop-up-new-pop-up-button')}
            </Button>
          </Col>
          <Col pull={6}>{/* <DeleteSelectedButton /> */}</Col>
          <ShowFiltersButton
            onToggle={toggleFilter}
            openedFilter={!popUpListingFilterState.openedFilter}
            offset={12}
          />
        </Row>
        <Row className="filter-collapse-area">
          <Col span={24}>
            <Filter
              clearFilterFields={clearFilterFields}
              openedFilter={popUpListingFilterState.openedFilter}
              fields={popUpFilterCursor}
              submitFilterFields={submitFilter}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <PagingTable
              emptyText={t('administrative-panel-empty-table')}
              dataSource={popUpListingState.dataSource}
              tableLoading={popUpListingState.tableLoading}
              columns={popUpColumnCursor}
              options={popUpColumnOptions}
              pageSize={popUpListingState.pageSize}
              pageIndex={popUpListingState.pageIndex}
              numberOfItems={popUpListingState.totalNumberOfElements}
              onChangePage={onChangePage}
              onChangeSort={onChangeSort}
              rowClickAction={(record: any) => {
                dispatch(PopUpListingSlice.actions.OPEN_PREVIEW_POP_UP(record));
              }}
              selectRowsToGivenTab={() => {}}
              selectIdFromRowsSelected={() => {}}
              disableSelectionColumn
            />
          </Col>
        </Row>
      </Row>
      {getEditorToPreview()}
    </>
  );
};
