import React from 'react';
import { Modal } from 'antd';

import {
  DeploymentUnitOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { unhookedTranslation } from '../../helpers/unhookedTranslation/unhookedTranslation';
import { getEditorToPreview } from '../../helpers/getEditorToPreview/getEditorToPreview';

export const activeModal = (
  type: string | undefined,
  title: string | undefined,
  content: string | undefined,
  onOk: Function,
  open: boolean,
  icon?: JSX.Element,
  className?: string,
  onOkText: string = unhookedTranslation(
    'documentation-confirmation-cancel-editing-yes-button',
  ),
) => {
  const modalDefaultSettings = {
    title,
    icon,
    content,
    open,
    centered: true,
    maskClosable: true,
    onOk: () => onOk(),
    okText: onOkText,
    className,
  };

  switch (type) {
    case 'DEPLOY':
      Modal.info({
        ...modalDefaultSettings,
        title: getEditorToPreview(title),
        content: getEditorToPreview(content),
        icon: icon || <DeploymentUnitOutlined style={{ color: '#41B4D2' }} />,
      });
      break;

    case 'ALERT':
      Modal.warning({
        ...modalDefaultSettings,
        title: getEditorToPreview(title),
        content: getEditorToPreview(content),
        icon: icon || <WarningOutlined style={{ color: '#faad14' }} />,
      });
      break;

    case 'INFO':
      Modal.info({
        ...modalDefaultSettings,
        title: getEditorToPreview(title),
        content: getEditorToPreview(content),
        icon: icon || (
          <ExclamationCircleOutlined style={{ color: '#46B8A6' }} />
        ),
      });
      break;

    default:
      break;
  }
};
