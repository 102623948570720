import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { messageNotification } from '../../../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../../../helpers/unhookedTranslation/unhookedTranslation';
import { administrativePanelTermsApi } from '../../../../services/administrative-panel-terms';
import { TermsListingAsyncActions } from '../TermsListing/TermListing.Slice';

interface INewTermsSlice {
  language: INewTermsLanguageSlice;
  form: INewTermsFormSlice;
  termDTO: INewTermsDTOTermSlice | undefined;
}

interface INewTermsLanguageSlice {
  selectedLanguage: undefined | 'pt' | 'en' | 'es';
}

interface INewTermsFormSlice {
  version: string;
  ptContent: string;
  enContent: string;
  esContent: string;
  originalVersion: string;
  originalPtContent: string;
  originalEnContent: string;
  originalEsContent: string;
  startFormValidation: boolean;
  finishedCreatingOrEditing: boolean;
  alreadyInEditMode: boolean;
  editorOutputOnEditing: string;
}

interface INewTermsDTOTermSlice {
  id: number;
  version: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
  accepted: boolean;
  contents: INewTermsDTOTermContentSlice[];
}

interface INewTermsDTOTermContentSlice {
  id: number;
  description: string;
  language: 'PORTUGUESE' | 'ENGLISH' | 'SPANISH';
  createdAt: Date;
  updatedAt: Date;
}

const initialState: INewTermsSlice = {
  language: {
    selectedLanguage: undefined,
  },
  form: {
    version: '',
    ptContent: '',
    enContent: '',
    esContent: '',
    originalVersion: '',
    originalPtContent: '',
    originalEnContent: '',
    originalEsContent: '',
    startFormValidation: false,
    finishedCreatingOrEditing: false,
    alreadyInEditMode: false,
    editorOutputOnEditing: '',
  },
  termDTO: undefined,
};

export const NewTermsAsyncActions = {
  CREATE_A_NEW_TERM: createAsyncThunk(
    'CREATE_A_NEW_TERM',
    async (payload: any, { dispatch }) => {
      await administrativePanelTermsApi.createTerm(payload);
      dispatch(TermsListingAsyncActions.GET_TERM_LIST(''));
    },
  ),
  GET_TERM_BY_ID: createAsyncThunk('GET_TERM_BY_ID', async (termId: number) => {
    const term = await administrativePanelTermsApi.getTermById(termId);

    return term.data;
  }),
  UPDATE_TERM: createAsyncThunk('UPDATE_TERM', async (payload: any) => {
    await administrativePanelTermsApi.updateTerm(
      payload.termId,
      payload.updatedTerm,
    );
  }),
};

export const NewTermsSlice = createSlice({
  name: 'NewTermsSlice',
  initialState,
  reducers: {
    SET_SELECTED_LANGUAGE: (state, action) => {
      state.language.selectedLanguage = action.payload;
    },
    CHANGE_SELECTED_LANGUAGE_AND_SAVE_EDITOR_CURRENT_VALUE: (state, action) => {
      state.form.version = action.payload.versionContent;

      switch (state.language.selectedLanguage) {
        case 'pt':
          state.form.ptContent = action.payload.editorContent;
          break;

        case 'en':
          state.form.enContent = action.payload.editorContent;
          break;

        case 'es':
          state.form.esContent = action.payload.editorContent;
          break;

        default:
          break;
      }

      state.language.selectedLanguage = action.payload.newLanguage;
    },
    UPDATE_TERM_VERSION_AND_CONTENT: (state, action) => {
      state.form.version = action.payload.formVersion;

      switch (state.language.selectedLanguage) {
        case 'pt':
          state.form.ptContent = action.payload.editorContent;
          break;

        case 'en':
          state.form.enContent = action.payload.editorContent;
          break;

        case 'es':
          state.form.esContent = action.payload.editorContent;
          break;

        default:
          break;
      }

      state.form.startFormValidation = true;
    },
    STOP_FORM_VALIDATION: (state) => {
      state.form.startFormValidation = false;
    },
    RESET_TO_INITIAL_STATE: () => {
      return initialState;
    },
    SAVE_INITIAL_CONTENT_IN_EDITOR: (state, action) => {
      state.form.editorOutputOnEditing = action.payload;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(
      NewTermsAsyncActions.CREATE_A_NEW_TERM.rejected,
      (state) => {
        state.form.startFormValidation = false;
      },
    ),
    builder.addCase(
      NewTermsAsyncActions.CREATE_A_NEW_TERM.fulfilled,
      (state) => {
        state.form.finishedCreatingOrEditing = true;

        messageNotification.successMessage(
          unhookedTranslation('terms-successfuly-created-title'),
          unhookedTranslation('terms-successfuly-created-content'),
        );
      },
    ),
    builder.addCase(NewTermsAsyncActions.UPDATE_TERM.rejected, (state) => {
      state.form.startFormValidation = false;
    }),
    builder.addCase(NewTermsAsyncActions.UPDATE_TERM.fulfilled, (state) => {
      state.form.finishedCreatingOrEditing = true;

      messageNotification.successMessage(
        unhookedTranslation('terms-successfuly-updated-title'),
        unhookedTranslation('terms-successfuly-updated-content'),
      );
    }),
    builder.addCase(
      NewTermsAsyncActions.GET_TERM_BY_ID.fulfilled,
      (state, action) => {
        state.termDTO = action.payload;

        state.form.alreadyInEditMode = true;

        state.form.originalVersion = action.payload.version;
        state.form.originalPtContent = action.payload.contents.find(
          (x) => x.language === 'PORTUGUESE',
        )!.description;
        state.form.originalEnContent = action.payload.contents.find(
          (x) => x.language === 'ENGLISH',
        )!.description;
        state.form.originalEsContent = action.payload.contents.find(
          (x) => x.language === 'SPANISH',
        )!.description;

        state.form.version = action.payload.version;

        state.form.ptContent = action.payload.contents.find(
          (x) => x.language === 'PORTUGUESE',
        )!.description;

        state.form.enContent = action.payload.contents.find(
          (x) => x.language === 'ENGLISH',
        )!.description;

        state.form.esContent = action.payload.contents.find(
          (x) => x.language === 'SPANISH',
        )!.description;
      },
    ),
    builder.addCase(NewTermsAsyncActions.GET_TERM_BY_ID.rejected, (state) => {
      state.form.finishedCreatingOrEditing = true;
    }),
  ],
});
