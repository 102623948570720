import React, { Fragment } from 'react';
import { Form, Input, Skeleton } from 'antd';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RedBar } from '../../../../assets/red_bar_icon.svg';

interface IPageForm {
  pageMode: 'read' | 'create' | 'edit' | undefined;
  loading: boolean;
}

export const PageForm: React.FC<IPageForm> = ({
  pageMode,
  loading,
}: IPageForm) => {
  const { t } = useTranslation();

  const createEditOrReadModeTitle = () => {
    if (pageMode === 'read') {
      return 'title-input-read-only';
    }
    return 'title-input';
  };

  const createEditOrReadModeDescription = () => {
    if (pageMode === 'read') {
      return 'description-input-read-only';
    }
    return 'description-input';
  };

  const showTitleOrDescriptionBorder = () => {
    if (pageMode === 'read') {
      return false;
    }
    return true;
  };

  const getFormTitleInputOrLoading = () => {
    if (loading) {
      return <Skeleton active />;
    }

    return (
      <Input
        id="page-form-title"
        key={`${uniqid()}${pageMode}`}
        className={createEditOrReadModeTitle()}
        bordered={showTitleOrDescriptionBorder()}
        placeholder={t('page-title-input-placeholder')}
        maxLength={50}
        readOnly={pageMode === 'read'}
      />
    );
  };

  const getFormDescriptionInputOrLoading = () => {
    if (loading) {
      return <Skeleton active />;
    }

    return (
      <Input
        id="page-form-description"
        key={`${uniqid()}${pageMode}`}
        className={createEditOrReadModeDescription()}
        bordered={showTitleOrDescriptionBorder()}
        placeholder={t('page-description-input-placeholder')}
        readOnly={pageMode === 'read'}
        maxLength={150}
      />
    );
  };

  return (
    <Fragment>
      <RedBar />
      <Form.Item name="title">{getFormTitleInputOrLoading()}</Form.Item>
      <Form.Item name="description">
        {getFormDescriptionInputOrLoading()}
      </Form.Item>
    </Fragment>
  );
};
