import React, { useEffect } from 'react';
import { Tag, Tabs } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import uniqid from 'uniqid';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import {
  ModalToUserAcceptTermSlice,
  ModalToUserAcceptTermAsyncActions,
} from './ModalToUserAcceptTerm.Slice';
import { LoginSlice } from '../Login/Login.Slice';
import { getEditorToPreview } from '../../helpers/getEditorToPreview/getEditorToPreview';

export const ModalToUserAcceptTerm = () => {
  const navbarState = useSelector((state: RootState) => state.navBar);
  const loginState = useSelector((state: RootState) => state.login);
  const modalToUserAcceptTermState = useSelector(
    (state: RootState) => state.modalToUserAcceptTerm,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!navbarState.loadedDefaultLanguage) {
      return;
    }

    if (modalToUserAcceptTermState.language.selectedLanguage) {
      return;
    }

    dispatch(
      ModalToUserAcceptTermSlice.actions.SET_SELECTED_LANGUAGE(
        navbarState.defaultLanguage,
      ),
    );
  }, [
    dispatch,
    navbarState.loadedDefaultLanguage,
    modalToUserAcceptTermState.language.selectedLanguage,
    navbarState.defaultLanguage,
  ]);

  useEffect(() => {
    if (!loginState.user) {
      return;
    }

    if (!loginState.user?.termV3Accepted) {
      dispatch(ModalToUserAcceptTermAsyncActions.GET_TERM_TO_MODAL());
    }
  }, [loginState.user, dispatch]);

  useEffect(() => {
    if (!modalToUserAcceptTermState.userRecentlyAccepted) {
      return;
    }

    dispatch(LoginSlice.actions.SET_TERM_AS_ACCEPTED_FOR_USER());
  }, [modalToUserAcceptTermState.userRecentlyAccepted, dispatch]);

  const onOk = () => {
    dispatch(ModalToUserAcceptTermAsyncActions.USER_ACCEPT_TERMS_VIA_MODAL());
  };

  const onCancel = () => {
    dispatch(ModalToUserAcceptTermSlice.actions.BACK_TO_ORIGINAL_STATE());
    navigate('/home');
    dispatch(LoginSlice.actions.LOGOUT());
  };

  return (
    <Modal
      title={t('terms-preview-modal-title')}
      open={modalToUserAcceptTermState.termsModalVisible}
      destroyOnClose
      centered
      onOk={onOk}
      okText={t('modal-user-hasnt-accepted-term-accept-button')}
      okButtonProps={{ shape: 'round' }}
      onCancel={onCancel}
      cancelText={t('modal-user-hasnt-accepted-term-decline-button')}
      cancelButtonProps={{ shape: 'round' }}
    >
      <p>
        {t('terms-preview-modal-term-version-label')}:
        <Tag color="magenta" style={{ marginLeft: '1rem' }}>
          {modalToUserAcceptTermState.modalContent.version}
        </Tag>
      </p>

      <Tabs
        key={`${
          modalToUserAcceptTermState.language.selectedLanguage
        }${uniqid()}`}
        activeKey={modalToUserAcceptTermState.language.selectedLanguage}
        onChange={(activeKey) => {
          dispatch(
            ModalToUserAcceptTermSlice.actions.SET_SELECTED_LANGUAGE(activeKey),
          );
        }}
        centered
      >
        <Tabs.TabPane tab={t('request-access-terms-pt-tab')} key="pt">
          {getEditorToPreview(
            modalToUserAcceptTermState.modalContent.ptContent,
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('request-access-terms-en-tab')} key="en">
          {getEditorToPreview(
            modalToUserAcceptTermState.modalContent.enContent,
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('request-access-terms-es-tab')} key="es">
          {getEditorToPreview(
            modalToUserAcceptTermState.modalContent.esContent,
          )}
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};
