import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  administrativePanelConcludedRequestsApi,
  IConcludedRequest,
} from '../../../../services/administrative-panel-concluded-requests.api';

export interface IConcludedRequests {
  filter: IConcludedRequestsFilter;
  listing: IConcludedRequestsListing;
  showRequestModal: boolean;
  requestResponseId: IPendingRequestResponseById;
}

interface IConcludedRequestsFilter {
  openedFilter: boolean;
  requestType: string | undefined;
  name: string | undefined;
  email: string | undefined;
  status: string | undefined;
  isFilteringByStatus: boolean;
  date: any;
}

interface IConcludedRequestsListing {
  dataSource: IConcludedRequest[];
  refresh: boolean;
  tableLoading: boolean;
  pageSize: number;
  pageIndex: number;
  totalNumberOfElements: number;
  filterQueryString: string;
  orderQueryString: string;
  defaultSortBehaviourActive: boolean;
}

interface IPendingRequestResponseById {
  type: string | undefined;
  id: number | undefined;
  status: string | undefined;
  analyzedBy: string | undefined;
  name: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  dateOfOrder: string;
  companyAddress: string | undefined;
  companyName: string | undefined;
  companyIdentifier: string | undefined;
  country: {
    id: number | undefined;
    code: string | undefined;
    name: string | undefined;
  };
}

const initialState: IConcludedRequests = {
  filter: {
    openedFilter: false,
    requestType: undefined,
    name: undefined,
    email: undefined,
    date: undefined,
    status: undefined,
    isFilteringByStatus: false,
  },
  listing: {
    dataSource: [],
    refresh: true,
    tableLoading: false,
    pageSize: 15,
    pageIndex: 1,
    totalNumberOfElements: 0,
    filterQueryString: '',
    orderQueryString: '',
    defaultSortBehaviourActive: true,
  },
  requestResponseId: {
    type: undefined,
    id: undefined,
    status: undefined,
    analyzedBy: undefined,
    name: undefined,
    lastName: undefined,
    email: undefined,
    dateOfOrder: '',
    companyAddress: undefined,
    companyName: undefined,
    companyIdentifier: undefined,
    country: {
      id: undefined,
      code: undefined,
      name: undefined,
    },
  },
  showRequestModal: false,
};

export const asyncActions = {
  GET_CONCLUDED_REQUESTS_LIST: createAsyncThunk(
    'GET_CONCLUDED_REQUESTS_LIST',
    async (queryParams: string) => {
      const concludedRequests =
        await administrativePanelConcludedRequestsApi.getConcludedRequests(
          queryParams,
        );

      return concludedRequests.data;
    },
  ),
  APPROVE_OR_REFUSE_CONCLUDED_REQUEST: createAsyncThunk(
    'APPROVE_OR_REFUSE_CONCLUDED_REQUEST',
    async (payload: any) => {
      switch (payload.approve_or_refuse) {
        case 'approve':
          await administrativePanelConcludedRequestsApi.approveRequest(
            payload.concludedRequestId,
          );
          break;

        case 'refuse':
          await administrativePanelConcludedRequestsApi.refuseRequest(
            payload.concludedRequestId,
          );
          break;

        default:
          break;
      }
    },
  ),
  GET_CONCLUDED_REQUEST_BY_ID: createAsyncThunk(
    'GET_CONCLUDED_REQUEST_BY_ID',
    async (payload: any) => {
      const concludedIdResponse =
        await administrativePanelConcludedRequestsApi.getConcludedRequestById(
          payload,
        );

      return {
        concludedIdResponse: concludedIdResponse.data,
      };
    },
  ),
};

export const ConcludedRequestsSlice = createSlice({
  name: 'ConcludedRequestsSlice',
  initialState,
  reducers: {
    CONCLUDED_REQUESTS_BACK_TO_ORIGINAL_STATE: () => {
      return {
        ...initialState,
      };
    },
    TOGGLE_FILTER: (state) => {
      if (state.filter.openedFilter) {
        state.filter.openedFilter = false;
      } else {
        state.filter.openedFilter = true;
      }
    },
    FILTER_SET_REQUEST_TYPE: (state, action) => {
      state.filter.requestType = action.payload;
    },
    FILTER_SET_STATUS_TYPE: (state, action) => {
      state.filter.status = action.payload;
    },
    FILTER_SET_STATUS_NAME: (state, action) => {
      state.filter.name = action.payload;
    },
    FILTER_SET_STATUS_EMAIL: (state, action) => {
      state.filter.email = action.payload;
    },
    FILTER_SET_DATE: (state, action) => {
      state.filter.date = action.payload;
    },
    FILTER_SET_QUERY_STRING: (state, action) => {
      state.listing.pageIndex = initialState.listing.pageIndex;
      state.listing.filterQueryString = action.payload;
      state.listing.refresh = true;
    },
    ORDER_SET_QUERY_STRING: (state, action) => {
      state.listing.defaultSortBehaviourActive = false;
      state.listing.orderQueryString = action.payload;
      state.listing.refresh = true;
    },
    FILTER_CLEAR_FIELDS: (state) => {
      if (state.listing.filterQueryString) {
        state.listing.pageIndex = initialState.listing.pageIndex;
      }
      state.listing.filterQueryString = initialState.listing.filterQueryString;
      state.filter = {
        ...initialState.filter,
        openedFilter: state.filter.openedFilter,
      };
      state.listing.refresh = true;
    },
    CHANGE_PAGE: (state, action) => {
      state.listing.pageIndex = action.payload;
      state.listing.refresh = true;
    },
    SET_FILTERING_BY_STATUS: (state, action) => {
      state.filter.isFilteringByStatus = action.payload;
    },
    REFRESH_LIST_AFTER_CHANGES_IN_NUMBER_OF_PENDING_REQUESTS: (state) => {
      state.listing.refresh = true;
    },
    SHOW_OR_CLOSE_REQUEST_MODAL: (state, action) => {
      state.showRequestModal = action.payload;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(
      asyncActions.GET_CONCLUDED_REQUESTS_LIST.pending,
      (state) => {
        state.listing.tableLoading = true;
      },
    ),
    builder.addCase(
      asyncActions.GET_CONCLUDED_REQUESTS_LIST.fulfilled,
      (state, action) => {
        state.listing.refresh = false;
        state.listing.tableLoading = false;

        state.listing.dataSource = action.payload.content;
        state.listing.totalNumberOfElements = action.payload.totalElements;
      },
    ),
    builder.addCase(
      asyncActions.GET_CONCLUDED_REQUESTS_LIST.rejected,
      (state) => {
        state.listing.refresh = false;
        state.listing.tableLoading = false;
      },
    ),
    builder.addCase(
      asyncActions.APPROVE_OR_REFUSE_CONCLUDED_REQUEST.pending,
      (state) => {
        state.listing.tableLoading = true;
      },
    ),
    builder.addCase(
      asyncActions.APPROVE_OR_REFUSE_CONCLUDED_REQUEST.fulfilled,
      (state) => {
        state.listing.tableLoading = false;
        state.listing.refresh = true;
      },
    ),
    builder.addCase(
      asyncActions.GET_CONCLUDED_REQUEST_BY_ID.fulfilled,
      (state, action) => {
        const requestIdResponse = action.payload.concludedIdResponse;

        state.requestResponseId.id = requestIdResponse.id;
        state.requestResponseId.type = requestIdResponse.type;
        state.requestResponseId.dateOfOrder = requestIdResponse.createdAt;
        state.requestResponseId.name = requestIdResponse.name;
        state.requestResponseId.email = requestIdResponse.email;
        state.requestResponseId.status = requestIdResponse.status;
        state.requestResponseId.lastName = requestIdResponse.lastName;
        state.requestResponseId.analyzedBy = requestIdResponse.analyzedBy;
        state.requestResponseId.companyAddress =
          requestIdResponse.companyAddress;
        state.requestResponseId.country.name = requestIdResponse.country.name;

        if (requestIdResponse.type === 'USER') {
          state.requestResponseId.companyName =
            requestIdResponse.userCompany.name;
          state.requestResponseId.companyIdentifier =
            requestIdResponse.userCompany.identifier;
        } else {
          state.requestResponseId.companyIdentifier =
            requestIdResponse.companyIdentifier;
        }
        state.showRequestModal = true;
      },
    ),
  ],
});
