import React, { Fragment, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import _ from 'lodash';
import { NewsSlice, asyncActions } from '../News.Slice';
import { NewRegisterNews } from '../../../../components/NewRegisterNews/NewRegisterNews';
import { AppDispatch, RootState } from '../../../../store/store';

import Background01 from '../../../../assets/news-background-01.svg';
import Background02 from '../../../../assets/news-background-02.svg';
import Background03 from '../../../../assets/news-background-03.svg';
import Background04 from '../../../../assets/news-background-04.svg';
import { LanguageDropdown } from '../../../../components/LanguageDropdown/LanguageDropdown';
import { messageNotification } from '../../../../helpers/messageNotification/messageNotification';

const banner01Key = 'bannerBackground01';
const banner02Key = 'bannerBackground02';
const banner03Key = 'bannerBackground03';
const banner04Key = 'bannerBackground04';

export const CreateNews: React.FC = () => {
  const newsState = useSelector((state: RootState) => state.news);
  const news = useSelector((state: RootState) => state.news.form);
  const newsLanguage = useSelector(
    (state: RootState) => state.news.selectedLanguage,
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const parsedURL = queryString.parse(window.location.search);
  const newsId = Number(parsedURL.id as string);
  const { actions } = NewsSlice;
  const { Option } = Select;
  const editorRef = useRef<FormInstance>(null);

  useEffect(() => {
    return () => {
      dispatch(actions.NEWS_BACK_TO_ORIGINAL_STATE_PERSISTING_FILTER());
    };
  }, [dispatch, actions]);

  useEffect(() => {
    dispatch(NewsSlice.actions.CHANGE_BACKGROUND_IMAGE_NEWS(banner01Key));
  }, [dispatch]);

  useEffect(() => {
    if (newsId) {
      dispatch(actions.SET_EDIT_MODE());
    }
  }, [dispatch, newsId, actions]);

  useEffect(() => {
    if (newsState.showEditMode) {
      if (isNaN(newsId)) {
        return navigate('/administrative-panel?tab=news');
      }

      dispatch(asyncActions.GET_NEWS_BY_ID(newsId));
    }
  }, [dispatch, newsId, newsState.showEditMode, history]);

  useEffect(() => {
    if (!newsState.newsExist) {
      return navigate('/administrative-panel?tab=news');
    }
  }, [history, newsState.newsExist]);

  useEffect(() => {
    if (newsState.createdNews) {
      return navigate('/administrative-panel?tab=news');
    }
  }, [history, newsState.createdNews]);

  useEffect(() => {
    if (!newsState.shouldValidateForm) {
      return;
    }

    let isFormValid = false;

    isFormValid =
      !!newsState.form.pt.title && newsState.form.pt.title.length > 0;
    isFormValid =
      isFormValid &&
      !!newsState.form.pt.subtitle &&
      newsState.form.pt.subtitle.length > 0;

    isFormValid =
      isFormValid &&
      !!newsState.form.en.title &&
      newsState.form.en.title.length > 0;
    isFormValid =
      isFormValid &&
      !!newsState.form.en.subtitle &&
      newsState.form.en.subtitle.length > 0;

    isFormValid =
      isFormValid &&
      !!newsState.form.es.title &&
      newsState.form.es.title.length > 0;
    isFormValid =
      isFormValid &&
      !!newsState.form.es.subtitle &&
      newsState.form.es.subtitle.length > 0;

    if (!isFormValid) {
      dispatch(NewsSlice.actions.STOP_FORM_VALIDATION());
      return messageNotification.errorMessage(
        t('administrative-panel-language-content-validation'),
        t('error-news-missing-language-content'),
      );
    }

    const newDTO = _.cloneDeep(newsState.original);
    const newForm = _.cloneDeep(newsState.form);

    newDTO.image = newForm.image;
    newDTO.link = newForm.link;

    newDTO.pt.title = newForm.pt.title;
    newDTO.pt.subtitle = newForm.pt.subtitle;

    newDTO.en.title = newForm.en.title;
    newDTO.en.subtitle = newForm.en.subtitle;

    newDTO.es.title = newForm.es.title;
    newDTO.es.subtitle = newForm.es.subtitle;

    if (!newsId) {
      dispatch(asyncActions.NEW_NEWS(newDTO));
    } else {
      dispatch(asyncActions.UPDATE_NEWS(newDTO));
    }
  }, [dispatch, newsId, newsState, t]);

  const getInitialValues = () => {
    switch (newsLanguage) {
      case 'pt':
        return news.pt;
      case 'en':
        return news.en;
      case 'es':
        return news.es;
      default:
        break;
    }
  };

  const initialValues = {
    title: getInitialValues()?.title ?? '',
    subtitle: getInitialValues()?.subtitle ?? '',
    image: news.image,
    link: news.link,
    active: news.active,
  };

  const onRegisterClickAction = (values: any) => {
    dispatch(
      NewsSlice.actions.SAVE_EDITOR_CURRENT_VALUE({
        editorContent: {
          title: values.title,
          subtitle: values.subtitle,
        },
        link: values.link,
        image: news.image,
        shouldValidateForm: true,
      }),
    );
  };

  const onCancelClickAction = () => {
    navigate('/administrative-panel?tab=news');
  };

  const menuOptionsBackground = [
    {
      key: banner01Key,
      backgroundImage: Background01,
    },
    {
      key: banner02Key,
      backgroundImage: Background02,
    },
    {
      key: banner03Key,
      backgroundImage: Background03,
    },
    {
      key: banner04Key,
      backgroundImage: Background04,
    },
  ];

  const whichBackgroundSelected = (key: string) => {
    switch (key) {
      case banner01Key:
        return Background01;
      case banner02Key:
        return Background02;
      case banner03Key:
        return Background03;
      case banner04Key:
        return Background04;
      default:
        return Background01;
    }
  };

  const onChangeBackgroundNewsAction = (keySelected: string) => {
    const formInstance = editorRef.current as unknown as FormInstance;
    if (formInstance) {
      const values = formInstance.getFieldsValue();
      dispatch(
        NewsSlice.actions.SAVE_EDITOR_CURRENT_VALUE({
          editorContent: {
            title: values.title,
            subtitle: values.subtitle,
          },
          link: values.link,
          image: news.image,
          shouldValidateForm: false,
        }),
      );
      dispatch(NewsSlice.actions.CHANGE_BACKGROUND_IMAGE_NEWS(keySelected));
    }
  };

  const onSelectLanguageDropdown = () => {
    const formInstance = editorRef.current as unknown as FormInstance;

    if (formInstance) {
      const languages = formInstance.getFieldsValue();

      switch (languages.selectLanguage) {
        case 'pt':
          formInstance.setFieldsValue({
            title: news.pt.title,
            subtitle: news.pt.subtitle,
          });
          break;
        case 'en':
          formInstance.setFieldsValue({
            title: news.en.title,
            subtitle: news.en.subtitle,
          });
          break;
        case 'es':
          formInstance.setFieldsValue({
            title: news.es.title,
            subtitle: news.es.subtitle,
          });
          break;
        default:
          break;
      }
    }
  };

  const onChangeLanguageDropdown = (languageKey: string) => {
    const formInstance = editorRef.current as unknown as FormInstance;
    if (formInstance) {
      const values = formInstance.getFieldsValue();
      const prevLanguage = newsLanguage;

      dispatch(
        NewsSlice.actions.CHANGE_SELECTED_LANGUAGE_AND_SAVE_EDITOR_CURRENT_VALUE(
          {
            editorContent: {
              title: values.title,
              subtitle: values.subtitle,
            },
            newLanguage: languageKey,
            link: values.link,
          },
        ),
      );

      if (prevLanguage !== languageKey) {
        switch (languageKey) {
          case 'pt':
            formInstance.setFieldsValue({
              title: news.pt.title,
              description: news.pt.subtitle,
            });
            break;
          case 'en':
            formInstance.setFieldsValue({
              title: news.en.title,
              subtitle: news.en.subtitle,
            });
            break;
          case 'es':
            formInstance.setFieldsValue({
              title: news.es.title,
              subtitle: news.es.subtitle,
            });
            break;
          default:
            break;
        }
      }
    }
  };

  const getLanguageDropdownOptions = () => {
    return (
      <Fragment>
        <Option value="pt">{t('create-page-language-dropdown-pt')}</Option>
        <Option value="en">{t('create-page-language-dropdown-en')}</Option>
        <Option value="es">{t('create-page-language-dropdown-es')}</Option>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <LanguageDropdown
        languageDropdown={newsLanguage}
        onChangeLanguageDropdown={onChangeLanguageDropdown}
        onSelectLanguageDropdown={onSelectLanguageDropdown}
        getLanguageDropdownOptions={getLanguageDropdownOptions}
      />
      <NewRegisterNews
        newsTitle={initialValues.title}
        newsSubtitle={initialValues.subtitle}
        newsLinkNews={initialValues.link}
        newsBackground={whichBackgroundSelected(news.image)}
        newsBackgroundMenu={menuOptionsBackground}
        selectedBackground={news.image}
        initialValues={initialValues}
        showEditMode={newsState.showEditMode}
        onChangeBackgroundNewsClickFunction={onChangeBackgroundNewsAction}
        onRegisterClickFunction={onRegisterClickAction}
        onCancelClickFunction={onCancelClickAction}
        editorRef={editorRef}
      />
    </Fragment>
  );
};
