import { Row, Col, Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../../../helpers/showModal/showModal';
import { AppDispatch, RootState, store } from '../../../../../store/store';
import { PermissionsByCompaniesSlice } from '../../PermissionsByCompanies/PermissionsByCompanies.Slice';
import { PermissionsByRepositoriesSlice } from '../../PermissionsByRepositories/PermissionsByRepositories.Slice';
import { PermissionsListingSliceAsyncActions } from '../PermissionsListing.Slice';

import './CancelOrSaveChanges.scss';

export interface ICancelOrSaveChanges {
  tableType: string;
  visible: () => boolean;
}

export const CancelOrSaveChanges: React.FC<ICancelOrSaveChanges> = ({
  tableType,
  visible,
}: ICancelOrSaveChanges) => {
  const permissionsCompaniesState = useSelector(
    (state: RootState) => state.permissionsByCompanies,
  );

  const permissionsRepositoriesState = useSelector(
    (state: RootState) => state.permissionsByRepositories,
  );
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const generateButton = () => {
    switch (tableType) {
      case 'companies':
        return permissionsCompaniesState.changes.totalOfChanges === 1
          ? `${permissionsCompaniesState.changes.totalOfChanges} ${t(
              'administrative-panel-permissions-companies-singular-changes',
            )}`
          : `${permissionsCompaniesState.changes.totalOfChanges} ${t(
              'administrative-panel-permissions-companies-plural-changes',
            )}`;

      case 'repositories':
        return permissionsRepositoriesState.changes.totalOfChanges === 1
          ? `${permissionsRepositoriesState.changes.totalOfChanges} ${t(
              'administrative-panel-permissions-repositories-singular-changes',
            )}`
          : `${permissionsRepositoriesState.changes.totalOfChanges}  ${t(
              'administrative-panel-permissions-repositories-plural-changes',
            )}`;
      default:
        break;
    }
  };

  const saveChangesInPermissions = () => {
    const selectedTableOnLeft =
      store.getState().permissions.permissionTables[0];
    const permissionsForCompanyOnRight =
      store.getState().permissionsByCompanies;
    const permissionsForArtifactOnRight =
      store.getState().permissionsByRepositories;

    const entity =
      selectedTableOnLeft.tableType === 'companies' ? 'COMPANY' : 'ARTIFACT';

    const entityId =
      permissionsForCompanyOnRight.selectedCompany ??
      permissionsForArtifactOnRight.selectedRepository;

    const changesInCompaniesOrRepositories = new Array<{
      id: number;
      grantedPermission: 'READ' | 'WRITE' | 'NONE';
    }>();

    const trackOfChanges =
      entity === 'COMPANY'
        ? permissionsForArtifactOnRight.changes
        : permissionsForCompanyOnRight.changes;

    trackOfChanges.entitiesWithAddedViewRights.forEach((x) => {
      changesInCompaniesOrRepositories.push({
        id: x,
        grantedPermission: 'READ',
      });
    });

    trackOfChanges.entitiesWithAddedEditRights.forEach((x) => {
      const checkIfAlreadyExistsInChanges =
        changesInCompaniesOrRepositories.find((y) => y.id === x);

      if (checkIfAlreadyExistsInChanges) {
        checkIfAlreadyExistsInChanges.grantedPermission = 'WRITE';
      } else {
        changesInCompaniesOrRepositories.push({
          id: x,
          grantedPermission: 'WRITE',
        });
      }
    });

    trackOfChanges.entitiesWithRemovedEditRights.forEach((x) => {
      changesInCompaniesOrRepositories.push({
        id: x,
        grantedPermission: 'READ',
      });
    });

    trackOfChanges.entitiesWithRemovedViewRights.forEach((x) => {
      const checkIfAlreadyExistsInChanges =
        changesInCompaniesOrRepositories.find((y) => y.id === x);

      if (checkIfAlreadyExistsInChanges) {
        checkIfAlreadyExistsInChanges.grantedPermission = 'NONE';
      } else {
        changesInCompaniesOrRepositories.push({
          id: x,
          grantedPermission: 'NONE',
        });
      }
    });

    dispatch(
      PermissionsListingSliceAsyncActions.SAVE_CHANGES_IN_PERMISSIONS({
        entity,
        entityId,
        updatedEntities: changesInCompaniesOrRepositories,
      }),
    );
  };

  const modalToConfirmChanges = () => {
    showModal(
      'confirm',
      t('administrative-panel-permissions-save-button-modal-title'),
      t('administrative-panel-permissions-save-button-modal-message'),
      () => {
        saveChangesInPermissions();
      },
      () => {},
      t('administrative-panel-permissions-save-button-modal-accept'),
      t('administrative-panel-permissions-save-button-modal-refuse'),
    );
  };

  const refreshTableAndResetChanges = () => {
    const selectedTableOnRight =
      store.getState().permissions.permissionTables[1].tableType;

    if (selectedTableOnRight === 'companies') {
      dispatch(
        PermissionsByCompaniesSlice.actions.REFRESH_TABLE_AND_RESET_CHANGES(),
      );
    }
    return dispatch(
      PermissionsByRepositoriesSlice.actions.REFRESH_TABLE_AND_RESET_CHANGES(),
    );
  };
  const modalToCancelChanges = () => {
    return showModal(
      'warning',
      t('administrative-panel-permissions-cancel-button-modal-title'),
      t('administrative-panel-permissions-cancel-button-modal-message'),
      () => {
        refreshTableAndResetChanges();
      },
      () => {},
      t('administrative-panel-permissions-cancel-button-modal-accept'),
      t('administrative-panel-permissions-cancel-button-modal-refuse'),
    );
  };

  const returnButtons = () => {
    if (visible()) {
      return (
        <Col>
          <p> {generateButton()}</p>
          <Row className="buttons-area">
            <Button type="link" onClick={modalToCancelChanges}>
              {t('administrative-panel-permissions-cancel-button')}
            </Button>
            <Button
              type="primary"
              ghost
              shape="round"
              onClick={modalToConfirmChanges}
            >
              {t('administrative-panel-permissions-save-button')}
            </Button>
          </Row>
        </Col>
      );
    }
  };

  return (
    <Row className="CancelOrSaveChanges" align="middle" justify="end">
      {returnButtons()}
    </Row>
  );
};
