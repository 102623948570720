import React, { useEffect } from 'react';
import './LanguageSelection.scss';

import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VerticalBlueBar } from '../../../../../assets/vertical_red_bar.svg';
import { RootState } from '../../../../../store/store';
import { NewTermsSlice } from '../NewTerms.Slice';

interface ILanguageSelection {
  inputRef: React.MutableRefObject<any>;
  editorRef: React.MutableRefObject<any>;
}

export const LanguageSelection: React.FC<ILanguageSelection> = ({
  inputRef,
  editorRef,
}: ILanguageSelection) => {
  const dispatch = useDispatch();
  const navbarState = useSelector((state: RootState) => state.navBar);
  const newTermsState = useSelector(
    (state: RootState) => state.createEditTerms,
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (!navbarState.loadedDefaultLanguage) {
      return;
    }

    dispatch(
      NewTermsSlice.actions.SET_SELECTED_LANGUAGE(navbarState.defaultLanguage),
    );
  }, [
    dispatch,
    navbarState.loadedDefaultLanguage,
    navbarState.defaultLanguage,
  ]);

  const generateLanguageOptions = () => {
    const showOrHideVerticalBlueBar = (language: string) => {
      if (language === newTermsState.language.selectedLanguage) {
        return <VerticalBlueBar />;
      }

      return <div />;
    };

    const setAsSelectedLanguage = (language: string) => {
      if (language === newTermsState.language.selectedLanguage) {
        return;
      }

      dispatch(
        NewTermsSlice.actions.CHANGE_SELECTED_LANGUAGE_AND_SAVE_EDITOR_CURRENT_VALUE(
          {
            versionContent: (inputRef.current as any).input.value,
            editorContent: editorRef.current,
            newLanguage: language,
          },
        ),
      );
    };

    return (
      <Row>
        <Col span={24}>
          <Row
            className="language-option"
            onClick={() => setAsSelectedLanguage('pt')}
          >
            <Col span={4}>{showOrHideVerticalBlueBar('pt')}</Col>
            <Col span={20}>
              {t('terms-create-or-edit-portuguese-language')}{' '}
            </Col>
          </Row>
          <Row
            className="language-option"
            onClick={() => setAsSelectedLanguage('en')}
          >
            <Col span={4}>{showOrHideVerticalBlueBar('en')}</Col>
            <Col span={20}> {t('terms-create-or-edit-english-language')}</Col>
          </Row>
          <Row
            className="language-option"
            onClick={() => setAsSelectedLanguage('es')}
          >
            <Col span={4}>{showOrHideVerticalBlueBar('es')}</Col>
            <Col span={20}> {t('terms-create-or-edit-spanish-language')}</Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <div className="LanguageSelection">
      <b> {t('terms-create-or-edit-languages')} </b>
      <div className="languages-area">{generateLanguageOptions()}</div>
    </div>
  );
};
