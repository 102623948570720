import { IUserData } from './IUserData';
import * as xlsx from 'xlsx';

export const generateCsv = async (userData: IUserData[]): Promise<string> => {
  return new Promise((resolve) => {
    let csvContent = 'data:text/csv;charset=utf-8,';

    // CSV Header
    const headers = Object.keys(userData[0]);
    csvContent += headers.join(';') + '\r\n';

    // CSV Rows
    userData.forEach((data) => {
      let row = headers.map((header) => (data as any)[header]).join(';');
      csvContent += row + '\r\n';
    });

    // Resolve the Promise with the encoded CSV content
    resolve(encodeURI(csvContent));
  });
};

export const generateXlsx = async (userData: IUserData[]): Promise<string> => {
  return new Promise((resolve) => {
    const worksheet = xlsx.utils.json_to_sheet(userData);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, 'Mailing List');
    const buffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'buffer' });

    let blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    resolve(URL.createObjectURL(blob));
  });
};

export const exportToClipboard = async (
  userData: IUserData[],
): Promise<void> => {
  return new Promise((resolve, reject) => {
    let content = '';
    userData.forEach((data) => {
      content += data.email + '\r\n';
    });

    // If the async clipboard API is not supported by the browser, fallback to document.execCommand('copy')
    if (!navigator.clipboard) {
      let textArea = document.createElement('textarea');
      textArea.value = content;

      // Avoid scrolling
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const result = document.execCommand('copy');
        if (!result) {
          reject();
        }
      } catch {
        reject();
      }

      document.body.removeChild(textArea);

      resolve();
    }

    navigator.clipboard.writeText(content).then(
      () => {
        resolve();
      },
      () => {
        reject();
      },
    );
  });
};
