import React from 'react';
import { Row, Col, Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import DigitalSolution from '../../../assets/home-images/digital_solution_world_tablet.svg';
import TerminalSolution from '../../../assets/home-images/terminal_solution_apos_2.svg';

import { ReactComponent as Bluebar } from '../../../assets/red_bar_icon.svg';

import './Solution.scss';
import { AppDispatch, RootState } from '../../../store/store';
import { asyncActions as navbarAsyncActions } from '../../.Container/Navbar/Navbar.Slice';

export const Solution: React.FC = () => {
  const loginState = useSelector((state: RootState) => state.login);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const onGenerateSolutions = () => {
    return (
      <Row>
        <Col span={24}>
          <Row>
            <Col span={11} className="products-and-services-title">
              <Bluebar />
              {t('home-page-digital-solution')}
            </Col>

            <Col span={11} offset={2} className="products-and-services-title">
              <Bluebar />
              {t('home-page-terminal-terminal-payment')}
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <div className="digital-solutions-description">
                {t('home-page-digital-solution-description')}
              </div>
              <img
                src={DigitalSolution}
                alt={t('home-page-digital-solution')}
                className="solution-size-image"
              />
            </Col>
            <Col span={2} className="divider-column">
              <Divider type="vertical" className="divider" />
            </Col>
            <Col span={11}>
              <div className="payment-terminals-description">
                {t('home-page-terminal-terminal-payment-description')}
              </div>
              <img
                src={TerminalSolution}
                alt={t('home-page-digital-solution')}
                className="solution-size-image"
              />
            </Col>
          </Row>
          <Row align="middle">
            <Col span={11} className="home-buttons">
              <Button shape="round" onClick={() => navigate('/created-pages')}>
                {t('home-page-discover')}
              </Button>
            </Col>
            <Col span={11} offset={2} className="home-buttons">
              <Button
                shape="round"
                onClick={() => {
                  if (!loginState.user) {
                    return navigate('/login?fromRepositoryRedirect=/');
                  }

                  dispatch(
                    navbarAsyncActions.GET_TRANSIENT_TOKEN_AND_REDIRECT('/'),
                  );
                }}
              >
                {t('home-page-discover')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return <div className="Solution">{onGenerateSolutions()}</div>;
};
