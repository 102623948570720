import { Badge } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

import './PendingRequestsBadge.scss';

interface IPendingRequestsBadge {
  marginLeft?: boolean;
  children?: JSX.Element;
}

export const PendingRequestsBadge = ({
  marginLeft,
  children,
}: IPendingRequestsBadge) => {
  const numberOfPendingRequests = useSelector(
    (state: RootState) => state.requestTracker.numberOfPendingRequests,
  );

  return (
    <Badge
      className={`PendingRequestsBadge ${
        marginLeft ? 'margin-left' : undefined
      }`}
      count={numberOfPendingRequests}
    >
      {children}
    </Badge>
  );
};
