import { EllipsisOutlined } from '@ant-design/icons';
import { Col, Dropdown, Menu, Row, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid';
import { showBlockOrUnblockUserConfirmationModal } from '../../helpers/showBlockOrUnblockUserConfirmationModal/showBlockOrUnblockUserConfirmationModal';
import { RootState } from '../../store/store';

import { ReactComponent as Padlock } from '../../assets/popover-icons/padlock.svg';
import { ReactComponent as ArrowDown } from '../../assets/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../assets/arrow-up.svg';

import './PagingTableWithExpansion.scss';
import {
  generatePagingTableSpecialColumns,
  generateRequestsActionColumns,
  generateRequestsTypeColumn,
} from '../../helpers/pagingTableSpecialColumns/pagingTableSpecialColumns';

interface IPagingTableWithExpansion {
  dataSource: any[];
  tableLoading: boolean;
  columns: IColumnCursor[];
  options: ITupleOptions[];
  pageSize: number;
  pageIndex: number;
  numberOfItems: number;
  onChangePage: Function;
  onChangeSort: Function;
  onChangeOptionsVisibility: Function;
  rowClickAction: Function;
  emptyText: string;
  disableOptionsColumn?: boolean;
  disableSelectionColumn?: boolean;
  defaultSortBehaviour?: IDefaultSortBehaviour;
  getIdFromRow?: (rowData: any) => number;
  isPendingTable?: boolean;
  selectRowsToGivenTab: Function;
  selectIdFromRowsSelected: Function;
}

interface IColumnCursor {
  columnTitle: string;
  columnProperty: string | string[];
  className?: string;
  disableSort?: boolean;
  width?: number;
  onResizeAction?: any;
}

interface ITupleOptions {
  optionId?: string;
  optionName: string;
  optionIcon: JSX.Element;
  optionOnClick: Function;
}

interface IDefaultSortBehaviour {
  columnProperty: string;
  disableDefaultSortBehaviour: Function;
  order: any;
}

export const PagingTableWithExpansion: React.FC<IPagingTableWithExpansion> = ({
  dataSource,
  tableLoading,
  columns,
  options,
  pageSize,
  pageIndex,
  numberOfItems,
  onChangePage,
  onChangeSort,
  onChangeOptionsVisibility,
  rowClickAction,
  disableOptionsColumn = false,
  disableSelectionColumn = false,
  emptyText,
  defaultSortBehaviour,
  getIdFromRow = (rowData: any) => rowData.id,
  isPendingTable,
  selectRowsToGivenTab,
  selectIdFromRowsSelected,
}: IPagingTableWithExpansion) => {
  const [expanding, setExpanding] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dateColumnsProperties = ['updatedAt', 'date'];
  const applicationLanguage = useSelector(
    (state: RootState) => state.navBar.defaultLanguage,
  );

  const generateUnblockOrBlockUserOption = (rowItem: any) => {
    if (rowItem.blocked) {
      return (
        <div>
          <Padlock />
          <span>
            {t('administrative-panel-users-table-column-unblock-user-option')}
          </span>
        </div>
      );
    }

    return (
      <div>
        <Padlock />
        <span>
          {t('administrative-panel-users-table-column-block-user-option')}
        </span>
      </div>
    );
  };

  const generateOptions = (
    menuOptions: ITupleOptions[],
    itemId: number,
    rowItem: any,
  ) => {
    const menuOptionItens = menuOptions
      .filter((x) => x.optionId !== 'unblock-block-user')
      .map((x) => (
        <Menu.Item
          key={x.optionName.toLowerCase()}
          onClick={() => {
            x.optionOnClick(itemId, rowItem);
          }}
        >
          {x.optionIcon}
          {x.optionName}
        </Menu.Item>
      ));

    if (menuOptions.find((x) => x.optionId === 'unblock-block-user')) {
      menuOptionItens.push(
        <Menu.Item
          key={uniqid()}
          onClick={() => {
            showBlockOrUnblockUserConfirmationModal(itemId, rowItem);
          }}
        >
          {generateUnblockOrBlockUserOption(rowItem)}
        </Menu.Item>,
      );
    }
    return (
      <Menu className="paging-table-options-column">{menuOptionItens}</Menu>
    );
  };

  const formatDateColumn = (unformattedDate: string) => {
    const date = new Date(unformattedDate);

    if (isNaN(date.getTime())) {
      return;
    }

    const dateFormat = new Intl.DateTimeFormat(applicationLanguage, {
      dateStyle: 'long',
      timeStyle: 'long',
    } as Intl.DateTimeFormatOptions);

    const formattedDate = dateFormat.format(date);

    return <span> {formattedDate} </span>;
  };

  const checkIfColumnHasDefaultSortBehaviour = (columnProperty: string) => {
    if (
      defaultSortBehaviour &&
      defaultSortBehaviour.columnProperty === columnProperty
    ) {
      return defaultSortBehaviour.order;
    }

    return undefined;
  };

  const generateColumns = () => {
    const tableColumns: JSX.Element[] = [];

    generateRequestsTypeColumn(tableColumns, t);

    columns.forEach((x) => {
      if (dateColumnsProperties.includes(x.columnProperty as string)) {
        tableColumns.push(
          <>
            <Table.Column
              title={x.columnTitle}
              key={x.columnProperty.toString()}
              sorter
              onCell={(record: any) => {
                return {
                  onClick: () => rowClickAction(record),
                };
              }}
              className={x.className}
              ellipsis
              defaultSortOrder={checkIfColumnHasDefaultSortBehaviour(
                x.columnProperty as string,
              )}
              showSorterTooltip={false}
              render={(y: any) =>
                formatDateColumn(y[x.columnProperty as string])
              }
              width={x.width ? `${x.width}rem` : '12rem'}
            />
            <Table.Column
              className="resizeHandle"
              onHeaderCell={() => {
                return {
                  onMouseDown: () => {
                    window.addEventListener('mousemove', x.onResizeAction);
                    window.addEventListener('mouseup', () => {
                      window.removeEventListener('mousemove', x.onResizeAction);
                    });
                  },
                };
              }}
            />
          </>,
        );
      } else {
        tableColumns.push(
          <>
            <Table.Column
              title={x.columnTitle}
              dataIndex={x.columnProperty}
              key={x.columnTitle.toLowerCase()}
              onCell={(record: any) => {
                return {
                  onClick: () => rowClickAction(record),
                };
              }}
              className={x.className}
              sorter={x.disableSort ? !x.disableSort : true}
              ellipsis
              showSorterTooltip={false}
              width={x.width ? `${x.width}rem` : '12rem'}
            />
            <Table.Column
              className="resizeHandle"
              onHeaderCell={() => {
                return {
                  onMouseDown: () => {
                    window.addEventListener('mousemove', x.onResizeAction!);
                    window.addEventListener('mouseup', () => {
                      window.removeEventListener(
                        'mousemove',
                        x.onResizeAction!,
                      );
                    });
                  },
                };
              }}
            />
          </>,
        );
      }
    });

    if (isPendingTable) {
      generateRequestsActionColumns(tableColumns, rowClickAction, t, dispatch);
    } else {
      generatePagingTableSpecialColumns(tableColumns, t);
    }

    if (!disableOptionsColumn) {
      tableColumns.push(
        <Table.Column
          title=""
          key="options"
          className="optionsColumn"
          ellipsis
          align="center"
          onCell={(record: any) => {
            return {
              onClick: () => onChangeOptionsVisibility(getIdFromRow(record)),
            };
          }}
          render={(x: any) => (
            <Dropdown
              arrow
              placement="bottomCenter"
              visible={x.optionsVisibility}
              onVisibleChange={() => onChangeOptionsVisibility(getIdFromRow(x))}
              overlay={generateOptions(options, getIdFromRow(x), x)}
              trigger={['contextMenu']}
            >
              <EllipsisOutlined />
            </Dropdown>
          )}
        />,
      );
    }

    return tableColumns;
  };

  const sortHandler = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    onChangeSort(field, order);
  };

  const rowSelectionProperties: TableRowSelection<any> = {
    preserveSelectedRowKeys: true,
    type: 'checkbox',
    onChange: (selectedRowKeys, selectedRows) => {
      selectRowsToGivenTab(selectedRows);

      selectIdFromRowsSelected(selectedRows.map((y) => getIdFromRow(y)));
    },
  };

  const paginationConfig: TablePaginationConfig = {
    total: numberOfItems,
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} ${t('paging-table-pagination-of')} ${total}`,
    current: pageIndex,
    pageSize,
    pageSizeOptions: [],
    position: ['bottomCenter'],
  };

  const toggleExpansion = () => {
    setExpanding(!expanding);
  };

  return (
    <Row className="PagingTableWithExpansion">
      <Col span={24}>
        <Table
          onChange={(pagination, filters, sorter: any) => {
            const sorterField = sorter.field ? sorter.field : sorter.columnKey;

            sortHandler(sorterField, sorter.order);
            onChangePage(pagination.current);
          }}
          locale={{ emptyText }}
          loading={tableLoading}
          rowSelection={
            disableSelectionColumn ? undefined : rowSelectionProperties
          }
          dataSource={expanding ? dataSource : dataSource.slice(0, 5)}
          pagination={expanding ? paginationConfig : false}
          rowKey={(record) => getIdFromRow(record)}
          footer={() => {
            const footerLabel = expanding
              ? t('administrative-panel-requests-table-col-see-less')
              : t('administrative-panel-requests-table-col-see-more');
            const footerIcon = expanding ? <ArrowUp /> : <ArrowDown />;

            return (
              <Row className="expansion-footer-row">
                <Col
                  className="expansion-footer"
                  span={3}
                  onClick={toggleExpansion}
                >
                  {footerLabel} {footerIcon}
                </Col>
              </Row>
            );
          }}
        >
          {generateColumns()}
        </Table>
      </Col>
    </Row>
  );
};
