import { securedBaseApi } from './api.config';

interface INewTermBody {
  version: string;
  contents: INewTermsBodyContents[];
}

interface INewTermsBodyContents {
  description: string;
  language: 'PORTUGUESE' | 'ENGLISH' | 'SPANISH';
}

interface ITermById {
  id: number;
  version: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
  accepted: boolean;
  contents: ITermByIdContents[];
}

interface ITermByIdContents {
  id: number;
  description: string;
  language: 'PORTUGUESE' | 'ENGLISH' | 'SPANISH';
  createdAt: Date;
  updatedAt: Date;
}

interface ITermList {
  content: ITermById[];
  totalElements: number;
}

export const administrativePanelTermsApi = {
  getTermList: (queryParams: string) => {
    return securedBaseApi.get<ITermList>(`/termsV3${queryParams}`);
  },
  createTerm: (newTermBody: INewTermBody) => {
    return securedBaseApi.post('/termsV3', newTermBody);
  },
  getTermById: (termId: number) => {
    return securedBaseApi.get<ITermById>(`/termsV3/${termId}`);
  },
  updateTerm: (termId: number, updatedTerm: any) => {
    return securedBaseApi.put(`/termsV3/${termId}`, updatedTerm);
  },
  activateTerm: (termId: number) => {
    return securedBaseApi.patch(`/termsV3/${termId}/activate`);
  },
  deleteTerm: (termId: number) => {
    return securedBaseApi.delete(`/termsV3/${termId}`);
  },
};
