import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { activateUserApi } from '../../services/activate-user-by-email.api';

export interface IActivateUserByEmail {
  token: string;
  activateAccountCompleted: boolean;
  activateAccountFailed: boolean;
  showSpin: boolean;
}

const initialState: IActivateUserByEmail = {
  token: '',
  activateAccountCompleted: false,
  activateAccountFailed: false,
  showSpin: true,
};

export const asyncActions = {
  VALIDATEACCOUNT: createAsyncThunk(
    'VALIDATEACCOUNT',
    async (token: string) => {
      await activateUserApi.activateAccount(token);
    },
  ),
};

export const ActivateUserByEmailSlice = createSlice({
  name: 'activateUserByEmailSlice',
  initialState,
  reducers: {
    GET_URL_TOKEN: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.VALIDATEACCOUNT.fulfilled, (state) => {
      state.activateAccountCompleted = true;
      state.showSpin = false;
    });
    builder.addCase(asyncActions.VALIDATEACCOUNT.rejected, (state) => {
      state.activateAccountCompleted = true;
      state.showSpin = false;
    });
  },
});
