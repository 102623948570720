import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { messageNotification } from '../../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../../helpers/unhookedTranslation/unhookedTranslation';
import {
  administrativePanelNewsApi,
  INewsBody,
  INewsListItem,
  NEWS_LANGUAGE_MAP,
} from '../../../services/administrative-panel-news';
import { ITab } from '../AdministrativePanel.Slice';

export interface IAdministrativePanelNewsState {
  showEditMode: boolean;
  list: INewsTab;
  original: TNewDTO;
  newsExist: boolean;
  createdNews: boolean;
  selectedLanguage: undefined | 'pt' | 'en' | 'es';
  shouldValidateForm: boolean;
  form: TNew;
  finishedCreatingOrEditing: boolean;
}

export type TNewsLanguages = 'pt' | 'en' | 'es';
export type TNew = {
  active: boolean;
  image: string;
  link: string;
} & {
  [key in TNewsLanguages]: {
    title: string;
    subtitle: string;
  };
};

interface INewNews {
  id?: number;
  contents: {
    id: number;
    title: string;
    subtitle: string;
    language: string;
  }[];
  link: string;
  image: string;
  active: boolean;
}

interface INewsTab extends ITab<INewsListItem> {
  filter: INewsFilter;
  queryString: string;
  showFilter: string;
  shouldRefresh: boolean;
  tableLoading: boolean;
  selectedRowToDeleteNews: number;
  deleteManyNewsIds: number[];
  pageSize: number;
  pageIndex: number;
  defaultSortBehaviourActive: boolean;
}

interface INewsFilter {
  filterByModifiedBy: string | undefined;
  filterByLink: string | undefined;
  filterByActive: string | undefined;
}

export type TNewDTO = {
  id: number;
  active: boolean;
  modifiedBy: string;
  image: string;
  link: string;
} & {
  [key in TNewsLanguages]: {
    id: number;
    title: string;
    subtitle: string;
  };
};

const initialState: IAdministrativePanelNewsState = {
  finishedCreatingOrEditing: false,
  shouldValidateForm: false,
  selectedLanguage: 'pt',
  showEditMode: false,
  form: {
    active: false,
    link: '',
    image: '',
    pt: {
      title: '',
      subtitle: '',
    },
    en: {
      title: '',
      subtitle: '',
    },
    es: {
      title: '',
      subtitle: '',
    },
  },
  original: {
    id: 0,
    modifiedBy: '',
    active: false,
    link: '',
    image: '',
    pt: {
      id: 0,
      title: '',
      subtitle: '',
    },
    en: {
      id: 0,
      title: '',
      subtitle: '',
    },
    es: {
      id: 0,
      title: '',
      subtitle: '',
    },
  },
  list: {
    queryString: '',
    showFilter: '',
    sortBy: '',
    pageIndex: 1,
    pageSize: 15,
    selectedRowToDeleteNews: 0,
    deleteManyNewsIds: [],
    numberOfItems: 0,
    shouldRefresh: true,
    tableLoading: false,
    idsInDatabase: undefined,
    filter: {
      filterByModifiedBy: undefined,
      filterByLink: undefined,
      filterByActive: undefined,
    },
    data: [],
    defaultSortBehaviourActive: true,
  },
  newsExist: true,
  createdNews: false,
};

export const asyncActions = {
  NEW_NEWS: createAsyncThunk('NEW_NEWS', async (payload: any) => {
    const body: INewsBody = {
      id: payload.id,
      active: payload.active,
      image: payload.image,
      link: payload.link,
      contents: [
        {
          id: payload.pt.id,
          language: 'PORTUGUESE',
          title: payload.pt.title,
          subtitle: payload.pt.subtitle,
        },
        {
          id: payload.en.id,
          language: 'ENGLISH',
          title: payload.en.title,
          subtitle: payload.en.subtitle,
        },
        {
          id: payload.es.id,
          language: 'SPANISH',
          title: payload.es.title,
          subtitle: payload.es.subtitle,
        },
      ],
    };

    const newNewsResponse = await administrativePanelNewsApi.newNews(body);

    return {
      newsCreated: newNewsResponse.data,
    };
  }),
  NEWS_LIST: createAsyncThunk('NEWS_LIST', async (queryParams: string) => {
    const result = await administrativePanelNewsApi.getNewsList(queryParams);
    const dataToList = result.data.content.map((x, index) => {
      return {
        ...x,
        indexInStore: index,
      };
    });
    return {
      dataToList,
      totalElements: result.data.totalElements,
      idsInDatabase: result.data.idsInDatabase,
    };
  }),
  GET_NEWS_BY_ID: createAsyncThunk('GET_NEWS_BY_ID', async (payload: any) => {
    const newsResponse = await administrativePanelNewsApi.getNewsById(payload);

    return newsResponse.data;
  }),
  UPDATE_NEWS: createAsyncThunk('UPDATE_NEWS', async (payload: any) => {
    const body: INewsBody = {
      id: payload.id,
      active: payload.active,
      image: payload.image,
      link: payload.link,
      contents: [
        {
          id: payload.pt.id,
          language: 'PORTUGUESE',
          title: payload.pt.title,
          subtitle: payload.pt.subtitle,
        },
        {
          id: payload.en.id,
          language: 'ENGLISH',
          title: payload.en.title,
          subtitle: payload.en.subtitle,
        },
        {
          id: payload.es.id,
          language: 'SPANISH',
          title: payload.es.title,
          subtitle: payload.es.subtitle,
        },
      ],
    };
    const updateNewResponse = await administrativePanelNewsApi.updateNews(
      payload.id,
      body,
    );

    return updateNewResponse;
  }),
  DELETE_ONE_NEWS: createAsyncThunk('DELETE_ONE_NEWS', async (id: number) => {
    const newsDelete = await administrativePanelNewsApi.deleteOne(id);
    return {
      deleteOneNewsId: newsDelete.data,
    };
  }),
  DELETE_MANY_NEWS: createAsyncThunk(
    'DELETE_MANY_NEWS',
    async (ids: number[]) => {
      const manyNewsDelete = await administrativePanelNewsApi.deleteMany(ids);

      return {
        deleteManyNewsIds: manyNewsDelete.data,
        status: manyNewsDelete.status,
      };
    },
  ),
  ACTIVATE_NEWS: createAsyncThunk('ACTIVATE_NEWS', async (id: number) => {
    await administrativePanelNewsApi.activateNews(id);
  }),
  DEACTIVATE_NEWS: createAsyncThunk('DEACTIVATE_NEWS', async (id: number) => {
    await administrativePanelNewsApi.deactivateNews(id);
  }),
};

export const NewsSlice = createSlice({
  name: 'newsSlice',
  initialState,
  reducers: {
    SAVE_EDITOR_CURRENT_VALUE: (state, action) => {
      switch (state.selectedLanguage) {
        case 'pt':
          state.form.pt = action.payload.editorContent;
          break;

        case 'en':
          state.form.en = action.payload.editorContent;
          break;

        case 'es':
          state.form.es = action.payload.editorContent;
          break;

        default:
          break;
      }

      state.form.link = action.payload.link;
      state.form.image = action.payload.image;
      state.shouldValidateForm = action.payload.shouldValidateForm;
    },
    CHANGE_SELECTED_LANGUAGE_AND_SAVE_EDITOR_CURRENT_VALUE: (state, action) => {
      switch (state.selectedLanguage) {
        case 'pt':
          state.form.pt = action.payload.editorContent;
          break;

        case 'en':
          state.form.en = action.payload.editorContent;
          break;

        case 'es':
          state.form.es = action.payload.editorContent;
          break;

        default:
          break;
      }

      state.selectedLanguage = action.payload.newLanguage;
      state.form.link = action.payload.link;
    },
    NEWS_BACK_TO_ORIGINAL_STATE: () => {
      return {
        ...initialState,
      };
    },
    NEWS_BACK_TO_ORIGINAL_STATE_PERSISTING_FILTER: (state) => {
      return {
        ...initialState,
        list: {
          ...initialState.list,
          queryString: state.list.queryString,
          showFilter: state.list.showFilter,
          sortBy: state.list.sortBy,
          pageIndex: state.list.pageIndex,
          filter: state.list.filter,
          defaultSortBehaviourActive: state.list.defaultSortBehaviourActive,
        },
      };
    },
    SET_EDIT_MODE: (state) => {
      state.showEditMode = true;
    },
    SET_SHOW_FILTERS: (state, action) => {
      state.list.showFilter = action.payload;
    },
    NEWS_CHANGE_PAGE: (state, action) => {
      state.list.pageIndex = action.payload;
    },
    NEWS_SORT_BY: (state, action) => {
      state.list.shouldRefresh = true;
      state.list.sortBy = action.payload;
      state.list.defaultSortBehaviourActive = false;
    },
    CLEAR_FILTER_FIELDS: (state) => {
      if (state.list.queryString) {
        state.list.pageIndex = initialState.list.pageIndex;
      }
      state.list.queryString = initialState.list.queryString;
      state.list.filter = initialState.list.filter;
      state.list.shouldRefresh = true;
    },
    NEWS_CREATED_SET_ID_TO_DELETE_NEWS: (state, action) => {
      // Checks if IDs provided by PagingTable are also present in DataBase(idsInDatabase)
      if (state.list.idsInDatabase && state.list.idsInDatabase?.length > 0) {
        const payloadKeyExist = state.list.idsInDatabase.filter((id) =>
          action.payload.includes(id),
        );

        state.list.deleteManyNewsIds = payloadKeyExist;
      }
    },
    NEWS_CREATED_SET_SELECTED_ROWS: (state, action) => {
      if (state.list.idsInDatabase && state.list.idsInDatabase?.length > 0) {
        const payloadKeyId = action.payload.map((y: any) => y.id);
        const payloadKeyExist = state.list.idsInDatabase.filter((id) =>
          payloadKeyId.includes(id),
        );

        state.list.selectedRowToDeleteNews = payloadKeyExist.length;
      }
    },
    CHANGE_BACKGROUND_IMAGE_NEWS: (state, action) => {
      state.form.image = action.payload;
    },
    FILTER_STATUS_CHANGE: (state, action) => {
      state.list.showFilter = action.payload;
    },
    NEWS_FILTER_SET_MODIFIED_BY: (state, action) => {
      state.list.filter.filterByModifiedBy = action.payload;
    },
    NEWS_FILTER_SET_LINK: (state, action) => {
      state.list.filter.filterByLink = action.payload;
    },
    NEWS_FILTER_SET_STATUS: (state, action) => {
      state.list.filter.filterByActive = action.payload;
    },
    NEWS_SET_FILTER_QUERY_STRING: (state, action) => {
      state.list.queryString = action.payload;
      state.list.shouldRefresh = true;
      state.list.pageIndex = initialState.list.pageIndex;
    },
    STOP_FORM_VALIDATION: (state) => {
      state.shouldValidateForm = false;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncActions.NEW_NEWS.fulfilled, (state) => {
      state.createdNews = true;
      state.shouldValidateForm = false;
      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-register-news-title'),
        unhookedTranslation('administrative-panel-register-news-content'),
      );
    }),
    builder.addCase(asyncActions.NEWS_LIST.pending, (state) => {
      state.list.tableLoading = true;
      state.list.shouldRefresh = false;
    }),
    builder.addCase(asyncActions.NEWS_LIST.rejected, (state) => {
      state.list.tableLoading = false;
    }),
    builder.addCase(asyncActions.NEWS_LIST.fulfilled, (state, action) => {
      state.list.tableLoading = false;
      state.list.numberOfItems = action.payload.totalElements;
      state.list.idsInDatabase = action.payload.idsInDatabase;
      state.list.data = action.payload.dataToList;

      if (state.list.deleteManyNewsIds.length > 0) {
        const matchedIds = state.list.deleteManyNewsIds.filter((id) =>
          state.list.idsInDatabase?.includes(id),
        );

        if (matchedIds && matchedIds.length > 0) {
          state.list.selectedRowToDeleteNews = matchedIds.length;
        }
      }
    }),
    builder.addCase(asyncActions.GET_NEWS_BY_ID.fulfilled, (state, action) => {
      updateNewState(state, action);
      // state.isContentLoaded = true
    }),
    builder.addCase(asyncActions.GET_NEWS_BY_ID.rejected, (state) => {
      state.newsExist = false;
    }),
    builder.addCase(asyncActions.UPDATE_NEWS.fulfilled, (state) => {
      state.showEditMode = false;
      state.list.shouldRefresh = true;
      state.shouldValidateForm = false;
      state.createdNews = true;
      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-update-news-title'),
        unhookedTranslation('administrative-panel-update-news-content'),
      );
    }),
    builder.addCase(asyncActions.DELETE_ONE_NEWS.fulfilled, (state) => {
      state.list.shouldRefresh = true;

      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-delete-news-title'),
        unhookedTranslation('administrative-panel-delete-news-content'),
      );
    }),
    builder.addCase(asyncActions.DELETE_MANY_NEWS.fulfilled, (state) => {
      const numberOfRecords = state.list.selectedRowToDeleteNews;
      state.list.shouldRefresh = true;
      state.list.pageIndex = 1;
      state.list.selectedRowToDeleteNews = 0;
      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-delete-news-title'),
        unhookedTranslation(
          numberOfRecords <= 1
            ? 'administrative-panel-delete-news-content'
            : 'administrative-panel-delete-many-news-content',
        ),
      );
    }),
    builder.addCase(asyncActions.ACTIVATE_NEWS.fulfilled, (state) => {
      state.list.shouldRefresh = true;
      state.list.selectedRowToDeleteNews = 0;

      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-news-activated-title'),
        unhookedTranslation('administrative-panel-news-activated-content'),
      );
    }),
    builder.addCase(asyncActions.DEACTIVATE_NEWS.fulfilled, (state) => {
      state.list.shouldRefresh = true;
      state.list.selectedRowToDeleteNews = 0;

      messageNotification.successMessage(
        unhookedTranslation('administrative-panel-news-deactivated-title'),
        unhookedTranslation('administrative-panel-news-deactivated-content'),
      );
    }),
  ],
});

const updateNewState = (
  state: IAdministrativePanelNewsState,
  action: PayloadAction<INewNews>,
) => {
  const mappedRes = mapResToState(action.payload);

  state.original = mappedRes;
  state.form = {
    active: mappedRes.active,
    link: mappedRes.link,
    image: mappedRes.image,
    pt: {
      title: mappedRes.pt.title,
      subtitle: mappedRes.pt.subtitle,
    },
    en: {
      title: mappedRes.en?.title,
      subtitle: mappedRes.en?.subtitle,
    },
    es: {
      title: mappedRes.es?.title,
      subtitle: mappedRes.es?.subtitle,
    },
  };
};

/** Maps Backend Response to State */
const mapResToState = (res: INewNews): TNewDTO => {
  const { link, image, active, id } = res;
  const languagesContents = res.contents.reduce((acc: any, cur: any) => {
    const languageKey = NEWS_LANGUAGE_MAP.get(cur.language) as string;

    return {
      ...acc,
      [languageKey]: {
        id: cur.id,
        title: cur.title,
        subtitle: cur.subtitle,
      },
    };
  }, {});

  return {
    id,
    active,
    link,
    image,
    ...languagesContents,
  };
};
