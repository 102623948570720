import { createSlice } from '@reduxjs/toolkit';

export interface IAdministrativePanelInitialState {
  currentTab: string | undefined;
}

export interface ITab<T> {
  data: T[];
  shouldRefresh: boolean;
  tableLoading: boolean;
  sortBy: string | undefined;
  pageSize: number;
  pageIndex: number;
  numberOfItems: number;
  idsInDatabase: number[] | undefined;
}

const initialState: IAdministrativePanelInitialState = {
  currentTab: undefined,
};

export const AdministrativePanelSlice = createSlice({
  name: 'administrativePanelSlice',
  initialState,
  reducers: {
    TAB_CHANGE: (state, action) => {
      state.currentTab = action.payload;
    },
    BACK_TO_ORIGINAL_STATE: () => {
      return initialState;
    },
  },
});
