import { nonSecuredBaseApi, nonSecuredMultipartApi } from './api.config';
import { IRequestAccessGetCompanyResponse } from '../interfaces/services-interfaces/IRequestAccessGetCompanyResponse';
import { ICountriesListInsideContent } from './administrative-panel-companies';

export interface IRequestNewCompany {
  company: {
    id: number;
    name: string;
    address: string;
    country: {
      id: number;
      code: string;
      name: string;
      companyIdentifierType: string;
    };
    createdAt: string;
    updatedAt: string;
    responsibleEmail: string;
    emailDomain: string;
    identifier: string;
  };
}

export const requestAccessApi = {
  getCompany: (companySearchQueryParams: string) => {
    return nonSecuredBaseApi.get<IRequestAccessGetCompanyResponse>(
      `/public/company?${companySearchQueryParams}`,
    );
  },
  requestCompany: (company: IRequestNewCompany) => {
    return nonSecuredBaseApi.post<IRequestNewCompany>(
      '/public/solicitation/company',
      company,
    );
  },
  getCountriesList: () => {
    return nonSecuredBaseApi.get<ICountriesListInsideContent>('/countries');
  },
  finishRequestAccess: (newRequestAccess: FormData) => {
    return nonSecuredMultipartApi.post<any>(
      '/public/request_access',
      newRequestAccess,
    );
  },
  finishRequestAccessAsSolicitation: (
    newRequestAccessAsSolicitation: FormData,
  ) => {
    return nonSecuredMultipartApi.post<any>(
      '/public/solicitation/user',
      newRequestAccessAsSolicitation,
    );
  },
};
