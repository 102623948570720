import { Row, Col, Input, Button } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import uniqid from 'uniqid';

import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { messageNotification } from '../../../../helpers/messageNotification/messageNotification';
import { AppDispatch, RootState } from '../../../../store/store';
import { LanguageSelection } from './LanguageSelection/LanguageSelection';

import './NewTerms.scss';
import { NewTermsAsyncActions, NewTermsSlice } from './NewTerms.Slice';
import { TermsEditor } from './TermsEditor/TermsEditor';
import { showModal } from '../../../../helpers/showModal/showModal';
import { useScrollToTop } from '../../../../helpers/useScrollToTop/useScrollToTop';

export const NewTerms = () => {
  const versionInput = useRef(null);
  const editorInput = useRef(null);
  const navigate = useNavigate();
  const termVersion = useSelector(
    (state: RootState) => state.createEditTerms.form.version,
  );
  const termsValuesInLanguages = useSelector(
    (state: RootState) => state.createEditTerms.form,
  );
  const termDto = useSelector(
    (state: RootState) => state.createEditTerms.termDTO,
  );

  const languageDropdown = useSelector(
    (state: RootState) => state.createEditTerms.language.selectedLanguage,
  );
  useScrollToTop();

  const dispatch = useDispatch<AppDispatch>();
  const parsedUrl = queryString.parse(window.location.search);

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(NewTermsSlice.actions.RESET_TO_INITIAL_STATE());
    };
  }, [dispatch]);

  useEffect(() => {
    if (termsValuesInLanguages.alreadyInEditMode) {
      return;
    }

    if (parsedUrl.id) {
      dispatch(
        NewTermsAsyncActions.GET_TERM_BY_ID(parsedUrl.id as unknown as number),
      );
    }
  }, [parsedUrl, termsValuesInLanguages.alreadyInEditMode, dispatch]);

  useEffect(() => {
    if (!termsValuesInLanguages.finishedCreatingOrEditing) {
      return;
    }

    navigate('/administrative-panel?tab=terms');
  }, [termsValuesInLanguages.finishedCreatingOrEditing, history]);

  const filterEditorContent = (content: any) => {
    const spanStart = /<\s*span[^>]*>/g;
    const spanEnd = /<\/\s*span[^>]*>/g;
    const linkStart = /<\s*a[^>]*>/g;
    const linkEnd = /<\/\s*a[^>]*>/g;

    return content
      .replace(/<[^>]+>/g, '')
      .replace(' ', '')
      .replace('<h1>', '')
      .replace('</h1>', '')
      .replace('<h2>', '')
      .replace('</h2>', '')
      .replace('<p>', '')
      .replace('</p>', '')
      .replace('<p style="text-align: left;">', '')
      .replace('</>', '')
      .replace('&amp;', '&')
      .replace('&lt;', '<')
      .replace('&gt;', '>')
      .replace('<strong>', '')
      .replace('</strong>', '')
      .replace('<em>', '')
      .replace('</em>', '')
      .replace('<U>', '')
      .replace('</U>', '')
      .replace('<span style="text-decoration: underline;">', '')
      .replace('</span>', '')
      .replace(spanStart, '')
      .replace(spanEnd, '')
      .replace(linkStart, '')
      .replace(linkEnd, '')
      .replace('&nbsp;', '')
      .replace('<br data-mce-bogus="1">', '')
      .replace('<br>', '')
      .trim();
  };

  useEffect(() => {
    if (!termsValuesInLanguages.startFormValidation) {
      return;
    }

    if (
      termVersion.trim().length <= 0 ||
      filterEditorContent(termsValuesInLanguages.ptContent).length <= 0 ||
      filterEditorContent(termsValuesInLanguages.enContent).length <= 0 ||
      filterEditorContent(termsValuesInLanguages.esContent).length <= 0 ||
      filterEditorContent(editorInput.current).length <= 0
    ) {
      dispatch(NewTermsSlice.actions.STOP_FORM_VALIDATION());
      return messageNotification.errorMessage(
        t('administrative-panel-language-content-validation'),
        t('terms-create-or-edit-validation-content'),
      );
    }

    if (parsedUrl.id) {
      const updatedTerm = _.cloneDeep(termDto);

      updatedTerm!.contents.find(
        (x) => x.language === 'PORTUGUESE',
      )!.description = termsValuesInLanguages.ptContent;

      updatedTerm!.contents.find((x) => x.language === 'ENGLISH')!.description =
        termsValuesInLanguages.enContent;

      updatedTerm!.contents.find((x) => x.language === 'SPANISH')!.description =
        termsValuesInLanguages.esContent;

      updatedTerm!.version = termVersion;

      dispatch(
        NewTermsAsyncActions.UPDATE_TERM({
          termId: updatedTerm!.id,
          updatedTerm,
        }),
      );
      return;
    }

    const termContents = [];
    termContents.push({
      language: 'PORTUGUESE',
      description: termsValuesInLanguages.ptContent,
    });
    termContents.push({
      language: 'ENGLISH',
      description: termsValuesInLanguages.enContent,
    });
    termContents.push({
      language: 'SPANISH',
      description: termsValuesInLanguages.esContent,
    });

    const termBody = {
      contents: termContents,
      version: termVersion,
    };

    dispatch(NewTermsAsyncActions.CREATE_A_NEW_TERM(termBody));
  }, [
    dispatch,
    termVersion,
    termsValuesInLanguages.ptContent,
    termsValuesInLanguages.enContent,
    termsValuesInLanguages.esContent,
    termsValuesInLanguages.startFormValidation,
    parsedUrl.id,
    t,
    termDto,
  ]);

  const onSubmit = () => {
    dispatch(
      NewTermsSlice.actions.UPDATE_TERM_VERSION_AND_CONTENT({
        formVersion: (versionInput.current as any).input.value,
        editorContent: editorInput.current,
      }),
    );
  };

  const returnOrShowModalOfDiscardChanges = () => {
    if (checkChanges()) {
      return showModal(
        'warning',
        t('documentation-confirmation-cancel-editing-title'),
        t('documentation-confirmation-cancel-editing-content'),
        () => navigate(-1),
      );
    }
    navigate(-1);
  };

  const checkChanges = () => {
    const formVersion = (versionInput.current as any).state?.value;
    const editorContent = editorInput.current;

    if (!termsValuesInLanguages.alreadyInEditMode) {
      if (languageDropdown === 'pt') {
        return (
          formVersion ||
          editorContent ||
          termsValuesInLanguages.enContent ||
          termsValuesInLanguages.esContent ||
          termsValuesInLanguages.startFormValidation ||
          false
        );
      }
      if (languageDropdown === 'en') {
        return (
          formVersion ||
          editorContent ||
          termsValuesInLanguages.ptContent ||
          termsValuesInLanguages.esContent ||
          termsValuesInLanguages.startFormValidation ||
          false
        );
      }
      if (languageDropdown === 'es') {
        return (
          formVersion ||
          editorContent ||
          termsValuesInLanguages.ptContent ||
          termsValuesInLanguages.enContent ||
          termsValuesInLanguages.startFormValidation ||
          false
        );
      }
    }
    if (languageDropdown === 'pt') {
      return (
        termsValuesInLanguages.originalVersion !== formVersion ||
        termsValuesInLanguages.originalPtContent !== editorContent ||
        termsValuesInLanguages.originalEnContent !==
          termsValuesInLanguages.enContent ||
        termsValuesInLanguages.originalEsContent !==
          termsValuesInLanguages.esContent ||
        false
      );
    }
    if (languageDropdown === 'en') {
      return (
        termsValuesInLanguages.originalVersion !== formVersion ||
        termsValuesInLanguages.originalEnContent !== editorContent ||
        termsValuesInLanguages.originalPtContent !==
          termsValuesInLanguages.ptContent ||
        termsValuesInLanguages.originalEsContent !==
          termsValuesInLanguages.esContent ||
        false
      );
    }
    if (languageDropdown === 'es') {
      return (
        termsValuesInLanguages.originalVersion !== formVersion ||
        termsValuesInLanguages.originalEsContent !== editorContent ||
        termsValuesInLanguages.originalEnContent !==
          termsValuesInLanguages.enContent ||
        termsValuesInLanguages.originalPtContent !==
          termsValuesInLanguages.ptContent ||
        false
      );
    }
    return false;
  };

  return (
    <Row className="NewTerms">
      <Col span={24}>
        <Row>
          <Col span={6}>
            <span className="version-label">
              {' '}
              {t('terms-create-or-edit-version')}{' '}
            </span>
            <Input
              key={`${termsValuesInLanguages.alreadyInEditMode}${uniqid()}`}
              id="term-version-input"
              ref={versionInput}
              maxLength={50}
              defaultValue={termVersion}
              className="version-input"
              placeholder={t('terms-create-or-edit-palceholder-version')}
            />
          </Col>
          <Col className="buttons-area" offset={12} span={4}>
            <span
              className="cancel-button"
              onClick={() => {
                returnOrShowModalOfDiscardChanges();
              }}
            >
              {t('terms-create-or-edit-cancel-button')}
            </span>
            <Button type="primary" ghost shape="round" onClick={onSubmit}>
              {t('terms-create-or-edit-save-button')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={2}>
            <LanguageSelection
              inputRef={versionInput}
              editorRef={editorInput}
            />
          </Col>
          <Col offset={2} span={18}>
            <TermsEditor editorRef={editorInput} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
