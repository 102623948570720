import { Modal, Tabs, Tag } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid';
import { RootState } from '../../../../../store/store';
import { TermsListingSlice } from '../TermListing.Slice';
import { getEditorToPreview } from '../../../../../helpers/getEditorToPreview/getEditorToPreview';

export const PreviewTerm = () => {
  const previewTermState = useSelector(
    (state: RootState) => state.termsListing.previewTerm,
  );
  const navbarState = useSelector((state: RootState) => state.navBar);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!navbarState.loadedDefaultLanguage) {
      return;
    }

    if (previewTermState.previewTermLanguage) {
      return;
    }

    dispatch(
      TermsListingSlice.actions.SET_PREVIEW_TERM_LANGUAGE(
        navbarState.defaultLanguage,
      ),
    );
  }, [
    dispatch,
    navbarState.loadedDefaultLanguage,
    previewTermState.previewTermLanguage,
    navbarState.defaultLanguage,
  ]);

  return (
    <Modal
      title={t('terms-preview-modal-title')}
      visible={previewTermState.version !== ''}
      destroyOnClose
      centered
      onCancel={() => {
        dispatch(TermsListingSlice.actions.CLOSE_PREVIEW_TERM());
      }}
      footer={null}
    >
      <p>
        {t('terms-preview-modal-term-version-label')}:
        <Tag color="magenta" style={{ marginLeft: '1rem' }}>
          {previewTermState.version}
        </Tag>
      </p>

      <Tabs
        key={`${previewTermState.previewTermLanguage}${uniqid()}`}
        activeKey={previewTermState.previewTermLanguage}
        onChange={(activeKey) => {
          dispatch(
            TermsListingSlice.actions.SET_PREVIEW_TERM_LANGUAGE(activeKey),
          );
        }}
        centered
      >
        <Tabs.TabPane tab={t('request-access-terms-pt-tab')} key="pt">
          {getEditorToPreview(previewTermState.ptContent)}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('request-access-terms-en-tab')} key="en">
          {getEditorToPreview(previewTermState.enContent)}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('request-access-terms-es-tab')} key="es">
          {getEditorToPreview(previewTermState.esContent)}
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};
