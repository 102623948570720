import { securedBaseApi } from './api.config';

export interface IConcludedRequestsResponse {
  content: IConcludedRequest[];
  totalElements: number;
}

export interface IConcludedRequest {
  date: Date;
  email: string;
  name: string;
  status: string;
  type: string;
  analyzedBy: string;
}

export interface IConcludedRequestResponseById {
  type: string;
  id: number;
  status: string;
  analyzedBy: string;
  name: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  createdAt: string;
  companyAddress: string | undefined;
  companyIdentifier: string | undefined;
  country: {
    id: number;
    code: string;
    name: string | undefined;
  };
  userCompany: {
    identifier: string;
    name: string;
  };
}

export const administrativePanelConcludedRequestsApi = {
  getConcludedRequests: (queryParams: string) => {
    return securedBaseApi.get<IConcludedRequestsResponse>(
      `/solicitations/history?${queryParams}`,
    );
  },
  getConcludedRequestById: (id: number) => {
    return securedBaseApi.get<IConcludedRequestResponseById>(
      `/solicitations/history/${id}`,
    );
  },
  approveRequest: (requestId: string) => {
    return securedBaseApi.patch(`/solicitations/${requestId}/approve`);
  },
  refuseRequest: (requestId: string) => {
    return securedBaseApi.patch(`/solicitations/${requestId}/refuse`);
  },
};
