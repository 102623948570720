import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { openDevPortalRedirectUrl } from '../../../helpers/repositoryRedirector/repositoryRedirector'
import { defaultLanguage } from '../../../i18n'
import { loginFormApi } from '../../../services/login-form.api'
import { v2v3IntercommunicationApi } from '../../../services/v2-v3-intercommunication.api'

interface INavbarInitialState {
  defaultLanguage: string
  languageToNotLoggedInUser: boolean
  loadedDefaultLanguage: boolean
}

const initialState: INavbarInitialState = {
  defaultLanguage,
  languageToNotLoggedInUser: false,
  loadedDefaultLanguage: false,
}

export const asyncActions = {
  PREFERENCELANGUAGE: createAsyncThunk(
    'PREFERENCELANGUAGE',
    async (language: any) => {
      await loginFormApi.languagePost(language)
      return language
    }
  ),
  GET_TRANSIENT_TOKEN_AND_REDIRECT: createAsyncThunk(
    'GET_TRANSIENT_TOKEN_AND_REDIRECT',
    async (redirectUrl: string) => {
      const transientToken = await v2v3IntercommunicationApi.getTransientToken()
      return {
        token: transientToken.headers.authorization,
        redirectUrl,
      }
    }
  ),
}

export const NavbarSlice = createSlice({
  name: 'navbarSlice',
  initialState,
  reducers: {
    CHANGE_DEFAULT_LANGUAGE: (state, action) => {
      state.defaultLanguage = action.payload
    },
    LOAD_USER_PREFERENCE_LANGUAGE: (state, action) => {
      state.defaultLanguage = action.payload
      state.loadedDefaultLanguage = true
    },
    SET_LOADED_DEFAULT_LANGUAGE_AFTER_FAILED_LOGIN: (state) => {
      state.loadedDefaultLanguage = true
    },
  },
  extraReducers: (builder) => [
    builder.addCase(
      asyncActions.PREFERENCELANGUAGE.fulfilled,
      (state, action) => {
        state.defaultLanguage = action.payload
        state.languageToNotLoggedInUser = true
      }
    ),
    builder.addCase(
      asyncActions.GET_TRANSIENT_TOKEN_AND_REDIRECT.fulfilled,
      (_, action) => {
        const transientToken = action.payload.token.split('Bearer ')[1]

        openDevPortalRedirectUrl(transientToken, action.payload.redirectUrl)
      }
    ),
  ],
})
