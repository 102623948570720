import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IngenicoWorld } from '../../../components/IngenicoWorld/IngenicoWorld';
import './ConcludedCreatePassword.scss';

export const ConcludedCreatePassword = () => {
  const { t } = useTranslation();
  return (
    <Col pull={1} span={22} push={1}>
      <IngenicoWorld title={t('concluded-create-password-response')} showThanks>
        <Row className="ConcludedCreatePassword">
          <Col>
            <p className="concluded-create-password-message">
              {t('concluded-create-password-message')}
            </p>
          </Col>
        </Row>
      </IngenicoWorld>
    </Col>
  );
};
