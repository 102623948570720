import { messageNotification } from '../messageNotification/messageNotification';

export const onStartMessage = (storedOnStartMessage: string | null) => {
  if (!storedOnStartMessage) {
    return;
  }

  switch (storedOnStartMessage) {
    case 're-authenticate':
      messageNotification.warningMessage(
        'Your Token has expired',
        'To continue using the application, please log-in again',
      );
      break;

    case 'forbidden':
      messageNotification.errorMessage(
        'Forbidden Access',
        'You dont have access to the following content. For further details, please enter in contact with support.',
      );
      break;

    case 'network-error':
      messageNotification.errorMessage(
        'Problems in Server',
        'The server is down or out of reach. Please contact administration for help or assistance.',
      );
      break;

    default:
      break;
  }

  localStorage.removeItem('on-start-message');
};
