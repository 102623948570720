import React, { Fragment } from 'react';

import { Row, Col } from 'antd';

import './Header.scss';
import { ReactComponent as EllipsisBlue } from '../../assets/ellipsis_blue.svg';
import { ReactComponent as EllipsisRed } from '../../assets/ellipsis_red.svg';

interface IHeader {
  children: JSX.Element;
  title: string | JSX.Element | undefined;
  showEllipsis?: boolean;
  subtitle?: string | JSX.Element;
}

export const Header = ({
  children,
  title,
  showEllipsis = false,
  subtitle,
}: IHeader) => {
  const showRedAndBlueEllipsis = () => {
    if (showEllipsis) {
      return (
        <Fragment>
          <EllipsisRed className="red-ellipsis" />
          <EllipsisBlue />
        </Fragment>
      );
    }
  };
  const showSubtitle = () => {
    if (subtitle) {
      return <span>{subtitle}</span>;
    }
  };
  return (
    <Row className="Header">
      <Col className="left-column" span={24}>
        <Row>
          <Col className="left-column-header" span={16}>
            <hr className="left-column-header-divider" />
            <h3 className="left-column-header-title"> {title} </h3>
            {showSubtitle()}
          </Col>
          <Col className="right-column" span={8}>
            {showRedAndBlueEllipsis()}
          </Col>
        </Row>
        {children}
      </Col>
    </Row>
  );
};
