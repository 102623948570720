import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { unhookedTranslation } from '../unhookedTranslation/unhookedTranslation';

export const showModal = (
  type: 'warning' | 'confirm' | 'info',
  title: string,
  content: string | JSX.Element,
  onOk: Function,
  onCancel: Function = () => {},
  onOkText: string = unhookedTranslation(
    'documentation-confirmation-cancel-editing-yes-button',
  ),
  onCancelText: string = unhookedTranslation(
    'documentation-confirmation-cancel-editing-no-button',
  ),
  icon?: JSX.Element,
  className?: string,
) => {
  const modalDefaultSettings = {
    title,
    icon,
    content,
    centered: true,
    maskClosable: true,
    onOk: () => onOk(),
    onCancel: () => onCancel(),
    okText: onOkText,
    cancelText: onCancelText,
    className,
  };

  switch (type) {
    case 'info':
      Modal.info({
        ...modalDefaultSettings,
        icon: icon || (
          <ExclamationCircleOutlined style={{ color: '#46B8A6' }} />
        ),
      });
      break;

    case 'warning':
      Modal.confirm({
        ...modalDefaultSettings,
        icon: icon || <ExclamationCircleOutlined />,
      });
      break;

    case 'confirm':
      Modal.confirm({ ...modalDefaultSettings });
      break;

    default:
      break;
  }
};
