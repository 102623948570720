import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IngenicoWorld } from '../../../components/IngenicoWorld/IngenicoWorld';
import { RequestAccessSlice } from '../RequestAccess.Slice';
import './ConcludedRequestAccess.scss';
import { RootState } from '../../../store/store';
import { useScrollToTop } from '../../../helpers/useScrollToTop/useScrollToTop';

export const ConcludedRequestAccess = () => {
  useScrollToTop('concluded-request-access-container');
  const requestAccessState = useSelector(
    (state: RootState) => state.requestAccess,
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { actions } = RequestAccessSlice;

  useEffect(() => {
    if (!requestAccessState.concluded) {
      navigate('/home');
    }
  }, [requestAccessState.concluded, history]);

  useEffect(() => {
    return () => {
      dispatch(actions.BACK_TO_ORIGINAL_STATE());
    };
  }, [dispatch, actions]);

  const shiftTitleBetweenCompaniesWithDomainOrWithout = () => {
    if (requestAccessState.companyStep.requestCompany) {
      return t('ingenico-world-request-company-response');
    }

    if (requestAccessState.companyStep.company.emailDomain) {
      return t('ingenico-world-request-auto-approved-response');
    }

    return t('ingenico-word-request-response');
  };

  const shiftDescriptionBetweenCompaniesWithDomainOrWithout = () => {
    if (requestAccessState.companyStep.requestCompany) {
      return t('ingenico-world-request-company-message');
    }

    if (requestAccessState.companyStep.company.emailDomain) {
      return t('request-access-auto-approved-message');
    }

    return t('request-access-message-warning');
  };

  return (
    <Col push={1} span={22} pull={1} id="concluded-request-access-container">
      <IngenicoWorld
        title={shiftTitleBetweenCompaniesWithDomainOrWithout()}
        showThanks
      >
        <Row className="ConcludedRequestAccess">
          <Col>
            <p className="concluded-request-access-message">
              {shiftDescriptionBetweenCompaniesWithDomainOrWithout()}
            </p>
          </Col>
        </Row>
      </IngenicoWorld>
    </Col>
  );
};
