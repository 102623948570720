import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { activeModalApi, IPopUpDTO } from '../../services/active-modal.api';

export interface IActiveModalState {
  isContentLoaded: boolean;
  isVisible: boolean;
  isClosed: boolean;
  modal: IPopUpDTO;
}

const initialState: IActiveModalState = {
  isContentLoaded: false,
  isVisible: false,
  isClosed: false,
  modal: {
    id: 0,
    active: false,
    type: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    contents: [],
  },
};

export const activeModalAsyncActions = {
  GET_MODAL: createAsyncThunk('GET_MODAL', async () => {
    const res = await activeModalApi.getPublicPopUp();

    return res.data;
  }),
};

export const ActiveModalSlice = createSlice({
  name: 'activeModalSlice',
  initialState,
  reducers: {
    RESET_TO_INITIAL_STATE: () => {
      return initialState;
    },
    OPEN_MODAL: (state) => {
      state.isVisible = true;
    },
    CLOSE_MODAL: (state) => {
      state.isVisible = false;
      state.isClosed = true;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(activeModalAsyncActions.GET_MODAL.pending, (state) => {
      state.isContentLoaded = false;
    }),
    builder.addCase(
      activeModalAsyncActions.GET_MODAL.fulfilled,
      (state, action) => {
        state.modal = action.payload;

        if (action.payload.contents) {
          state.isContentLoaded = true;
        }
      },
    ),
  ],
});
