import React from 'react';
import { Steps, Row, Col } from 'antd';

import './StepsRequestAccess.scss';

interface IStepsRequestAccess {
  mapStep: Function;
  generateRequestAccessStepButtons: Function;
  currentStep: number;
  currentStepContent: JSX.Element;
}

export const StepsRequestAccess: React.FC<IStepsRequestAccess> = ({
  mapStep,
  generateRequestAccessStepButtons,
  currentStep,
  currentStepContent,
}: IStepsRequestAccess) => {
  return (
    <Row className="Steps">
      <Col span={24}>
        <Row className="steps-align">
          <Col span={15}>
            <Steps progressDot current={currentStep} size="small">
              {mapStep()}
            </Steps>
          </Col>
        </Row>
        <Row className="steps-align">
          <Col className="steps-content" span={15}>
            {currentStepContent}
            <Row className="steps-action">
              <Col>{generateRequestAccessStepButtons()}</Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
