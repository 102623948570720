import {
  DeploymentUnitOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import ReactQuill from 'react-quill-new';
import { Col, Radio, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';
import TextArea from 'antd/lib/input/TextArea';
import { TPopUpType } from '../../../../../services/administrative-panel-pop-up';
import { RootState } from '../../../../../store/store';
import './PopUpEditor.scss';
import 'react-quill-new/dist/quill.snow.css';

export interface IPopUpLanguageForm {
  type: TPopUpType;
  header: string;
  content: string;
}
interface IPopUpEditor {
  headerRef: React.MutableRefObject<any>;
  contentRef: React.MutableRefObject<any>;
  typeRef: React.MutableRefObject<any>;
}

export const PopUpEditor: React.FC<IPopUpEditor> = ({
  headerRef,
  contentRef,
  typeRef,
}) => {
  const { t } = useTranslation();
  const parsedUrl = queryString.parse(window.location.search);
  const popUpLanguage = useSelector(
    (state: RootState) => state.popUp.selectedLanguage,
  );
  const popUpValues = useSelector((state: RootState) => state.popUp.form);

  const [type, setType] = useState(popUpValues.type);

  useEffect(() => {
    if (popUpValues) {
      setType(popUpValues.type);
      typeRef.current = popUpValues.type;
    }
  }, [popUpValues, typeRef]);

  const getEditorDefaultValue = () => {
    switch (popUpLanguage) {
      case 'pt':
        headerRef.current = popUpValues.pt.header;
        contentRef.current = popUpValues.pt.content;
        return popUpValues.pt;
      case 'en':
        headerRef.current = popUpValues.en.header;
        contentRef.current = popUpValues.en.content;
        return popUpValues.en;
      case 'es':
        headerRef.current = popUpValues.es.header;
        contentRef.current = popUpValues.es.content;
        return popUpValues.es;

      default:
        break;
    }
  };

  const buildIconRadioGroup = (defaultValue?: any) => {
    if (parsedUrl.id && !type) return null;
    return (
      <Radio.Group
        defaultValue={defaultValue}
        onChange={(value) => {
          typeRef.current = value.target.value;
        }}
      >
        <Space direction="vertical">
          <Radio value="DEPLOY">
            <DeploymentUnitOutlined />{' '}
            <span>{t('administrative-panel-pop-up-icon-new-version')}</span>
          </Radio>
          <Radio value="INFO">
            <ExclamationCircleOutlined />{' '}
            <span>{t('administrative-panel-pop-up-icon-info')}</span>
          </Radio>
          <Radio value="ALERT">
            <WarningOutlined />{' '}
            <span>{t('administrative-panel-pop-up-icon-alert')}</span>
          </Radio>
        </Space>
      </Radio.Group>
    );
  };

  const colors = [
    '#000000',
    '#FFFFFF',
    '#4A4F55',
    '#41B4D2',
    '#F08791',
    '#F5AF82',
    '#46B8A6',
  ];

  const modules = {
    toolbar: [
      [{ background: colors }, { color: colors }],
      ['bold', 'italic', 'underline'],
      [{ list: 'bullet' }, { list: 'ordered' }],
    ],
  };

  const formats = [
    'background',
    'color',
    'bold',
    'italic',
    'underline',
    'list',
  ];

  return (
    <div className="PopUpEditor">
      <Row>
        <Col sm={24} md={6} className="pop-up-icons">
          <b>{t('administrative-panel-pop-up-icons')}</b>
          <div style={{ height: '20px' }} />
          {/* == TYPE == */}
          {buildIconRadioGroup(type)}
        </Col>
        <Col sm={24} md={18}>
          {/* == HEADER == */}
          {t('administrative-panel-pop-up-header-placeholder')}
          <TextArea
            className="pop-up-title"
            autoSize
            maxLength={50}
            defaultValue={getEditorDefaultValue()?.header}
            onChange={(value) => {
              headerRef.current = value.target.value;
              // setTitle(value)
            }}
            key={`${popUpLanguage}${uniqid()}`}
          />
          {/* == CONTENT == */}
          {t('administrative-panel-pop-up-content-placeholder')}
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            value={getEditorDefaultValue()?.content}
            onChange={(content) => {
              contentRef.current = content;
            }}
            style={{ height: 500 }}
          />
          <Row>
            <hr />
          </Row>
        </Col>
      </Row>
      <div style={{ height: '50px' }} />
      <Row justify="end" />
    </div>
  );
};
