import { store } from '../../store/store';

export const checkChangesInUserRegisterOrEdit = (mode: 'create' | 'edit') => {
  const nameInput = (document.getElementById('firstName') as HTMLInputElement)
    .value;

  const lastNameInput = (
    document.getElementById('lastName') as HTMLInputElement
  ).value;

  const companySelect = store.getState().users.newRegister.company.id;

  const emailInput = (document.getElementById('userEmail') as HTMLInputElement)
    .value;

  const userTypeSelect = store.getState().users.newRegister.userTypeOrFunction;

  if (mode === 'create') {
    const emailConfirmationInput = (
      document.getElementById('confirmEmail') as HTMLInputElement
    ).value;

    return (
      nameInput ||
      lastNameInput ||
      companySelect ||
      emailInput ||
      emailConfirmationInput ||
      userTypeSelect ||
      false
    );
  }

  const userBeforeChanges = store.getState().users.newRegister;
  return (
    userBeforeChanges.originalFirstName !== nameInput ||
    userBeforeChanges.originalLastName !== lastNameInput ||
    userBeforeChanges.originalCompany.id !== companySelect ||
    userBeforeChanges.originalUserTypeOrFunction !== userTypeSelect ||
    false
  );
};

export const checkChangesInCompanyRegisterOrEdit = (
  mode: 'create' | 'edit',
) => {
  const nameInput = (document.getElementById('companyName') as HTMLInputElement)
    .value;

  const addressInput = (
    document.getElementById('companyAdress') as HTMLInputElement
  ).value;

  const countrySelect =
    store.getState().CompanyAdministrativePanel.companies.companyRegister
      .countryId;

  const identifierInput = (
    document.getElementById('cnpjTaxId') as HTMLInputElement
  ).value;

  const responsibleEmailInput = (
    document.getElementById('responsibleEmail') as HTMLInputElement
  ).value;

  if (mode === 'create') {
    return (
      nameInput ||
      addressInput ||
      countrySelect ||
      identifierInput ||
      responsibleEmailInput ||
      false
    );
  }

  const companyBeforeChanges =
    store.getState().CompanyAdministrativePanel.companies.companyRegister;
  return (
    companyBeforeChanges.originalName !== nameInput ||
    companyBeforeChanges.originalAddress !== addressInput ||
    companyBeforeChanges.originalCountryId !== countrySelect ||
    companyBeforeChanges.originalIdentifierInput !== identifierInput ||
    companyBeforeChanges.originalResponsibleEmail !== responsibleEmailInput ||
    false
  );
};

export const checkChangesInNewsRegisterOrEdit = (mode: 'create' | 'edit') => {
  const newsTitleInput = (
    document.getElementById('newNewsTitle') as HTMLInputElement
  ).value;
  const newsSubtitleInput = (
    document.getElementById('newNewsSubtitle') as HTMLInputElement
  ).value;
  const newsLinkInput = (
    document.getElementById('newNewsLink') as HTMLInputElement
  ).value;

  if (mode === 'create') {
    return newsTitleInput || newsSubtitleInput || newsLinkInput || false;
  }

  const newsCurrentState = store.getState().news.form;
  const newsBeforeAnyUpdate = store.getState().news.original;

  return (
    newsBeforeAnyUpdate.link !== newsLinkInput ||
    newsBeforeAnyUpdate.image !== newsCurrentState.image ||
    false
  );
};
