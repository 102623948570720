import React, { useEffect, Fragment } from 'react';
import {
  Row,
  Col,
  Button,
  Space,
  Card,
  Input,
  Skeleton,
  Result,
  Spin,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FolderOpenOutlined } from '@ant-design/icons';
import { Header } from '../../components/Header/Header';
import { AppDispatch, RootState } from '../../store/store';
import { PageSearch } from '../../components/PageSearch/PageSearch';
import { ReactComponent as ReturnArrow } from '../../assets/return_arrow_icon.svg';
import { PageResult } from '../../components/PageSearch/PageResult/PageResult';

import './PagesList.scss';
import { useScrollToTop } from '../../helpers/useScrollToTop/useScrollToTop';
import {
  asyncPageActions,
  CreateReadEditPageSlice,
} from './CreateReadEditPages/CreateReadEditPage.Slice';

import { PathTrackerSlice } from '../PathTracker/PathTracker.Slice';

// This conversion is necessary because the backend expects the full name of the language in uppercase, while the navbar stores the current language as an abbreviation.
const languageMap: { [key: string]: string } = {
  en: 'ENGLISH',
  es: 'SPANISH',
  pt: 'PORTUGUESE',
};

export const PagesList = () => {
  const loginState = useSelector((state: RootState) => state.login);
  const createReadEditPageState = useSelector(
    (state: RootState) => state.createReadEditPageSlice,
  );

  const loadedPagesState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.loadedPages,
  );
  const navbarState = useSelector((state: RootState) => state.navBar);

  useScrollToTop('pages-list-id');

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const { Meta } = Card;
  const { Search } = Input;

  // The same as the one being validated on the home page
  useEffect(() => {
    const storedOnStartMessage = localStorage.getItem('on-start-message');

    const defaultLanguage = languageMap[navbarState.defaultLanguage];

    if (
      !window.location.href.includes('created-pages?networkRedirector=true')
    ) {
      dispatch(asyncPageActions.GET_ALL_PAGES(defaultLanguage));
    } else if (
      window.location.href.includes('created-pages?networkRedirector=true') &&
      !storedOnStartMessage
    ) {
      dispatch(asyncPageActions.GET_ALL_PAGES(defaultLanguage));
    }

    return () => {
      dispatch(CreateReadEditPageSlice.actions.BACK_TO_ORIGINAL_STATE());
    };
  }, [dispatch, navbarState.defaultLanguage]);

  useEffect(() => {
    if (
      loadedPagesState &&
      window.location.href.includes('created-pages?networkRedirector=true')
    ) {
      navigate('/created-pages');
    }
  }, [loadedPagesState, history]);

  const goToPageCreator = () => {
    navigate('/pages', { state: { mode: 'create' } });
  };
  const setTitleToRemakeSearchPage = (value: string) => {
    dispatch(
      asyncPageActions.FIND_PAGE_BY_TITLE({
        language: languageMap[navbarState.defaultLanguage],
        title: value,
      }),
    );
  };
  const goToSpecificPage = (slug: string) => {
    dispatch(
      PathTrackerSlice.actions.SET_PREVIOUS_PATH_FOR_PAGES('/created-pages'),
    );
    navigate(`/pages/${slug}`);
  };

  const generatePagesCardListing = () => {
    if (!createReadEditPageState.pageListResponse?.content) {
      return (
        <Row className="no-pages-existing" align="middle" justify="center">
          <Col span={24}>
            <Spin
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '200px',
              }}
              size="large"
            />
          </Col>
        </Row>
      );
    }

    if (createReadEditPageState.pageListResponse.content.length === 0) {
      return (
        <Row className="no-pages-existing" align="middle" justify="center">
          <Col span={24}>
            <Result
              icon={<FolderOpenOutlined />}
              status="404"
              title={t('documentations-listing-length-equals-to-zero-title')}
              subTitle={t(
                'documentations-listing-length-equals-to-zero-content',
              )}
            />
          </Col>
        </Row>
      );
    }

    return createReadEditPageState.pageListResponse.content
      .filter((x) => x.mainContent)
      .map((x) => {
        return (
          <Col
            className="page-card-item"
            span={10}
            key={x.page.id}
            id="card-item"
          >
            <Card onClick={() => goToSpecificPage(x.page.slug)} hoverable>
              <Meta title={x.title.toUpperCase()} description={x.description} />
            </Card>
          </Col>
        );
      });
  };

  const showButtonsIfLoggedIn = () => {
    if (!loginState.loggedIn) {
      return;
    }

    if (loginState.user?.role === 'U') {
      return;
    }

    return (
      <Col span={22} className="page-buttons-col">
        <Space>
          <Button
            name="created-pages"
            type="primary"
            shape="round"
            ghost
            onClick={() => navigate('/edit-pages')}
          >
            {t('pages-created-documentations-title')}
          </Button>
          <Button
            name="new-page"
            type="primary"
            shape="round"
            ghost
            onClick={goToPageCreator}
          >
            {t('pages-created-create-a-new-documentation')}
          </Button>
        </Space>
      </Col>
    );
  };

  const showListPagesOrResultSearch = () => {
    if (window.location.href.includes('created-pages?networkRedirector=true')) {
      return (
        <Row className="no-pages-existing" align="middle" justify="center">
          <Col span={24}>
            <Result
              icon={<FolderOpenOutlined />}
              status="404"
              title={t('return-message-network-error-documentations-title')}
              subTitle={t(
                'return-message-network-error-documentations-description',
              )}
            />
          </Col>
        </Row>
      );
    }
    if (createReadEditPageState.showAllPages === true) {
      return (
        <Fragment>
          <Row className="page-listing-title-search" justify="space-between">
            <Col className="search-page-field">
              <PageSearch />
            </Col>
          </Row>
          <Row className="pages-card-listing">{generatePagesCardListing()}</Row>
        </Fragment>
      );
    }

    if (createReadEditPageState.loadingSearchedPage) {
      return <Skeleton active />;
    }

    return showResultSearchList();
  };

  const showNumberOfTotalResults = () => {
    if (
      createReadEditPageState.pageObjectResponseSearch?.numberOfElements === 0
    ) {
      return `${t('documentation-total-zero-results')}`;
    }

    if (
      createReadEditPageState.pageObjectResponseSearch?.numberOfElements === 1
    ) {
      return `${
        createReadEditPageState.pageObjectResponseSearch?.numberOfElements
      }
      ${t('documentation-total-one-results')}`;
    }
    return `${
      createReadEditPageState.pageObjectResponseSearch?.numberOfElements
    } ${t('documentation-total-multiple-results')}`;
  };

  const showResultSearchList = () => {
    return (
      <Fragment>
        <Row className="listing-result" justify="space-between">
          <Col id="return-button">
            <ReturnArrow
              className="return-button"
              onClick={() =>
                dispatch(
                  CreateReadEditPageSlice.actions.BACK_TO_ALL_PAGES_LISTING(),
                )
              }
            />
          </Col>
          <Col span={6} pull={7} id="search-page-result-list">
            <Search
              className="search-button-results"
              name="search-button-results"
              placeholder={t('documentation-listing-search')}
              defaultValue={createReadEditPageState.searchPageTitle}
              onSearch={(x) => setTitleToRemakeSearchPage(x)}
            />
          </Col>
          <Col className="total-results">{showNumberOfTotalResults()}</Col>
        </Row>
        <Row className="page-card-results">
          <PageResult
            focusedWord={createReadEditPageState.searchPageTitle}
            goToBeadcrumb={goToSpecificPage}
            searchResult={createReadEditPageState.pageObjectResponseSearch}
          />
        </Row>
      </Fragment>
    );
  };

  return (
    <Col pull={1} span={22} push={1} id="pages-list-id">
      <div>
        <Header
          title={t('header-footer-documentations')}
          subtitle={t('pages-created-documentations-subtitle')}
          showEllipsis
        >
          <Row>{showButtonsIfLoggedIn()}</Row>
        </Header>
        {showListPagesOrResultSearch()}
      </div>
    </Col>
  );
};
