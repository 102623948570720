import { Col, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IngenicoWorld } from '../../components/IngenicoWorld/IngenicoWorld';
import { useScrollToTop } from '../../helpers/useScrollToTop/useScrollToTop';
import { AppDispatch, RootState } from '../../store/store';
import { asyncActions } from './ActivateUserByEmail.Slice';
import './ActivateUserByEmail.scss';

export const ActivateUserByEmail: React.FC = () => {
  useScrollToTop('active-email-concluded-container');

  const activeUserByEmail = useSelector(
    (state: RootState) => state.activateAccount,
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const tokenToValidate = window.location.href.match(/.{36}$/);
    if (tokenToValidate) {
      setTimeout(() => {
        dispatch(asyncActions.VALIDATEACCOUNT(tokenToValidate[0]));
      }, 4000);
    }
  }, [dispatch, activeUserByEmail.token]);

  useEffect(() => {
    if (!activeUserByEmail.showSpin) {
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    }
  });

  const showSpinOrSuccessMessage = () => {
    if (activeUserByEmail.showSpin) {
      return (
        <IngenicoWorld
          title={t('ingenico-world-request-loading-activate-account-response')}
        >
          <Row className="ConcludedActivateAccount">
            <Col span={13} className="spin-class">
              <Spin size="large" />{' '}
            </Col>
          </Row>
        </IngenicoWorld>
      );
    }

    return (
      <IngenicoWorld
        title={t('ingenico-world-request-activate-account-response')}
        showThanks
      >
        <Row className="ConcludedActivateAccount">
          <Col>
            <p className="concluded-activate-account-message">
              {t('request-access-activate-account-message')}
            </p>
          </Col>
        </Row>
      </IngenicoWorld>
    );
  };

  return (
    <Col push={1} span={22} pull={1} id="active-email-concluded-container">
      {showSpinOrSuccessMessage()}
    </Col>
  );
};
