import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { administrativePanelRequestsApi } from '../../../services/administrative-panel-requests.api';

export interface IRequests {
  requestCompanyResponse: IRequestCompanyResponse;
}

interface IRequestCompanyResponse {
  type: string;
  id: number | undefined;
  status: string;
  name: string;
  email: string;
  dateOfOrder: string;
  company: {
    id: number | undefined;
    name: string;
    address: string;
    country: {
      name: string;
    };
    identifier: string;
  };
}

const initialState: IRequests = {
  requestCompanyResponse: {
    type: '',
    id: undefined,
    status: '',
    name: '',
    email: '',
    dateOfOrder: '',
    company: {
      id: undefined,
      name: '',
      address: '',
      country: {
        name: '',
      },
      identifier: '',
    },
  },
};

export const asyncActions = {
  FIND_COMPANY_REQUEST: createAsyncThunk(
    'FIND_COMPANY_REQUEST',
    async (payload: any) => {
      const companyResponseData =
        await administrativePanelRequestsApi.getCompanySolicitation(payload);

      return {
        companyResponseData: companyResponseData.data,
      };
    },
  ),
};

export const RequestsSlice = createSlice({
  name: 'RequestsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => [
    builder.addCase(
      asyncActions.FIND_COMPANY_REQUEST.fulfilled,
      (state, action) => {
        const companyResponse = action.payload.companyResponseData;

        state.requestCompanyResponse.dateOfOrder = companyResponse.createdAt;
        state.requestCompanyResponse.email = companyResponse.email;
        state.requestCompanyResponse.status = companyResponse.status;
        state.requestCompanyResponse.id = companyResponse.id;
        state.requestCompanyResponse.company.identifier =
          companyResponse.company.identifier;
        state.requestCompanyResponse.company.country.name =
          companyResponse.company.country.name;
        state.requestCompanyResponse.company.address =
          companyResponse.company.address;
        state.requestCompanyResponse.company.name =
          companyResponse.company.name;
      },
    ),
  ],
});
