import React, { Fragment } from 'react';
import { Row, Col, Table, Divider, Tooltip, Spin } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table/interface';

import './PagingTableSearch.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AllowedViewIcon } from '../../assets/view-request.svg';
import { ReactComponent as UnallowedViewIcon } from '../../assets/view-request-unselected.svg';

import { ReactComponent as RedEditIcon } from '../../assets/edit_permissions_red.svg';
import { ReactComponent as GreenEditIcon } from '../../assets/edit_permissions_green.svg';

import { ReactComponent as RedViewIcon } from '../../assets/view_permissions_red.svg';
import { ReactComponent as GreenViewIcon } from '../../assets/view_permissions_green.svg';

import { ReactComponent as AllowedEditIcon } from '../../assets/edit_permissions_icon_selected.svg';
import { ReactComponent as UnallowedEditIcon } from '../../assets/edit_permissions_icon_unselected.svg';

import { ReactComponent as UnallowedBlueEditIcon } from '../../assets/edit_permissions_blue_unselected.svg';
import { ReactComponent as AllowedBlueEditIcon } from '../../assets/edit_permissions_blue_selected.svg';
import { ReactComponent as AllowedBlueViewIcon } from '../../assets/view_permissions_blue_selected.svg';
import { ReactComponent as UnallowedBlueViewIcon } from '../../assets/view_permissions_blue_unselected.svg';

import { InputWithSearch } from '../InputWithSearch/InputWithSearch';

interface IPagingTableSearch {
  isFirstTable: boolean;
  tableEntityLabel: string;
  onTableSearch: Function;
  searchInputValue: string;
  dataSource: any[];
  tableLoading: boolean;
  columns: IColumnCursor[];
  pageSize: number;
  pageIndex: number;
  totalPages?: number;
  numberOfItems: number;
  numberOfExtraItems: number;
  onChangePage: Function;
  onChangeSort: Function;
  rowClickAction: Function;
  emptyText: string;
  selectedRow?: number | undefined;
  disableTable?: boolean;
  defaultSortBehaviour?: IDefaultSortBehaviour;
  getIdFromRow?: (rowData: any) => number;
}

interface IColumnCursor {
  columnTitle: string;
  columnProperty: string | string[];
  className?: string;
  disableSort?: boolean;
  columnAction?: Function;
}

export interface IDefaultSortBehaviour {
  columnProperty: string;
  disableDefaultSortBehaviour: Function;
  order: 'ascend' | 'descend' | null | undefined;
}

export const PagingTableSearch: React.FC<IPagingTableSearch> = ({
  tableEntityLabel,
  onTableSearch,
  searchInputValue,
  dataSource,
  tableLoading,
  columns,
  pageSize,
  pageIndex,
  numberOfItems,
  numberOfExtraItems,
  onChangePage,
  onChangeSort,
  totalPages,
  rowClickAction,
  emptyText,
  isFirstTable,
  getIdFromRow = (rowData: any) => rowData.id,
  disableTable = false,
  selectedRow,
}: IPagingTableSearch) => {
  const readAndWriteColumnKeys = ['canRead', 'canWrite'];

  const { t } = useTranslation();

  const setupReadAndWriteCells = (
    columnProperty: 'canWrite' | 'canRead',
    columnValue: any,
  ) => {
    if (!columnValue.firstRow) {
      switch (columnProperty) {
        case 'canRead':
          if (columnValue[columnProperty] && columnValue.originalCanRead) {
            return <AllowedViewIcon className="allowed-view-edit-icon" />;
          }

          if (!columnValue[columnProperty] && columnValue.originalCanRead) {
            return <RedViewIcon className="allowed-view-edit-icon" />;
          }

          if (columnValue[columnProperty] && !columnValue.originalCanRead) {
            return <GreenViewIcon className="allowed-view-edit-icon" />;
          }

          return <UnallowedViewIcon className="unallowed-view-edit-icon" />;

        case 'canWrite':
          if (columnValue[columnProperty] && columnValue.originalCanWrite) {
            return <AllowedEditIcon className="allowed-view-edit-icon" />;
          }

          if (!columnValue[columnProperty] && columnValue.originalCanWrite) {
            return <RedEditIcon className="allowed-view-edit-icon" />;
          }

          if (columnValue[columnProperty] && !columnValue.originalCanWrite) {
            return <GreenEditIcon className="allowed-view-edit-icon" />;
          }

          return <UnallowedEditIcon className="unallowed-view-edit-icon" />;

        default:
          return;
      }
    }

    switch (columnProperty) {
      case 'canRead':
        if (columnValue[columnProperty]) {
          return (
            <Tooltip title={columnValue.permissionSelectedViewText}>
              <AllowedBlueViewIcon className="allowed-view-edit-blue-icon" />
            </Tooltip>
          );
        }

        return (
          <Tooltip title={columnValue.permissionUnselectedViewText}>
            <UnallowedBlueViewIcon className="unallowed-view-edit-blue-icon" />
          </Tooltip>
        );

      case 'canWrite':
        if (columnValue[columnProperty]) {
          return (
            <Tooltip title={columnValue.permissionSelectedEditText}>
              <AllowedBlueEditIcon className="allowed-view-edit-blue-icon" />
            </Tooltip>
          );
        }

        return (
          <Tooltip title={columnValue.permissionUnselectedEditText}>
            <UnallowedBlueEditIcon className="unallowed-view-edit-blue-icon" />
          </Tooltip>
        );

      default:
        break;
    }
  };

  const generateColumns = () => {
    let tableColumns: JSX.Element[] = [];

    tableColumns = columns.map((x) => {
      if (readAndWriteColumnKeys.includes(x.columnProperty as string)) {
        return (
          <Table.Column
            className="permissions-icon-table-column"
            title={x.columnTitle}
            key={x.columnProperty.toString()}
            align="center"
            showSorterTooltip={false}
            sorter
            onCell={(record: any) => {
              return {
                onClick: () =>
                  x.columnAction ? x.columnAction(record) : undefined,
              };
            }}
            render={(rowValue) => {
              return (
                <Row align="middle" justify="space-around">
                  <Col>
                    {setupReadAndWriteCells(
                      x.columnProperty as 'canRead' | 'canWrite',
                      rowValue,
                    )}
                  </Col>
                </Row>
              );
            }}
          />
        );
      }

      return (
        <Table.Column
          className={x.className}
          title={x.columnTitle}
          dataIndex={x.columnProperty}
          key={x.columnTitle.toLowerCase()}
          onCell={(record: any) => {
            return {
              onClick: () => rowClickAction(record),
            };
          }}
          sorter={x.disableSort ? !x.disableSort : true}
          ellipsis
          showSorterTooltip={false}
        />
      );
    });

    return tableColumns;
  };

  const totalElementsFooter = (total: any, range: any) => {
    if (isFirstTable || searchInputValue.length > 0) {
      return `${range[0]}-${range[1]} ${t('paging-table-pagination-of')} ${
        total + numberOfExtraItems
      }`;
    }
    if (pageIndex === 1 && searchInputValue.length <= 0) {
      return `${range[0]}-${range[1] - 1} ${t('paging-table-pagination-of')} ${
        total - numberOfExtraItems
      }`;
    }
    if (pageIndex === totalPages) {
      return `${pageIndex * (pageSize - 1) - (pageSize - 2)} - ${
        total - numberOfExtraItems
      } ${t('paging-table-pagination-of')} ${total - numberOfExtraItems}`;
    }
    return `${pageIndex * (pageSize - 1) - (pageSize - 2)} - ${
      pageIndex * (pageSize - 1)
    } ${t('paging-table-pagination-of')} ${total - numberOfExtraItems}`;
  };

  const paginationConfig: TablePaginationConfig = {
    total: numberOfItems,
    showTotal: (total, range) => {
      return totalElementsFooter(total, range);
    },
    current: pageIndex,
    defaultPageSize: pageSize,
    pageSizeOptions: [],
    position: ['bottomCenter'],
  };

  const sortHandler = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    onChangeSort(field, order);
  };

  const getSelectedRowStyleForClickedRow = (record: any) => {
    if (!selectedRow) {
      return '';
    }

    return record.id === selectedRow ? 'selected-row' : '';
  };

  const getTableActiveOrDisabled = () => {
    const tableWithSearchContent = (
      <Fragment>
        <Row>
          <Col span={24}>
            <Row
              align="middle"
              justify="space-between"
              className="table-label-and-search-row"
            >
              <Col className="table-entity-label" span={6}>
                {tableEntityLabel}
              </Col>
              <Col className="table-search-input-container">
                <InputWithSearch
                  placeholder=""
                  onSearch={onTableSearch}
                  value={searchInputValue}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              className={
                !isFirstTable && searchInputValue.length <= 0
                  ? 'tableWithFirstRow'
                  : undefined
              }
              onChange={(pagination, filters, sorter: any) => {
                const sorterField = sorter.field
                  ? sorter.field
                  : sorter.columnKey;

                sortHandler(sorterField, sorter.order);
                onChangePage(pagination.current);
              }}
              rowClassName={getSelectedRowStyleForClickedRow}
              locale={{ emptyText }}
              loading={tableLoading}
              dataSource={dataSource}
              pagination={paginationConfig}
              rowKey={(record) => getIdFromRow(record)}
            >
              {generateColumns()}
            </Table>
          </Col>
        </Row>
      </Fragment>
    );

    if (disableTable) {
      return (
        <Spin spinning wrapperClassName="disable-loading-icon">
          {tableWithSearchContent}
        </Spin>
      );
    }

    return tableWithSearchContent;
  };

  return (
    <Row className="PagingTableWithSearch">
      <Col span={24}>{getTableActiveOrDisabled()}</Col>
    </Row>
  );
};
