import { Col, Row, Form, Input, Divider, Button, Popover } from 'antd';
import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';
import { FormInstance } from 'antd/lib/form';
import { Header } from '../../components/Header/Header';
import { MyProfileSlice, asyncActionsMyProfile } from './MyProfile.Slice';
import { asyncActions as LoginAsyncActions } from '../Login/Login.Slice';
import './MyProfile.scss';
import { AppDispatch, RootState, store } from '../../store/store';
import { PasswordValidationPopOver } from '../../components/RequestAccess/PersonalDataForm/PasswordValidationPopOver/CardPasswordValidation';
import { showModal } from '../../helpers/showModal/showModal';
import { useScrollToTop } from '../../helpers/useScrollToTop/useScrollToTop';

export const MyProfile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { actions } = MyProfileSlice;
  const myProfileState = useSelector((state: RootState) => state.myProfile);
  const userFirstName = useSelector(
    (state: RootState) => state.login.user?.firstName,
  );
  const userLastName = useSelector(
    (state: RootState) => state.login.user?.lastName,
  );
  const formRefPersonalData = useRef(null);
  const formRefPassword = useRef(null);

  const { t } = useTranslation();

  useScrollToTop('my-profile-id');

  useEffect(() => {
    return () => {
      dispatch(actions.RESET_CHANGES_FORM());
    };
  }, [dispatch, actions]);

  useEffect(() => {
    if (myProfileState.refreshPersonalData) {
      dispatch(LoginAsyncActions.ME());
    }
    dispatch(actions.STOP_REFRESH_DATA());
  }, [dispatch, myProfileState.refreshPersonalData, actions]);

  const checkIfPasswordFieldIsValid = (password: string) => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;

    if (password) {
      const lowerCaseMatch = password.match(lowerCaseLetters);
      const upperCaseMatch = password.match(upperCaseLetters);
      const numberMatch = password.match(numbers);
      const lenMatch = password.length > 7;

      if (lowerCaseMatch) {
        dispatch(actions.SET_LOWER_CASE_VALIDATION('valid'));
      } else {
        dispatch(actions.SET_LOWER_CASE_VALIDATION('invalid'));
      }

      if (upperCaseMatch) {
        dispatch(actions.SET_UPPER_CASE_VALIDATION('valid'));
      } else {
        dispatch(actions.SET_UPPER_CASE_VALIDATION('invalid'));
      }

      if (numberMatch) {
        dispatch(actions.SET_NUMBER_VALIDATION('valid'));
      } else {
        dispatch(actions.SET_NUMBER_VALIDATION('invalid'));
      }

      if (lenMatch) {
        dispatch(actions.SET_LENGTH_VALIDATION('valid'));
      } else {
        dispatch(actions.SET_LENGTH_VALIDATION('invalid'));
      }

      if (lowerCaseMatch && upperCaseMatch && numberMatch && lenMatch) {
        return dispatch(actions.SET_PASSWORD_VALIDATION(true));
      }
    } else {
      dispatch(actions.SET_LOWER_CASE_VALIDATION('invalid'));
      dispatch(actions.SET_UPPER_CASE_VALIDATION('invalid'));
      dispatch(actions.SET_NUMBER_VALIDATION('invalid'));
      dispatch(actions.SET_LENGTH_VALIDATION('invalid'));
    }

    return false;
  };

  const submitPasswordChanges = (values: any) => {
    const passwordChangesBody = {
      oldPassword: values.originalPassword,
      password: values.newPassword,
      passwordConfirmation: values.confirmNewPassword,
    };

    dispatch(asyncActionsMyProfile.CHANGE_PASSWORD(passwordChangesBody));
  };

  const showDiscardChangesModalInChangePassword = () => {
    const formInstance = formRefPassword.current as unknown as FormInstance;
    if (formInstance) {
      const values = formInstance.getFieldsValue();
      if (
        values.originalPassword ||
        values.newPassword ||
        values.confirmNewPassword
      ) {
        return showModal(
          'warning',
          t('documentation-confirmation-cancel-editing-title'),
          t('documentation-confirmation-cancel-editing-content'),
          () => {
            dispatch(actions.SHOW_CHANGE_PASSWORD_FORM());
          },
        );
      }
      dispatch(actions.SHOW_CHANGE_PASSWORD_FORM());
    }
  };

  const savePersonalDataForm = () => {
    const formInstance = formRefPersonalData.current as unknown as FormInstance;
    if (formInstance) {
      const values = formInstance.getFieldsValue();
      if (values.name !== userFirstName || values.lastName !== userLastName) {
        dispatch(
          actions.SET_NAME_AND_LASTNAME_FOR_USER({
            editedName: values.name,
            editedLastName: values.lastName,
          }),
        );
      }
    }
  };

  const showChangePasswordAndSavePersonalDataForm = () => {
    savePersonalDataForm();
    dispatch(actions.SHOW_CHANGE_PASSWORD_FORM());
  };

  const showChangePasswordForm = () => {
    return (
      <Row justify="center" className="profile-password-form">
        <h2>{t('my-profile-change-password-section-title')}</h2>
        <Col span={24}>
          <Form
            layout="vertical"
            className="my-profile-change-password-form"
            onFinish={(values) => submitPasswordChanges(values)}
            ref={formRefPassword}
          >
            <Form.Item
              name="originalPassword"
              label={t('my-profile-original-password-label')}
              rules={[
                {
                  required: true,
                  message: t('my-profile-password-required'),
                },
              ]}
            >
              <Input.Password
                maxLength={64}
                placeholder={t('my-profile-original-password-label')}
              ></Input.Password>
            </Form.Item>
            <Form.Item
              name="newPassword"
              label={t('my-profile-new-password-label')}
              rules={[
                {
                  required: true,
                  message: ' ',
                },
                () => ({
                  validator(rule, newPassword) {
                    const passwordNonExists = !newPassword;
                    const passwordNotValid =
                      !checkIfPasswordFieldIsValid(newPassword);

                    if (passwordNonExists || passwordNotValid) {
                      return Promise.reject(
                        new Error(t('create-password-not-secured-password')),
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Row>
                <Col span={24}>
                  <Popover
                    id="passwordPopover"
                    content={
                      <PasswordValidationPopOver
                        styleLet={myProfileState.cardValidationStyleLowerCase}
                        styleCap={myProfileState.cardValidationStyleUpperCase}
                        styleNum={myProfileState.cardValidationStyleNumber}
                        styleLen={myProfileState.cardValidationStyleLength}
                      />
                    }
                    trigger={['click', 'focus']}
                    placement="right"
                  >
                    <Input.Password
                      maxLength={64}
                      placeholder={t('my-profile-new-password-label')}
                    ></Input.Password>
                  </Popover>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="confirmNewPassword"
              label={t('my-profile-confirm-new-password-label')}
              dependencies={['newPassword']}
              rules={[
                {
                  required: true,
                  message: t('create-password-confirm-required'),
                },
                ({ getFieldValue }) => ({
                  validator(rule, confirmNewPassword) {
                    if (
                      !confirmNewPassword ||
                      getFieldValue('newPassword') === confirmNewPassword
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t('create-password-observation-not-equal-passwords'),
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                maxLength={64}
                placeholder={t('my-profile-confirm-new-password-label')}
              ></Input.Password>
            </Form.Item>
            <Form.Item className="profile-form-buttons">
              <Row justify="end">
                <Col>
                  <Button
                    htmlType="button"
                    type="link"
                    shape="round"
                    onClick={() => showDiscardChangesModalInChangePassword()}
                  >
                    {t('my-profile-cancel-button')}
                  </Button>
                  <Button
                    htmlType="submit"
                    shape="round"
                    onClick={() => savePersonalDataForm()}
                  >
                    {t('my-profile-save-password-changes-button')}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  };

  const showButtonOrFormToChangePassword = () => {
    if (!myProfileState.showChangePasswordForm) {
      return (
        <Row justify="end">
          <Col>
            <Button
              type="primary"
              shape="round"
              onClick={() => showChangePasswordAndSavePersonalDataForm()}
            >
              {t('my-profile-show-change-password-form-button')}
            </Button>
          </Col>
        </Row>
      );
    }
    return showChangePasswordForm();
  };

  const submitNewUserName = (values: any) => {
    const newUserData = {
      firstName: values.name.trim(),
      lastName: values.lastName.trim(),
    };

    dispatch(asyncActionsMyProfile.SET_NEW_PERSONAL_DATA(newUserData));
  };

  const initialValues = {
    name: myProfileState.nameInForm ? myProfileState.nameInForm : userFirstName,
    lastName: myProfileState.lastNameInForm
      ? myProfileState.lastNameInForm
      : userLastName,
    email: store.getState().login.user?.email,
    company: store.getState().login.user?.company.name,
  };

  const showDiscardChangesModalInPersonalData = () => {
    const formInstance = formRefPersonalData.current as unknown as FormInstance;
    if (formInstance) {
      const values = formInstance.getFieldsValue();
      if (
        values.name !== initialValues.name ||
        values.lastName !== initialValues.lastName
      ) {
        showModal(
          'warning',
          t('documentation-confirmation-cancel-editing-title'),
          t('documentation-confirmation-cancel-editing-content'),
          () => {
            dispatch(actions.SHOW_EDIT_MODE_IN_PERSONAL_FORM());
          },
        );
      } else dispatch(actions.SHOW_EDIT_MODE_IN_PERSONAL_FORM());
    }
  };

  const generateFormKey = () => {
    if (initialValues) {
      return `${initialValues.toString()}${uniqid()}`;
    }
  };

  const showButtonsOnPersonalDataForm = () => {
    if (myProfileState.showEditModeInPersonalForm) {
      return (
        <Fragment>
          <Form.Item className="profile-form-buttons">
            <Row justify="end">
              <Col>
                <Button
                  htmlType="button"
                  type="link"
                  shape="round"
                  onClick={() => showDiscardChangesModalInPersonalData()}
                >
                  {t('my-profile-cancel-button')}
                </Button>
                <Button htmlType="submit" shape="round">
                  {t('my-profile-save-user-changes-button')}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Fragment>
      );
    }
    return (
      <Form.Item className="profile-form-buttons">
        <Row justify="end">
          <Col>
            <Button
              type="primary"
              shape="round"
              onClick={() =>
                dispatch(actions.SHOW_EDIT_MODE_IN_PERSONAL_FORM())
              }
            >
              {t('my-profile-change-personal-data-button')}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const generateMyProfileForm = () => {
    return (
      <Row className="profile-form-row" justify="center">
        <Col span={10}>
          <Row justify="center">
            <h2> {t('my-profile-personal-data-section-title')} </h2>
          </Row>
          <Form
            ref={formRefPersonalData}
            className="my-profile-form"
            layout="vertical"
            onFinish={(values) => submitNewUserName(values)}
            key={generateFormKey()}
            initialValues={initialValues}
          >
            <Form.Item name="email" label={t('my-profile-email-label')}>
              <Input
                className="disable-input"
                disabled
                bordered={false}
                placeholder={t('my-profile-email-label')}
              />
            </Form.Item>
            <Form.Item name="company" label={t('my-profile-company-label')}>
              <Input
                className="disable-input"
                disabled
                bordered={false}
                placeholder={t('my-profile-company-label')}
              />
            </Form.Item>
            <Form.Item
              name="name"
              label={t('my-profile-name-label')}
              rules={[
                {
                  required: myProfileState.showEditModeInPersonalForm,
                  message: t('my-profile-firstname-required'),
                },
              ]}
            >
              <Input
                placeholder={t('my-profile-name-label')}
                className="disable-input"
                maxLength={40}
                disabled={!myProfileState.showEditModeInPersonalForm}
                bordered={myProfileState.showEditModeInPersonalForm}
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              label={t('my-profile-lastname-label')}
              rules={[
                {
                  required: myProfileState.showEditModeInPersonalForm,
                  message: t('my-profile-lastname-required'),
                },
              ]}
            >
              <Input
                placeholder={t('my-profile-lastname-label')}
                className="disable-input"
                maxLength={40}
                disabled={!myProfileState.showEditModeInPersonalForm}
                bordered={myProfileState.showEditModeInPersonalForm}
              />
            </Form.Item>
            {showButtonsOnPersonalDataForm()}
            <Row>
              <Col span={24}>
                <Divider className="my-profile-divider-style" />
              </Col>
            </Row>
          </Form>
          {showButtonOrFormToChangePassword()}
        </Col>
      </Row>
    );
  };

  return (
    <Col pull={1} span={22} push={1} id="my-profile-id">
      <div>
        <Header
          title={t('my-profile-title')}
          subtitle={t('my-profile-subtitle')}
        >
          <Fragment />
        </Header>
        {generateMyProfileForm()}
      </div>
    </Col>
  );
};
