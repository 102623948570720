import React from 'react';
import { Form, Skeleton } from 'antd';
import { ReactQuillEditor } from '../../../../components/ReactQuill/ReactQuillEditor';

import { useDispatch } from 'react-redux';
import { CreateReadEditPageSlice } from '../CreateReadEditPage.Slice';

interface IPageReactQuillWrapper {
  pageMode: 'read' | 'create' | 'edit' | undefined;
  editorInitialValue: string;
  loading: boolean;
}

export const PageReactQuillWrapper: React.FC<IPageReactQuillWrapper> = ({
  pageMode,
  editorInitialValue,
  loading,
}: IPageReactQuillWrapper) => {
  const dispatch = useDispatch();

  const createEditOrReadModeBorder = () => {
    if (pageMode === 'read') {
      return 'content-read-only';
    }
  };

  const getReactQuillEditorOrLoading = () => {
    if (loading) {
      return <Skeleton active />;
    }

    return (
      <ReactQuillEditor
        initialValue={editorInitialValue}
        readonly={pageMode === 'read'}
        canUploadImage={true}
        uploadImageAsFile={true}
        onChangeHandler={(content) => {
          dispatch(CreateReadEditPageSlice.actions.EDITOR_ON_CHANGE(content));
        }}
      />
    );
  };

  return (
    <Form.Item name="editorContent" className={createEditOrReadModeBorder()}>
      {getReactQuillEditorOrLoading()}
    </Form.Item>
  );
};
