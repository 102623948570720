import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col } from 'antd';

import { useTranslation } from 'react-i18next';
import { IngenicoWorld } from '../../components/IngenicoWorld/IngenicoWorld';
import { ForgotPasswordForm } from '../../components/ForgotPasswordForm/ForgotPasswordForm';
import { AppDispatch, RootState } from '../../store/store';
import { IForgotPasswordRequest } from '../../interfaces/services-interfaces/IForgotPasswordRequest';
import { ForgotPasswordSlice, asyncActions } from './ForgotPassword.Slice';

export const ForgotPassword: React.FC = () => {
  const forgotPasswordState = useSelector(
    (state: RootState) => state.forgotPassword,
  );
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { actions } = ForgotPasswordSlice;

  useEffect(() => {
    if (forgotPasswordState.concluded) {
      navigate('/forgot-password/concluded');
    }

    return () => {
      dispatch(actions.RESET_INITIAL_STATE());
    };
  }, [actions, dispatch, history, forgotPasswordState.concluded]);

  const submitForgotPassword = (forgotPasswordBody: IForgotPasswordRequest) => {
    dispatch(asyncActions.FORGOTPASSWORD(forgotPasswordBody));
  };

  const goBackToLogin = () => {
    navigate('/login');
  };

  return (
    <Col pull={1} span={22} push={1}>
      <IngenicoWorld title={t('forgot-password-header')}>
        <ForgotPasswordForm
          goBackToLogin={goBackToLogin}
          submitForgotPassword={submitForgotPassword}
          forgotPasswordLoading={forgotPasswordState.forgotPasswordLoading}
        />
      </IngenicoWorld>
    </Col>
  );
};
