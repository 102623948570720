import { showModal } from '../showModal/showModal';
import { unhookedTranslation } from '../unhookedTranslation/unhookedTranslation';
// import { asyncActions } from '../../pages/AdministrativePanel/Users/Users.Slice'
// import { store } from '../../store/store'

export const showEnableOrDisableNewsConfirmationModal = (
  id: number,
  rowItem: any,
) => {
  if (!rowItem.status) {
    showModal(
      'warning',
      `${unhookedTranslation(
        'administrative-panel-enable-news-warning-title',
      )}`,
      `${unhookedTranslation(
        'administrative-panel-enable-news-warning-content',
      )}`,
      () => {},
      () => {},
      `${unhookedTranslation('administrative-panel-enable-news-okText')}`,
      `${unhookedTranslation('administrative-panel-enable-news-cancelText')}`,
    );
  } else {
    showModal(
      'warning',
      `${unhookedTranslation(
        'administrative-panel-disable-news-warning-title',
      )}`,
      `${unhookedTranslation(
        'administrative-panel-disable-news-warning-content',
      )}`,
      () => {},
      () => {},
      `${unhookedTranslation('administrative-panel-disable-news-okText')}`,
      `${unhookedTranslation('administrative-panel-disable-news-cancelText')}`,
    );
  }
};
