import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useScrollToTop } from '../../helpers/useScrollToTop/useScrollToTop';
import { CarouselHeader } from './Carousel/CarouselHeader';
import { Solution } from './Solution/Solution';
import './Home.scss';
import { AppDispatch, RootState, store } from '../../store/store';
import { carouselAsyncActions } from './Carousel/Carousel.Slice';
import { activeModalAsyncActions, ActiveModalSlice } from './ActiveModal.Slice';
import { onStartMessage } from '../../helpers/onStartMessage/onStartMessage';
import { activeModal } from '../../components/ActiveModal/ActiveModal';
import { getEditorToPreview } from '../../helpers/getEditorToPreview/getEditorToPreview';

export const Home: React.FC = () => {
  const loadedActivatedNewsState = useSelector(
    (state: RootState) => state.carousel.loadedActivatedNews,
  );

  const { modal, isContentLoaded, isVisible, isClosed } = useSelector(
    (state: RootState) => state.activeModal,
  );

  useScrollToTop('home-id');

  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(ActiveModalSlice.actions.RESET_TO_INITIAL_STATE());
    };
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(ActiveModalSlice.actions.SET_LANGUAGE(navbarState))
  // }, [dispatch, language, navbarState])

  useEffect(() => {
    const storedOnStartMessage = localStorage.getItem('on-start-message');

    // Condition to valuate if BE is out, if it is, we don't dispatch the news get to the carousel
    // This condition prevents infinite looping of the application when BE is out
    if (
      !loadedActivatedNewsState &&
      !window.location.href.includes('/home?networkRedirector=true')
    ) {
      dispatch(carouselAsyncActions.GET_ACTIVATED_NEWS_FOR_CAROUSEL());
      onStartMessage(storedOnStartMessage);
    }

    // Now we are validating if BE is online again, if so, we take the user to the home page
    else if (
      loadedActivatedNewsState &&
      window.location.href.includes('/home?networkRedirector=true')
    ) {
      navigate('/home');
    }

    // This case happens when the user is on the home page and he reloads the page, and then we try to communicate with the BE again
    else if (!storedOnStartMessage) {
      dispatch(carouselAsyncActions.GET_ACTIVATED_NEWS_FOR_CAROUSEL());
    }
  }, [dispatch, loadedActivatedNewsState, history]);

  useEffect(() => {
    if (loadedActivatedNewsState && !isContentLoaded) {
      dispatch(activeModalAsyncActions.GET_MODAL());
    }
  }, [dispatch, loadedActivatedNewsState, isContentLoaded]);

  useEffect(() => {
    if (!isContentLoaded || isVisible) {
      return;
    }

    if (modal.active && !isClosed) {
      setTimeout(() => {
        const navbarLanguage = store.getState().navBar.defaultLanguage;

        let filterBylanguage = '';

        switch (navbarLanguage) {
          case 'pt':
            filterBylanguage = 'PORTUGUESE';
            break;

          case 'es':
            filterBylanguage = 'SPANISH';
            break;

          case 'en':
            filterBylanguage = 'ENGLISH';
            break;

          default:
            break;
        }

        const contentsByNavbarLanguage = modal.contents.filter(
          (x) => x.language === filterBylanguage,
        );

        const okText = `${t('home-page-ok-text-modal')}`;

        activeModal(
          modal.type,
          contentsByNavbarLanguage[0].title,
          contentsByNavbarLanguage[0].description,
          () => {
            dispatch(ActiveModalSlice.actions.CLOSE_MODAL());
          },
          isVisible,
          undefined,
          okText,
        );
      }, 500);

      dispatch(ActiveModalSlice.actions.OPEN_MODAL());
    }
  }, [dispatch, t, isVisible, isClosed, isContentLoaded, modal]);

  return (
    <>
      <Col span={24} id="home-id">
        <div>
          <div className="HomePage">
            <CarouselHeader />
            <Col push={1} span={22} pull={1}>
              <div className="home-page-card-solutions">
                <h2 className="home-page-label-our-services">
                  {t('home-page-products-and-services')}
                </h2>
                <Solution />
              </div>
            </Col>
          </div>
        </div>
      </Col>
      {getEditorToPreview()}
    </>
  );
};
