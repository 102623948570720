import React, { useEffect, useState } from 'react';
import { RedocStandalone } from 'redoc';
import queryString from 'query-string';
import axios from 'axios';
import { Col, Result, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

export const RedocAPI = () => {
  const { t } = useTranslation();
  const [redocSpecification, setRedocSpecification] = useState();
  const [errorRetrievingApiInfo, setErrorRetrievingApiInfo] = useState(false);

  const parsedUrl = queryString.parse(window.location.search);
  const apiurl = parsedUrl.apiurl as string;

  useEffect(() => {
    axios
      .get(apiurl)
      .then((x) => {
        setRedocSpecification(x.data);
      })
      .catch(() => {
        setErrorRetrievingApiInfo(true);
      });
  }, [setRedocSpecification, apiurl, setErrorRetrievingApiInfo]);

  const returnRedocIfSpecificationIsLoaded = () => {
    if (errorRetrievingApiInfo) {
      return (
        <Result
          status="404"
          title={t('swagger-redoc-error-title')}
          subTitle={t('swagger-redoc-error-subtitle')}
        />
      );
    }

    if (!redocSpecification) {
      return (
        <Row style={{ width: '100vw', height: '100vh' }}>
          <Col
            span={24}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spin size="large" />
          </Col>
        </Row>
      );
    }

    return (
      <RedocStandalone
        spec={redocSpecification}
        options={{
          nativeScrollbars: true,
        }}
        onLoaded={(error) => {
          if (error) {
            setErrorRetrievingApiInfo(true);
          }
        }}
      />
    );
  };

  return returnRedocIfSpecificationIsLoaded();
};
